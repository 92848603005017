import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, map, Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {IResponse} from '../shared/models/IResponse.model';
import {ToastService} from './toast.service';
import {IBasicPermissionsModel} from "../shared/models/basic-permissions.model";
import {IBasicPermissionApplicationModel} from "../shared/models/basic-permission-application.model";
import {IPermissionSaveModel} from "../shared/models/permission-save.model";

@Injectable({
  providedIn: 'root'
})
export class BasicPermissionsService {


  constructor(private httpClient: HttpClient, private toastService: ToastService) {
  }

  fetchBasicPermissions(filter: Partial<IBasicPermissionsModel> = {isActive: true}): Observable<IBasicPermissionsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}basic-permissions/search`, filter, {}).pipe(
      map((item: IResponse<IBasicPermissionsModel[]>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
    );
  }

  savePermissions(basicPermission: IPermissionSaveModel): Observable<boolean> {
    const obs = this.httpClient.post(`${environment.api_base_url}basic-permissions/save`, basicPermission, {})
    return obs.pipe(
      map((item: IResponse<boolean>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item.data;
      }),
      catchError((error) => {
        this.toastService.showError("Error saving Permission");
        return of(false);
      })
    );
  }

  deletePermissions(data: IPermissionSaveModel): Observable<boolean> {
    return this.httpClient.post(`${environment.api_base_url}basic-permissions/delete`, data).pipe(
      map((item: IResponse<boolean>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        let message = 'Error deleting Permission(s)';
        if (error instanceof HttpErrorResponse) {
          const response = error.error as IResponse<any>;
          if (response?.message) {
            message = response.message;
          }
        }
        this.toastService.showError(message);
        return of(false);
      })
    );
  }

  deleteBasicPermission(basicPermissionId: number): Observable<boolean> {
    return this.httpClient.delete(`${environment.api_base_url}basic-permissions/${basicPermissionId}`, {}).pipe(
      map((item: IResponse<boolean>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        let message = 'Error deleting Permission';
        if (error instanceof HttpErrorResponse) {
          const response = error.error as IResponse<any>;
          if (response?.message) {
            message = response.message;
          }
        }
        this.toastService.showError(message);
        return of(false);
      })
    );
  }

  fetchBasicPermissionsApplication(filter: Partial<IBasicPermissionApplicationModel> = {isActive: true}): Observable<IBasicPermissionApplicationModel[]> {
    return this.httpClient.post(`${environment.api_base_url}basic-permission-application/search`, filter, {}).pipe(
      map((item: IResponse<IBasicPermissionApplicationModel[]>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
    );
  }

  saveBasicPermissionsApplication(basicPermissionApplication: IBasicPermissionApplicationModel): Observable<IBasicPermissionApplicationModel> {
    let obs: Observable<IResponse<IBasicPermissionApplicationModel>>;
    if (basicPermissionApplication.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}basic-permission-application/${basicPermissionApplication.id}`, basicPermissionApplication, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}basic-permission-application/create`, basicPermissionApplication, {})
    }
    return obs.pipe(
      map((item: IResponse<IBasicPermissionApplicationModel>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving Permission");
        return of({});
      })
    );
  }

  deleteBasicPermissionApplication(basicPermissionApplicationId: number): Observable<boolean> {
    return this.httpClient.delete(`${environment.api_base_url}basic-permission-application/${basicPermissionApplicationId}`, {}).pipe(
      map((item: IResponse<boolean>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error) => {
        let message = 'Error deleting Permission';
        if (error instanceof HttpErrorResponse) {
          const response = error.error as IResponse<any>;
          if (response?.message) {
            message = response.message;
          }
        }
        this.toastService.showError(message);
        return of(false);
      })
    );
  }
}
