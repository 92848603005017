import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CameraModalComponent } from '../../../camera-modal/camera-modal.component';

@Component({
  selector: 'app-camera-feed',
  templateUrl: './camera-feed.component.html',
  styleUrls: ['./camera-feed.component.scss']
})
export class CameraFeedComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {

  }

  openFullscreen(ifr: HTMLIFrameElement) {
    ifr.requestFullscreen({
      navigationUI: 'auto',
    });
    // this.modalService.open(CameraModalComponent, {
    //   size: 'xxl',
    //   animation: true
    // });
  }
}
