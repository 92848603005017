<div class="modal-header">
  <span class="modal-title">{{ flightServiceType?.id ? 'Edit Flight Service Type' : 'Add Flight Service Type' }}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="edit-flight-service-type-close-button">
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="form">
    <form [formGroup]="form">
      <div class="flex flex-column">
        <div class="flex justify-content-evenly">
          <div class="col-4 title">
            Code
          </div>
          <div class="col-4 title">
            Description
          </div>
        </div>

        <div class="flex justify-content-evenly">
          <div class="col-4 form-field-header">
            <input type="text" class="form-control" id="settings-passenger-class-code" placeholder="" formControlName="code" data-test="edit-flight-service-type-code-input">
          </div>
          <div class="col-4 form-field-header">
            <input type="text" class="form-control" id="settings-passenger-class-description" placeholder="" formControlName="description" data-test="edit-flight-service-type-description-input">
          </div>
        </div>

      </div>
    </form>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()" data-test="edit-flight-service-type-cancel-button">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="form?.disabled || form?.invalid || isBusy" data-test="edit-flight-service-type-save-button">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
