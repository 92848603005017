import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../../../services/toast.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IGenericContainerObject } from '../../../../../shared/models/genericContainerObject.model';
import { DefinedGses } from '../../../../../shared/constants/defined-gses';
import { SlaGseFunctionService } from '../../../../../services/functions/sla-gse-function.service';
import { IProcessesModel } from '../../../../../shared/models/processes.model';
import { IGsesModel } from '../../../../../shared/models/gses.model';
import { IAirport } from '../../../../../shared/models/airport.model';
import { IAcType } from '../../../../../shared/models/ac-type.model';
import { AddEditSlaGseDialogComponent } from '../add-edit-sla-gse-dialog/add-edit-sla-gse-dialog.component';
import { take } from 'rxjs/operators';
import { GseService } from '../../../../../services/gse.service';
import { DeleteModalComponent } from '../../../../../shared/components/delete-modal/delete-modal.component';

@Component({
  selector: 'app-sla-gse-level-airport-dialog',
  templateUrl: './sla-gse-level-airport-dialog.component.html',
  styleUrls: ['./sla-gse-level-airport-dialog.component.scss']
})
export class SlaGseLevelAirportDialogComponent implements OnInit {

  @Input() title?: string;
  isNew?: boolean;
  process?: IProcessesModel;
  gseList?: IGenericContainerObject<IGsesModel>;
  filteredGseList?: IGenericContainerObject<IGsesModel>;
  formGroup?: UntypedFormGroup;
  gseKVPairs = DefinedGses.IGENERIC_CONTAINER_OBJECT;

  acTypeId?: number;
  airportId?: number;
  airportList?: IGenericContainerObject<IAirport>;
  acTypeList?: IGenericContainerObject<IAcType>;

  constructor(private fb: UntypedFormBuilder, private modalService: NgbModal, private slaGseFunctionService: SlaGseFunctionService, private activeModal: NgbActiveModal, private gseService: GseService, private toastService: ToastService) {
  }

  ngOnInit() {

    this.filteredGseList = {};
    for (const gse of Object.values(this.gseList)) {
      if(gse.airportId === this.airportId && gse.acTypeId === this.acTypeId
        || this.airportId === 0 && !gse.airportId && this.acTypeId === 0 && !gse.acTypeId
        || this.airportId === 0 && !gse.airportId && gse.acTypeId === this.acTypeId
        || gse.airportId === this.airportId && !gse.acTypeId && this.acTypeId === 0) {
        this.filteredGseList[gse.id] = gse;
      }
    }

    this.formGroup = this.fb.group({
      airportName: [this.airportList[this.airportId]?.title, Validators.required]
    });
  }

  addGse() {
    const acTypeText = !this.acTypeId ? 'Default A/C Types' : this.acTypeList[this.acTypeId]?.alias;
    const airportText = !this.airportId ? 'Default A/C Types' : this.airportList[this.airportId]?.iata;

    const modalRef = this.modalService.open(AddEditSlaGseDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as AddEditSlaGseDialogComponent;
    modal.isNew = true;
    modal.process = this.process;
    modal.airportId = this.airportId;
    modal.acTypeId = this.acTypeId;
    modal.title = `Add SLA / GSE ${this.process?.title} / ${acTypeText} / ${airportText} / new GSE`;
    modalRef.dismissed.pipe((take(1))).subscribe(() => {
      this.updateList();
    });
  }

  editGse(gseId?: number) {
    if (!gseId || !this.filteredGseList || !this.filteredGseList[gseId]) {
      console.log(`gse with ID ${gseId} was not found! `, this.filteredGseList);
      return;
    }


    const acTypeText = !this.acTypeId ? 'Default A/C Types' : this.acTypeList[this.acTypeId]?.alias;
    const airportText = !this.airportId ? 'Default A/C Types' : this.airportList[this.airportId]?.iata;

    const modalRef = this.modalService.open(AddEditSlaGseDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as AddEditSlaGseDialogComponent;
    modal.title = `Edit SLA / GSE ${this.process.id} / ${acTypeText} / ${airportText} / ${gseId}`;
    modal.isNew = false;
    modal.acTypeId = this.acTypeId;
    modal.airportId = this.airportId;
    modal.gse = this.gseList[gseId];

    modalRef.dismissed.pipe((take(1))).subscribe(() => {
      this.updateList();
    });
    console.log('edit ac type clicked for airport name: ', gseId);
    return;
  }

  deleteItem(gseId?: number) {
    if (!this.process || !gseId || this.gseList[gseId].title === 'none') {
      console.log('cannot delete gse because one or more args are empty');
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'GSE ' + this.gseList[gseId].title;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      // this.gseService.deleteGses(gseId).subscribe((result) => {
      //   if(result) {
      //     delete this.gseList[gseId];
      //     delete this.filteredGseList[gseId];
      //   }
      // });
      this.gseService.deleteGses(this.process.id, this.acTypeId, this.airportId, gseId).subscribe((result) => {
        if(result) {
          this.updateList();
        }
      });
    });
  }

  saveButtonClicked() {
    if (this.isNew && this.formGroup?.invalid) {
      this.formGroup?.markAsTouched();
      return;
    } else if (this.isNew && this.formGroup?.valid) {
      this.saveWithDefaultValues();
    }
  }

  saveWithDefaultValues() {
    if (!this.process || !this.formGroup?.value?.airportName) {
      console.log('cannot update gse because one or more args are empty');
      return;
    }

    this.gseService.saveAirportException({
      isSla: true,
      acTypeId: this.acTypeId === 0 ? null : this.acTypeId,
      airportId: +this.formGroup?.value?.airportName,
      processId: this.process?.id,
      gseIdentifier: '',
      isNone: true,
      title: 'none',
      validFrom: new Date(),
      validTo: null,
      maximumProcessTimeInMinutes: 0,
    }).subscribe((result) => {
      if(result) {
        this.toastService.showSuccess('Airport exception has been saved');
        this.airportId = +this.formGroup?.value?.airportName;
        this.isNew = false;
        this.gseList[result.id] = result;
        this.updateList();
      }
    });
  }

  updateList()
  {
    for (const prop in this.gseList) {
      delete this.gseList[prop];
    }
    this.gseService.fetchGses({ processId: this.process?.id, isActive: true }).subscribe((result) => {
      for (const gse of result) {
        this.gseList[gse.id] = gse;
      }
      this.filteredGseList = {};
      for (const gse of Object.values(this.gseList)) {
        if(gse.airportId === this.airportId && gse.acTypeId === this.acTypeId
          || this.airportId === 0 && !gse.airportId && this.acTypeId === 0 && !gse.acTypeId
          || this.airportId === 0 && !gse.airportId && gse.acTypeId === this.acTypeId
          || gse.airportId === this.airportId && !gse.acTypeId && this.acTypeId === 0) {
          this.filteredGseList[gse.id] = gse;
        }
      }
      console.log('filtered gse list:', this.filteredGseList);
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    if (this.isNew && this.formGroup?.invalid) {
      this.formGroup?.markAsTouched();
      return;
    } else if (this.isNew && this.formGroup?.valid) {
      this.saveWithDefaultValues();
    }
  }
}
