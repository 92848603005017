<div class="position-relative h-100" *ngIf="pageAccess === null">
  <app-access-denied></app-access-denied>
</div>
<ng-container *ngIf="form && alertType">
  <app-settings-header [backButton]="true" backButtonUrl=".." [text]="'Edit Alert Type ' + alertType.description"
                       [showSaveButton]="true" [textSize]="20" (onSaveClicked)="onSaveClick()"
                       [saveButtonDisabled]="form.invalid || pageAccess !== Access.RW"
                       [spinner]="isBusy"></app-settings-header>
  <div class="settings-container mt-3 flex flex-column avitium-scrollbar">
    <app-settings-card sectionTitle="Settings" [formGroup]="form" data-test="general-card">
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="name" class="form-label">Alert Name</label>
          <input type="text" class="form-control" id="name" formControlName="name" data-test="settings-alert-name">
        </div>
        <div class="flex-basis-30">
          <label for="severity" class="form-label">Severity</label>
          <ng-select formControlName="severity" id="severity"
                     [items]="['LOW','INTERMEDIATE','CRITICAL']"
                     [clearable]="false" [searchable]="false">
          </ng-select>
        </div>
        <div class="flex-basis-30">
          <ng-container *ngIf="alertSettings?.dynamicMinutesAmount1">
            <label for="minutes1" class="form-label" [ngbTooltip]="alertSettings?.dynamicMinutesAmount1Description">
              Time difference (minutes)
              <svg *ngIf="alertSettings?.dynamicMinutesAmount1Description" xmlns="http://www.w3.org/2000/svg" width="14"
                   height="14" class="bi bi-info-circle-fill" fill="#4285F6" viewBox="0 0 16 16">
                <path
                  d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
              </svg>
            </label>
            <input type="number" class="form-control" id="minutes1" formControlName="minutes1"
                   data-test="settings-alert-minutes">
          </ng-container>
        </div>
      </div>

    </app-settings-card>


  </div>
</ng-container>
