import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteFormTypes} from '../../constants/deleteFormTypes.constants';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  @Input() term: string;
  @Input() formType: DeleteFormTypes;
  @Input() data?: any;
  @Input() title?: string;
  @Output() startDeletion = new EventEmitter();

  eventsSubject: Subject<void> = new Subject<void>();

  formTypes = DeleteFormTypes;

  isDeleteButtonDisabled: boolean;

  interruptDeletion: boolean;

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (this.formType === this.formTypes.DELETE_MEMO_FORM) {
      this.interruptDeletion = true;
    }
  }

  delete() {
    if (!this.interruptDeletion) {
      this.startDeletion.emit();
    }
    this.eventsSubject.next(null);
    this.eventsSubject.complete();
    this.closeModal('deletion done');
  }

  closeModal(reason: string) {
    this.activeModal.dismiss(reason);
  }

  nonClosingDeleteAction(data: any) {
    this.startDeletion.emit(data);
  }

  formStatusChanged(status: any) {
    if ('INVALID' === status && !this.isDeleteButtonDisabled) {
      this.isDeleteButtonDisabled = true;
    } else if ('VALID' === status && this.isDeleteButtonDisabled) {
      this.isDeleteButtonDisabled = false;
    }
  }

}
