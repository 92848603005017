<div class="dp-hidden position-absolute">
  <div class="input-group">
    <input name="datepicker"
           class="form-control"
           ngbDatepicker
           #datepicker="ngbDatepicker"
           [autoClose]="'outside'"
           (dateSelect)="onDateSelection($event)"
           [displayMonths]="2"
           [dayTemplate]="t"
           outsideDays="hidden"
           [startDate]="fromDate!"
           tabindex="-1" id="hidden-dp">
  </div>
</div>
<div class="input-group flex">
  <input
         class="form-control blue-border" placeholder="{{ dateFormat }} - {{ dateFormat }}"
         name="dpTextInput"
         [value]="toDate !== null ? formatter.format(fromDate).concat(' - ').concat(formatter.format(toDate)) : formatter.format(fromDate).concat(' - ').concat(formatter.format(fromDate)) "
          readonly data-test="date-range-input">
  <button class="btn btn-outline-secondary demo-icon icon-icons8-calendar" (click)="datepicker.toggle()" type="button" data-test="date-range-calendar-button"></button>
</div>

<ng-template #t let-date let-focused="focused">
    <span class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null">
      {{ date.day }}
    </span>
</ng-template>
