<div class="position-relative h-100" *ngIf="pageAccess === null">
  <app-access-denied></app-access-denied>
</div>
<app-settings-header text="Alert Management" [showSaveButton]="false" [textSize]="20"></app-settings-header>
<app-settings-card sectionTitle="Alerts">
  <app-settings-data-table [testTagPipe]="AlertsTestTagPipe" [data]="alertTypes" class="mt-3"
                           [columns]="['Active', 'Name', 'Severity']"
                           [variableNames]="['isActive', 'description','severity']" (onEditClicked)="doEdit($event)"
                           [showDelete]="false" [viewOnlyMode]="pageAccess === Access.R">
    <ng-template propName="isActive" let-value>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ff0000" class="bi bi-x-circle-fill"
           viewBox="0 0 16 16" *ngIf="!value">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00ff00" class="bi bi-check-circle-fill"
           viewBox="0 0 16 16" *ngIf="value">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
      </svg>
    </ng-template>
  </app-settings-data-table>
</app-settings-card>
