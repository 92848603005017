import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ISimpleData } from '../../../../../../shared/models/simpleData.model';
import { IAirlineDesignator } from '../../../../../../shared/models/airline-designator.model';

@Component({
  selector: 'app-seat-configuration',
  templateUrl: './seat-configuration.component.html',
  styleUrls: ['./seat-configuration.component.scss']
})
export class SeatConfigurationComponent {

  @Input() form: UntypedFormGroup;
  @Input() passengerClasses: ISimpleData[];
  @Input() airlineDesignators: IAirlineDesignator[];
  constructor() { }
}
