<div class="item dark-blue" *ngIf="leg" [ngClass]="{ active: isSelected }" (click)="itemClicked.emit()">
  <div
    class="align-self-center">{{ leg.airlineDesignator }}{{ leg.flightNumber }} {{ leg.departureStation }} {{ leg.std | date:'HH:mm':'+0000' }}
    - {{ leg.arrivalStation }} {{ leg.sta | date:'HH:mm':'+0000' }}
    | {{ leg.acType }} {{ leg.eta ? '| ETA: ' + (leg.eta | date: 'HH:mm':"+0000") : '' }}
    <ng-container *ngIf="assignedLegData">
      | {{ assignedLegData?.userModel?.firstname }} {{ assignedLegData?.userModel?.lastname }}
    </ng-container>

    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00ff00"
         class="bi bi-check-circle-fill float-end" viewBox="0 0 16 16" *ngIf="assignedLegData && !deleteInProgress"
         (click)="unassignedClicked.emit(assignedLegData.id); $event.stopPropagation()">
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
    </svg>
    <app-loading-spinner color="#4285F6" class="ms-1 float-end" *ngIf="deleteInProgress"></app-loading-spinner>
  </div>
</div>
