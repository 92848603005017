import {Pipe, PipeTransform} from '@angular/core';
import {IAlertsModel} from "../models/alerts.model";
import {AlertService} from "../../services/alert.service";
import {AlertConstants} from "../constants/alert.constants";
import {AlertTypeDescriptionPipe} from "./alert-type-description.pipe";

@Pipe({
  name: 'alertDescription'
})
export class AlertDescriptionPipe implements PipeTransform {
  constructor(private alertService: AlertService, private alertTypePipe: AlertTypeDescriptionPipe) {
  }

  transform(value: IAlertsModel, ...args: unknown[]): unknown {
    if (!value) {
      return '';
    }
    switch (value.alertTypeId) {
      case AlertConstants.STR_TO_ID.LATE_ONGOING_PROCESS:
        return `Late Ongoing Process (${value.remarks ?? ''})`
      default:
        return this.alertService.alertTypes[value.alertTypeId]?.description;
    }
  }

}
