import { Pipe, PipeTransform } from '@angular/core';
import { ISeatingConfigurationModel } from '../models/seating-configuration.model';

@Pipe({
  name: 'seatingConfigurationsTestTag'
})
export class SeatingConfigurationsTestTagPipe implements PipeTransform {

  transform(value: ISeatingConfigurationModel, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    if (value?.acRegistrationId) {
      return `seating-configuration-ac-reg-${value?.acRegistrationId}`;
    }
    if (value?.acTypeId) {
      return `seating-configuration-ac-type-${value?.acTypeId}`;
    }
    return `seating-configuration-${value?.code}`;
  }

}
