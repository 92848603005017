<div *ngIf="isLoadingTop" class="text-center mt-3 pt-3">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="leg-container">
<ng-container *ngFor="let pairDetail of visibleFlights; trackBy: pairTrackById">
  <div class="mx-3">
    <app-leg *ngIf="pairDetail && pairDetail.id"
             [flightPairKey]="pairDetail.id.toString()"
             [pairDetail]="pairDetail"
             [passengerClasses]="passengerClasses"
             [timezone]="timezone"
             [isActive]="pairDetail.id.toString() === activePairIndex"
             [userRole]="userRole"
             (mainComponentClicked)="doSetPairIndex(pairDetail.id.toString())"
             (setPairDetail)="setSelectedPair($event)"
             [isFirestore]="true"
             [attr.data-test]="pairDetail | pairTestAttribute"
             [ngClass]="{ 'has-etd-request': pairDetail?.turnaround?.lastExtendEtdRequest && !pairDetail?.turnaround?.lastExtendEtdRequest?.answerUser, 'ta-in-progress': pairDetail?.turnaround?.currentState === 'IN_PROGRESS' }"
    ></app-leg>
  </div>
</ng-container>
</div>

<div *ngIf="filteredFlights && filteredFlights.length > 0" class="filter-container mx-3 flex flex-row justify-content-around">
<!--<div *ngIf="pairDetails && pairDetails.length > 0" class="filter-container mx-3 flex flex-row justify-content-around">-->
<!--  <button class="btn btn-outline-dark flex-basis-20 d-flex align-items-center justify-content-around"-->
<!--          (click)="paginateBackward()" [ngClass]="{ 'hidden': this.flightPairService.pageNumber === 1 }">-->

<!--    <svg class="bi bi-caret-left-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"-->
<!--         xmlns="http://www.w3.org/2000/svg">-->
<!--      <path-->
<!--        d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 00-1.659-.753l-5.48 4.796a1 1 0 000 1.506z"/>-->
<!--    </svg>-->

<!--    Previous Flights-->
<!--  </button>-->

  <app-pagination-button text="Previous Flights" [disabled]="testPage === 1" (onClick)="paginateBackward()" data-test="paginate-previous"></app-pagination-button>
  <app-pagination-button text="Next Flights" [disabled]="filteredFlights.length < (testPage*20)" (onClick)="paginateForward()" data-test="paginate-next"></app-pagination-button>

<!--  <button class="btn btn-outline-dark flex-basis-20 d-flex align-items-center justify-content-around"-->
<!--          (click)="paginateForward()" [ngClass]="{ 'hidden': !this.flightPairService.hasNextPage }">-->
<!--    Next Flights-->

<!--    <svg class="bi bi-caret-right-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"-->
<!--         xmlns="http://www.w3.org/2000/svg">-->
<!--      <path-->
<!--        d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 011.659-.753l5.48 4.796a1 1 0 010 1.506z"/>-->
<!--    </svg>-->

<!--  </button>-->

  <!-- <button class="btn btn-outline-light pagination-button flex-basis-20" (click)="paginateForward()">next</button> -->
</div>


<!-- <div *ngIf="flatPairList && flatPairList.length > 0"
     class="search-results"
     infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="0"
     (scrolled)="onScrollDown()"
     (scrolledUp)="onScrollUp()"
></div> -->
<div *ngIf="isLoadingBottom" class="text-center mt-3 pt-3">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div *ngIf="!filteredFlights || filteredFlights.length === 0" class="text-center default-message-container dark-blue">
  <p>No pairs are available!</p>
  <p>Please change your filter selection to see more pairs.</p>
</div>
