export interface GanttItem {
  id: number;
  name: string;
  start: Date;
  end: Date;
}

export enum MenuItemType {
  GROUP = 'GROUP',
  UNGROUP = 'UNGROUP',
  HIGHTLIGHT_MATCHES = 'HIGHTLIGHT_MATCHES',
  ASSIGN_TAIL = 'ASSIGN_TAIL',
  UNASSIGN_TAIL = 'UNASSIGN_TAIL',
  CANCEL_FLIGHT = 'CANCEL_FLIGHT',
  CONNECTED_PASSENGERS = 'CONNECTED_PASSENGERS',
  MISCONNECTED_PASSENGERS = 'MISCONNECTED_PASSENGERS',
  MISCONNECTED_CREW = 'MISCONNECTED_CREW',
}

export interface GroundTimeData {
  pairId?: number;
  arrivalTime: Date;
  departureTime: Date;
  arrivalLegId?: number;
  departureLegId?: number;
}


export enum GanttChangesType {
  RESCHEDULE = 'RESCHEDULE',
  CHANGE_REGISTRATION = 'CHANGE_REGISTRATION',
  CANCELLATION = 'CANCELLATION',
}

export interface IRescheduleChange {
  date?: string;
  flightNr?: string;
  fromTo?: string;
  oldStd?: string;
  oldSta?: string;
  newStd?: string;
  newSta?: string;
}

export interface ITailChange {
  date?: string;
  flightNr?: string;
  fromTo?: string;
  oldTail?: string;
  newTail?: string;
}

export interface ICancellation {
  date?: string;
  flightNr?: string;
  fromTo?: string;
}
