import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { ITimezone } from '../shared/models/timezone.model';
import { IResponse } from '../shared/models/IResponse.model';
import {IAirportCurfewTime, IAirportCurfewTimesUpdateModel} from '../shared/models/airport-curfew-time.model';
import { ISlotTimeTolerance } from '../shared/models/slotTimeTolerance.model';
import { IAirportContact } from '../shared/models/airport-contact.model';
import { IAirportTerminals } from '../shared/models/airport-terminals.model';
import { environment } from '../../environments/environment';
import { ToastService } from './toast.service';
import { ITurnaroundTimes } from '../shared/models/turnaround-times.model';
import { FlightType } from '../shared/constants/flight-types.constants';
import { IAirport } from '../shared/models/airport.model';
import { IMasterAirportsModel } from '../shared/models/global-airport.model';
import { Searchable } from '../shared/utils/searchable.type';


@Injectable({
  providedIn: 'root'
})
export class AirportsService {

  private _airports: BehaviorSubject<IAirport[]> = new BehaviorSubject([]);
  private _masterAirports: BehaviorSubject<IMasterAirportsModel[]> = new BehaviorSubject([]);
  private _airportTerminals: BehaviorSubject<IAirportTerminals[]> = new BehaviorSubject([]);
  private _airportCurfewTimes: BehaviorSubject<IAirportCurfewTime[]> = new BehaviorSubject([]);
  private _airportSlotTimeTolerance: BehaviorSubject<ISlotTimeTolerance[]> = new BehaviorSubject([]);
  private _airportContactCompanies: BehaviorSubject<IAirportContact[]> = new BehaviorSubject([]);
  private _airportTimezones: BehaviorSubject<ITimezone[]> = new BehaviorSubject([]);


  get airports(): BehaviorSubject<IAirport[]> {
    return this._airports;
  }

  get masterAirports(): BehaviorSubject<IMasterAirportsModel[]> {
    return this._masterAirports;
  }

  get airportTerminals(): BehaviorSubject<IAirportTerminals[]> {
    return this._airportTerminals;
  }

  get airportCurfewTimes(): BehaviorSubject<IAirportCurfewTime[]> {
    return this._airportCurfewTimes;
  }

  get airportSlotTimeTolerance(): BehaviorSubject<ISlotTimeTolerance[]> {
    return this._airportSlotTimeTolerance;
  }

  get airportContactCompanies(): BehaviorSubject<IAirportContact[]> {
    return this._airportContactCompanies;
  }

  get airportTimezones(): BehaviorSubject<ITimezone[]> {
    return this._airportTimezones;
  }

  constructor(private httpClient: HttpClient, private toastService: ToastService) {
    this.fetchAirports().subscribe(() => {});
  }

  fetchAirports(filter: Searchable<IAirport> = { isActive: true }): Observable<IAirport[]> {
    return this.httpClient.post(`${environment.api_base_url}airports/search`, filter, {}).pipe(
      map((item: IResponse<IAirport[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: IAirport[]) => {
        this._airports.next(value);
      })
    );
  }

  fetchAirportTimezones(airportId?: number): Observable<ITimezone[]>  {
    return this.httpClient.post(`${environment.api_base_url}airport-timezones/search`, { isActive: true, airportId: airportId }, {}).pipe(
      map((item: IResponse<ITimezone[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: ITimezone[]) => {
        if (airportId) {
          return;
        }
        this._airportTimezones.next(value);
      })
    );
  }

  saveAirportTimezone(airportTimezone: ITimezone): Observable<ITimezone> {
    let obs;
    if (airportTimezone.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}airport-timezones/${airportTimezone.id}`, airportTimezone, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}airport-timezones/create`, airportTimezone, {})
    }
    return obs.pipe(
      map((item: IResponse<ITimezone>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving timezone");
        return of({});
      })
    );
  }

  fetchAirportCurfewTimes(airportId?: number): Observable<IAirportCurfewTime[]> {
    return this.httpClient.post(`${environment.api_base_url}airport-curfew-times/search`, { isActive: true, airportId: airportId }, {}).pipe(
      map((item: IResponse<IAirportCurfewTime[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: IAirportCurfewTime[]) => {
        if (airportId) {
          return;
        }
        this._airportCurfewTimes.next(value);
      })
    );
  }

  deleteAirportCurfewTime(curfewTimeId: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}airport-curfew-times/${curfewTimeId}`, {}).pipe(
      map((item: IResponse<IAirportCurfewTime>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error deleting curfew time");
        return of({});
      })
    );
  }

  saveAirportCurfewTimes(airportCurfewTimes: IAirportCurfewTimesUpdateModel): Observable<boolean> {
    return this.httpClient.post(`${environment.api_base_url}airport-curfew-times/save`, airportCurfewTimes, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || false;
      }),
      catchError((error: HttpErrorResponse) => {
        let message = 'Error saving curfew times';
        if (error?.error?.message) {
          message = error.error.message;
        }
        this.toastService.showError(message);
        return of(false);
      })
    );
  }

  saveAirportCurfewTime(airportCurfewTime: IAirportCurfewTime): Observable<IAirportCurfewTime> {
    let obs;
    if (airportCurfewTime.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}airport-curfew-times/${airportCurfewTime.id}`, airportCurfewTime, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}airport-curfew-times/create`, airportCurfewTime, {})
    }
    return obs.pipe(
      map((item: IResponse<IAirportCurfewTime>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving curfew time");
        return of({});
      })
    );
  }

  fetchAirportSlotTimeTolerance(airportId?: number): Observable<ISlotTimeTolerance[]> {
    return this.httpClient.post(`${environment.api_base_url}airport-slot-time-tolerance/search`, { isActive: true, airportId: airportId }, {}).pipe(
      map((item: IResponse<ISlotTimeTolerance[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: ISlotTimeTolerance[]) => {
        if (airportId) {
          return;
        }
        this._airportSlotTimeTolerance.next(value);
      })
    );
  }

  saveAirportSlotTimeTolerance(airportSlotTimeTolerance: ISlotTimeTolerance): Observable<ISlotTimeTolerance> {
    let obs;
    if (airportSlotTimeTolerance.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}airport-slot-time-tolerance/${airportSlotTimeTolerance.id}`, airportSlotTimeTolerance, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}airport-slot-time-tolerance/create`, airportSlotTimeTolerance, {})
    }
    return obs.pipe(
      map((item: IResponse<ISlotTimeTolerance>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving slot time tolerance");
        return of({});
      })
    );
  }

  fetchAirportTerminals(airportId?: number): Observable<IAirportTerminals[]> {
    return this.httpClient.post(`${environment.api_base_url}airport-terminals/search`, { isActive: true, airportId: airportId }, {}).pipe(
      map((item: IResponse<IAirportTerminals[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: IAirportTerminals[]) => {
        if (airportId) {
          return;
        }
        this._airportTerminals.next(value);
      })
    );
  }

  deleteAirportTerminal(terminalId: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}airport-terminals/${terminalId}`, {}).pipe(
      map((item: IResponse<IAirportTerminals>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error deleting terminal");
        return of({});
      })
    );
  }

  saveAirportTerminal(airportTerminal: IAirportTerminals): Observable<IAirportTerminals> {
    let obs;
    if (airportTerminal.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}airport-terminals/${airportTerminal.id}`, airportTerminal, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}airport-terminals/create`, airportTerminal, {})
    }
    return obs.pipe(
      map((item: IResponse<IAirportTerminals>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving terminal");
        return of({});
      })
    );
  }

  fetchAirportContactCompanies(airportId?: number): Observable<IAirportContact[]> {
    return this.httpClient.post(`${environment.api_base_url}airport-contact-companies/search`, { isActive: true, airportId: airportId }, {}).pipe(
      map((item: IResponse<IAirportContact[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: IAirportContact[]) => {
        if (airportId) {
          return;
        }
        this._airportContactCompanies.next(value);
      })
    );
  }

  saveAirportContactCompanies(airportContact: IAirportContact): Observable<IAirportContact> {
    let obs;
    if (airportContact.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}airport-contact-companies/${airportContact.id}`, airportContact, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}airport-contact-companies/create`, airportContact, {})
    }
    return obs.pipe(
      map((item: IResponse<IAirportContact>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving contact company");
        return of({});
      })
    );
  }

  deleteAirportContactCompany(airportContactCompany: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}airport-contact-companies/${airportContactCompany}`, {}).pipe(
      map((item: IResponse<IAirportContact>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error deleting contact company");
        return of({});
      })
    );
  }

  fetchTurnaroundTimes(filter: Searchable<ITurnaroundTimes> = { isActive: true }): Observable<ITurnaroundTimes[]> {
    return this.httpClient.post(`${environment.api_base_url}turnaround-times/search`, filter, {}).pipe(
      map((item: IResponse<ITurnaroundTimes[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  fetchTurnaroundTimesByAcType(acTypeId: number, flightType: FlightType): Observable<ITurnaroundTimes[]> {
    return this.httpClient.post(`${environment.api_base_url}turnaround-times/search`, { isActive: true, acTypeId, flightType }, {}).pipe(
      map((item: IResponse<ITurnaroundTimes[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  fetchTurnaroundTimesByAirport(airportId: number, flightType?: FlightType): Observable<ITurnaroundTimes[]> {
    return this.httpClient.post(`${environment.api_base_url}turnaround-times/search`, { isActive: true, airportId, flightType }, {}).pipe(
      map((item: IResponse<ITurnaroundTimes[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  saveTurnaroundTime(turnaround: ITurnaroundTimes): Observable<ITurnaroundTimes> {
    let obs;
    if (turnaround.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}turnaround-times/${turnaround.id}`, turnaround, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}turnaround-times/create`, turnaround, {})
    }
    return obs.pipe(
      map((item: IResponse<ITurnaroundTimes>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        this.toastService.showError("Error saving turnaround times");
        return of(null);
      })
    );
  }

  deleteTurnaroundTime(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}turnaround-times/${id}`, {}).pipe(
      catchError((error) => {
        this.toastService.showError("Error deleting turnaround time");
        return of({});
      })
    );
  }

  saveAirport(airport: IAirport): Observable<IAirport> {
    let obs;
    if (airport.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}airports/${airport.id}`, airport, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}airports/create`, airport, {})
    }
    return obs.pipe(
      map((item: IResponse<IAirport>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        this.toastService.showError("Error saving airport");
        return of(null);
      })
    );
  }

  deleteAirport(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}airports/${id}`, {}).pipe(
      catchError((error) => {
        this.toastService.showError("Error deleting airport");
        return of({});
      })
    );
  }



  fetchMasterAirportTimezone(countryId: number): Observable<ITimezone[]> {
    return this.httpClient.post(`${environment.api_base_url}master-countries-timezones/search`, { isActive: true, masterCountryId: countryId }, {}).pipe(
      map((item: IResponse<ITimezone[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  deleteMasterAirport(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}master-airports/${id}`, {}).pipe(
      catchError((error) => {
        this.toastService.showError("Error deleting master airport");
        return of({});
      })
    );
  }

  fetchMasterAirports(filter: IMasterAirportsModel = { isActive: true }): Observable<IMasterAirportsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}master-airports/search`, filter, {}).pipe(
      map((item: IResponse<IMasterAirportsModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: IMasterAirportsModel[]) => {
        if (!filter.isActive || Object.keys(filter).length > 1) {
          return;
        }
        this._masterAirports.next(value);
      })
    );
  }

  saveMasterAirport(airport: IMasterAirportsModel): Observable<IMasterAirportsModel> {
    let obs;
    if (airport.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}master-airports/${airport.id}`, airport, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}master-airports/create`, airport, {})
    }
    return obs.pipe(
      map((item: IResponse<IAirport>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        this.toastService.showError("Error saving master airport");
        return of(null);
      })
    );
  }
}
