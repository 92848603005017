<div class="modal-header">
  <span class="modal-title">View Further Information</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="leg-info-close-button">
  </button>
</div>
<div class="modal-body children-fs16 p-0">
  <app-tabs-navigation [tabs]="tabs" (tabChanged)="activeTab = $event"></app-tabs-navigation>
  <div class="flex flex-column gap-3 p-3 pt-0 main-container avitium-scrollbar">
    <ng-container *ngIf="activeTab === 'Passengers'">
      <div class="flex flex-column gap-1">
        <div class="flex">
          <div class="form-field-header left-text">
            Next information (NI) at:
          </div>
          <div class="form-field-subheader flex-grow-1">
            {{!isPairOverview(pair) ? pair?.departureLeg?.ni ? (pair.departureLeg.ni | date:'HH:mm': '+0000') : '-' : 'Loading...' }}
          </div>
        </div>
        <div class="flex">
          <div class="form-field-header left-text">
            NI Delay Code:
          </div>
          <div class="flex-grow-1 form-field-subheader">
            {{ niDelayCode ? niDelayCode.code + ' ' + niDelayCode.description : '-' }}
          </div>
        </div>
      </div>

      <div class="flex flex-column ">
        <div class="col-6 sub-title mt-2">
          Connecting Passengers:
        </div>
        <div class="flex">
          <div class="left-text form-field-header">EW0656/14 ATH Y12</div>
          <div class="dark-blue">01:45</div>
        </div>
        <div class="flex">
          <div class="left-text form-field-header">EW0858/14 BER C1 Y22</div>
          <div class="dark-blue">00:39</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="activeTab === 'A-CDM'">
      <div class="flex justify-content-between acdm">
          <div class="flex-column arriving flex gap-2 mx-auto">
            <div class="sub-title">
              Arriving Flight
            </div>
            <div class="flex">
              <div class="form-field-header ">SIBT</div>
              <div class="form-field-content dark-blue">18:50*</div>
            </div>
            <div class="flex">
              <div class="form-field-header ">IBT</div>
              <div class="form-field-content dark-blue">18:50*</div>
            </div>
            <div class="flex">
              <div class="form-field-header ">Rwy</div>
              <div class="form-field-content dark-blue">03R*</div>
            </div>
            <div class="flex">
              <div class="form-field-header ">STATA</div>
              <div class="form-field-content dark-blue">AIRB*</div>
            </div>
          </div>
          <div class="right-border mx-3"></div>
          <div class="flex-column departing flex gap-2 mx-auto">
            <div class="sub-title">
              Departing Flight
            </div>
            <div class="flex">
              <div class="form-field-header ">TOBT</div>
              <div class="form-field-content dark-blue">19:35*</div>
            </div>
            <div class="flex">
              <div class="form-field-header ">OBT</div>
              <div class="form-field-content dark-blue">19:35*</div>
            </div>
            <div class="flex">
              <div class="form-field-header ">SOBT</div>
              <div class="form-field-content dark-blue">19:35*</div>
            </div>
            <div class="flex">
              <div class="form-field-header ">TOT</div>
              <div class="form-field-content dark-blue">-*</div>
            </div>
            <div class="flex">
              <div class="form-field-header ">TSAT</div>
              <div class="form-field-content dark-blue">19:20*</div>
            </div>
            <div class="flex">
              <div class="form-field-header ">TTOT</div>
              <div class="form-field-content dark-blue">19:50*</div>
            </div>
          </div>
        </div>
    </ng-container>
    <ng-container *ngIf="activeTab === 'Crew'">
      <div class="fw-bold dark-blue">Arriving Flight (A1 100 ATH - SKG)</div>
      <div class="dark-blue">Flight Deck:</div>
      <div class="flex">
        <div class="form-field-header col-5">Captain:</div>
        <div class="dark-blue col-6">George Ellis</div>
      </div>
      <div class="flex">
        <div class="form-field-header col-5">First Officer:</div>
        <div class="dark-blue col-6">Johan Skift</div>
      </div>
      <div class="dark-blue">Cabin Crew:</div>
      <div class="flex">
        <div class="form-field-header col-5">Sr. Flight Attendant:</div>
        <div class="dark-blue col-6">Maria Antouanetta</div>
      </div>
      <div class="flex">
        <div class="form-field-header col-5">Flight Attendant:</div>
        <div class="dark-blue col-6">Philipp Morris</div>
      </div>
      <div class="flex">
        <div class="form-field-header col-5">Flight Attendant:</div>
        <div class="dark-blue col-6">Monica Belluci</div>
      </div>
      <div class="flex">
        <div class="form-field-header col-5">Flight Attendant:</div>
        <div class="dark-blue col-6">Bob Ross</div>
      </div>

      <div class="fw-bold dark-blue">Departing Flight (A1 101 SKG - ATH)</div>
      <div class="dark-blue">Flight Deck:</div>
      <div class="flex">
        <div class="form-field-header col-5">Captain:</div>
        <div class="dark-blue col-6">George Ellis</div>
      </div>
      <div class="flex">
        <div class="form-field-header col-5">First Officer:</div>
        <div class="dark-blue col-6">Johan Skift</div>
      </div>
      <div class="dark-blue">Cabin Crew:</div>
      <div class="flex">
        <div class="form-field-header col-5">Sr. Flight Attendant:</div>
        <div class="dark-blue col-6">Maria Antouanetta</div>
      </div>
      <div class="flex">
        <div class="form-field-header col-5">Flight Attendant:</div>
        <div class="dark-blue col-6">Philipp Morris</div>
      </div>
      <div class="flex">
        <div class="form-field-header col-5">Flight Attendant:</div>
        <div class="dark-blue col-6">Monica Belluci</div>
      </div>
      <div class="flex">
        <div class="form-field-header col-5">Flight Attendant:</div>
        <div class="dark-blue col-6">Bob Ross</div>
      </div>
    </ng-container>
    <ng-container *ngIf="activeTab === 'Weather'">
      <div *ngFor="let station of interactingStations">
        <div class="fw-bold dark-blue">METAR/TAF {{station}}:</div>
        <div> {{metarData[station]?.content}}</div><br>
        <div> {{tafData[station]?.content}}</div>
      </div>

    </ng-container>
    <ng-container *ngIf="activeTab === 'LIR'">
      <img src="content/images/plane-nose.png" alt="Plane nose" width="130" class="align-self-center">
      <div class="flex justify-content-between">
        <div class="light-blue-border loading-box"><strong>21</strong>
          VIE/PAG/140A/C/<strong>3700</strong>/
          PEM.EAT
        </div>
        <div class="light-blue-border loading-box"><strong>22</strong>
          TRANSIT: ATH/PAG 250A
          /C/<strong>2543</strong>/AOG.FKT.COMAIL
        </div>
      </div>
      <div class="flex justify-content-between">
        <div class="light-blue-border loading-box"><strong>41</strong>L
                                                                      VIE/AKE 24501 IR/B/<strong>500</strong>

        </div>
        <div class="light-blue-border loading-box"><strong>41</strong>R
                                                                      VIE/AKE 25001 IR/B/<strong>500</strong>

        </div>
      </div>
      <div class="flex justify-content-between">
        <div class="light-blue-border loading-box"><strong>42</strong>L
                                                                      VIE/AKE 24501 IR/B/<strong>400</strong>

        </div>
      </div>
    </ng-container>
  </div>
</div>
