import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appTimepickerFocus]'
})
export class TimepickerFocusDirective {
  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const element = event.target as HTMLInputElement;
    if (element.value?.length === 2 && element?.parentElement?.classList.contains('ngb-tp-hour')) {
      const elem = element?.parentElement?.parentElement?.querySelector('.ngb-tp-minute input') as HTMLInputElement;
      elem.focus();
    }
  }
}
