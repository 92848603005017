import {Component, OnInit} from '@angular/core';
import {InterfacesTestTagPipe} from '../../../shared/pipes/interfaces-test-tag.pipe';
import {PermissionService} from "../../../services/permission.service";
import {Access, PermissionUIMasks} from "../../../shared/constants/enums";

type InterfaceData = {
  name: string;
  status: boolean;
  canEdit: boolean;
}

@Component({
  selector: 'app-settings-interface-management',
  templateUrl: './settings-interface-management.component.html',
  styleUrls: ['./settings-interface-management.component.scss']
})
export class SettingsInterfaceManagementComponent implements OnInit {
  tableData: InterfaceData[] = [];
  pageAccess: Access;

  constructor(private permissionService: PermissionService) {
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_INTERFACE_MANAGEMENT).then((pageAccess) => {
      this.pageAccess = pageAccess;
      if (!pageAccess) {
        return;
      }
      this.tableData.push({
          name: 'Flight Schedule Management (SSIM, ASM, SSM)',
          status: true,
          canEdit: pageAccess === Access.RW,
        }, {
          name: 'Actual Information Messages (MVT, ACARS)',
          status: true,
          canEdit: pageAccess === Access.RW,
        },
        {
          name: 'Passenger Information (PTM, RESERVATION SYSTEM)',
          status: true,
          canEdit: pageAccess === Access.RW,
        },
        {
          name: 'Stands & Gates',
          status: false,
          canEdit: false,
        },
        {
          name: 'A-CDM',
          status: false,
          canEdit: false,
        },
        {
          name: 'Third Party Interfaces',
          status: false,
          canEdit: false,
        });
    });
  }


  ngOnInit() {
  }

  public readonly InterfacesTestTagPipe = InterfacesTestTagPipe;
  public readonly Access = Access;
}
