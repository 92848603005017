export const TimespanFilterValuesConstant: {
  [id: number]: readonly [number, number];
} = {
  1: [4, 4],
  2: [8, 8],
  3: [24, 24],
  4: [0, 8],
  5: [0, 12],
  6: [0, 24],
} as const;
