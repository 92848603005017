import { IBaseDBModel } from './base-db.model';

export enum CurfewWeekday {
  SUN = '0',
  MON = '1',
  TUE = '2',
  WED = '3',
  THU = '4',
  FRI = '5',
  SAT = '6',
}

export const CurfewWeekdayIdToName = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
}

export interface IAirportCurfewTimesUpdateModel {
  data: IAirportCurfewTime[];
  oldIds: number[];
}

export interface IAirportCurfewTableData {
  isUtc?: boolean;
  from?: Date;
  to?: Date;
  opening?: string;
  closing?: string;
  mon?: IAirportCurfewTime;
  tue?: IAirportCurfewTime;
  wed?: IAirportCurfewTime;
  thu?: IAirportCurfewTime;
  fri?: IAirportCurfewTime;
  sat?: IAirportCurfewTime;
  sun?: IAirportCurfewTime;
}

export interface IAirportCurfewTime extends IBaseDBModel {
  airportId?: number;
  validFrom?: Date;
  validTo?: Date;
  curfewStartTime?: string;
  curfewEndTime?: string;
  isUtc?: boolean;
  weekday?: CurfewWeekday;
  curfewStartTimeLocal?: string;
  curfewEndTimeLocal?: string;
}

export class AirportCurfewTime implements IAirportCurfewTime {
  airportId?: number;
  validFrom?: Date;
  validTo?: Date;
  curfewStartTime?: string;
  curfewEndTime?: string;
  isUtc?: boolean;
  weekday?: CurfewWeekday;
  curfewStartTimeLocal?: string;
  curfewEndTimeLocal?: string;
}

