<div class="modal-header">
  <span class="modal-title">Select Ramp Agent</span>
</div>
<div class="modal-body">
  <ng-container *ngIf="users?.length">
    <div class="flex flex-column gap-3">
      <div class="flex gap-3" *ngFor="let user of users">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" [attr.id]="'select-user-' + user.id"
                 [checked]="selectedUserId === user.id"
                 [attr.data-test]="'ramp-agent-' + user.firstname + '-' + user.lastname"
                 (change)="changedUser(user.id, $event)">
          <label class="form-check-label dark-blue"
                 [attr.for]="'select-user-' + user.id">{{ user.firstname }} {{ user.lastname }}</label>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()"
          data-test="save-ramp-agent-cancel-button">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="isBusy" data-test="save-ramp-agent-btn">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
