import { Pipe, PipeTransform } from '@angular/core';
import { IAirportContact } from '../models/airport-contact.model';

@Pipe({
  name: 'airportContactsTestTag'
})
export class AirportContactsTestTagPipe implements PipeTransform {

  transform(value: IAirportContact, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `contact-${value?.firstname} ${value.lastname}`;
  }

}
