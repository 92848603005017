import { Pipe, PipeTransform } from '@angular/core';

type InterfaceData = {
  name: string;
  status: boolean;
  canEdit: boolean;
}

@Pipe({
  name: 'interfacesTestTag'
})
export class InterfacesTestTagPipe implements PipeTransform {

  transform(value: InterfaceData, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `interface-${value?.name}`;
  }

}
