import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

export class ExitScreenCreateMvtFormBuilder {

    static constructForm(fb: UntypedFormBuilder, dateFormat: string): UntypedFormGroup {

    return fb.group({
      messageType: ['', Validators.required],
      airlineDesignator: ['', Validators.required],
      flightNumber: ['', Validators.required],
      dateOfDeparture: ['', Validators.required],
      acRegistration: ['', Validators.required],
      movementAirport: ['', Validators.required],
      delayCode: ['', Validators.required],
      extraDelayCode: [''],
      delayMinutes: ['', Validators.required],
      extraDelayMinutes: [''],
      etdDate: [null],
      etdTime: ['', Validators.required],
      niDate: [null],
      niTime: ['', Validators.required],
      atdDate: [null],
      atdTime: [''],
      totDate: [null],
      totTime: ['', Validators.required],
      etaTime: ['', Validators.required],
      arrAirport: ['', Validators.required],
      ataDate: [null],
      ataTime: ['', Validators.required],
      landDate: [null],
      landTime: ['', Validators.required],
      pax: [''],
      si: [''],
    });
  }
}
