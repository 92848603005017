<form [formGroup]="formGroup" *ngIf="permissionsFetched">

  <div class="container dark-blue mt-4">
    <!-- <div class="row mt-3 flex item light-blue-border" style="height:65px">
            <div class="col-5 align-self-center">All Data</div>
            <div class="col-4 flex justify-content-center align-self-center">
                <app-date-picker-range-popup></app-date-picker-range-popup>
            </div>
            <div class="col-1 cursor-pointer align-self-center" style="color: grey !important;"> <fa-icon size="2x" [icon]="pdfIcon"></fa-icon></div>
            <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="csvIcon"></fa-icon></div>
            <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="codeIcon"></fa-icon></div>
    </div> -->
    <div class="row mt-3 flex item light-blue-border position-relative" style="height:65px">
      <app-access-denied *ngIf="!flightDetailsAccess"></app-access-denied>
      <div class="col-5 align-self-center">Flight Details</div>
      <div class="col-4 flex justify-content-center align-self-center">
        <app-date-picker-range-popup formControlName="flightDetails"
                                     data-test="flight-details-date-range"></app-date-picker-range-popup>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="pdfIcon" data-test="flight-details-pdf"></fa-icon>
      </div>
      <div class="col-1 cursor-pointer align-self-center">
        <fa-icon size="2x" (click)="getFLightDetailsXLS()" [icon]="csvIcon" data-test="flight-details-csv"></fa-icon>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="codeIcon" data-test="flight-details-code"></fa-icon>
      </div>
    </div>
    <div class="row mt-3 flex item light-blue-border position-relative" style="height:65px">
      <app-access-denied *ngIf="!flightPairingsAccess"></app-access-denied>
      <div class="col-5 align-self-center">Flight Pairings</div>
      <div class="col-4 flex justify-content-center align-self-center">
        <app-date-picker-range-popup formControlName="flightPairings"
                                     data-test="flight-pairings-date-range"></app-date-picker-range-popup>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="pdfIcon" data-test="flight-pairings-pdf"></fa-icon>
      </div>
      <div class="col-1 cursor-pointer align-self-center">
        <fa-icon size="2x" (click)="getFLightPairingsXLS()" [icon]="csvIcon" data-test="flight-pairings-csv"></fa-icon>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="codeIcon" data-test="flight-pairings-code"></fa-icon>
      </div>
    </div>
    <div class="row mt-3 flex item light-blue-border position-relative" style="height:65px">
      <app-access-denied *ngIf="!usersReportAccess"></app-access-denied>
      <div class="col-5 align-self-center">Users Report</div>
      <div class="col-4 flex justify-content-center align-self-center">
        <app-date-picker-range-popup></app-date-picker-range-popup>
      </div>
      <div class="col-1  align-self-center">
        <fa-icon size="2x" [icon]="pdfIcon" data-test="user-reports-pdf"></fa-icon>
      </div>
      <div class="col-1 cursor-pointer align-self-center">
        <fa-icon size="2x" (click)="getUsersReportXLS()" [icon]="csvIcon" data-test="user-reports-csv"></fa-icon>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="codeIcon" data-test="user-reports-code"></fa-icon>
      </div>
    </div>
    <div class="row mt-3 flex item light-blue-border position-relative" style="height:65px">
      <app-access-denied *ngIf="!gseUtilizationPerAirportAccess"></app-access-denied>
      <div class="col-5 align-self-center">GSE Utilization per Airport</div>
      <div class="col-4 flex justify-content-center align-self-center">
        <app-date-picker-range-popup formControlName="gseUtilization"
                                     data-test="gse-util-date-range"></app-date-picker-range-popup>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="pdfIcon" data-test="gse-utilization-pdf"></fa-icon>
      </div>
      <div class="col-1 cursor-pointer align-self-center">
        <fa-icon size="2x" (click)="getGseUtilizationXLS()" [icon]="csvIcon" data-test="gse-utilization-csv"></fa-icon>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="codeIcon" data-test="gse-utilization-code"></fa-icon>
      </div>
    </div>
    <div class="row mt-3 flex item light-blue-border position-relative" style="height:65px">
      <app-access-denied *ngIf="!listOfProcessesAccess"></app-access-denied>
      <div class="col-5 align-self-center">List of SLA/Non-SLA Processes</div>
      <div class="col-4 flex justify-content-center align-self-center">
        <app-date-picker-range-popup formControlName="processes"
                                     data-test="processes-date-range"></app-date-picker-range-popup>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="pdfIcon" data-test="processes-pdf"></fa-icon>
      </div>
      <div class="col-1 cursor-pointer align-self-center">
        <fa-icon size="2x" (click)="getProcessesXLS()" [icon]="csvIcon" data-test="processes-csv"></fa-icon>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="codeIcon" data-test="processes-code"></fa-icon>
      </div>
    </div>
    <!-- <div class="row mt-3 flex item light-blue-border" style="height:65px">
        <div class="col-5 align-self-center">EU 261 Eligible Flights</div>
        <div class="col-4 flex justify-content-center align-self-center">
            <app-date-picker-range-popup></app-date-picker-range-popup>
        </div>
        <div class="col-1 align-self-center"> <fa-icon size="2x" [icon]="pdfIcon"></fa-icon></div>
        <div class="col-1 cursor-pointer align-self-center" > <fa-icon size="2x" [icon]="csvIcon"></fa-icon></div>
        <div class="col-1 align-self-center" > <fa-icon size="2x" [icon]="codeIcon"></fa-icon></div>
    </div> -->
    <div class="row mt-3 flex item light-blue-border light-blue-border position-relative" style="height:65px">
      <app-access-denied *ngIf="!flightsMemosAccess"></app-access-denied>
      <div class="col-5 align-self-center">Flights Memos</div>
      <div class="col-4 flex justify-content-center align-self-center">
        <app-date-picker-range-popup formControlName="memos" data-test="memos-date-range"></app-date-picker-range-popup>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="pdfIcon" data-test="memos-pdf"></fa-icon>
      </div>
      <div class="col-1 cursor-pointer align-self-center">
        <fa-icon size="2x" (click)="getMemosXLS()" [icon]="csvIcon" data-test="memos-csv"></fa-icon>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="codeIcon" data-test="memos-code"></fa-icon>
      </div>
    </div>
    <div class="row mt-3 flex item light-blue-border position-relative" style="height:65px">
      <app-access-denied *ngIf="!turnaroundGroundTimeDifferencesAccess"></app-access-denied>
      <div class="col-5 align-self-center">Turnaround/ground time differences</div>
      <div class="col-4 flex justify-content-center align-self-center">
        <app-date-picker-range-popup formControlName="turnaroundVsGround"
                                     data-test="turnaround-ground-date-range"></app-date-picker-range-popup>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="pdfIcon" data-test="turnaround-ground-pdf"></fa-icon>
      </div>
      <div class="col-1 cursor-pointer align-self-center">
        <fa-icon size="2x" (click)="getTurnaroundGroundTimeDiffXLS()" [icon]="csvIcon"
                 data-test="turnaround-ground-csv"></fa-icon>
      </div>
      <div class="col-1 align-self-center">
        <fa-icon size="2x" [icon]="codeIcon" data-test="turnaround-ground-code"></fa-icon>
      </div>
    </div>
  </div>
</form>


