/* tslint:disable:max-line-length */
import {Component, OnInit} from '@angular/core';
import {NgbCalendar, NgbDate, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  SettingsFlightScheduleMaintValidateSsimFileComponent
} from './maint-validate-ssim-file/settings-flight-schedule-maint-validate-ssim-file.component';
import * as dayjs from 'dayjs';
import {PermissionService} from "../../../services/permission.service";
import {Access, PermissionUIMasks} from "../../../shared/constants/enums";

@Component({
  selector: 'app-settings-flight-schedule-maintenance',
  templateUrl: './settings-flight-schedule-maintenance.component.html',
  styleUrls: ['./settings-flight-schedule-maintenance.component.scss']
})
export class SettingsFlightScheduleMaintenanceComponent implements OnInit {

  ssimFile: File;
  ssimFileContent: any;
  isReady: boolean;
  delimitFlights: boolean;
  from: any;
  to: any;
  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;
  pageAccess: Access;

  constructor(calendar: NgbCalendar, private modalService: NgbModal, private permissionService: PermissionService) {
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_FLIGHTS_SCHEDULE_MANAGEMENT).then((pageAccess) => {
      this.pageAccess = pageAccess;
    });
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {
    this.delimitFlights = false;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && !date.before(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  handleFileInput(files: FileList) {
    console.log(files.item(0));

    const fileReader = new FileReader();

    fileReader.onloadend = (e) => {
      this.ssimFileContent = fileReader.result;
      this.ssimFile = files.item(0);
    };

    if (files.item(0) != null && files.item(0).size > 0) {
      console.log('start read file');
      fileReader.readAsText(files.item(0));
    } else {
      console.log('file is empty');
    }

    this.isReady = true;
  }

  openValidationView() {
    const modalRef = this.modalService.open(SettingsFlightScheduleMaintValidateSsimFileComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as SettingsFlightScheduleMaintValidateSsimFileComponent;
    modal.title = 'Validate SSIM File and prepare upload';
    modal.fileName = this.ssimFile.name;
    modal.ssimFileContent = this.ssimFileContent;
    modal.periodFrom = (this.fromDate && this.delimitFlights) ? dayjs(String(this.fromDate.year) + '-' + String(this.fromDate.month) + '-' + String(this.fromDate.day), 'YYYY-M-D').utc(true) : null;
    modal.periodTo = (this.toDate && this.delimitFlights) ? dayjs(String(this.toDate.year) + '-' + String(this.toDate.month) + '-' + String(this.toDate.day), 'YYYY-M-D').utc(true) : null;
  }

}
