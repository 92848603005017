import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  GanttChangesType,
  ICancellation,
  IRescheduleChange,
  ITailChange
} from "../../../../../shared/models/gantt.models";

@Component({
  selector: 'app-gantt-pending-changes-tab',
  templateUrl: './gantt-pending-changes-tab.component.html',
  styleUrls: ['./gantt-pending-changes-tab.component.scss']
})
export class GanttPendingChangesTabComponent {

  protected readonly GanttChangesType = GanttChangesType;
  @Input() registrationChanges: ITailChange[];
  @Input() rescheduleChanges: IRescheduleChange[];
  @Input() cancellationChanges: ICancellation[];
  @Output() saveClicked = new EventEmitter<GanttChangesType>();
  @Output() undoClicked = new EventEmitter<GanttChangesType>();

  save(type: GanttChangesType) {
    this.saveClicked.emit(type);
  }

  undoChanges(type: GanttChangesType) {
    this.undoClicked.emit(type);
  }
}
