<div class="modal-header">
    <span class="modal-title">{{title}}</span>
    <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="issue-new-information-close-button">
    </button>
  </div>
<div class="modal-body">
    <ng-container *ngIf="form">
        <form [formGroup]="form">
            <div class="container">
            <div class="row">
                <div class="col-12">
                <div class="container">
                    <div class="row">
                    <div class="col-5 form-field-header">
                        New Information*
                    </div>
                    <div class="col-4">
                        <div class="mb-3">
                        <div class="input-group">
                            <input class="form-control" [placeholder]="dateFormat"
                                name="dp" formControlName="newInformationDate" ngbDatepicker #d="ngbDatepicker" data-test="issue-new-information-date-input">
                            <div class="input-group-append">
                            <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar" (click)="d.toggle()" type="button" data-test="issue-new-information-calendar-button"></button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <ngb-timepicker appTimepickerFocus formControlName="newInformationTime" [spinners]="false" data-test="issue-new-information-time-picker"></ngb-timepicker>
                    </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row mt-6">
                    <div class="col-5 form-field-header">
                        Delay Code*
                    </div>
                    <div class="col-4">
                        <div class="mb-3">
                        <div class="mb-3">
                            <ng-select formControlName="delayCode">
                            <ng-option *ngFor="let delayCode of delayCodes" [value]="delayCode.id">{{delayCode.code}} {{ delayCode.description}}</ng-option>
                            </ng-select>
                        </div>
                        </div>
                    </div>
                    <div class="col-3">
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </form>
    </ng-container>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()" data-test="issue-new-information-cancel-button">Cancel
    </button>
    <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
            [disabled]="form?.disabled || form?.invalid || isBusy" data-test="issue-new-information-save-button">
      {{ isBusy ? '' : 'Save' }}
      <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
    </button>
  </div>
