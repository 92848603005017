<div class="col-12 flex flex-column item dark-blue light-blue-border"
     [ngClass]="getBgColor(this.activeAlertIndex === alert.id)">
  <div class="col-12 flex cursor-pointer" style="height:65px" (click)="toggleClicked.emit(this.alert.id)">
    <div class="col-3 flex flex-column justify-content-center">
      <div *ngIf="this.alertService.alertTypes[alert.alertTypeId].severity === localSeverityEnum.CRITICAL"
           class="fw-bold" data-test="severity-critical">Critical
      </div>
      <div *ngIf="this.alertService.alertTypes[alert.alertTypeId].severity === localSeverityEnum.INTERMEDIATE"
           class="fw-bold" data-test="severity-intermediate">Intermediate
      </div>
      <div *ngIf="this.alertService.alertTypes[alert.alertTypeId].severity === localSeverityEnum.LOW" class="fw-bold"
           data-test="severity-low">Low
      </div>
      <div class="progress w-25 align-self-center" style="height: 10px;">
        <div
          [ngClass]="{'bg-danger w-100': this.alertService.alertTypes[alert.alertTypeId].severity === localSeverityEnum.CRITICAL, 'bg-warning w-50': this.alertService.alertTypes[alert.alertTypeId].severity === localSeverityEnum.INTERMEDIATE, 'bg-info w-25': this.alertService.alertTypes[alert.alertTypeId].severity === localSeverityEnum.LOW}"
          class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
    <div class="col-3 align-self-center" data-test="alert-description">{{ alert | alertDescription }}</div>
    <div class="col-3 align-self-center" data-test="alert-datetime">{{alert.occuredAt| date:'medium':timezone}}</div>
    <div class="col-3 align-self-center" data-test="alert-affected-sectors"><a>{{ getAffectedSectors() }}</a></div>
  </div>
  <div class="col-12 detail-section" [ngClass]="{ 'collapse': this.activeAlertIndex !== alert.id }">
    <div class="flex mt-3 mb-3 text-start ms-2 me-2">
      <div class="flex-grow-1 align-self-center">
        <span class="fw-bold">Proposed Action(s): </span>
        <ng-container [ngSwitch]="alert.alertTypeId">
          <span *ngSwitchDefault>{{ this.alertService.alertTypes[alert.alertTypeId].resolution }}</span>
          <ng-container *ngSwitchCase="9">
            <!--              <div class="flex flex-column" *ngIf="alert?.__pair__?.departureLegId === null">&ndash;&gt;-->
            <!--                <span>1. Consider delaying affected sectors in the planning system{{ calculateEtdExtension() }}</span>-->
            <!--                <span>2. Introduce new sector(s) in the planning system</span>-->
            <!--                <span>3. Cancel Sector {{ alert.__pair__.__arrivalLegModel__.airlineDesignator.concat(alert.__pair__.__arrivalLegModel__.flightNumber) }} in the planning system.</span>-->
            <!--                <span>4. Change a/c registration for Sector {{ alert.__pair__.__arrivalLegModel__.airlineDesignator.concat(alert.__pair__.__arrivalLegModel__.flightNumber) }} in the planning system</span>-->
            <!--              </div>-->

            <div class="flex flex-column" *ngIf="alert.__pair__?.arrivalLegId === null">
              <span>{{ calculateEtdFromEta() }}</span>
              <ng-container *ngIf="this.alert.__pair__.__prevDeparturePairModel__?.__arrivalLegModel__?.eta">
                <span>2. Cancel Sector {{ alert.__pair__?.__departureLegModel__?.airlineDesignator.concat(alert.__pair__?.__departureLegModel__?.flightNumber) }}
                  in the planning system.</span>
                <span>3. Change a/c registration for Sector {{ alert.__pair__?.__departureLegModel__?.airlineDesignator.concat(alert.__pair__?.__departureLegModel__.flightNumber) }}
                  in the planning system</span>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="10">
            <div class="flex flex-column">
              <span>1. Amend EOBT/ETD for flight {{ alert.__leg__.airlineDesignator.concat(alert.__leg__.flightNumber) }}
                in your planning system</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="11">
            <div class="flex flex-column">
              <span>1. Amend ETD (if necessary) in Avitium for flight {{ alert.__leg__.airlineDesignator.concat(alert.__leg__.flightNumber) }}</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="12">
            <div class="flex flex-column">
              <span>1. Monitor the situation </span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="13">
            <div class="flex flex-column">
              <span>1. Monitor the situation </span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="14">
            <div class="flex flex-column">
              <span>Aircraft operating flight {{ alert.__leg__.flightNumber }}
                has diverted to {{ alert.__leg__.arrivalStation }}.</span>
              <span>Monitor flight status</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="1">
            <div class="flex flex-column">
              <span>1. Monitor the situation in the planning system</span>
              <span>2. Issue an ETD for the flight {{ alert.__leg__.flightNumber }}</span>
              <span>3. Tail to be assigned (if applicable)</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <div class="flex flex-column">
              <span>
                1. Issue ETD {{ calculateNewEtdForInadequate() }}
              </span>
              <span>2. Consider aircraft change</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="4">
            <div class="flex flex-column">
              <span>1.New Information issued for {{ djs(alert.__leg__.ni).utc().format('HH:mm') }}. Monitor the situation </span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="AlertConstants.STR_TO_ID.ARRIVE_AT_CLOSED_AIRPORT">
           <span>Coordinate with airport authorities for curfew exemption</span>
          </ng-container>
          <ng-container *ngSwitchCase="AlertConstants.STR_TO_ID.DEPART_FROM_CLOSED_AIRPORT">
            <div class="flex flex-column">
              <span>1. Set appropriate ETD to avoid curfew time</span>
              <span>2. Coordinate with airport authorities for curfew exemption</span>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="button-set justify-content-center flex gap-2 align-self-center">
        <button type="button" class="btn btn-outline-success" (click)="markAsResolve($event)"
                [attr.data-test]="'alert-mark-as-resolved-id-' + alert.id">Mark as Resolved
          <fa-icon [icon]="checkIcon"></fa-icon>
        </button>
        <button type="button"
                [ngClass]="{'btn-outline-dark': this.alertService.alertTypes[alert.alertTypeId].severity !== localSeverityEnum.LOW, 'btn-outline-secondary': this.alertService.alertTypes[alert.alertTypeId].severity === localSeverityEnum.LOW}"
                [disabled]="this.alertService.alertTypes[alert.alertTypeId].title === 'NEW_INFORMATION_ISSUED' || this.alertService.alertTypes[alert.alertTypeId].title === 'TURNAROUND_NOT_STARTED' || this.alertService.alertTypes[alert.alertTypeId].title === 'AIRCRAFT_RETURN_FROM_AIRBORNE' || this.alertService.alertTypes[alert.alertTypeId].title === 'AIRCRAFT_RETURN_TO_RAMP' || this.alertService.alertTypes[alert.alertTypeId].title === 'AIRCRAFT_DIVERSION' || this.alertService.alertTypes[alert.alertTypeId]?.title === 'LATE_ONGOING_PROCESS' ||  this.alertService.alertTypes[alert.alertTypeId].title === 'CTOT_VIOLATION' || this.alertService.alertTypes[alert.alertTypeId].title === 'OVERLAPPING_SECTORS' && alert?.__pair__?.departureLegId === null || this.alertService.alertTypes[alert.alertTypeId].title === 'OVERLAPPING_SECTORS' && !this.alert.__pair__.__prevDeparturePairModel__?.__arrivalLegModel__?.eta || this.alertService.alertTypes[alert.alertTypeId].severity === localSeverityEnum.LOW || alertService.alertTypes[alert.alertTypeId].title === 'ARRIVE_AT_CLOSED_AIRPORT'"
                class="btn" (click)="edit($event)" [attr.data-test]="'alert-edit-pair-id-' + alert.pairId">Edit
          <fa-icon [icon]="editIcon"></fa-icon>
        </button>
        <button type="button"
                [ngClass]="{'button-blue-outlined': this.alertService.alertTypes[alert.alertTypeId].severity !== localSeverityEnum.LOW, 'btn-outline-secondary': this.alertService.alertTypes[alert.alertTypeId].severity === localSeverityEnum.LOW}"
                [disabled]="this.alertService.alertTypes[alert.alertTypeId].severity === localSeverityEnum.LOW"
                class="btn" (click)="remindMeLaterClicked()" [attr.data-test]="'alert-remind-me-later-id-' + alert.id">
          Set Reminder
          <fa-icon [icon]="bellIcon"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<!--   <div class="col-12 flex flex-column">-->
<!--    <div class="col-12 flex">-->
<!--      <div class="col-3 flex flex-column justify-content-center">-->
<!--        <div>Intermidiate</div>-->
<!--        <div class="progress w-50 align-self-center">-->
<!--          <div class="progress-bar bg-warning" role="progressbar" style="width: 50%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-3">PAX Misconnection</div>-->
<!--      <div class="col-3">6/09/2022 12:04</div>-->
<!--      <div class="col-3"><a>A123 VIE-ATH</a></div>-->
<!--    </div>-->
<!--    <div style="background-color: #f2f2f9; border-top:2px solid #e0e0e4" class="col-12 mt-3" [ngClass]="{ 'collapse': this.activeAlertIndex !== alert.id }">-->
<!--      <div class="flex">-->
<!--        <div class="col-9">-->
<!--          <span class="fw-bold">Solution : </span>t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here'.-->
<!--        </div>-->
<!--        <div class="col-3">-->
<!--          <button type="button" class="btn btn-success me-2">Mark as Resolved</button>-->
<!--          <button type="button" class="btn button-blue">Set Reminder</button>-->
<!--        </div>-->
<!--    </div>-->
<!--    </div>-->
<!--   </div>-->
<!-- <div class="alert alert-danger" role="alert">
    A simple danger alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
  </div>
  <div class="alert alert-warning" role="alert">
    A simple warning alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
  </div>
  <div class="alert alert-info" role="alert">
    A simple info alert with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
  </div> -->
