<div class="container-fluid mt-4">
  <app-access-denied *ngIf="pageAccess === null"></app-access-denied>
  <div class="flex skip-invalid-border gap-5 position-relative" [formGroup]="form">
    <div class="filter flex">
      <input class="light-blue-border form-control filter-background" type="text" formControlName="date" ngbDatepicker
             #dp="ngbDatepicker" [restoreFocus]="true" data-test="user-assign-date-input" placeholder="Date"/>
      <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar date-selector-btn"
              (click)="dp.toggle()" type="button" data-test="assign-user-date-calendar-button"></button>
    </div>

    <div class="filter">
      <input class="light-blue-border form-control filter-background" type="text" formControlName="flightNr"
             placeholder="Flight Number" data-test="assign-flight-nr-input"/>
    </div>

    <div class="filter">
      <ng-select class="new-select" placeholder="Airport" formControlName="station"
                 data-test="assign-filter-station-select">
        <ng-option *ngFor="let station of stations" [value]="station?.iata">{{ station?.iata }}</ng-option>
      </ng-select>
    </div>

    <div class="filter">
      <ng-select class="new-select" placeholder="Assign Status" formControlName="status"
                 data-test="assign-status-select">
        <ng-option [value]="true">Assigned Flights</ng-option>
        <ng-option [value]="false">Not Assigned Flights</ng-option>
      </ng-select>
    </div>

  </div>
  <div class="mt-4 flex justify-content-between dark-blue gap-3">
    <div class="light-blue-border custom-card flex flex-column left-card">
      <div class="custom-card-header roboto p-3">
        Ramp Agents
      </div>
      <div class="p-3">
        <ng-container *ngIf="users?.length">
          <div class="flex flex-column gap-3">
            <div class="flex gap-3" *ngFor="let user of users">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" [attr.id]="'select-user-' + user.id"
                       [checked]="selectedUserId === user.id"
                       [attr.data-test]="'ramp-agent-' + user.firstname + '-' + user.lastname"
                       (change)="changedUser(user.id, $event)">
                <label class="form-check-label dark-blue"
                       [attr.for]="'select-user-' + user.id">{{ user.firstname }} {{ user.lastname }}
                  | {{ assignmentsPerUser[user.id] ?? 0 }}</label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="light-blue-border custom-card flex flex-column central-card">
      <div class="custom-card-header roboto p-3">
        Flights
      </div>
      <div class="py-2 flex flex-column justify-content-between flight-container">
        <div class="flex flex-column gap-1 overflow-auto ">
          <app-flight-list-item
            *ngFor="let flight of filteredFlights | slice:(currentPage * pageSize):((currentPage + 1) * pageSize); trackBy: trackByFunction"
            [leg]="flight"
            class="cursor-pointer"
            [assignedLegData]="legAssignmentsKV[flight.id]"
            [isSelected]="selectedLegIds?.includes(flight.id)"
            (itemClicked)="flightClicked(flight.id)"
            (unassignedClicked)="unassignAgent($event)"
            [deleteInProgress]="deleting?.includes(legAssignmentsKV[flight.id]?.id)"></app-flight-list-item>
        </div>
        <div class="flex flex-column">
          <div class="flex justify-content-between p-2 pagination-buttons">
            <button class="btn button-blue-outlined" [disabled]="currentPage === 0"
                    (click)="currentPage = currentPage - 1 ">
              Previous Flights
            </button>

            <button class="btn button-blue-outlined"
                    [disabled]="!this.filteredFlights?.length || !(this.filteredFlights?.length > 0 && this.currentPage + 1 < Math.ceil(this.filteredFlights?.length / this.pageSize))"
                    (click)="currentPage = currentPage + 1 ">
              Next Flights
            </button>
          </div>
          <div class="assign-section p-2 flex">
            <button class="btn button-blue m-auto assign-button" (click)="assignFlights()"
                    [disabled]="!selectedUserId || !selectedLegIds?.length || isBusy || pageAccess !== Access.RW">
              <ng-container *ngIf="!isBusy; else spinner">Assign Selected Flights</ng-container>
              <ng-template #spinner>
                <app-loading-spinner></app-loading-spinner>
              </ng-template>
            </button>
          </div>
        </div>

      </div>
    </div>

    <div class="right-chat-section">
      <div class="bg-white chat-selection vcard-container  light-blue-border">
        <div class="flex justify-content-center p-2 chats-bg" [ngClass]="{ 'inactive': !selectedUserId }">
          <div class="fw-normal mb-0 chats-text dark-blue">Chats</div>
        </div>
        <div class="select-pair p-2 m-2">
          <div class="text-center m-0 select-pair-text dark-blue" *ngIf="!selectedUserId">Select a user from the left
            side to chat
          </div>
          <ng-container *ngIf="selectedUserId">
            <app-chat-area [user]="usersKV[selectedUserId]" [canClose]="false"></app-chat-area>
          </ng-container>
        </div>


      </div>
    </div>
  </div>
</div>
