import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { IGsesModel } from '../shared/models/gses.model';
import { IAcType } from '../shared/models/ac-type.model';
import { Searchable } from '../shared/utils/searchable.type';
import { IGseExceptionsForAirport } from '../shared/models/gse-exceptions-for-airport.model';

@Injectable({
  providedIn: 'root'
})
export class GseService {
  private _gses: BehaviorSubject<IGsesModel[]> = new BehaviorSubject([]);
  get gses(): BehaviorSubject<IGsesModel[]> {
    return this._gses;
  }
  constructor(private httpClient: HttpClient, private toastService: ToastService) {
    this.fetchGses().subscribe(() => {});
  }

  // deleteGses(id: number): Observable<any> {
  //   return this.httpClient.delete(`${environment.api_base_url}gses/${id}`, {}).pipe(
  //     catchError((error) => {
  //       this.toastService.showError("Error deleting gses");
  //       return of({});
  //     })
  //   );
  // }

  deleteGses(processId: number, acTypeId?: number, airportId?: number, gseId?: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}gses/delete/${processId}/${acTypeId ?? 0}/${airportId ?? 0}/${gseId ?? 0}`, {}).pipe(
      catchError((error) => {
        console.error(error);
        this.toastService.showError("Error deleting gses");
        return of({});
      })
    );
  }

  fetchGses(filter: Searchable<IGsesModel> = { isActive: true }): Observable<IGsesModel[]> {
    return this.httpClient.post(`${environment.api_base_url}gses/search`, filter, {}).pipe(
      map((item: IResponse<IGsesModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
      tap((value: IAcType[]) => {
        if (!filter.isActive || Object.keys(filter).length > 1) {
          return;
        }
        this._gses.next(value);
      })
    );
  }

  saveGses(gse: Partial<IGsesModel>): Observable<IGsesModel> {
    let obs;
    if (gse.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}gses/${gse.id}`, gse, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}gses/create`, gse, {})
    }
    return obs.pipe(
      map((item: IResponse<IGsesModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        this.toastService.showError("Error saving process");
        return of(null);
      })
    );
  }

  saveAirportExceptions(airportId: number, processId: number, exceptions: IGseExceptionsForAirport): Observable<boolean> {
    const obs = this.httpClient.post(`${environment.api_base_url}gses/exceptions/` + processId + '/' + airportId, exceptions, {})
    return obs.pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        this.toastService.showError("Error saving process");
        return of(null);
      })
    );
  }

  saveAcTypeException(gses: IGsesModel): Observable<IGsesModel> {
    let obs;
    if (gses.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}gses/${gses.id}`, gses, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}gses/create`, gses, {})
    }
    return obs.pipe(
      map((item: IResponse<IGsesModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        this.toastService.showError("Error saving AC Type exception");
        return of(null);
      })
    );
  }

  saveAirportException(gses: IGsesModel): Observable<IGsesModel> {
    let obs;
    if (gses.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}gses/${gses.id}`, gses, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}gses/create`, gses, {})
    }
    return obs.pipe(
      map((item: IResponse<IGsesModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError((error) => {
        this.toastService.showError("Error saving airport exception");
        return of(null);
      })
    );
  }

}
