import {Component, OnInit} from '@angular/core';
import {IAirport} from '../../../shared/models/airport.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {take} from 'rxjs/operators';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {DeleteModalComponent} from '../../../shared/components/delete-modal/delete-modal.component';
import {AirportsService} from '../../../services/airports.service';
import {ToastService} from '../../../services/toast.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AirportsTestTagPipe} from '../../../shared/pipes/airports-test-tag.pipe';
import {PermissionService} from "../../../services/permission.service";
import {Access, PermissionUIMasks} from "../../../shared/constants/enums";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-settings-airport-management',
  templateUrl: './settings-airport-management.component.html',
  styleUrls: ['./settings-airport-management.component.scss']
})
export class SettingsAirportManagementComponent implements OnInit {

  airports: IAirport[];
  filteredAirports: IAirport[] = [];
  searchText: string;
  pageAccess: Access;
  accessPerAirport: Record<number, Access>;
  isEditable: (obj: IAirport) => boolean = (obj: IAirport) => this.accessPerAirport[-1] === Access.RW || this.accessPerAirport[obj.id] === Access.RW || this.accessPerAirport[0] === Access.RW && obj.iata === this.authService.userSubject.value.location;
  canViewCheck: (obj: IAirport) => boolean = (obj: IAirport) => this.accessPerAirport[-1] === Access.RW || !!this.accessPerAirport[obj.id] || !!this.accessPerAirport[0] && obj.iata === this.authService.userSubject.value.location;

  constructor(private router: Router, private modalService: NgbModal, private fns: AngularFireFunctions, private airportsService: AirportsService, private toastService: ToastService, private route: ActivatedRoute, private permissionService: PermissionService, private authService: AuthService) {
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_AIRPORT_MANAGEMENT).then((pageAccess) => {
      this.pageAccess = pageAccess;
      if (!this.pageAccess) {
        return;
      }
      this.permissionService.getPermissionAccessForAllAirports(PermissionUIMasks.WEB_SETTINGS_AIRPORT_MANAGEMENT).then((access) => this.accessPerAirport = access);
      this.airportsService.fetchAirports().subscribe((airports) => {
        this.airports = airports;
        this.filterAirports();
      });
    });
  }

  ngOnInit() {
  }

  filterAirports() {
    this.filteredAirports = this.airports.filter((airport) => {
      if (this.searchText?.length) {
        const search = this.searchText?.toLowerCase();
        return airport.title.toLowerCase().includes(search) || airport.iata.toLowerCase().includes(search) || airport.icao.toLowerCase().includes(search) || airport.country.toLowerCase().includes(this.searchText.toLowerCase());
      }
      return true;
    });
  }

  doAdd() {
    this.router.navigate(['add'], {relativeTo: this.route});
  }

  doEdit(item: string | any) {
    this.router.navigate([item], {relativeTo: this.route});
  }

  doDelete(item: string | any) {
    const airport = this.airports?.find((port) => port.id === +item);
    if (!item || !airport) {
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Airport ' + airport.iata;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.airportsService.deleteAirport(airport.id).subscribe(() => {
        this.toastService.showSuccess("The airport has been deleted");
        this.airportsService.fetchAirports().subscribe((results) => {
          this.airports = results;
          this.filterAirports();
        });
      });
    });
  }

  public readonly AirportsTestTagPipe = AirportsTestTagPipe;
  public readonly Access = Access;
}
