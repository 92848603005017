import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToString'
})
export class ArrayToStringPipe implements PipeTransform {

  transform(value: string[], separator: string): string {
    if (!value || value?.length === 0) {
      return '-';
    }
    return value?.join(separator);
  }

}
