import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {catchError, filter, map, Observable, of, switchMap, timeout} from 'rxjs';
import {AuthService} from './auth.service';
import {take} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {StaticUserGroupConstants} from "../shared/constants/static-user-group.constants";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router, private fireAuth: AngularFireAuth) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.fireAuth.authState.pipe(
      take(1),
      switchMap(user => {
        if (user) {
          return this.authService.userSubject.pipe(
            filter(userDetails => !!userDetails),
            timeout(5000),
            take(1),
            map(userDetails => {
              if (userDetails && userDetails.userGroup && userDetails.userGroup !== StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT) {
                return true;  // user data is loaded and user is an admin
              } else {
                this.router.navigate(['/login']); // if not admin, redirect to not-authorized page
                return false;
              }
            }),
            catchError(error => {
              // if timeout error, navigate to login
              this.router.navigate(['/login']);
              return of(false);
            })
          );
        } else {
          // user is not logged in
          this.router.navigate(['/login']);
          return of(false);
        }
      })
    );
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(next, state);
  }
}
