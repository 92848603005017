<div *ngIf="isLoadingTop" class="text-center mt-3 pt-3">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="leg-container">
<ng-container *ngFor="let pairOverview of pairOverviewList">
  <div class="mx-3">
    <app-leg *ngIf="pairOverview && pairOverview.id"
             [flightPairKey]="pairOverview.id.toString()"
             [pairOverview]="pairOverview"
             [passengerClasses]="passengerClasses"
             [timezone]="timezone"
             [isActive]="pairOverview.id.toString() === activePairIndex"
             [userRole]="userRole"
             [ngClass]="{ 'has-etd-request': pairOverview?.hasExtensionRequest, 'ta-in-progress': pairOverview.turnaroundInProgress }"
             (setPairDetail)="setSelectedPair($event)"
             (mainComponentClicked)="doSetPairIndex(pairOverview.id.toString())"
             [refreshSql]="refreshSql"
             [attr.data-test]="pairOverview | pairTestAttribute"
    ></app-leg>
  </div>

</ng-container>

</div>

<div *ngIf="pairOverviewList && pairOverviewList.length > 0" class="filter-container mx-3 flex flex-row justify-content-around">
  <app-pagination-button text="Previous Flights" [disabled]="page === 1" (onClick)="paginateBackward()"></app-pagination-button>
  <app-pagination-button text="Next Flights" [disabled]="!hasNextPage" (onClick)="paginateForward()"></app-pagination-button>
  <!-- <button class="btn btn-outline-light pagination-button flex-basis-20" (click)="paginateForward()">next</button> -->
</div>


<!-- <div *ngIf="flatPairList && flatPairList.length > 0"
     class="search-results"
     infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="0"
     (scrolled)="onScrollDown()"
     (scrolledUp)="onScrollUp()"
></div> -->
<div *ngIf="isLoadingBottom" class="text-center mt-3 pt-3">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div *ngIf="!pairOverviewList || pairOverviewList.length === 0" class="text-center default-message-container dark-blue">
  <p>No pairs are available!</p>
  <p>Please change your filter selection to see more pairs.</p>
</div>
<!--
<app-leg
[legState]="states.ERROR"
></app-leg>

<app-leg
[legState]="states.ERROR"
[ctotState]="states.ERROR"
[iconMemo]="true"
[iconWarning]="true"
[isActive]="false"
></app-leg>

<app-leg
[legState]="states.WARNING"
[iconWarning]="true"
[iconMessage]="true"
[iconMemo]="true"
></app-leg>

<app-leg
[legState]="states.OK"
></app-leg>

<app-leg
[iconMemo]="true"
></app-leg>

<app-leg
[iconCheck]="true"
></app-leg>
-->
