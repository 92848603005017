import {Component, OnInit} from '@angular/core';
import {IUser} from "../../shared/models/user.model";
import {IAirport} from "../../shared/models/airport.model";
import {IGlobalCountry} from "../../shared/models/global-country.model";
import {IAirlineDesignator} from "../../shared/models/airline-designator.model";
import {forkJoin, Subject} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AirportsService} from "../../services/airports.service";
import {ToastService} from "../../services/toast.service";
import {take} from "rxjs/operators";
import {DeleteModalComponent} from "../../shared/components/delete-modal/delete-modal.component";
import {ActivatedRoute, Router} from "@angular/router";
import {IBasicPermissionsModel} from "../../shared/models/basic-permissions.model";
import {BasicPermissionsService} from "../../services/basic-permissions.service";
import {IBasicPermissionApplicationModel} from "../../shared/models/basic-permission-application.model";
import {HasAccess} from "../../shared/constants/has-access.enum";
import {Access, App, PermissionUIMasks} from "../../shared/constants/enums";
import {IUserGroupsModel} from "../../shared/models/user-group.model";
import {UserService} from "../../services/user.service";
import {AddRoleComponent} from "./add-role/add-role.component";
import {PermissionService} from "../../services/permission.service";
import {RoleManagementTestTagPipe} from "../../shared/pipes/role-management-test-tag.pipe";

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss']
})
export class RoleManagementComponent implements OnInit {

  users: IUser[];
  airports: IAirport[];
  countryList: IGlobalCountry[];
  airlineDesignators: IAirlineDesignator[];
  unsubscribe$ = new Subject();

  permissions: IBasicPermissionsModel[];
  permissionsApplication: IBasicPermissionApplicationModel[];
  rolePermissions: Record<string, {
    permissions: IBasicPermissionsModel[],
    permissionApplications: IBasicPermissionApplicationModel[]
  }> = {};

  rolePermissionsPerAirport: { [roleName: string]: { [airportId: number]: number[] } } = {};

  searchText: string = '';
  completeTableData: {
    role: string;
    airports: string;
    web: { read: HasAccess, write: HasAccess },
    mobile: { read: HasAccess, write: HasAccess },
    id: string,
    userGroupId: number;
  }[] = [];
  userGroups: IUserGroupsModel[];
  filteredTableData: {
    role: string;
    airports: string;
    web: { read: HasAccess, write: HasAccess },
    mobile: { read: HasAccess, write: HasAccess },
    id: string,
    userGroupId: number;
  }[] = [];

  pageAccess: Access;

  isEditable = (obj: any) => obj.userGroupId > 6;
  isViewable = (obj: any) => obj.userGroupId <= 6;

  constructor(private modalService: NgbModal, private airportService: AirportsService, private toastService: ToastService, private router: Router, private route: ActivatedRoute, private basicPermissionsService: BasicPermissionsService, private userService: UserService, private permissionService: PermissionService) {
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_ROLE_MANAGEMENT).then((pageAccess) => {
      this.pageAccess = pageAccess;
      if (!this.pageAccess) {
        return;
      }
      forkJoin([this.airportService.fetchAirports(), this.basicPermissionsService.fetchBasicPermissions(), this.basicPermissionsService.fetchBasicPermissionsApplication(), userService.fetchUserGroups()]).subscribe(([airports, permissions, permissionsApplication, userGroups]) => {
        this.airports = airports;
        this.permissions = permissions;
        this.permissionsApplication = permissionsApplication;
        this.userGroups = userGroups;
        this.generatePermissionPerAirport();
        this.generateTable();
      });
    });
  }

  generatePermissionPerAirport() {
    this.rolePermissionsPerAirport = {};
    for (const perm of this.permissionsApplication) {
      if (!this.rolePermissionsPerAirport[perm.role]) {
        this.rolePermissionsPerAirport[perm.role] = {};
      }
      if (!this.rolePermissionsPerAirport[perm.role][perm.airportId ?? -1]) {
        this.rolePermissionsPerAirport[perm.role][perm.airportId ?? -1] = [];
      }
      this.rolePermissionsPerAirport[perm.role][perm.airportId ?? -1].push(perm.basicPermissionId);
    }

    for (const role in this.rolePermissionsPerAirport) {
      for (const airportId in this.rolePermissionsPerAirport[role]) {
        this.rolePermissionsPerAirport[role][airportId ?? -1].sort();
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  ngOnInit() {

  }

  doAdd() {
    const modalRef = this.modalService.open(AddRoleComponent, {size: 'md'});
    const modal = modalRef.componentInstance as AddRoleComponent;
    modal.userGroups = this.userGroups.map((group) => group.title);
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.userService.fetchUserGroups().subscribe((result) => {
        if (result?.length) {
          this.userGroups = result;
          this.generateTable();
        }
      });
    });
  }

  doEdit(item: string | any) {
    // const id = this.permissions.find((perm) => perm.role === item)?.userGroupId;
    // const airports = new Set(this.permissionsApplication.filter((perm) => perm.role === item)?.map((perm) => perm.airportId));
    this.router.navigate([item], {relativeTo: this.route});
  }

  doDelete(item: string | any) {
    const userGroupSplit = item?.split('_');
    const airports: number[] = [];
    for (const airportId of userGroupSplit?.[1]?.split('|') ?? []) {
      airports.push(Number(airportId) ?? -1);
    }
    const userGroup = this.userGroups.find((permission) => permission.id === +userGroupSplit[0]);
    if (!item || !this.permissions || !userGroupSplit[0] || !userGroup) {
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Role ' + userGroup.title + ' and its associated permission set';
    modalRef.componentInstance.title = 'Delete role ' + userGroup.title;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.basicPermissionsService.deletePermissions({
        userGroupId: userGroup.id,
        airports,
        uiMasks: {},
      }).subscribe((result) => {
        if (result) {
          this.toastService.showSuccess("Role has been deleted");
          forkJoin([this.basicPermissionsService.fetchBasicPermissions(), this.basicPermissionsService.fetchBasicPermissionsApplication(), this.userService.fetchUserGroups()]).subscribe(([basicPermissions, permissionApplications, userGroups]) => {
            this.userGroups = userGroups;
            this.permissions = basicPermissions;
            this.permissionsApplication = permissionApplications;
            this.generatePermissionPerAirport();
            this.generateTable();
          });
        }
      });
    });
  }

  generateTable() {
    this.completeTableData = [];
    const data: Record<string, number[]> = {};
    for (const role in this.rolePermissionsPerAirport) {
      for (const airport in this.rolePermissionsPerAirport[role]) {
        const basicPermissionIds = this.rolePermissionsPerAirport[role][+airport];
        if (!data[basicPermissionIds.join(',')]) {
          data[basicPermissionIds.join(',')] = [];
        }
        data[basicPermissionIds.join(',')].push(+airport);
      }
    }
    for (const key in data) {
      const permissionIds: string[] = key.split(',');
      const role = this.permissions.find((perm) => perm.id === +permissionIds[0])?.role;
      const accessTypes = new Set<string>();
      const appTypes = new Set<string>();
      for (const permission of this.permissions) {
        accessTypes.add(permission.access);
        appTypes.add(permission.app);
      }
      const webPermissions = this.permissions.filter((p) => p.role === role && p.app === App.ARW_WEB && permissionIds.includes(String(p.id)));
      const webReadAccess: HasAccess = webPermissions.some((perm) => !!perm.access) ? HasAccess.YES : HasAccess.NO;
      const webWriteAccess: HasAccess = webPermissions.some((perm) => perm.access === Access.RW) ? HasAccess.YES : HasAccess.NO;

      const mobilePermissions = this.permissions.filter((p) => p.role === role && p.app === App.ARW_MOBILE && permissionIds.includes(String(p.id)));
      const mobileReadAccess: HasAccess = mobilePermissions.some((perm) => !!perm.access) ? HasAccess.YES : HasAccess.NO;
      const mobileWriteAccess: HasAccess = mobilePermissions.some((perm) => perm.access === Access.RW) ? HasAccess.YES : HasAccess.NO;
      console.log('perm:', data[key]);
      const airports = this.airports.filter((airport) => data[key].includes(airport.id)).map((airport) => airport.iata);
      const airportIds = this.airports.filter((airport) => data[key].includes(airport.id)).map((airport) => airport.id);
      if (data[key]?.includes(-1)) {
        airports.push('All');
        airportIds.push(-1);
      } else if (data[key]?.includes(0)) {
        airports.push('Inherit from user');
        airportIds.push(0);
      }
      this.completeTableData.push({
        role: role,
        airports: airports.join(', '),
        web: {read: webReadAccess, write: webWriteAccess},
        mobile: {read: mobileReadAccess, write: mobileWriteAccess},
        id: this.permissions.find((perm) => perm.id === +permissionIds[0])?.userGroupId + '_' + airportIds.join('|'),
        userGroupId: this.permissions.find((perm) => perm.id === +permissionIds[0])?.userGroupId
      });
    }
    for (const userGroup of this.userGroups.filter((group) => group.internalName !== 'ADMIN' && group.internalName !== 'DUTY_MANAGER' && group.internalName !== 'OPS_CONTROLLER' && group.internalName !== 'RAMP_AGENT' && group.internalName !== 'OBSERVER')) {
      if (this.completeTableData.findIndex((data) => data.role === userGroup.title) !== -1) {
        continue;
      }
      this.completeTableData.push({
        role: userGroup.title,
        airports: '',
        web: {read: HasAccess.NO, write: HasAccess.NO},
        mobile: {read: HasAccess.NO, write: HasAccess.NO},
        id: userGroup.id.toString(),
        userGroupId: userGroup.id
      })
    }
    console.log('Complete table data after insertion:', this.completeTableData);
    this.filterData();
  }

  filterData() {
    this.filteredTableData = this.completeTableData.filter((td) => !this.searchText?.length || td.role.toLowerCase().includes(this.searchText?.toLowerCase()) || td.airports.includes(this.searchText?.toUpperCase()));
  }

  public readonly Access = Access;
  public readonly RoleManagementTestTagPipe = RoleManagementTestTagPipe;
}
