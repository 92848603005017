import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAlertsModel } from '../../../shared/models/alerts.model';
import { AlertService } from '../../../services/alert.service';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { IGenericContainerObject } from '../../../shared/models/genericContainerObject.model';
import { IDelayModel } from '../../../shared/models/delays.model';
import { TimezoneService } from '../../../services/timezone.service';
import { AlertTypesEnum } from '../../../shared/constants/alert-types.enum';
import { forkJoin } from 'rxjs';
import { ISimpleData } from '../../../shared/models/simpleData.model';

@Component({
  selector: 'app-edit-pair-dialog',
  templateUrl: './edit-pair-dialog.component.html',
  styleUrls: ['./edit-pair-dialog.component.scss']
})
export class EditPairDialogComponent implements OnInit {

  title = 'Edit pair';
  alert: IAlertsModel;
  alertTypes = AlertTypesEnum;

  dateFormat = 'DD/MM/YYYY';
  delayCodes: IDelayModel[];
  passengerClasses: ISimpleData[];
  timezone: string;
  constructor(private activeModal: NgbActiveModal,
              public alertService: AlertService,
              private generalSettingsService: GeneralSettingsService,
              public timezoneService: TimezoneService) { }


  ngOnInit() {
    forkJoin([this.generalSettingsService.getDelayCodes(), this.generalSettingsService.getPassengerClasses()]).subscribe((results) => {
      this.delayCodes = results[0];
      this.passengerClasses = results[1];
      this.dateFormat = this.generalSettingsService.generalSettings.value.dateFormat;
    })
    
  }
  closeModal() {
    this.activeModal.dismiss();
  }
}
