import {Component, OnInit} from '@angular/core';
import {IMemoModel} from '../../models/memo.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MemosService} from '../../../services/memos.service';
import {ToastService} from '../../../services/toast.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IUser} from '../../models/user.model';
import {forkJoin, Observable} from 'rxjs';
import {IPairDetail} from '../../models/pair-detail.model';
import {Access} from "../../constants/enums";

@Component({
  selector: 'app-add-edit-memos-dialog',
  templateUrl: './add-edit-memos-dialog.component.html',
  styleUrls: ['./add-edit-memos-dialog.component.scss']
})
export class AddEditMemosDialogComponent implements OnInit {
  isBusy = false;
  pair?: IPairDetail;
  arrivalMemo?: IMemoModel;
  departureMemo?: IMemoModel;
  arrivalMemoUser?: IUser;
  departureMemoUser?: IUser;
  form?: UntypedFormGroup;
  title?: string;
  flightTypeSelection?: string;
  userRole?: string;

  permission: Access;


  constructor(private fb: UntypedFormBuilder, private memoService: MemosService, private toastService: ToastService, private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.generateForm(this.fb);
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    if (!this.form.value.arrivalText.length && !this.form.value.departureText.length) {
      return;
    }
    const observables: Observable<any>[] = [];
    if (this.form.value.arrivalText.length && this.permission === Access.RW) {
      observables.push(this.memoService.saveMemo({
        id: this.arrivalMemo?.id ?? undefined,
        legId: this.pair.arrivalLeg?.id,
        content: this.form.value.arrivalText,
        pairId: this.pair.id,
        userGroupId: 1,
        role: this.userRole,
      }));
    }

    if (this.form.value.departureText.length && this.permission === Access.RW) {
      observables.push(this.memoService.saveMemo({
        id: this.departureMemo?.id ?? undefined,
        legId: this.pair.departureLeg?.id,
        content: this.form.value.departureText,
        pairId: this.pair.id,
        userGroupId: 1,
        role: this.userRole,
      }));
    }

    this.isBusy = true;
    forkJoin(observables).subscribe((results) => {
      if (!results.includes(null)) {
        this.toastService.showSuccess('Memos has been saved');
        for (const result of results) {
          if (this.pair.departureLeg?.id === result.legId && this.departureMemo) {
            this.departureMemo.content = result.content;
          }
          if (this.pair.arrivalLeg?.id === result.legId && this.arrivalMemo) {
            this.arrivalMemo.content = result.content;
          }
        }
        this.closeModal();
      }
      this.isBusy = false;
    });
  }

  generateForm(fb: UntypedFormBuilder) {
    this.form = fb.group({
      arrivalText: [''],
      departureText: [''],
    });

    this.flightTypeSelection = 'arrival';

    if (this.arrivalMemo && this.permission) {
      this.form.patchValue({
        arrivalText: this.arrivalMemo.content
      });
    }

    if (this.departureMemo && this.permission) {
      this.form.patchValue({
        departureText: this.departureMemo.content
      });
    }

    if (!this.pair.arrivalLeg?.id) {
      this.flightTypeSelection = 'departure';
    }

    if (!this.pair.departureLeg?.id) {
      this.flightTypeSelection = 'arrival';
    }
  }
}
