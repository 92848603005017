import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { NgbActiveModal, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AirportsService } from '../../../../../services/airports.service';
import { ToastService } from '../../../../../services/toast.service';
import { ISlotTimeTolerance } from '../../../../../shared/models/slotTimeTolerance.model';
import { TimeFromMinutesPipe } from '../../../../../shared/pipes/time-from-minutes.pipe';
import { MinutesFromTimePipe } from '../../../../../shared/pipes/minutes-from-time.pipe';

@Component({
  selector: 'app-add-edit-airport-slot-time-tolerance-dialog',
  templateUrl: './add-edit-airport-slot-time-tolerance-dialog.component.html',
  styleUrls: ['./add-edit-airport-slot-time-tolerance-dialog.component.scss'],
})
export class AddEditAirportSlotTimeToleranceDialogComponent implements OnInit {

  form?: UntypedFormGroup;
  @Input() slotTimeToleranceData?: ISlotTimeTolerance;
  @Input() title?: string;
  @Input() airportId?: number;
  isBusy = false;

  destroySubject = new Subject();

  constructor(private cd: ChangeDetectorRef, private activeModal: NgbActiveModal, private airportService: AirportsService, private toastService: ToastService, private timeFromMinutesPipe: TimeFromMinutesPipe, private minsFromTimePipe: MinutesFromTimePipe) {
  }

  ngOnInit() {
    this.fetchData();
  }

  ngOnDestroy() {
    this.destroySubject.next(null);
    this.destroySubject.complete();
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    const data =  this.form.value;
    data.departureLateInMinutes = this.minsFromTimePipe.transform(data?.departureLateInMinutes);
    data.departureEarlyInMinutes = this.minsFromTimePipe.transform(data?.departureEarlyInMinutes);
    data.arrivalLateInMinutes = this.minsFromTimePipe.transform(data?.arrivalLateInMinutes);
    data.arrivalEarlyInMinutes = this.minsFromTimePipe.transform(data?.arrivalEarlyInMinutes);
    data.airportId = this.airportId;
    data.id = this.slotTimeToleranceData?.id;
    if(!data.id)
    {
      data.id = undefined;
    }
    this.isBusy = true;
    this.airportService.saveAirportSlotTimeTolerance(data).subscribe((result) => {
      if(result?.id) {
        this.toastService.showSuccess('The slot time tolerance has been saved!');
        this.closeModal();
      }
      this.isBusy = false;
    });
  }

  fetchData() {
    this.airportService.fetchAirportSlotTimeTolerance(this.airportId).subscribe((result) => {
      this.slotTimeToleranceData = result?.length ? result[0] : undefined;
      const depEarlyMinutes = this.timeFromMinutesPipe.transform(result?.length ? result[0]?.departureEarlyInMinutes : 0);
      const depLateMinutes = this.timeFromMinutesPipe.transform(result?.length ? result[0]?.departureLateInMinutes : 0);
      const arrEarlyMinutes = this.timeFromMinutesPipe.transform(result?.length ? result[0]?.arrivalEarlyInMinutes : 0);
      const arrLateMinutes = this.timeFromMinutesPipe.transform(result?.length ? result[0]?.arrivalLateInMinutes : 0);
      this.form = new UntypedFormGroup({
        departureEarlyInMinutes: new UntypedFormControl(depEarlyMinutes, Validators.required),
        departureLateInMinutes: new UntypedFormControl(depLateMinutes, Validators.required),
        arrivalEarlyInMinutes: new UntypedFormControl(arrEarlyMinutes, Validators.required),
        arrivalLateInMinutes: new UntypedFormControl(arrLateMinutes, Validators.required),
      });
    });
  }
}
