<div class="modal-header">
    <span class="modal-title">{{title}}</span>
    <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="divert-close-button">
    </button>
  </div>
<div class="modal-body">
<form [formGroup]="form">
    <div class="container">
      <div class="row">
        <div class="col-7">
          <div class="container">
            <div class="mb-3 flightInfo">
             <b>{{leg?.departureStation}} {{leg?.std | date:'HH:mm':'+00:00'}}z - {{leg?.arrivalStation}} {{leg?.sta | date:'HH:mm':'+00:00'}}z ({{leg?.acType}})</b>
            </div>
            <div class="row mb-2">
              <div class="col-5 form-field-header">
                Diversion Airport
              </div>
              <div class="col-3 form-field-header">
                <select class="form-control form-select" id="airport" formControlName="airport" data-test="divert-airport-select">
                    <option *ngFor="let airport of airports | keyvalue" [value]="airport.value.id">{{airport.value.iata}}</option>
                  </select>
              </div>
            </div>
            <div class="row">
              <div class="col-5 form-field-header">
                ETA
              </div>
              <div class="col-4">
                <div class="mb-3">
                  <div class="input-group">
                    <input class="form-control" [placeholder]="dateFormat"
                           name="dp" formControlName="etaDate" ngbDatepicker #d="ngbDatepicker" data-test="divert-date-input">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar" (click)="d.toggle()" type="button" data-test="divert-calendar-button"></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3" style="display:flex;" [ngClass]="">
                <ngb-timepicker appTimepickerFocus name="newEtdTime" formControlName="etaTime" [spinners]="false" data-test="divert-eta-time-picker"></ngb-timepicker>
              </div>
            </div>

          </div>
        </div>
        <div class="col-5">
            <div class="container flex " style="margin-top: 43px">
                    <div class="form-field-header col-2">SI</div>
                    <div class="col-10">
                    <textarea  class="form-control" name="si" formControlName="si" data-test="divert-si-textarea"></textarea>
                    </div>
            </div>
        </div>
      </div>
    </div>
  </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary settings-modal-button" [disabled]="form?.disabled || isBusy" (click)="closeModal()" data-test="divert-cancel-button">Cancel
    </button>
    <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
            [disabled]="form?.disabled || form?.invalid || isBusy" data-test="divert-save-button">
      {{ isBusy ? '' : 'Save' }}
      <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
    </button>
  </div>
