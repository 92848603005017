import { Pipe, PipeTransform } from '@angular/core';
import { MessageTestTagPipe } from './message-test-tag.pipe';
import { AirlineDesignatorTestTagPipe } from './airline-designator-test-tag.pipe';
import { PassengerClassesTestTagPipe } from './passenger-classes-test-tag.pipe';
import { FlightServiceTypesTestTagPipe } from './flight-service-types-test-tag.pipe';
import { DelayCodesTestTagPipe } from './delay-codes-test-tag.pipe';
import { MeasurementUnitsTestTagPipe } from './measurement-units-test-tag.pipe';
import { UsersTestTagPipe } from './users-test-tag.pipe';
import { AcTypesTestTagPipe } from './ac-types-test-tag.pipe';
import { SeatingConfigurationsTestTagPipe } from './seating-configurations-test-tag.pipe';
import { AcRegistrationsTestTagPipe } from './ac-registrations-test-tag.pipe';
import { AirportsTestTagPipe } from './airports-test-tag.pipe';
import { SlaGseTestTagPipe } from './sla-gse-test-tag.pipe';
import { AirportTerminalsTestTagPipe } from './airport-terminals-test-tag.pipe';
import { AirportContactsTestTagPipe } from './airport-contacts-test-tag.pipe';
import { InterfacesTestTagPipe } from './interfaces-test-tag.pipe';

@Pipe({
  name: 'testTag',
})
export class TestTagPipe implements PipeTransform {

  constructor(private messagePipe: MessageTestTagPipe, private airlineDesignatorPipe: AirlineDesignatorTestTagPipe,
              private passengerClassesTestTagPipe: PassengerClassesTestTagPipe,
              private flightServiceTypesTestTagPipe: FlightServiceTypesTestTagPipe,
              private delayCodesTestTagPipe: DelayCodesTestTagPipe,
              private measurementUnitsTestTagPipe: MeasurementUnitsTestTagPipe,
              private usersTestTagPipe: UsersTestTagPipe,
              private acTypesTestTagPipe: AcTypesTestTagPipe,
              private seatingConfigurationsTestTagPipe: SeatingConfigurationsTestTagPipe,
              private acRegistrationsTestTagPipe: AcRegistrationsTestTagPipe,
              private airportsTestTagPipe: AirportsTestTagPipe,
              private slaGseTestTagPipe: SlaGseTestTagPipe,
              private airportTerminalTestTagPipe: AirportTerminalsTestTagPipe,
              private airportContactTestTagPipe: AirportContactsTestTagPipe,
              private interfacesTestTagPipe: InterfacesTestTagPipe) {
  }

  transform(value: any, ...args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    const pipe: typeof MessageTestTagPipe = args[0] as any;
    if (!pipe) {
      return 'invalid-pipe-parameter';
    }
    switch(pipe)
    {
      case MessageTestTagPipe:
        return this.messagePipe.transform(value);
      case AirlineDesignatorTestTagPipe:
        return this.airlineDesignatorPipe.transform(value);
      case PassengerClassesTestTagPipe:
        return this.passengerClassesTestTagPipe.transform(value);
      case FlightServiceTypesTestTagPipe:
        return this.flightServiceTypesTestTagPipe.transform(value);
      case DelayCodesTestTagPipe:
        return this.delayCodesTestTagPipe.transform(value);
      case MeasurementUnitsTestTagPipe:
        return this.measurementUnitsTestTagPipe.transform(value);
      case UsersTestTagPipe:
        return this.usersTestTagPipe.transform(value);
      case AcTypesTestTagPipe:
        return this.acTypesTestTagPipe.transform(value);
      case SeatingConfigurationsTestTagPipe:
        return this.seatingConfigurationsTestTagPipe.transform(value);
      case AcRegistrationsTestTagPipe:
        return this.acRegistrationsTestTagPipe.transform(value);
      case AirportsTestTagPipe:
        return this.airportsTestTagPipe.transform(value);
      case SlaGseTestTagPipe:
        return this.slaGseTestTagPipe.transform(value);
      case AirportTerminalsTestTagPipe:
        return this.airportTerminalTestTagPipe.transform(value);
      case AirportContactsTestTagPipe:
        return this.airportContactTestTagPipe.transform(value);
      case InterfacesTestTagPipe:
        return this.interfacesTestTagPipe.transform(value);
    }
    return 'invalid-pipe-parameter';
  }

}
