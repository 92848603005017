import {Injectable} from '@angular/core';
import {catchError, map, Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {IResponse} from '../shared/models/IResponse.model';
import {HttpClient} from '@angular/common/http';
import {ToastService} from './toast.service';
import {IUserLegAssignmentModel} from "../shared/models/user-leg-assignment.model";

@Injectable({
  providedIn: 'root'
})
export class UserLegAssignmentService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) {
  }

  getUserLegAssignment(filter: IUserLegAssignmentModel = {isActive: true}): Observable<IUserLegAssignmentModel[]> {
    return this.httpClient.post(`${environment.api_base_url}user-leg-assignment/search`, filter, {}).pipe(
      map((item: IResponse<IUserLegAssignmentModel[]>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  deleteUserLegAssignment(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.api_base_url}user-leg-assignment/${id}`, {}).pipe(
      catchError((error) => {
        this.toastService.showError("Error deleting ramp agent from leg");
        return of({});
      })
    );
  }

  saveUserLegAssignment(leg: IUserLegAssignmentModel): Observable<IUserLegAssignmentModel> {
    let obs;
    if (leg.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}user-leg-assignment/${leg.id}`, leg, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}user-leg-assignment/create`, leg, {})
    }
    return obs.pipe(
      map((item: IResponse<IUserLegAssignmentModel>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError(() => {
        this.toastService.showError("Error saving ramp agent assignment");
        return of(null);
      })
    );
  }
}
