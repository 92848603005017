import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FlightsFilterService} from '../../../services/flights-filter.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {FlightsFilter, IFlightsFilter} from '../../models/filters/flights-filter.model';
import {Subject} from 'rxjs';
import {IUser} from '../../models/user.model';
import {GeneralSettingsService} from '../../../services/general-settings.service';
import {AirportsService} from '../../../services/airports.service';
import {TimespanFilterValuesConstant} from '../../constants/timespan-filter-values.constant';
import {StaticUserGroupConstants} from "../../constants/static-user-group.constants";

@Component({
  selector: 'app-execution-filter',
  templateUrl: './execution-filter.component.html',
  styleUrls: ['./execution-filter.component.scss']
})
export class ExecutionFilterComponent implements OnInit, OnDestroy {

  @Input() user: IUser;
  @Input() dateFilter: boolean;
  datePlaceholder = 'DD/MM/YYYY'

  filterForm: UntypedFormGroup;
  unsubscribe$ = new Subject();
  stations?: string[] = [];

  constructor(private fb: UntypedFormBuilder, private flightsFilterService: FlightsFilterService, private generalSettingsService: GeneralSettingsService, private airportService: AirportsService) {
  }

  ngOnInit() {
    this.filterForm = this.createForm();
    this.onChanges(this.filterForm);
    this.airportService.fetchAirports().subscribe((airports) => {
      this.stations.push(...(airports.map((val) => val.iata)));
      if (this.user && this.user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER) {
        this.filterForm.patchValue({
          station: this.user.location
        });

        this.filterForm.get('station').disable();
      }
    });

    this.generalSettingsService.getGeneralSettings().pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      this.datePlaceholder = result?.[0]?.dateFormat || 'DD/MM/YYYY';
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
    this.flightsFilterService.resetFilters();
  }

  createForm(): UntypedFormGroup {
    return this.fb.group({
      timespan: [3],
      date: [null],
      station: [this.user?.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER ? {
        value: null,
        disabled: true
      } : null],
      status: [null],
      alerts: [null],
      sortBy: [null],
      showUnpaired: [false],
      acRegistration: [''],
      flightNumber: [''],
    });
  }

  onChanges(form: UntypedFormGroup) {
    form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((val: IFlightsFilter) => {
      const values = new FlightsFilter(val);
      this.flightsFilterService.setFilterValues(values);
    });
  }

  public readonly TimespanFilterValuesConstant = TimespanFilterValuesConstant;
}
