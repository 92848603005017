import { Pipe, PipeTransform } from '@angular/core';
import { IDelayCode } from '../models/delay-code.model';

@Pipe({
  name: 'delayCodesTestTag'
})
export class DelayCodesTestTagPipe implements PipeTransform {

  transform(value: IDelayCode, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `delay-code-${value?.code}`;
  }

}
