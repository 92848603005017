import {Component, OnInit} from '@angular/core';
import {AlertService} from "../../../services/alert.service";
import {IAlertTypesModel} from "../../../shared/models/alert-types.model";
import {ActivatedRoute, Router} from "@angular/router";
import {forkJoin} from "rxjs";
import {IAlertTypeSettings} from "../../../shared/models/alert-type-settings.model";
import {AlertsTestTagPipe} from "../../../shared/pipes/alerts-test-tag.pipe";
import {AlertConstants} from "../../../shared/constants/alert.constants";
import {Access, PermissionUIMasks} from "../../../shared/constants/enums";
import {PermissionService} from "../../../services/permission.service";

@Component({
  selector: 'app-settings-alert-management',
  templateUrl: './settings-alert-management.component.html',
  styleUrls: ['./settings-alert-management.component.scss']
})
export class SettingsAlertManagementComponent implements OnInit {

  alertTypes: IAlertTypesModel[];
  alertSettings: IAlertTypeSettings[];
  pageAccess: Access;

  constructor(private alertService: AlertService, private router: Router, private route: ActivatedRoute, private permissionService: PermissionService) {
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_ALERT_MANAGEMENT).then((pageAccess) => {
      this.pageAccess = pageAccess;
      if (!this.pageAccess) {
        return;
      }
      forkJoin([this.alertService.getAlertTypes(), this.alertService.getAlertTypeSettings()]).subscribe(([alertTypes, alertSettings]) => {
        const acarsMvtAlert = alertTypes.find((alert) => alert.id === AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_ATD);
        if (acarsMvtAlert) {
          acarsMvtAlert.description = 'Time Difference between MVT and ACARS of same event times';
        }
        this.alertTypes = alertTypes.filter((alert) => alert.id < AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_ATA || alert.id > AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_TOT);
        this.alertSettings = alertSettings;
        for (const alertType of alertTypes) {
          const settings = this.alertSettings.find((setting) => setting.alertTypesId === alertType.id);
          if (settings?.severityOverride) {
            alertType.severity = settings.severityOverride;
          }
        }
      });
    });
  }

  ngOnInit(): void {
  }

  doEdit(item: number) {
    this.router.navigate([item], {relativeTo: this.route});
  }

  public readonly AlertsTestTagPipe = AlertsTestTagPipe;
  public readonly Access = Access;
}
