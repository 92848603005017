<div class="conversation-container avitium-scrollbar position-relative">
  <app-access-denied *ngIf="readonly"></app-access-denied>


  <div class="row gx-0 mb-2" *ngFor="let convo of conversation; let i = index">
    <div class="text-center datetime"
         *ngIf="i === conversation.length-1 || dateDiff(conversation[i+1].createdAt, convo.createdAt) > 30">{{ convo.createdAt | date: 'MMM d, y, hh:mm':(timezone$ | async) }}
    </div>
    <app-message-item [ownMessage]="+convo.createdBy === authService.user.id" [message]="convo" [user]="user"
                      [attr.data-test]="'message-' + convo.id"></app-message-item>
  </div>

</div>
