import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {IGenericContainerObject} from '../../shared/models/genericContainerObject.model';
import {IUser} from '../../shared/models/user.model';
import {UserRoles} from '../../shared/constants/user-roles.constants';
import {AuthService} from '../../services/auth.service';
import {ChatService} from '../../services/chat.service';
import {debounceTime, firstValueFrom, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IMyChatGroups} from '../../shared/models/my-chat-groups.interface';
import {IChatMessageModel} from '../../shared/models/chat-message.model';
import {PermissionService} from "../../services/permission.service";
import {Access, PermissionUIMasks} from "../../shared/constants/enums";
import {AirportsService} from "../../services/airports.service";
import {IAirport} from "../../shared/models/airport.model";
import {StaticUserGroupConstants} from "../../shared/constants/static-user-group.constants";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  formGroup = new UntypedFormGroup({
    searchText: new UntypedFormControl(null),
    sort: new UntypedFormControl(null)
  });

  popoverFormGroup = new UntypedFormGroup({
    searchText: new UntypedFormControl(null)
  });
  userRoleObject = UserRoles;
  activeIndex = 0;
  users: IGenericContainerObject<IUser> = {};
  availableChatUsers: IUser[] = [];
  popoverUsers: IUser[] = [];
  messageGroups: IMyChatGroups[] = [];
  filteredGroups: IMyChatGroups[] = [];
  destroy$ = new Subject();
  pageAccess: Access;
  chatAccessPerAirport: Record<number, Access> = {};
  airports: IAirport[];

  constructor(private userService: UserService, public authService: AuthService, public chatService: ChatService, private permissionService: PermissionService, private airportService: AirportsService) {
  }

  ngOnInit(): void {
    Promise.all([this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_CHAT), firstValueFrom(this.airportService.fetchAirports())]).then(async ([pageAccess, airports]) => {
      this.pageAccess = pageAccess;
      if (!this.pageAccess) {
        return;
      }
      this.airports = airports;
      this.chatAccessPerAirport = await this.permissionService.getPermissionAccessForAllAirports(PermissionUIMasks.WEB_CHAT);
      this.userService.fetchUsers().subscribe((result) => {
        for (const user of result) {
          if (user.id === this.authService.user.id) {
            continue;
          }
          this.users[user.id] = user;
        }
        if (this.authService.user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER) {
          this.availableChatUsers = Object.values(this.users).filter((user) => user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER || user.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT && user.location === this.authService.user.location);
        } else {
          this.availableChatUsers = result.filter((user) => !!this.chatAccessPerAirport[airports.find((airport) => airport.iata === user.location)?.id] || !!this.chatAccessPerAirport[-1]);
        }
        this.popoverUsers = this.availableChatUsers;
      });
      this.messageGroups = Object.values(this.chatService.messageGroups);
      this.popoverFormGroup.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(250)).subscribe((change) => {
        // this.popoverUsers = Object.values(this.users).filter((user) => user?.location?.toLowerCase() === change.searchText?.toLowerCase() || user.firstname.concat(user.lastname).toLowerCase().includes(change.searchText?.replace(" ", "").toLowerCase()));
        if (this.authService.user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER) {
          this.popoverUsers = Object.values(this.users).filter((user) => ((user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER || user.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT && user.location === this.authService.user.location)) && (user?.location?.toLowerCase() === change.searchText?.toLowerCase() || user.firstname.concat(user.lastname).toLowerCase().includes(change.searchText?.replace(" ", "").toLowerCase())));
        } else {
          this.popoverUsers = Object.values(this.users).filter((user) => !!this.chatAccessPerAirport[airports.find((airport) => airport.iata === user.location)?.id] && (user?.location?.toLowerCase() === change.searchText?.toLowerCase() || user.firstname.concat(user.lastname).toLowerCase().includes(change.searchText?.replace(" ", "").toLowerCase())));
        }
      });
      this.formGroup.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(250)).subscribe((change) => {
        if (change.searchText?.length >= 0) {
          this.messageGroups = Object.values(this.chatService.messageGroups).filter((group) => {
            return group.__user__?.location?.toLowerCase() === this.formGroup.value.searchText?.toLowerCase() || group.__user__?.firstname.concat(group.__user__.lastname).toLowerCase().includes(this.formGroup.value.searchText?.replace(" ", "").toLowerCase())
          });
        }
        this.sortConversations();
      });

      this.sortConversations();
    });
  }

  sortConversations() {
    this.messageGroups.sort((a, b) => {
      const aUnread = this.chatService.myMessageGroups[a.chatGroupId]?.numberOfUnreadMessages;
      const bUnread = this.chatService.myMessageGroups[b.chatGroupId]?.numberOfUnreadMessages;
      switch (this.formGroup.value?.sort) {
        case "Airport":
          if (a.__user__.location.toLowerCase() === b.__user__.location.toLowerCase()) {
            return aUnread > bUnread ? -1 : 1;
          }
          return a.__user__.location.toLowerCase() > b.__user__.location.toLowerCase() ? 1 : -1;
        case "Role":
          if (a.__user__.role.toLowerCase() === b.__user__.role.toLowerCase()) {
            return aUnread > bUnread ? -1 : 1;
          }
          return a.__user__.role.toLowerCase() > b.__user__.role.toLowerCase() ? 1 : -1;
        default:
          if (aUnread !== bUnread) {
            return aUnread > bUnread ? -1 : 1;
          }
          if (a.lastMessage && !b.lastMessage) {
            return -1;
          } else if (!a.lastMessage && b.lastMessage) {
            return 1;
          }
          return a.lastMessage?.lastChangedAt > b.lastMessage?.lastChangedAt ? -1 : 1;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  setActiveIndex(index: number) {
    this.activeIndex = index;
  }

  createChatGroup(user: IUser) {
    const existingGroup = Object.values(this.chatService.messageGroups).find((group) => group.userId === user.id);
    if (existingGroup) {
      this.activeIndex = existingGroup.chatGroupId;
      return;
    }

    this.chatService.createChatGroup([this.authService.userSubject.value?.id, user.id]).subscribe((chatGroupId) => {
      if (chatGroupId) {
        this.chatService.getMyChatGroups().subscribe((result) => {
          this.chatService.refreshChatGroups(result);
          this.activeIndex = chatGroupId;
        });
      }
    })
  }

  addUnreadMessage(message: IChatMessageModel) {
    this.chatService.messageGroups[this.activeIndex].numberOfUnreadMessages++;
    this.chatService.messageGroups[this.activeIndex].lastMessage = message;
  }
}
