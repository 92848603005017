import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, firstValueFrom, forkJoin, Subject} from "rxjs";
import {IGeneralSettingsModel} from "../../../../shared/models/general-settings.model";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../services/toast.service";
import {ActivatedRoute, Router} from "@angular/router";
import {GeneralSettingsService} from "../../../../services/general-settings.service";
import {AlertService} from "../../../../services/alert.service";
import {IAlertTypesModel} from "../../../../shared/models/alert-types.model";
import {IAlertTypeSettings} from "../../../../shared/models/alert-type-settings.model";
import {AlertConstants} from "../../../../shared/constants/alert.constants";
import {PermissionService} from "../../../../services/permission.service";
import {Access, PermissionUIMasks} from "../../../../shared/constants/enums";

@Component({
  selector: 'app-edit-alert',
  templateUrl: './edit-alert.component.html',
  styleUrls: ['./edit-alert.component.scss']
})
export class EditAlertComponent implements OnInit {

  alertTypeId: number;
  generalSettings: IGeneralSettingsModel;
  destroySubject = new Subject();
  form?: UntypedFormGroup;
  dateFormat: string;
  isBusy = false;
  loaded = false;
  unsubscribe$ = new Subject();
  alertType: IAlertTypesModel;
  alertSettings: IAlertTypeSettings;
  pageAccess: Access;

  constructor(
    private alertService: AlertService,
    private toastService: ToastService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private generalSettingsService: GeneralSettingsService,
    private router: Router,
    private permissionService: PermissionService) {
    this.alertTypeId = Number((this.route.params as BehaviorSubject<any>).value.id) || null;
    if (!this.alertTypeId) {
      this.router.navigate(['..'], {relativeTo: this.route});
      return;
    }
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_ALERT_MANAGEMENT).then((pageAccess) => {
      this.pageAccess = pageAccess;
      if (!pageAccess) {
        return;
      }
      if (this.alertTypeId > AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_ATD && this.alertTypeId < AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_TOT) {
        this.router.navigate(['..', AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_ATD.toString()], {relativeTo: this.route});
        this.alertTypeId = AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_ATD;
      }
      const obs = [this.generalSettingsService.getGeneralSettings(), this.alertService.getAlertTypes({
        id: this.alertTypeId,
        isActive: true,
      }), this.alertService.getAlertTypeSettings({
        alertTypesId: this.alertTypeId,
        isActive: true
      })];
      forkJoin(obs).subscribe((results) => {
        this.generalSettings = results?.[0]?.[0];
        this.alertType = results?.[1]?.[0];
        this.alertSettings = results?.[2]?.[0];
        if (this.alertTypeId === AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_ATD) {
          this.alertType.description = 'Time Difference between MVT and ACARS of same event times';
        }
        if (!this.alertType) {
          this.router.navigate(['..'], {
            relativeTo: this.route,
          });
          return;
        }
        this.dateFormat = this.generalSettingsService.generalSettings.value.dateFormat;
        this.form = this.fb.group({
          name: [{value: this.alertType.description, disabled: true}, Validators.required],
          severity: [this.alertSettings?.severityOverride ? this.alertSettings.severityOverride : this.alertType.severity, Validators.required],
          minutes1: [{
            value: this.alertSettings?.dynamicMinutesAmount1 ?? null,
            disabled: !this.alertSettings?.dynamicMinutesAmount1
          }],
          minutes1Percentage: [this.alertSettings?.dynamicMinutesPercentage1 ?? null],
          validTo: [this.alertSettings?.validTo ?? null],
        });
        if (pageAccess !== Access.RW) {
          this.form.disable();
        }
      });
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      enabled: new UntypedFormControl(this.alertType?.isActive ?? false)
    });

  }

  async onSaveClick() {
    if (!this.form.valid) {
      return;
    }
    this.isBusy = true;
    await this.save({
      id: this.alertSettings?.id,
      isActive: true,
      severityOverride: this.form.value.severity,
      dynamicMinutesAmount1: this.form.value.minutes1,
      dynamicMinutesPercentage1: this.form.value?.minutes1Percentage || null,
      alertTypesId: this.alertType.id,
      validTo: null,
      dynamicMinutesAmount1Description: null,
      dynamicMinutesPercentage1Description: null,
      lastChangedAt: new Date(),
      lastChangedBy: 1,
      validFrom: this.alertSettings?.validFrom ?? new Date(),
    });
    this.isBusy = false;
  }

  async save(data: IAlertTypeSettings): Promise<IAlertTypeSettings> {
    const result = await firstValueFrom(this.alertService.saveAlertSettings(data));
    if (result?.id) {
      this.toastService.showSuccess("Alert settings has been saved");
    }
    return result;
  }

  public readonly Access = Access;
}
