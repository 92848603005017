import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

export class SettingsGeneralMainFormBuilder {

  static constructForm(
    fb: UntypedFormBuilder,
    dateFormat: string): UntypedFormGroup {


    return fb.group({
      airlineMainBase: [''],
      dateFormat: [dateFormat],
      turnaroundTimeType: ['MINIMUM'],
      turnaroundThreshold: [''],
      minimumPassengerConnectionTimeInMinutes: [''],
    });
  }
}
