import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IAcRegistration} from '../../../shared/models/ac-registration.model';
import {IAirport} from '../../../shared/models/airport.model';
import {GeneralSettingsService} from '../../../services/general-settings.service';
import {AcRegistrationsService} from '../../../services/ac-registrations.service';
import {AirportsService} from '../../../services/airports.service';
import {ExitScreenCreateMvtFormBuilder} from '../../../shared/forms/formBuilders/exit-screen/create-mvt-form-builder';
import {
  ExitScreenMvtMessage,
  MVT_AA_EXIT_TYPE,
  MVT_AA_REQUIRED_FIELDS,
  MVT_AD_EXIT_TYPE,
  MVT_AD_REQUIRED_FIELDS,
  MVT_ED_EXIT_TYPE,
  MVT_ED_REQUIRED_FIELDS,
  MVT_INITIAL_EXIT_TYPE,
  MVT_NI_EXIT_TYPE,
  MVT_NI_REQUIRED_FIELDS
} from '../../../shared/constants/exit-screen-mvt-msg.constants';
import {ngbDateToFormat} from '../../../shared/utils/utils';
import {takeUntil} from 'rxjs/operators';
import {forkJoin, Subject} from 'rxjs';
import {ISimpleData} from '../../../shared/models/simpleData.model';
import {MvtService} from '../../../services/mvt.service';
import {IAirlineDesignator} from '../../../shared/models/airline-designator.model';
import {PermissionService} from "../../../services/permission.service";
import {Access, PermissionUIMasks} from "../../../shared/constants/enums";

@Component({
  selector: 'app-exit-screen-mvt',
  templateUrl: './exit-screen-mvt.component.html',
  styleUrls: ['./exit-screen-mvt.component.scss']
})
export class ExitScreenMvtComponent implements OnInit {

  dateFormat = 'dd/mm/yyyy';
  form?: UntypedFormGroup;
  acRegistrations: IAcRegistration[];
  airports: IAirport[];
  airlineDesignators: IAirlineDesignator[];
  exitScreenSetup: ExitScreenMvtMessage;
  requiredFieldSetup: Partial<ExitScreenMvtMessage>;
  isBusy = false;
  fileContent: string;
  forOthers?: boolean = false;
  forAvitium?: boolean = true;
  delayCodes: ISimpleData[];
  mvtAccess: Access;

  constructor(
    private fb: UntypedFormBuilder,
    private generalSettingsService: GeneralSettingsService,
    private acRegistrationsService: AcRegistrationsService,
    private airportsService: AirportsService,
    private mvtService: MvtService,
    private permissionService: PermissionService
  ) {
  }

  ngOnInit(): void {
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_MESSAGE_CREATION_MVT).then((mvtAccess) => {
      this.mvtAccess = mvtAccess;
      this.dateFormat = this.generalSettingsService.generalSettings.value.dateFormat;
      if (!mvtAccess) {
        this.form = ExitScreenCreateMvtFormBuilder.constructForm(this.fb, this.dateFormat);
        return;
      }
      forkJoin([this.acRegistrationsService.fetchAcRegistrations(), this.airportsService.fetchAirports(), this.generalSettingsService.getAirlineDesignators(), this.generalSettingsService.getDelayCodes()]).subscribe((results) => {
        this.acRegistrations = results[0];
        this.airports = results[1];
        this.airlineDesignators = results[2];
        this.delayCodes = results[3];
        this.generateForm();
      });
    })


  }

  unsubscribe$ = new Subject();

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();

  }

  generateForm() {
    this.form = ExitScreenCreateMvtFormBuilder.constructForm(this.fb, this.dateFormat);
    //this.form.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(item => this.updateDelayTimespan(item));
    this.form.get('messageType').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      switch (result) {
        case 'ED':
          this.exitScreenSetup = MVT_ED_EXIT_TYPE;
          this.requiredFieldSetup = MVT_ED_REQUIRED_FIELDS;
          break;
        case 'NI':
          this.exitScreenSetup = MVT_NI_EXIT_TYPE;
          this.requiredFieldSetup = MVT_NI_REQUIRED_FIELDS;
          break;
        case 'AD':
          this.exitScreenSetup = MVT_AD_EXIT_TYPE;
          this.requiredFieldSetup = MVT_AD_REQUIRED_FIELDS;
          break;
        case 'AA':
          this.exitScreenSetup = MVT_AA_EXIT_TYPE;
          this.requiredFieldSetup = MVT_AA_REQUIRED_FIELDS;
          break;
        default:
          this.exitScreenSetup = MVT_INITIAL_EXIT_TYPE;
          break;
      }
      this.updateFormValidity();
    });

    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateFileContent('<br>');
    });
  }

  restoreForm() {
    this.form.reset();
    this.generateForm();
  }

  updateFormValidity() {
    for (const [key, val] of Object.entries(this.requiredFieldSetup)) {
      if (val) {
        this.form.controls[key].setValidators(Validators.required);
      } else {
        this.form.controls[key].clearValidators();
      }
      this.form.controls[key].updateValueAndValidity();
    }
  }

  updateFileContent(lineSeperator = '\n', returnOnly = false) {
    const type = this.form.value?.messageType;
    if (!type) {
      return;
    }
    let content = `MVT${lineSeperator}${(this.form.value.airlineDesignator || '') + (this.form.value.flightNumber || '')}/${this.form.value.dateOfDeparture ? ngbDateToFormat(this.form.value.dateOfDeparture, 'DD').toUpperCase() : ''}.${this.form.value.acRegistration || ''}.${this.form.value.movementAirport || ''}${lineSeperator}`;

    switch (type) {
      case 'ED':
        content += `ED${this.form.value.etdDate ? ngbDateToFormat(this.form.value.etdDate, 'DD').toUpperCase() : ''}${this.form.value.etdTime?.replace(':', '') || ''}${lineSeperator}DL${this.form.value.delayCode?.code || ''}${this.form.value.si ? lineSeperator + 'SI ' + this.form.value.si : ''}`
        break;
      case 'NI':
        content += `NI${this.form.value.niDate ? ngbDateToFormat(this.form.value.niDate, 'DD').toUpperCase() : ''}${this.form.value.niTime?.replace(':', '') || ''}${this.form.value.si ? lineSeperator + 'SI ' + this.form.value.si : ''}`
        break;
      case 'AD':
        content += `AD${this.form.value.atdDate ? ngbDateToFormat(this.form.value.atdDate, 'DD').toUpperCase() : ''}${this.form.value.atdTime?.replace(':', '') || ''}/${this.form.value.totDate ? ngbDateToFormat(this.form.value.totDate, 'DD').toUpperCase() : ''}${this.form.value.totTime?.replace(':', '') || ''} EA${this.form.value.etaTime?.replace(':', '') || ''} ${this.form.value.arrAirport}${lineSeperator}DL${this.form.value?.delayCode?.code || ''}${this.form.value.extraDelayCode?.code || ''}/${this.form.value.delayMinutes}${this.form.value.extraDelayMinutes ? '/' + this.form.value.extraDelayMinutes : ''}${this.form.value.pax ? lineSeperator + 'PX' + this.form.value.pax : ''}${this.form.value.si ? lineSeperator + 'SI ' + this.form.value.si : ''}`
        break;
      case 'AA':
        content += `AA${this.form.value.landDate ? ngbDateToFormat(this.form.value.landDate, 'DD').toUpperCase() : ''}${this.form.value.landTime?.replace(':', '') || ''}/${this.form.value.ataDate ? ngbDateToFormat(this.form.value.ataDate, 'DD').toUpperCase() : ''}${this.form.value.ataTime?.replace(':', '') || ''}${this.form.value.si ? lineSeperator + 'SI ' + this.form.value.si : ''}`
        break;
      default:
        break;
    }
    if (returnOnly) {
      return content.toUpperCase();
    }
    this.fileContent = content.toUpperCase();
  }

  downloadFile() {
    if (this.form.invalid) {
      return;
    }
    const dlink: HTMLAnchorElement = document.createElement('a');
    dlink.download = this.getFileName();
    const content = this.updateFileContent('\n', true);
    dlink.href = 'data:text/plain;charset=utf-16,' + content;
    dlink.click(); // this will trigger the dialog window
    dlink.remove();
  }

  getFileName(): string {
    return `${this.form.value.messageType + ngbDateToFormat(this.form.value.dateOfDeparture, 'DDMMMYYYY').toUpperCase()}.txt`;
  }

  clearForm() {
    this.form.reset();
    this.fileContent = '';
  }

  submit() {

    this.mvtService.uploadMvt({
      fileName: this.getFileName(),
      fileContent: this.updateFileContent('\n', true),
    }).subscribe((result) => {
      console.log('Result:', result);
    });
  }

}
