import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../models/user.model';

@Pipe({
  name: 'usersTestTag'
})
export class UsersTestTagPipe implements PipeTransform {

  transform(value: IUser, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `user-${value?.firstname}-${value?.lastname}`;
  }

}
