import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FlightType } from '../../../../../shared/constants/flight-types.constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AirportsService } from '../../../../../services/airports.service';
import { ISimpleData } from '../../../../../shared/models/simpleData.model';
import { ITurnaroundTimes } from '../../../../../shared/models/turnaround-times.model';
import { BehaviorSubject, forkJoin, map, Observable } from 'rxjs';
import { TimeFromMinutesPipe } from '../../../../../shared/pipes/time-from-minutes.pipe';
import { MinutesFromTimePipe } from '../../../../../shared/pipes/minutes-from-time.pipe';
import { ToastService } from '../../../../../services/toast.service';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { IAcType } from '../../../../../shared/models/ac-type.model';
import { IAirport } from '../../../../../shared/models/airport.model';
import { IGenericContainerObject } from '../../../../../shared/models/genericContainerObject.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AcTypesService } from '../../../../../services/ac-types.service';

@Component({
  selector: 'app-add-edit-airport-turnaround-times-dialog',
  templateUrl: './add-edit-airport-turnaround-times-dialog.component.html',
  styleUrls: ['./add-edit-airport-turnaround-times-dialog.component.scss']
})
export class AddEditAirportTurnaroundTimesDialogComponent {

  airportId: number;
  acTypeId: number;
  constructor(private modalService: NgbModal, private airportsService: AirportsService, private generalSettingsService: GeneralSettingsService,
              private timeFromMinutesPipe: TimeFromMinutesPipe, private minutesFromTimePipe: MinutesFromTimePipe, private toastService: ToastService,
              private router: Router, private route: ActivatedRoute, private acTypesService: AcTypesService) {
    this.airportId = Number((this.route.params as BehaviorSubject<any>).value.id) || null;
    this.acTypeId = Number((this.route.params as BehaviorSubject<any>).value.acid) || null;
    if (!this.airportId || !this.acTypeId) {
      this.router.navigateByUrl('..');
      return;
    }
    forkJoin([this.acTypesService.fetchAcTypes({ id: this.acTypeId, isActive: true }), this.airportsService.fetchAirports({ id: this.airportId, isActive: true }), this.airportsService.fetchTurnaroundTimes({
      acTypeId: this.acTypeId,
      airportId: this.airportId,
      isActive: true,
    }), this.generalSettingsService.getFlightServiceTypes()]).subscribe((results) => {
      this.acType = results[0]?.[0];
      this.airport = results[1]?.[0];
      if (!this.acType || !this.airport) {
        this.router.navigate(['../..'], { relativeTo: this.route });
        return;
      }
      this.turnaroundTimes = results[2];
      this.flightServiceTypes = results[3];
      for (const fType of this.flightServiceTypes) {
        this.flightServiceTypeLetters[fType.code] = fType.id;
      }
      this.forms = {};
      this.createForm(FlightType.DOMDOM);
      this.createForm(FlightType.DOMINT);
      this.createForm(FlightType.INTINT);
      this.createForm(FlightType.INTDOM);
      this.patchData();
      this.turnaroundTime = this.timeFromMinutesPipe.transform(this.acType?.defaultMinTurnaroundTimeInMinutes || 0);
    });
  }

  // Key is flight service type ID value
  flightServiceTypes: ISimpleData[];
  flightServiceTypeLetters: { [p: string]: number } = {};
  forms: { [p: string]: UntypedFormGroup };
  isBusy = false;
  DOMDOM: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};
  DOMINT: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};
  INTINT: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};
  INTDOM: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};

  acType?: IAcType;
  airport?: IAirport;
  turnaroundTime: string;
  turnaroundTimes: ITurnaroundTimes[];
  flightTypes = FlightType;

  constructForm(step: FlightType) {
    this.forms[step] = new UntypedFormGroup({});
    for (const flightServiceType1 of Object.values(this.flightServiceTypes)) {
      for (const flightServiceType2 of Object.values(this.flightServiceTypes)) {
        this.forms[step].addControl(flightServiceType1.code, new UntypedFormGroup({}));
        (this.forms[step].get(flightServiceType1.code) as UntypedFormGroup).addControl(flightServiceType2.code, new UntypedFormGroup({time: new UntypedFormControl(''), id: new UntypedFormControl(0)}));
      }
    }
  }

  onSaveClick() {
    this.isBusy = true;
    const observables: Observable<any>[] = [];
    observables.push(...this.saveTurnaroundTimeObservables(FlightType.DOMDOM));
    observables.push(...this.saveTurnaroundTimeObservables(FlightType.DOMINT));
    observables.push(...this.saveTurnaroundTimeObservables(FlightType.INTINT));
    observables.push(...this.saveTurnaroundTimeObservables(FlightType.INTDOM));
    forkJoin(observables).subscribe((results) => {
      this.isBusy = false;
      const errors = results?.filter((item) => !item);
      if (errors?.length) {
        this.toastService.showError("There was an error during saving");
      } else {
        this.toastService.showSuccess('Save successful!');
      }
    });
  }


  applyTimeToAll(flightType: FlightType) {
    if(!this.turnaroundTime || this.turnaroundTime.length !== 5 || !/[0-9][0-9]:[0-9][0-9]/.test(this.turnaroundTime)) {
      return;
    }
    for(const ctrl of Object.values(this.forms[flightType].controls) as UntypedFormGroup[]) {
      for(const ctrl2 of Object.values(ctrl.controls) as UntypedFormGroup[]) {
        ctrl2.patchValue({ time: this.turnaroundTime });
        ctrl2.get('time').markAsDirty();
      }
    }
  }

  createForm(flightType: FlightType) {
    this.forms[flightType] = new UntypedFormGroup({});
    for (const flightServiceType of this.flightServiceTypes) {
      for (const flightServiceType2 of this.flightServiceTypes) {
        this.forms[flightType].addControl(flightServiceType.code, new UntypedFormGroup({}));

        (this.forms[flightType].get(flightServiceType.code) as UntypedFormGroup).addControl(flightServiceType2.code, new UntypedFormGroup({time: new UntypedFormControl(''), id: new UntypedFormControl(0)}));
      }
    }
  }

  saveTurnaroundTimeObservables(flightType: FlightType): Observable<any>[] {
    const observables: Observable<any>[] = [];
    for (let key1 in this.forms[flightType].controls) {
      for (const key2 in (this.forms[flightType].controls[key1] as UntypedFormGroup).controls) {
        const control: UntypedFormControl = ((this.forms[flightType].controls[key1] as UntypedFormGroup).controls[key2] as UntypedFormGroup).get('time') as UntypedFormControl;
        if (!control.dirty) {
          continue;
        }
        const time: string = ((this.forms[flightType].controls[key1] as UntypedFormGroup).controls[key2] as UntypedFormGroup).get('time').value;
        const id: number = ((this.forms[flightType].controls[key1] as UntypedFormGroup).controls[key2] as UntypedFormGroup).get('id').value;
        if (id && !time.trim().length) {
          observables.push(this.airportsService.deleteTurnaroundTime(id));
          continue;
        }
        const timeInMinutes = this.minutesFromTimePipe.transform(time);
        if(timeInMinutes === 0)
        {
          if(id) {
            observables.push(this.airportsService.deleteTurnaroundTime(id));
          }
          continue;
        }

        const fromId = this.flightServiceTypeLetters[key1];
        const toId = this.flightServiceTypeLetters[key2];
        if (!fromId || !toId) {
          this.toastService.showError("Saving turnaround data failed.");
          return;
        }
        observables.push(this.airportsService.saveTurnaroundTime({
          id: id !== 0 ? id : undefined, timeInMinutes: timeInMinutes, acTypeId: this.acType?.id || undefined, airportId: this.airport?.id, flightServiceTypeFrom: fromId, flightServiceTypeTo: toId, flightType
        }).pipe(map((item) => !!item)));
      }
    }
    return observables;
  }

  patchData() {
    for (const tTime of this.turnaroundTimes) {
      const from = this.flightServiceTypes.find((fType) => fType.id === tTime.flightServiceTypeFrom);
      const to = this.flightServiceTypes.find((fType) => fType.id === tTime.flightServiceTypeTo);
      if (!from || !to) {
        continue;
      }
      const ctrl = this.forms[tTime.flightType].get(from.code).get(to?.code);
      ctrl.patchValue({
        time: this.timeFromMinutesPipe.transform(tTime.timeInMinutes),
        id: tTime.id,
      })
    }
  }
}
