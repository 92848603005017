// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebaseConfig: {
    apiKey: "AIzaSyAtzOdWswKTfVLiSAesJp1dGuSvapwPabQ",
    authDomain: "arw-ts1-9895b.firebaseapp.com",
    projectId: "arw-ts1-9895b",
    storageBucket: "arw-ts1-9895b.appspot.com",
    messagingSenderId: "1022388239458",
    appId: "1:1022388239458:web:5568bc0d79a0c1ed5e653b",
    measurementId: "G-55EWSRDX31"
  },


  production: false,
  api_base_url: 'https://api.arw-dev.avitium.de:443/api/',
  // web version: replace api with web
  // mobile: replace api with mobile

  // TODO: DEV TA MGMT
  /*
  firebaseConfig: {
    apiKey: 'AIzaSyAiIricL2wn1-5kSx65EoynxxiV1ZX_wUg',
    authDomain: 'devtamgmt.firebaseapp.com',
    databaseURL: 'https://devtamgmt.firebaseio.com',
    projectId: 'devtamgmt',
    storageBucket: '',
    messagingSenderId: '16286674724',
    appId: '1:16286674724:web:0986b78d180682b6a30db1'
  } */

  // TODO: DEV AD DESKTOP RAMP WATCH
  /*
    firebaseConfig: {
      apiKey: 'AIzaSyDm72WrkCAaUJ88cHHoWkKm87VvGBNabnA',
      authDomain: 'dev-arw-ad.firebaseapp.com',
      databaseURL: 'https://dev-arw-ad.firebaseio.com',
      projectId: 'dev-arw-ad',
      storageBucket: 'dev-arw-ad.appspot.com',
      messagingSenderId: '348013241331',
      appId: '1:348013241331:web:a54534bc2f0f1a61782886'
    } */

  // TODO: DEV AL DESKTOP RAMP WATCH
  /*
    firebaseConfig: {
      apiKey: 'AIzaSyAtHJ8k31GT2k2vxDx8C2BcdcJ45ItaPv4',
      authDomain: 'dev-arw-al.firebaseapp.com',
      databaseURL: 'https://dev-arw-al.firebaseio.com',
      projectId: 'dev-arw-al',
      storageBucket: 'dev-arw-al.appspot.com',
      messagingSenderId: '521313131877',
      appId: '1:521313131877:web:c1355eb5634f70cc14558c'
    } */

  // TODO: DEV HK DESKTOP RAMP WATCH


    // firebaseConfig: {
    //   apiKey: 'AIzaSyAlxRK40rBXkVLRRq0qRcbNWcrcfzxBPYU',
    //   authDomain: 'dev-arw-hk.firebaseapp.com',
    //   databaseURL: 'https://dev-arw-hk.firebaseio.com',
    //   projectId: 'dev-arw-hk',
    //   storageBucket: 'dev-arw-hk.appspot.com',
    //   messagingSenderId: '143004306859',
    //   appId: '1:143004306859:web:e83afa5d4fd0fd4178d6ee'
    // }

  /* firebaseConfig: {
    apiKey: "AIzaSyBgtYpRbzLk5pOuXN0-nvF2kEFarKj8ckE",
    authDomain: "arwdev1.firebaseapp.com",
    databaseURL: "https://arwdev1-default-rtdb.firebaseio.com",
    projectId: "arwdev1",
    storageBucket: "arwdev1.appspot.com",
    messagingSenderId: "111240042976",
    appId: "1:111240042976:web:99b01e1448f4add631f385"
  } */


  // TODO: ARW TS 1 MOBILE RAMP WATCH

  // Harry's Environment
  // firebaseConfig: {
  //   apiKey: "AIzaSyAtzOdWswKTfVLiSAesJp1dGuSvapwPabQ",
  //   authDomain: "arw-ts1-9895b.firebaseapp.com",
  //   projectId: "arw-ts1-9895b",
  //   storageBucket: "arw-ts1-9895b.appspot.com",
  //   messagingSenderId: "1022388239458",
  //   appId: "1:1022388239458:web:5568bc0d79a0c1ed5e653b" // ,
  //   // measurementId: "G-55EWSRDX31"
  // }


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
