export enum App {
  ARW_WEB = 'ARW_WEB',
  ARW_MOBILE = 'ARW_MOBILE',
  ARW_BACKEND = 'ARW_BACKEND',
}

export enum Access {
  R = 'R',
  RW = 'RW',
}

export enum PermissionUIMasks {
  WEB_FLIGHTS_ARRIVING_INFORMATION = "WEB_FLIGHTS_ARRIVING_INFORMATION",
  WEB_FLIGHTS_DEPARTING_INFORMATION = "WEB_FLIGHTS_DEPARTING_INFORMATION",
  WEB_FLIGHTS_TURNAROUND_PROCESSES_AND_GSES = "WEB_FLIGHTS_TURNAROUND_PROCESSES_AND_GSES",
  WEB_FLIGHTS_MEMOS = "WEB_FLIGHTS_MEMOS",
  WEB_FLIGHTS_ISSUE_ETD = "WEB_FLIGHTS_ISSUE_ETD",
  WEB_FLIGHTS_ISSUE_NI = "WEB_FLIGHTS_ISSUE_NI",
  WEB_RAMP_AGENT_ASSIGNMENT = "WEB_RAMP_AGENT_ASSIGNMENT",
  WEB_REPORTS_OPERATIONS = "WEB_REPORTS_OPERATIONS",
  WEB_REPORTS_PUNCTUALITY = "WEB_REPORTS_PUNCTUALITY",
  WEB_REPORTS_PASSENGERS = "WEB_REPORTS_PASSENGERS",
  WEB_REPORTS_UTILIZATION = "WEB_REPORTS_UTILIZATION",
  WEB_REPORTS_FLIGHT_DETAILS = "WEB_REPORTS_FLIGHT_DETAILS",
  WEB_REPORTS_FLIGHT_PAIRINGS = "WEB_REPORTS_FLIGHT_PAIRINGS",
  WEB_REPORTS_USERS_REPORT = "WEB_REPORTS_USERS_REPORT",
  WEB_REPORTS_GSE_UTILIZATION = "WEB_REPORTS_GSE_UTILIZATION",
  WEB_REPORTS_LIST_PROCESSES = "WEB_REPORTS_LIST_PROCESSES",
  WEB_REPORTS_FLIGHTS_MEMOS = "WEB_REPORTS_FLIGHTS_MEMOS",
  WEB_REPORTS_TURNAROUND_GROUND_DIFFERENCES = "WEB_REPORTS_TURNAROUND_GROUND_DIFFERENCES",
  WEB_SETTINGS_GENERAL_SETTINGS = "WEB_SETTINGS_GENERAL_SETTINGS",
  WEB_SETTINGS_USER_MANAGEMENT = "WEB_SETTINGS_USER_MANAGEMENT",
  WEB_SETTINGS_ROLE_MANAGEMENT = "WEB_SETTINGS_ROLE_MANAGEMENT",
  WEB_SETTINGS_FLIGHTS_SCHEDULE_MANAGEMENT = "WEB_SETTINGS_FLIGHTS_SCHEDULE_MANAGEMENT",
  WEB_SETTINGS_AC_TYPE_MANAGEMENT = "WEB_SETTINGS_AC_TYPE_MANAGEMENT",
  WEB_SETTINGS_AC_REGISTRATION_MANAGEMENT = "WEB_SETTINGS_AC_REGISTRATION_MANAGEMENT",
  WEB_SETTINGS_AIRPORT_MANAGEMENT = "WEB_SETTINGS_AIRPORT_MANAGEMENT",
  WEB_SETTINGS_SLA_AND_GSES_MANAGEMENT = "WEB_SETTINGS_SLA_AND_GSES_MANAGEMENT",
  WEB_SETTINGS_INTERFACE_MANAGEMENT = "WEB_SETTINGS_INTERFACE_MANAGEMENT",
  WEB_SETTINGS_ALERT_MANAGEMENT = "WEB_SETTINGS_ALERT_MANAGEMENT",
  WEB_MESSAGE_CREATION_ASM = "WEB_MESSAGE_CREATION_ASM",
  WEB_MESSAGE_CREATION_MVT = "WEB_MESSAGE_CREATION_MVT",
  WEB_MESSAGE_CREATION_LIST = "WEB_MESSAGE_CREATION_LIST",
  WEB_CHAT = "WEB_CHAT",
  WEB_ALERTS_UNPAIRED_SECTOR = "WEB_ALERTS_UNPAIRED_SECTOR",
  WEB_ALERTS_INADEQUATE_TURNAROUND_TIME = "WEB_ALERTS_INADEQUATE_TURNAROUND_TIME",
  WEB_ALERTS_NEW_INFORMATION_ISSUED = "WEB_ALERTS_NEW_INFORMATION_ISSUED",
  WEB_ALERTS_TURNAROUND_NOT_STARTED = "WEB_ALERTS_TURNAROUND_NOT_STARTED",
  WEB_ALERTS_REQUEST_FOR_EXTENSION = "WEB_ALERTS_REQUEST_FOR_EXTENSION",
  WEB_ALERTS_UNSUCCESSFUL_MESSAGE = "WEB_ALERTS_UNSUCCESSFUL_MESSAGE",
  WEB_ALERTS_NO_ACTUAL_TIMES_ON_SECTOR = "WEB_ALERTS_NO_ACTUAL_TIMES_ON_SECTOR",
  WEB_ALERTS_OVERLAPPING_SECTORS = "WEB_ALERTS_OVERLAPPING_SECTORS",
  WEB_ALERTS_CTOT_VIOLATION = "WEB_ALERTS_CTOT_VIOLATION",
  WEB_ALERTS_EARLY_DEPARTURE = "WEB_ALERTS_EARLY_DEPARTURE",
  WEB_ALERTS_AIRCRAFT_RETURN_TO_RAMP = "WEB_ALERTS_AIRCRAFT_RETURN_TO_RAMP",
  WEB_ALERTS_AIRCRAFT_RETURN_FROM_AIRBORNE = "WEB_ALERTS_AIRCRAFT_RETURN_FROM_AIRBORNE",
  WEB_ALERTS_AIRCRAFT_DIVERSION = "WEB_ALERTS_AIRCRAFT_DIVERSION",
  WEB_ALERTS_ARRIVE_AT_CLOSED_AIRPORT = "WEB_ALERTS_ARRIVE_AT_CLOSED_AIRPORT",
  WEB_ALERTS_DEPART_FROM_CLOSED_AIRPORT = "WEB_ALERTS_DEPART_FROM_CLOSED_AIRPORT",
  WEB_ALERTS_LATE_ONGOING_PROCESS = "WEB_ALERTS_LATE_ONGOING_PROCESS",
  WEB_ALERTS_MVT_ACARS_DIFFERENCE_IN_ATD = "WEB_ALERTS_MVT_ACARS_DIFFERENCE_IN_ATD",
  WEB_ALERTS_ASSIGN_RAMP_AGENT = "WEB_ALERTS_ASSIGN_RAMP_AGENT",
  MOBILE_START_TURNAROUND = "MOBILE_START_TURNAROUND",
  MOBILE_FLIGHT_INFO = "MOBILE_FLIGHT_INFO",
  MOBILE_PROCESSES = "MOBILE_PROCESSES",
  MOBILE_LOADING_INFO = "MOBILE_LOADING_INFO",
  MOBILE_TIMES = "MOBILE_TIMES",
}

export enum GanttTool {
  SELECT,
  RESCHEDULE,
  CHANGE_REGISTRATION,
}

export enum GanttSliderTab {
  FLIGHT_INFO = 'FLIGHT_INFO',
  CHANGES = 'CHANGES',
  ALERTS = 'ALERTS',
  PROCESSES = 'PROCESSES',
}
