import { Pipe, PipeTransform } from '@angular/core';
import { IAlertsModel } from '../models/alerts.model';
import { AlertService } from '../../services/alert.service';
import { TestTagsUtils } from '../utils/test-tags.utils';

@Pipe({
  name: 'alertTestAttribute'
})
export class AlertTestAttributePipe implements PipeTransform {
  constructor(private alertService: AlertService) {
  }

  transform(alert: IAlertsModel, ...args: unknown[]): string {
    if (!alert) {
      return 'alert-item-undefined';
    }
    if(!alert.__leg__ && !alert.__pair__) {
      return 'alert-item-low-' + this.alertService.alertTypes[alert.alertTypeId]?.title;
    }
    if (alert.__leg__) {
      return 'alert-item-' + TestTagsUtils.getLegTestTag(alert.__leg__);
    }
    return 'alert-item-' + TestTagsUtils.getPairTestTag(alert.__pair__);
  }

}
