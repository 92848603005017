import { Pipe, PipeTransform } from '@angular/core';
import { IAirport } from '../models/airport.model';

@Pipe({
  name: 'airportsTestTag'
})
export class AirportsTestTagPipe implements PipeTransform {

  transform(value: IAirport, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `airport-${value?.iata}`;
  }

}
