import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserRoles} from '../../../shared/constants/user-roles.constants';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  user: any;
  form: UntypedFormGroup;
  userRoles = UserRoles;
  responseError: boolean;
  destroy$ = new Subject();

  constructor(private fb: UntypedFormBuilder, private authService: AuthService, private router: Router, private auth: AngularFireAuth, private route: ActivatedRoute, private ngZone: NgZone) {

  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  createForm() {
    this.form = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  signIn() {
    const formValues = this.form.value;

    if (this.form.invalid || !formValues.username || !formValues.password) {
      return;
    }

    this.responseError = false;

    this.authService.loginWithCredentials(formValues.username, formValues.password)
      .then(() => {
        this.router.navigate(['flights']);
      })
      .catch(error => {
        console.log(error);
        this.responseError = true;
      });
  }

  onKeyPress(evt: KeyboardEvent) {
    if (!evt?.key || typeof evt.key !== 'string') {
      return;
    }
    if (evt.key.toLowerCase() === 'enter') {
      this.signIn();
    }
  }
}
