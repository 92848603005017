import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {IAlertsModel} from "../../../../../shared/models/alerts.model";
import {AlertSeverity} from "../../../../../shared/constants/alert-severity.enum";
import {AlertService} from "../../../../../services/alert.service";
import {AuthService} from "../../../../../services/auth.service";
import {IUser} from "../../../../../shared/models/user.model";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {SortDirection} from "../../../../../shared/constants/table-sort.types";

@Component({
  selector: 'app-gantt-alert-tab',
  templateUrl: './gantt-alert-tab.component.html',
  styleUrls: ['./gantt-alert-tab.component.scss']
})
export class GanttAlertTabComponent implements OnInit, OnDestroy, OnChanges {

  @Input() alertPermission?: boolean;
  @Input() alertSearchText?: string = '';
  @Output() alertSearchTextChange = new EventEmitter<string>();

  user: IUser;
  @Input() alerts: IAlertsModel[];
  @Output() searchTextChanged = new EventEmitter<string>();
  @Output() updateRequired = new EventEmitter();

  @Input() severityFilters: AlertSeverity[];
  @Output() severityFiltersChange = new EventEmitter();

  @Input() sortOrder?: SortDirection;
  @Output() sortOrderChanged = new EventEmitter();
  @Output() remindMeLaterClicked = new EventEmitter<{ id: number; hours: number; minutes: number }>();
  @Output() refetchRequired: EventEmitter<void> = new EventEmitter();
  protected readonly localAlertSeverity = AlertSeverity;
  unsubscribe$ = new Subject<void>();


  constructor(public alertService: AlertService, private authService: AuthService) {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {

  }


  ngOnInit() {
    this.authService.userSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.user = user;
    });
  }


  severitySwitched(severity: AlertSeverity) {
    const index = this.severityFilters.findIndex((item) => item === severity);
    if (index !== -1) {
      this.severityFilters.splice(index, 1);
      this.updateRequired.emit();
      return;
    }
    this.severityFilters.push(severity);
    this.severityFiltersChange.emit(this.severityFilters);
  }

  sortChanged() {
    this.sortOrderChanged.emit();
  }

  remindLater(data: { id: number; hours: number; minutes: number }) {
    this.remindMeLaterClicked.emit(data);
    // this.alertsToRemind[data.id] = dayjs().add(data.hours, 'hours').add(data.minutes, 'minutes').unix();
    // localStorage.setItem('alerts_to_remind', JSON.stringify(this.alertsToRemind));
    // this.updateRequired.emit();
  }

  // severitySwitched(severity: AlertSeverity) {
  //   const index = this.severityFilters.findIndex((item) => item === severity);
  //   if (index !== -1) {
  //     this.severityFilters.splice(index, 1);
  //     this.updateRequired.emit();
  //     return;
  //   }
  //   this.severityFilters.push(severity);
  //   this.updateRequired.emit();
  // }
  //
  //
  // remindLater(data: { id: number; hours: number; minutes: number }) {
  //   this.alertsToRemind[data.id] = dayjs().add(data.hours, 'hours').add(data.minutes, 'minutes').unix();
  //   localStorage.setItem('alerts_to_remind', JSON.stringify(this.alertsToRemind));
  //   this.updateRequired.emit();
  // }
  //
  // sortChanged() {
  //   this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
  //   this.updateRequired.emit();
  // }
}
