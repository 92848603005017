import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IPairOverview } from '../../models/pair-overview.model';
import { IPairDetail, isPairDetail } from '../../models/pair-detail.model';
import { isPairOverview } from '../../utils/utils';
import { IDelayCode } from '../../models/delay-code.model';
import { LegDelaysLogService } from '../../../services/leg-delays-log.service';
import { firstValueFrom, forkJoin, mergeMap } from 'rxjs';
import { PairLegTimesLogService } from '../../../services/pair-leg-times-log.service';
import { TimeTypesService } from '../../../services/time-types.service';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { PairsService } from '../../../services/pairs.service';
import { WeatherService } from '../../../services/weather.service';
import { AirportsService } from '../../../services/airports.service';
import { IAirport } from '../../models/airport.model';
import { IWeatherMetarModel } from '../../models/weather-metar.model';
import { IWeatherTafModel } from '../../models/weather-taf.model';

@Component({
  selector: 'app-leg-information-window',
  templateUrl: './leg-information-window.component.html',
  styleUrls: ['./leg-information-window.component.scss']
})
export class LegInformationWindowComponent implements OnInit {

  pair: IPairOverview | IPairDetail;
  activeTab: 'Passengers' | 'A-CDM' | 'Crew' | 'Weather' | 'LIR' = 'Passengers';
  tabs: string[] = ['Passengers', 'A-CDM', 'Crew', 'Weather', 'LIR'];
  niDelayCode: IDelayCode;
  legId: number;
  interactingStations = new Set<string>();
  metarData: {[iata: string]: IWeatherMetarModel} = {};
  tafData: {[iata: string]: IWeatherTafModel} = {};
  airports: IAirport[];
  icaos = [];
  constructor(
    private activeModal: NgbActiveModal,
    private pairService: PairsService,
    private generalSettingsService: GeneralSettingsService,
    private weatherService: WeatherService,
    private airportsService: AirportsService,) { }

  ngOnInit(): void {
    if (isPairOverview(this.pair)) {
      this.pairService.getPairDetail(this.pair.id).subscribe((result) => {
        this.pair = result as IPairDetail;
        if ((this.pair as IPairDetail).departureLeg?.id) {
          this.interactingStations.add((this.pair as IPairDetail).departureLeg.departureStation);
          this.interactingStations.add((this.pair as IPairDetail).departureLeg.arrivalStation);
        }
        if ((this.pair as IPairDetail).arrivalLeg?.id) {
          this.interactingStations.add((this.pair as IPairDetail).arrivalLeg.departureStation);
          this.interactingStations.add((this.pair as IPairDetail).arrivalLeg.arrivalStation);
        }

        if ((this.pair as IPairDetail).departureLeg?.id && (this.pair as IPairDetail).departureLeg.niDelayCode) {
          this.generalSettingsService.getDelayCodes({ id: (this.pair as IPairDetail).departureLeg.niDelayCode }).subscribe((result) => {
            if (result?.length) {
              this.niDelayCode = result[0];
            }
          })
        }
        this.getWeatherData();
      });
    }
    if (isPairDetail(this.pair)) {
      if (this.pair.departureLeg.id) {
        this.interactingStations.add(this.pair.departureLeg.departureStation);
        this.interactingStations.add(this.pair.departureLeg.arrivalStation);
      }
      if (this.pair.arrivalLeg.id) {
        this.interactingStations.add(this.pair.arrivalLeg.departureStation)
        this.interactingStations.add(this.pair.arrivalLeg.arrivalStation)
      }
      this.getWeatherData();

      if (this.pair.departureLeg?.id && this.pair.departureLeg.niDelayCode) {
        this.generalSettingsService.getDelayCodes({ id: this.pair.departureLeg.niDelayCode }).subscribe((result) => {
          if (result?.length) {
            this.niDelayCode = result[0];
          }
        })
      }
    }
  }

  getWeatherData() {
    this.airportsService.fetchAirports().subscribe((result) => {
      this.airports = result;
      for (const station of this.interactingStations) {
        this.icaos.push(this.airports.find((airport) => {return airport.iata === station}).icao)
      }
      this.weatherService.getWeatherMetarData({icao: this.icaos, isActive: true}).subscribe((metars) => {
        for (const metar of metars) {
          this.metarData[this.airports.find((air) => air.icao === metar.icao).iata] = metar
        }
      })
      this.weatherService.getWeatherTafData({icao: this.icaos, isActive: true}).subscribe((tafs) => {
        for (const taf of tafs) {
          this.tafData[this.airports.find((air) => air.icao === taf.icao).iata] = taf
        }

      })
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  public readonly isPairOverview = isPairOverview;
}
