<div class="light-blue-border p-2 mt-2 flex justify-content-between" (click)="toggleAccordion($event)">
  <div class="form-check form-switch" *ngIf="showSwitch; else plainTitle">
    <input class="form-check-input" type="checkbox" role="switch" [id]="switchId" data-test="accordion-check"
           [checked]="switchStatus">
    <label class="form-check-label dark-blue ms-2" [for]="switchId">{{ title }}</label>
  </div>
  <ng-template #plainTitle>
    <span class="ms-2">{{ title }}</span>
  </ng-template>
  <div class="flex gap-2 float-right">
    <!--    <ng-container *ngIf="content">-->
    <!--      <ng-container *ngTemplateOutlet="content"></ng-container>-->
    <!--    </ng-container>-->
    <ng-container *ngTemplateOutlet="getTemplate('inner-body')"></ng-container>
    <div class="align-items-center"><img src="content/images/Arrow.png" class="arrow" alt="Arrow" width="16"
                                         [ngClass]="{ open: this.isOpen, hidden: !showArrow }"></div>
  </div>
</div>
<!--<div class="child-content" [ngClass]="{ shown: isOpen }" [ngStyle]="{ height: isOpen ? contentHeight + 'px' : '0' }">-->
<div class="child-content" [ngClass]="{ shown: isOpen }">
  <div>
    <ng-container *ngTemplateOutlet="getTemplate('body')"></ng-container>
  </div>

</div>

<!--<ng-content></ng-content>-->


