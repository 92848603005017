import { Pipe, PipeTransform } from '@angular/core';

type GseTableData = {
  name: string;
  gseRequired: string;
  gseIcon: string;
  sla: string;
  maxTime: string;
  gseId: number;
}

@Pipe({
  name: 'slaGseTestTag'
})
export class SlaGseTestTagPipe implements PipeTransform {

  transform(value: GseTableData, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `sla-gse-${value?.name}`;
  }

}
