import { Pipe, PipeTransform } from '@angular/core';
import {IAlertTypesModel} from "../models/alert-types.model";

@Pipe({
  name: 'alertsTestTag'
})
export class AlertsTestTagPipe implements PipeTransform {

  transform(value: IAlertTypesModel, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `alert-${value?.title}`;
  }

}
