<form>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="dateCheckbox" name="dateCheckbox"
                       [(ngModel)]="changeDate"> &nbsp; &nbsp;
                <label class="form-check-label" for="dateCheckbox">Date</label>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <div class="input-group">
                  <input class="form-control" placeholder="dd.mm.yyyy"
                         name="dp" [(ngModel)]="etdDate" ngbDatepicker #d="ngbDatepicker" [disabled]="!changeDate">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"
                            (click)="d.toggle()" type="button" [disabled]="!changeDate"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="acTypeCheckbox" name="acTypeCheckbox"
                       [(ngModel)]="changeACType"> &nbsp; &nbsp;
                <label class="form-check-label" for="acTypeCheckbox">A/C Type</label>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <select class="form-control form-select" id="search-flight-ac-type" placeholder="" [disabled]="!changeACType">
                  <option>A219</option>
                  <option>A320</option>
                  <option>B747</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="acRegistrationCheckbox"
                       name="acRegistrationCheckbox" [(ngModel)]="changeACRegistration"> &nbsp; &nbsp;
                <label class="form-check-label" for="acRegistrationCheckbox">A/C Registration</label>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <select class="form-control form-select" id="search-flight-ac-registration" placeholder=""
                        [disabled]="!changeACRegistration">
                  <option>MB123</option>
                  <option>MC123</option>
                  <option>MD123</option>
                </select></div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="flightNumberCheckbox" name="flightNumberCheckbox"
                       [(ngModel)]="changeFlightNumber"> &nbsp; &nbsp;
                <label class="form-check-label" for="flightNumberCheckbox">Flight Number</label>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="search-flight-number" placeholder=""
                       [disabled]="!changeFlightNumber">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 border-start">
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="departingStationCheckbox"
                       name="departingStationCheckbox" [(ngModel)]="changeDepartingStation"> &nbsp; &nbsp;
                <label class="form-check-label" for="departingStationCheckbox">Departing Station</label>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <select class="form-control form-select" id="search-departing-flight-number" placeholder=""
                        [disabled]="!changeDepartingStation">
                  <option>ATH</option>
                  <option>ZRH</option>
                  <option>VIE</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row mb-3">
            <div class="col-6 form-field-header">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="STDCheckbox" name="STDCheckbox"
                       [(ngModel)]="changeSTD"> &nbsp; &nbsp;
                <label class="form-check-label" for="STDCheckbox">STD</label>
              </div>
            </div>
            <div class="col-6">
              <ngb-timepicker appTimepickerFocus [(ngModel)]="stdTime" [spinners]="false" name="stdTime"
                              [disabled]="!changeSTD"></ngb-timepicker>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row mb-3">
            <div class="col-6 form-field-header">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="arrivingStationCheckbox"
                       name="arrivingStationCheckbox" [(ngModel)]="changeArrivingStation"> &nbsp; &nbsp;
                <label class="form-check-label" for="arrivingStationCheckbox">Arriving Station</label>
              </div>
            </div>
            <div class="col-6">
              <select class="form-control form-select" id="search-arriving-flight-number" placeholder=""
                      [disabled]="!changeArrivingStation">
                <option>ATH</option>
                <option>ZRH</option>
                <option>VIE</option>
              </select>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row mb-3">
            <div class="col-6 form-field-header">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="staCheckbox" name="staCheckbox"
                       [(ngModel)]="changeSTA"> &nbsp; &nbsp;
                <label class="form-check-label" for="staCheckbox">STA</label>
              </div>
            </div>
            <div class="col-6">
              <div class="container px-0">
                <div class="row">
                  <div class="col-12">
                    <ngb-timepicker appTimepickerFocus [(ngModel)]="staTime" [spinners]="false" name="staTime"
                                    [disabled]="!changeSTA"></ngb-timepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row mb-3">
            <div class="col-6 form-field-header">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="flightServiceTypeCheckbox"
                       name="flightServiceTypeCheckbox" [(ngModel)]="changeFlightServiceType"> &nbsp; &nbsp;
                <label class="form-check-label" for="flightServiceTypeCheckbox">Flight Service Type</label>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <select class="form-control form-select" id="search-flight-flight-service-type" placeholder=""
                        [disabled]="!changeFlightServiceType">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </select></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
