import { Pipe, PipeTransform } from '@angular/core';
import { IAirlineDesignator } from '../models/airline-designator.model';

@Pipe({
  name: 'airlineDesignatorTestTag'
})
export class AirlineDesignatorTestTagPipe implements PipeTransform {
 transform(value: IAirlineDesignator, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `airline-designator-${value?.code}`;
  }

}
