import { Pipe, PipeTransform } from '@angular/core';
import { IAirportTerminals } from '../models/airport-terminals.model';

@Pipe({
  name: 'airportTerminalsTestTag'
})
export class AirportTerminalsTestTagPipe implements PipeTransform {

  transform(value: IAirportTerminals, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `terminal-${value?.name}`;
  }

}
