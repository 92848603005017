import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TimeFromMinutesPipe } from '../../../pipes/time-from-minutes.pipe';
import { ISimpleData } from '../../../models/simpleData.model';

export class FlightsChangeFlightDetailsArrivalDepartureFlightFormBuilder {

  static constructForm(
    fb: UntypedFormBuilder,
    timeFromMinutesPipe: TimeFromMinutesPipe,
    passengerClassesArray: ISimpleData[] = [],
    passengerCategoriesArray: ISimpleData[] = [],
  ): UntypedFormGroup {
    console.log('passenger categories:', passengerCategoriesArray);

    const actualPassengersFormGroup = new UntypedFormGroup({});
    const estimatedPassengersFormGroup = new UntypedFormGroup({});

    passengerClassesArray.forEach((item: ISimpleData) => {
      if (item?.code) {
        actualPassengersFormGroup.addControl(item.code, new UntypedFormGroup({
          code: new UntypedFormControl(item.code, Validators.required),
          description: new UntypedFormControl()
        }));
        estimatedPassengersFormGroup.addControl(item.code, new UntypedFormGroup({
          code: new UntypedFormControl(item.code, Validators.required),
          description: new UntypedFormControl()
        }));
      }
    });

    const passengerCategoriesFormGroup = new UntypedFormGroup({});
    passengerCategoriesArray.forEach((item: ISimpleData) => {
      if (item?.code) {
        passengerCategoriesFormGroup.addControl(item.code, new UntypedFormGroup({
          code: new UntypedFormControl(item.code, [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/), Validators.maxLength(5)]),
          amount: new UntypedFormControl(null, Validators.pattern(/^\d+$/))
        }));
      }
    });

    return fb.group({
      etdDate: [{value: null, disabled: true}],
      etdTime: [{value: null, disabled: true}, Validators.pattern('\\d{2}[:]\\d{2}')],
      atdDate: [null],
      atdTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      totTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      etaTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      lndTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      ataTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      ctotTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      actualPassengers: actualPassengersFormGroup,
      estimatedPassengers: estimatedPassengersFormGroup,
      gate: [null],
      stand: [null],
      niTime: [null, Validators.pattern('\\d{2}[:]\\d{2}')],
      departureDelays: new UntypedFormGroup({}),
      arrivalDelays: new UntypedFormGroup({}),
      arrivalDelaysNew: new UntypedFormGroup({
        arrival1: new UntypedFormGroup({
          delayCode: new UntypedFormControl(null),
          time: new UntypedFormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        arrival2: new UntypedFormGroup({
          delayCode: new UntypedFormControl(null),
          time: new UntypedFormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        })
      }),
      departureDelaysNew: new UntypedFormGroup({
        departure1: new UntypedFormGroup({
          delayCode: new UntypedFormControl(null),
          time: new UntypedFormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure2: new UntypedFormGroup({
          delayCode: new UntypedFormControl(null),
          time: new UntypedFormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure3: new UntypedFormGroup({
          delayCode: new UntypedFormControl(null),
          time: new UntypedFormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure4: new UntypedFormGroup({
          delayCode: new UntypedFormControl(null),
          time: new UntypedFormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
      }),
      categories: passengerCategoriesFormGroup,
    });
  }
}
