import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'timeFromSeconds'
})
export class timeFromSecondsPipe extends DecimalPipe implements PipeTransform {

  transform(secondsPassed: number): string | any {
    if (!secondsPassed || secondsPassed === 0) {
      return '00:00';
    }
    const minutes = Math.floor(secondsPassed / 60);
    const seconds = secondsPassed - (minutes * 60);
    if(minutes < 10) {
        if(seconds < 10) {
          return "0" + minutes + ":" + "0" + seconds;
        } else {
          return "0" + minutes + ":" + seconds;
        }
      } else {
        if(seconds < 10) {
          return minutes + ":" + "0" + seconds;
        } else {
          return minutes + ":" + seconds;
        }
      }
  }
}