<div class="position-relative h-100" *ngIf="pageAccess === null">
  <app-access-denied></app-access-denied>
</div>
<app-settings-header text="Role Management" [showSaveButton]="false"></app-settings-header>
<app-settings-card (onAddClicked)="doAdd()" sectionTitle="Roles" addText="Add Role" [canAdd]="pageAccess === Access.RW">
  <div class="flex flex-column gap-1">
    <app-search-bar [(text)]="searchText" (textChange)="filterData()" width="250px"></app-search-bar>
    <app-settings-data-table [testTagPipe]="RoleManagementTestTagPipe" class="mt-3"
                             [columns]="['Role', 'Airports', 'Web', 'Mobile']"
                             [data]="filteredTableData"
                             [variableNames]="['role', 'airports', 'web', 'mobile']"
                             (onEditClicked)="doEdit($event)"
                             (onDeleteClicked)="doDelete($event)" emitProperty="id" [isEditable]="isEditable"
                             [viewModePerRow]="pageAccess === Access.RW"
                             [viewModeCheck]="isViewable"
                             [isDeletable]="isEditable" [canDelete]="pageAccess === Access.RW"
                             [viewOnlyMode]="pageAccess === Access.R" customCSS="tr td:nth-child(2) div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-wrap: break-word;
  line-clamp: 2;
} tr td:not(:nth-child(2)) { text-wrap: nowrap } ">
      <ng-template propName="web" let-value>
        <div class="flex flex-column justify-content-evenly">
          <div>Read: {{ value.read | titlecase }}</div>
          <div>Write: {{ value.write | titlecase }}</div>
        </div>
      </ng-template>

      <ng-template propName="mobile" let-value>
        <div class="flex flex-column justify-content-evenly">
          <div>Read: {{ value.read | titlecase }}</div>
          <div>Write: {{ value.write | titlecase }}</div>
        </div>
      </ng-template>

      <ng-template propName="airports" let-value>
        <div>{{ value }}

        </div>
      </ng-template>
    </app-settings-data-table>
  </div>
</app-settings-card>
