<div class="settings-container flex flex-column">
    <div class="settings-row font-size-20">
      <div class="custom-file">
        <input type="file" class="custom-file-input" id="file" (change)="handleFileInput($event.target.files)" data-test="arcid-file-input">
        <label class="custom-file-label" for="file">{{(file) ? file.name : 'Choose file'}}</label>
      </div>
    </div>

    <div class="settings-row font-size-20">
      <button type="button" class="btn add-button button-blue flex-basis-20 border-radius-5" (click)="callFunction()" [disabled]="!file || isBusy" data-test="arcid-upload-button">
        {{ isBusy ? '' : 'Proceed' }}
        <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
      </button>
    </div>
  </div>
