export enum AlertTypesEnum {
  UNPAIRED_SECTOR = 'UNPAIRED_SECTOR',
  AIRPORT_CURFEW_TIME = 'AIRPORT_CURFEW_TIME',
  INADEQUATE_TURNAROUND_TIME = 'INADEQUATE_TURNAROUND_TIME',
  NEW_INFORMATION_ISSUED = 'NEW_INFORMATION_ISSUED',
  TURNAROUND_NOT_STARTED = 'TURNAROUND_NOT_STARTED',
  REQUEST_FOR_EXTENSION = 'REQUEST_FOR_EXTENSION',
  UNSUCCESSFUL_MESSAGE = 'UNSUCCESSFUL_MESSAGE',
  NO_ACTUAL_TIMES_ON_SECTOR = 'NO_ACTUAL_TIMES_ON_SECTOR',
  OVERLAPPING_SECTORS = 'OVERLAPPING_SECTORS',
  CTOT_VIOLATION = 'CTOT_VIOLATION',
  EARLY_DEPARTURE = 'EARLY_DEPARTURE',
  AIRCRAFT_RETURN_TO_RAMP = 'AIRCRAFT_RETURN_TO_RAMP',
  AIRCRAFT_RETURN_FROM_AIRBORNE = 'AIRCRAFT_RETURN_FROM_AIRBORNE',
  AIRCRAFT_DIVERSION = 'AIRCRAFT_DIVERSION',
  ARRIVE_AT_CLOSED_AIRPORT = 'ARRIVE_AT_CLOSED_AIRPORT',
  DEPART_FROM_CLOSED_AIRPORT = 'DEPART_FROM_CLOSED_AIRPORT',
  LATE_ONGOING_PROCESS = "LATE_ONGOING_PROCESS",
  MVT_ACARS_DIFFERENCE_IN_ATD = 'MVT_ACARS_DIFFERENCE_IN_ATD',
  MVT_ACARS_DIFFERENCE_IN_ATA = 'MVT_ACARS_DIFFERENCE_IN_ATA',
  MVT_ACARS_DIFFERENCE_IN_LND = 'MVT_ACARS_DIFFERENCE_IN_LND',
  MVT_ACARS_DIFFERENCE_IN_TOT = 'MVT_ACARS_DIFFERENCE_IN_TOT',
  PASSENGER_MISCONNECTION = 'PASSENGER_MISCONNECTION',
}
