import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IChatMessageModel } from '../../../models/chat-message.model';
import { ChatService } from '../../../../services/chat.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'app-edit-message-dialog',
  templateUrl: './edit-message-dialog.component.html',
  styleUrls: ['./edit-message-dialog.component.scss']
})
export class EditMessageDialog implements OnInit {
  message: IChatMessageModel;
  formGroup: UntypedFormGroup;
  constructor(private activeModal: NgbActiveModal, private chatService: ChatService, private toastService: ToastService) { }

  ngOnInit(): void {
    console.log('Content:', this.message.content)
    this.formGroup = new UntypedFormGroup({
      content: new UntypedFormControl(this.message.content, Validators.required)
    })
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  editMessage()
  {
    if(!this.formGroup.value.content) {
      return;
    }
    this.chatService.editMessage({ ...this.message, content: this.formGroup.value.content }).subscribe((result) => {
      if (result.id) {
        this.message.content = result.content;
        this.message.lastChangedAt = new Date();
        this.toastService.showSuccess('Message has been edited!');
        this.closeModal();
      } else {
        this.toastService.showError('Could not edit message. Please try again!');
      }
    });
  }
}
