import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-gantt-section-tab',
  templateUrl: './gantt-section-tab.component.html',
  styleUrls: ['./gantt-section-tab.component.scss']
})
export class GanttSectionTabComponent implements OnChanges {
  @Input() text: string;
  @Input() isOpen: boolean;
  @Output() onClicked = new EventEmitter<string>();
  hide: boolean = true;
  timer?: ReturnType<typeof setTimeout>;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen) {
      clearTimeout(this.timer);
      if (this.isOpen) {
        this.hide = false;
      } else {
        this.timer = setTimeout(() => this.hide = true, 600);
      }
    }
  }
}
