<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" *ngIf="form">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <div class="container">
            <div class="row h-54">
              <div class="col-6 form-field-header" [ngClass]="{'invalid-field-col': form?.get('utcWithoutDst')?.invalid}">
                Non DST Variation*
              </div>
              <div class="col-2">
                <div class="mb-3">
                  <select class="form-control form-select" id="settings-airport-timezone-non-dst-variation-operator"
                          formControlName="utcWithoutDstOperator">
                    <option value="">+</option>
                    <option value="-">-</option>
                  </select>
                </div>
              </div>
              <div class="col-4 text-end">
                <ngb-timepicker appTimepickerFocus [spinners]="false" formControlName="utcWithoutDst"  data-test="settings-airport-timezone-utc-without-dst-time-picker"></ngb-timepicker>
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header" [ngClass]="{'invalid-field-col': form?.value?.dstApplies && form?.get('utcWithDst')?.invalid}">
                DST Variation{{form?.value?.dstApplies ? '*' : ''}}
              </div>
              <div class="col-2">
                <div class="mb-3">
                  <select class="form-control form-select" id="settings-airport-timezone-dst-variation-operator"
                          formControlName="utcWithDstOperator">
                    <option value="">+</option>
                    <option value="-">-</option>
                  </select>
                </div>
              </div>
              <div class="col-4 text-end">
                <ngb-timepicker appTimepickerFocus [spinners]="false" formControlName="utcWithDst"></ngb-timepicker>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="container">
            <div class="row h-54">
              <div class="col-6 form-field-header">
                DST applies
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="checkbox" class="form-check-input" id="settings-airport-timezone-dst-applies"
                         formControlName="dstApplies">
                </div>
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header"></div>
              <div class="col-6 form-field-content"></div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="form?.value?.dstApplies" class="row">
        <div class="col-6">
          <div class="container">
            <div class="row">
              <div class="col-12 form-field-header colorBlack mb-3">
                DST Start
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header" [ngClass]="{'invalid-field-col': form?.get('dstStartTime')?.invalid}">
                Time{{form?.value?.dstApplies ? '*' : ''}}
              </div>
              <div class="col-6 form-field-content">
                <ngb-timepicker appTimepickerFocus [spinners]="false" formControlName="dstStartTime"></ngb-timepicker>
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header" [ngClass]="{'invalid-field-col': form?.get('dstStartMonth')?.invalid}">
                Month{{form?.value?.dstApplies ? '*' : ''}}
              </div>
              <div class="col-6 form-field-content">
                <div class="mb-3">
                  <select class="form-control form-select" id="settings-airport-timezone-dst-start-month"
                          formControlName="dstStartMonth">
                    <option value="JAN">January</option>
                    <option value="FEB">February</option>
                    <option value="MAR">March</option>
                    <option value="APR">April</option>
                    <option value="MAY">May</option>
                    <option value="JUN">June</option>
                    <option value="JUL">July</option>
                    <option value="AUG">August</option>
                    <option value="SEP">September</option>
                    <option value="OCT">October</option>
                    <option value="NOV">November</option>
                    <option value="DEC">December</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header" [ngClass]="{'invalid-field-col': form?.get('dstStartWeekday')?.invalid}">
                Weekday{{form?.value?.dstApplies ? '*' : ''}}
              </div>
              <div class="col-6 form-field-content">
                <div class="mb-3">
                  <select class="form-control form-select" id="settings-airport-timezone-dst-start-weekday"
                          formControlName="dstStartWeekday">
                    <option value="MON">Monday</option>
                    <option value="TUE">Tuesday</option>
                    <option value="WED">Wednesday</option>
                    <option value="THU">Thursday</option>
                    <option value="FRI">Friday</option>
                    <option value="SAT">Saturday</option>
                    <option value="SUN">Sunday</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header" [ngClass]="{'invalid-field-col': form?.get('dstStartWeekOfMonth')?.invalid}">
                Week of the month{{form?.value?.dstApplies ? '*' : ''}}
              </div>
              <div class="col-6 form-field-content">
                <div class="mb-3">
                  <select class="form-control form-select" id="settings-airport-timezone-dst-start-weekday-counter"
                          formControlName="dstStartWeekOfMonth">
                    <option value="First">First</option>
                    <option value="Second">Second</option>
                    <option value="Third">Third</option>
                    <option value="Last">Last</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 border-start">
          <div class="container">
            <div class="row">
              <div class="col-12 form-field-header colorBlack mb-3">
                DST End
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header" [ngClass]="{'invalid-field-col': form?.get('dstEndTime')?.invalid}">
                Time{{form?.value?.dstApplies ? '*' : ''}}
              </div>
              <div class="col-6 form-field-content">
                <ngb-timepicker appTimepickerFocus [spinners]="false" formControlName="dstEndTime"></ngb-timepicker>
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header" [ngClass]="{'invalid-field-col': form?.get('dstEndMonth')?.invalid}">
                Month{{form?.value?.dstApplies ? '*' : ''}}
              </div>
              <div class="col-6 form-field-content">
                <div class="mb-3">
                  <select class="form-control form-select" id="settings-airport-timezone-dst-end-month" formControlName="dstEndMonth">
                    <option value="JAN">January</option>
                    <option value="FEB">February</option>
                    <option value="MAR">March</option>
                    <option value="APR">April</option>
                    <option value="MAY">May</option>
                    <option value="JUN">June</option>
                    <option value="JUL">July</option>
                    <option value="AUG">August</option>
                    <option value="SEP">September</option>
                    <option value="OCT">October</option>
                    <option value="NOV">November</option>
                    <option value="DEC">December</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header" [ngClass]="{'invalid-field-col': form?.get('dstEndWeekday')?.invalid}">
                Weekday{{form?.value?.dstApplies ? '*' : ''}}
              </div>
              <div class="col-6 form-field-content">
                <div class="mb-3">
                  <select class="form-control form-select" id="settings-airport-timezone-dst-end-weekday"
                          formControlName="dstEndWeekday">
                    <option value="MON">Monday</option>
                    <option value="TUE">Tuesday</option>
                    <option value="WED">Wednesday</option>
                    <option value="THU">Thursday</option>
                    <option value="FRI">Friday</option>
                    <option value="SAT">Saturday</option>
                    <option value="SUN">Sunday</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header" [ngClass]="{'invalid-field-col': form?.get('dstEndWeekOfMonth')?.invalid}">
                Week of the month{{form?.value?.dstApplies ? '*' : ''}}
              </div>
              <div class="col-6 form-field-content">
                <div class="mb-3">
                  <select class="form-control form-select" id="settings-airport-timezone-dst-end-weekday-counter"
                          formControlName="dstEndWeekOfMonth">
                    <option value="First">First</option>
                    <option value="Second">Second</option>
                    <option value="Third">Third</option>
                    <option value="Last">Last</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="form?.disabled || form?.invalid || isBusy">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
