<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="edit-memo-close-button">
  </button>
</div>
<app-tabs-navigation [tabs]="['Arrival leg', 'Departure leg']" (tabChanged)="flightTypeSelection = $event.split(' ')[0].toLowerCase()"></app-tabs-navigation>
<div class="modal-body">
  <ng-container *ngIf="form">
    <form [formGroup]="form">
        <ng-container *ngIf="flightTypeSelection === 'arrival'">
<!--          <div class="row mb-3">-->

<!--            <div *ngIf="arrivalMemo" class="col-12 color-gray">-->
<!--              &lt;!&ndash; last update 20.05.2019 22:30 by Anna Black &ndash;&gt;-->
<!--              {{arrivalMemo?.content !== '' ? 'last update on ' : 'deleted on '}} {{arrivalMemo?.lastChangedAt | date}} by {{arrivalMemoUser?.username}}-->
<!--            </div>-->
<!--            <div *ngIf="!arrivalMemo" class="col-12 color-gray">-->
<!--              no memo added yet-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <div class="mb-3 w-100 mx-3">-->
<!--              <textarea class="form-control" id="arrivalMemoContent" formControlName="arrivalText" rows="7"></textarea>-->
<!--            </div>-->
<!--          </div>-->
          <div class="flex flex-column dark-blue">
            <div class="last-activity" *ngIf="arrivalMemo">Last activity: {{arrivalMemo?.content !== '' ? 'last update on ' : 'deleted on '}} {{arrivalMemo?.lastChangedAt | date}} by {{arrivalMemoUser?.firstname}} {{arrivalMemoUser?.lastname}}</div>
            <div class="last-activity" *ngIf="!arrivalMemo">Last activity: -</div>
            <textarea class="form-control light-blue-border dark-blue" id="arrivalMemoContent" formControlName="arrivalText" rows="7" data-test="edit-memo-arrival-textarea"></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="flightTypeSelection === 'departure'">
          <div class="flex flex-column dark-blue">
            <div class="last-activity" *ngIf="departureMemo">Last activity: {{departureMemo?.content !== '' ? 'last update on ' : 'deleted on '}} {{departureMemo?.lastChangedAt | date}} by {{arrivalMemoUser?.firstname}} {{arrivalMemoUser?.lastname}}</div>
            <div class="last-activity" *ngIf="!departureMemo">Last activity: -</div>
            <textarea class="form-control light-blue-border dark-blue" id="departureMemoContent" formControlName="departureText" rows="7" data-test="edit-memo-departure-textarea"></textarea>
          </div>
        </ng-container>

    </form>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()" data-test="edit-memo-cancel-button">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="form?.disabled || form?.invalid || isBusy" data-test="edit-memo-save-button">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
