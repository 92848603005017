import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faThumbsUp} from '@fortawesome/free-solid-svg-icons';
import {ChatService} from '../../../../services/chat.service';
import {IChatMessageModel} from '../../../models/chat-message.model';
import {firstValueFrom} from 'rxjs';
import {AuthService} from '../../../../services/auth.service';
import {ToastService} from '../../../../services/toast.service';
import {IUser} from '../../../models/user.model';
import {PermissionService} from "../../../../services/permission.service";
import {Access, PermissionUIMasks} from "../../../constants/enums";
import {IAirport} from "../../../models/airport.model";

@Component({
  selector: 'app-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.scss']
})
export class ChatFormComponent implements OnInit, OnChanges {
  @Input() conversation: IChatMessageModel[];
  @Input() chatGroupId: number;
  @Output() chatGroupIdChange = new EventEmitter<number>();
  @Input() numberOfUnreadMessages: number;
  @Input() user: IUser;
  @Output() newMessage = new EventEmitter<IChatMessageModel>();
  @Input() airports: IAirport[];

  formGroup = new UntypedFormGroup({
    text: new UntypedFormControl('')
  });

  likeIcon: IconDefinition = faThumbsUp;
  chatAccess: Access;

  constructor(private chatService: ChatService, private authService: AuthService, private toastService: ToastService, private permissionService: PermissionService) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.user || !this.airports) {
      return;
    }
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_CHAT, this.airports.find((airport) => airport.iata === this.user.location)?.id).then((chatAccess) => {
      this.chatAccess = chatAccess;
      if (this.chatAccess !== Access.RW) {
        this.formGroup.disable();
      } else {
        this.formGroup.enable();
      }
    });
  }

  async submit(overrideMessage?: string) {
    if (this.chatAccess !== Access.RW) {
      return;
    }
    const message = overrideMessage ?? this.formGroup.get('text').value?.trim();
    if (!message?.length) {
      return;
    }
    this.formGroup.reset();
    if (!this.chatGroupId) {
      const chatGroupId = await firstValueFrom(this.chatService.createChatGroup([this.authService.user.id, this.user.id]));
      if (!chatGroupId) {
        return;
      }
      this.chatGroupId = chatGroupId;
      this.chatGroupIdChange.emit(chatGroupId);
    }
    this.chatService.sendMessage(this.chatGroupId, message).subscribe((result) => {
      if (!result.id) {
        this.toastService.showError('There was a problem sending your message. Please try again');
      } else {
        this.newMessage.emit(result);
      }
    });
  }

  submitKeyboard(evt: KeyboardEvent) {
    if (evt.key === 'Enter') {
      this.submit();
    }
  }

  public readonly Access = Access;
}
