import { Pipe, PipeTransform } from '@angular/core';
import { IAcType } from '../models/ac-type.model';

@Pipe({
  name: 'acTypesTestTag'
})
export class AcTypesTestTagPipe implements PipeTransform {

  transform(value: IAcType, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `ac-type-${value?.iata}`;
  }

}
