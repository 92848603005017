import {Component, Input, OnInit} from '@angular/core';
import {AirportsService} from '../../../../../services/airports.service';
import {ToastService} from '../../../../../services/toast.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IAirportContact} from '../../../../../shared/models/airport-contact.model';
import {AuthService} from '../../../../../services/auth.service';

@Component({
  selector: 'app-add-edit-airport-contact-dialog',
  templateUrl: './add-edit-airport-contact-dialog.component.html',
  styleUrls: ['./add-edit-airport-contact-dialog.component.scss']
})
export class AddEditAirportContactComponent implements OnInit {

  title = "Add Airport Contact";
  @Input() contactData?: IAirportContact;
  @Input() airportId?: number;
  form: UntypedFormGroup;
  isBusy = false;
  readonly: boolean;

  constructor(private airportService: AirportsService, private toastService: ToastService, private activeModal: NgbActiveModal, private authService: AuthService) {
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      firstname: new UntypedFormControl(this.contactData?.firstname || '', Validators.required),
      lastname: new UntypedFormControl(this.contactData?.lastname || '', Validators.required),
      email: new UntypedFormControl(this.contactData?.email || '', Validators.pattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)),
      company: new UntypedFormControl(this.contactData?.company || '', Validators.required),
      department: new UntypedFormControl(this.contactData?.department || ''),
      mobile: new UntypedFormControl(this.contactData?.mobile || ''),
      phone: new UntypedFormControl(this.contactData?.phone || ''),
      remarks: new UntypedFormControl(this.contactData?.remarks || ''),
      sita1: new UntypedFormControl(this.contactData?.sita1 || ''),
      sita2: new UntypedFormControl(this.contactData?.sita2 || ''),
      vhf: new UntypedFormControl(this.contactData?.vhf || ''),
    });
    if (this.readonly) {
      this.form.disable();
    }
  }

  closeModal(reason?: any) {
    this.activeModal.dismiss(reason);
  }

  onSaveClick() {
    const data: IAirportContact = {
      ...this.contactData,
      airportId: this.airportId,
      userId: this.authService.user.id, ...this.form.value
    };
    this.isBusy = true;
    this.airportService.saveAirportContactCompanies(data).subscribe((result) => {
      if (result?.id) {
        this.toastService.showSuccess("Contact company has been saved");
        this.closeModal(true);
      }
      this.isBusy = false;
    });
  }
}
