import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../../services/toast.service";
import {UserService} from "../../../services/user.service";
import {IUserGroupsModel} from "../../../shared/models/user-group.model";

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {

  form?: UntypedFormGroup;
  isBusy = false;
  userGroups: string[];

  constructor(private activeModal: NgbActiveModal, private toastService: ToastService, private userService: UserService) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
    }, {
      validators: this.nameValidator().bind(this)
    });
  }

  closeModal(reason?: any) {
    this.activeModal.dismiss(reason);
  }

  onSaveClick() {
    const data: IUserGroupsModel = {
      title: this.form.value.name,
      internalName: this.form.value.name.toUpperCase().replace(' ', '_'),
    };
    this.isBusy = true;
    this.userService.saveUserGroup(data).subscribe((result) => {
      if (result?.id) {
        this.toastService.showSuccess("Role has been created!");
        this.closeModal(true);
      }
      this.isBusy = false;
    });

  }

  nameValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const name = formGroup.get('name');

      for (const group of this.userGroups) {
        if (group?.toLowerCase() === name?.value?.toLowerCase()) {
          return {nameExists: true};
        }
      }
      return null;
    };
  }

}
