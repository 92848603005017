import { Pipe, PipeTransform } from '@angular/core';
import { InterfaceMessage } from '../models/interfaceMessage.model';
import dayjs from 'dayjs';

@Pipe({
  name: 'messageTestTag'
})
export class MessageTestTagPipe implements PipeTransform {

  transform(value: InterfaceMessage, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `${ dayjs.utc(value?.datetime).format("DDMMYYYY") }-${ value?.type }-${ value?.subtype }-${ value?.flightNumber }-${ value?.acRegistration }`;
  }

}
