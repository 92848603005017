<div class="container-fluid chat-font height-fix mt-3 position-relative">
  <app-access-denied *ngIf="pageAccess === null"></app-access-denied>
  <div class="row h-100">
    <div class="col-auto left">
      <div class="row">
        <div class="w-100">
          <div class="chat-text-section justify-content-between light-blue-border">
            <div class="align-self-center fs-4 fw-bold dark-blue">
              Chats
            </div>
            <div class="align-self-center">
              <app-new-chat-button class="align-self-center cursor-pointer" [ngbPopover]="popContent"
                                   popoverClass="chat-search-popover-size avitium-scrollbar" placement="bottom"
                                   autoClose="outside" #popover="ngbPopover"
                                   data-test="new-chat-button"></app-new-chat-button>
              <ng-template #popContent>
                <div class="flex flex-column popover-height">
                  <form [formGroup]="popoverFormGroup" class="input-group has-search search-box w-100 mb-1">
                    <img src="content/images/icons/search.svg" width="26" height="26" class="form-control-feedback"
                         alt="search">
                    <input type="text" class="form-control popover-search input-bg-color light-blue-border"
                           placeholder="Search" formControlName="searchText" data-test="chat-new-chat-search-input">
                  </form>
                  <div class="chat-contact-list avitium-scrollbar">
                    <app-simple-chat-contact *ngFor="let user of popoverUsers" [user]="user"
                                             (click)="createChatGroup(user); popover.close();"
                                             [attr.data-test]="'chat-contact-user-' + user?.id"></app-simple-chat-contact>
                  </div>

                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="w-100">
          <form class="flex search-section justify-content-between" [formGroup]="formGroup">
            <div class="input-group has-search search-box w-50">
              <img src="content/images/icons/search.svg" width="26" height="26" class="form-control-feedback"
                   alt="search">
              <input type="text" class="form-control search-bar input-bg-color" placeholder="Search"
                     formControlName="searchText" data-test="chat-search-contacts-input">
            </div>
            <select class="form-select w-50 sort-box input-bg-color light-blue-border" aria-label="Sort"
                    formControlName="sort" data-test="chat-contacts-sort-select">
              <option selected [value]="null" data-test="default-sort">Sort</option>
              <option value="Airport" data-test="airport-sort">Airport</option>
              <option value="Role" data-test="role-sort">Role</option>
            </select>
          </form>
        </div>
      </div>
      <div class="left-side-border-divider"></div>
      <div class="contact-list-container gx-0 avitium-scrollbar">
        <app-advanced-chat-contact class="contact light-blue-border" *ngFor="let group of this.messageGroups"
                                   [numberOfUnreadMessages]="this.chatService.myMessageGroups[group.chatGroupId]?.numberOfUnreadMessages"
                                   [sentAreUnread]="this.chatService.messageGroups[group.chatGroupId]?.numberOfUnreadMessages > 0"
                                   [chatGroup]="group" [user]="group.__user__"
                                   [activeIndex]="activeIndex" (click)="setActiveIndex(group.chatGroupId)"
                                   [attr.data-test]="'chat-contact-group-' + group.chatGroupId"></app-advanced-chat-contact>
      </div>
    </div>
    <div class="col-auto right">
      <ng-container *ngIf="activeIndex">
        <div class="row">
          <div class="w-100">
            <div class="selected-user-section light-blue-border justify-content-between">
              <div class="flex flex-column text-start justify-content-between">
                <div class="right-side-user-name fw-bold">
                  <div
                    class="align-self-center fw-bold dark-blue">{{ this.chatService.messageGroups[activeIndex].__user__?.firstname ?? '' }} {{ this.chatService.messageGroups[activeIndex].__user__?.lastname ?? '' }}
                    ({{ this.chatService.messageGroups[activeIndex].__user__?.location ?? '' }})
                  </div>
                </div>
                <div class="user-role">
                  {{ this.chatService.messageGroups[activeIndex].__user__?.role ?? '' | i18nSelect: userRoleObject.DISPLAY_TITLE_MAP }}
                </div>
              </div>
              <div class="align-self-center">
                <img src="content/images/icons/search.svg" width="20" height="20">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="w-100">
            <div class="right-chat-section light-blue-border">
              <div class="flex flex-column">
                <ng-container *ngIf="activeIndex">
                  <app-chat-session [chatGroupId]="this.chatService.messageGroups[activeIndex].chatGroupId"
                                    [user]="this.chatService.messageGroups[activeIndex].__user__"
                                    #session></app-chat-session>
                  <app-chat-form [chatGroupId]="this.chatService.messageGroups[activeIndex].chatGroupId"
                                 [numberOfUnreadMessages]="this.chatService.myMessageGroups[activeIndex]?.numberOfUnreadMessages"
                                 [user]="this.chatService.messageGroups[activeIndex].__user__"
                                 (newMessage)="session.conversation.unshift($event); addUnreadMessage($event)"
                                 [conversation]="session.conversation" [airports]="airports"></app-chat-form>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
