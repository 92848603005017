import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TurnaroundStatus } from '../../../../constants/turnaround-status.constants';
import { IPairOverview } from '../../../../models/pair-overview.model';
import { IPairDetail } from '../../../../models/pair-detail.model';
import { interval, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-boarding-process',
  templateUrl: './boarding-process.component.html',
  styleUrls: ['./boarding-process.component.scss']
})
export class BoardingProcessComponent implements OnInit, OnDestroy {
  @Input() pairOverview: IPairOverview;
  @Input() pairDetail: IPairDetail;
  currentPassengers: number = Math.floor(Math.random() * 40);
  maxPassengers: number = 162;
  turnaroundProgressBarColor: string = 'primary';
  turnaroundProgressPercentage: number = this.currentPassengers / this.maxPassengers * 100;
  unsubscribe$ = new Subject();
  constructor() { }

  ngOnInit(): void {
    interval(5000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.currentPassengers++;
      if(this.currentPassengers < this.maxPassengers && Math.random() > 0.5) {
        this.currentPassengers++;
      }
      this.turnaroundProgressPercentage = this.currentPassengers / this.maxPassengers * 100;
      if (this.currentPassengers === this.maxPassengers) {
        this.unsubscribe$.next(undefined);
        this.unsubscribe$.complete();
      }
    });
  }


  ngOnDestroy() {
      this.unsubscribe$?.next(undefined);
      this.unsubscribe$?.complete();
    }

    protected readonly TurnaroundStatus = TurnaroundStatus;
}
