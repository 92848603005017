import {Component, OnInit} from '@angular/core';
import {ToastService} from "../../../services/toast.service";
import {UserLegAssignmentService} from "../../../services/user-leg-assignment.service";
import {UserService} from "../../../services/user.service";
import {IUser} from "../../models/user.model";
import {IUserLegAssignmentModel} from "../../models/user-leg-assignment.model";
import {forkJoin} from "rxjs";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {StaticUserGroupConstants} from "../../constants/static-user-group.constants";

@Component({
  selector: 'app-select-ramp-agent',
  templateUrl: './select-ramp-agent.component.html',
  styleUrls: ['./select-ramp-agent.component.scss']
})
export class SelectRampAgentComponent implements OnInit {

  airportId: number;
  airportIata: string;
  legId: number;
  isBusy = false;
  users: IUser[];
  legAssignments: IUserLegAssignmentModel[];
  selectedUserId: number;

  constructor(private toastService: ToastService, private userLegAssignmentService: UserLegAssignmentService, private userService: UserService, private activeModal: NgbActiveModal) {

  }

  changedUser(id: number, event: Event) {
    if (this.isBusy) {
      return;
    }
    const elem = event.target as HTMLInputElement;
    this.selectedUserId = elem.checked ? id : null;
  }

  ngOnInit(): void {
    forkJoin([this.userService.fetchUsers({
      isActive: true,
      location: this.airportIata,
      userGroup: StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT,
    }), this.userLegAssignmentService.getUserLegAssignment({
      isActive: true,
      arrivalLegId: this.legId
    })]).subscribe(([users, legAssignments]) => {
      this.users = users;
      this.legAssignments = legAssignments;
      if (this.legAssignments?.length) {
        this.selectedUserId = this.legAssignments[0]?.userId;
      }
    });
  }

  closeModal(reason = false) {
    this.activeModal.dismiss(reason);
  }

  onSaveClick() {
    if (!this.selectedUserId && !this.legAssignments?.length || this.isBusy) {
      return;
    }
    this.isBusy = true;
    if (this.legAssignments?.length) {
      this.userLegAssignmentService.deleteUserLegAssignment(this.legAssignments[0].id).subscribe(() => {
        if (!this.selectedUserId) {
          this.toastService.showSuccess('Ramp agent removed successfully');
          this.closeModal(true);
        }
        this.isBusy = false;
      });
    }
    if (this.selectedUserId) {
      this.userLegAssignmentService.saveUserLegAssignment({
        userId: this.selectedUserId,
        arrivalLegId: this.legId
      }).subscribe((res) => {
        if (res?.id) {
          this.toastService.showSuccess('Ramp agent assigned successfully');
          this.closeModal(true);
        }
        this.isBusy = false;
      });
    }

  }
}
