export class SSIMValidatorErrors {
  static RC2_INVALID_TIMEZONE = 'Invalid timezone indicator given in record type 2';
  static RC2_INVALID_PERIOD = 'Invalid period given in record type 2';
  static RC2_UNKNOWN_AIRLINE_DESIGNATOR = 'Unknown airline designator given in record type 2';
  static RC2_NOT_ALLOWED_FOR_SSIM = 'The SSIM you are trying to upload does not correspond to the correct Airline Designator as defined in settings - SSIM file';
  static RC2_UNREGISTERED_AIRLINE_DESIGNATOR = 'The Airline Code in the SSIM does not exist into the Airline Code Settings. Please insert the Airline Code and the Description first and try again. Error on SSIM File';


  static RC3_INVALID_AIRLINE_DESIGNATOR = 'Invalid airline designator given in record type 3';
  static RC3_UNKNOWN_AIRLINE_DESIGNATOR = 'Unknown airline designator given in record type 3';
  static RC3_INVALID_FLIGHT_NUMBER = 'Invalid flight number given in record type 3';
  static RC3_INVALID_DAYS_OF_OPERATION = 'Invalid days of operation given in record type 3';
  static RC3_INVALID_SERVICE_TYPE = 'Invalid service type given in record type 3';
  static RC3_UNKNOWN_SERVICE_TYPE = 'Unknown service type given in record type 3';
  static RC3_INVALID_DEPARTURE_STATION = 'Invalid departure station given in record type 3';
  static RC3_UNKNOWN_DEPARTURE_STATION = 'Unknown departure station given in record type 3';
  static RC3_WARNING_NEW_DEPARTURE_STATION = 'Departing airport station will be added from internal global list but will have to be filled completely by admin';
  static RC3_INVALID_ARRIVAL_STATION = 'Invalid arrival station given in record type 3';
  static RC3_UNKNOWN_ARRIVAL_STATION = 'Unknown arrival station given in record type 3';
  static RC3_WARNING_NEW_ARRIVAL_STATION = 'Arrival airport station will be added from internal global list but will have to be filled completely by admin';
  static RC3_INVALID_PERIOD = 'Invalid period given in record type 3';
  static RC3_INVALID_AC_TYPE = 'Invalid aircraft type given in record type 3';
  static RC3_UNKNOWN_AC_TYPE = 'Unknown aircraft type given in record type 3. Continuing will automatically create this aircraft type';
  static RC3_INVALID_SEATING_CONFIGURATION = 'Invalid seating configuration given in record type 3';
  static RC3_UNKNOWN_SEATING_CONFIGURATION = 'Unknown seating configuration given in record type 3';
  static RC3_INVALID_AIRCRAFT_STD = 'Invalid aircraft STD given in record type 3';
  static RC3_INVALID_AIRCRAFT_STA = 'Invalid aircraft STA given in record type 3';
  static RC3_INVALID_AIRCRAFT_STD_VARIATION = 'Invalid aircraft STD time variation given in record type 3';
  static RC3_INVALID_AIRCRAFT_STA_VARIATION = 'Invalid aircraft STA time variation given in record type 3';
}
