<div class="modal-header">
  <span class="modal-title">{{ airlineDesignator?.id ? 'Edit Airline Code' : 'Add Airline Code' }}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="edit-airline-designator-close-button">
  </button>
</div>
<div class="modal-body">
<ng-container *ngIf="form">
  <form [formGroup]="form">
    <div class="flex flex-column">
      <div class="flex justify-content-evenly">
        <div class="col-4 title">
          Code
        </div>
        <div class="col-4 title">
          Description
        </div>
      </div>

      <div class="flex justify-content-evenly">
        <div class="col-4 form-field-header">
          <input type="text" class="form-control" id="settings-passenger-class-code" placeholder="" formControlName="code" data-test="edit-airline-designator-code-input">
        </div>
        <div class="col-4 form-field-header">
          <input type="text" class="form-control" id="settings-passenger-class-description" placeholder="" formControlName="description" data-test="edit-airline-designator-description-input">
        </div>
      </div>

      <div class="flex justify-content-evenly mt-3">
        <div class="col-4 title">
          Schedule
        </div>
        <div class="col-4 title">
          Operate
        </div>
      </div>

      <div class="flex justify-content-evenly">
        <div class="col-4 form-field-header">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="settings-airline-allow-ssim" formControlName="allowForSsim" data-test="edit-airline-designator-allow-ssim-schedule-check">
          </div>
        </div>
        <div class="col-4 form-field-header">
          <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="settings-airline-allow-ac-operation" formControlName="allowAcOperation" data-test="edit-airline-designator-allow-ac-operation-check">
          </div>
        </div>
      </div>

    </div>
</form>
</ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()" data-test="edit-airline-designator-cancel-button">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="form?.disabled || form?.invalid || isBusy" data-test="edit-airline-designator-save-button">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
