import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ISimpleData } from '../../../models/simpleData.model';
import { seatingConfigurationValidator } from '../../../validators/seating-configuration.validator';

export class SettingsAcRegistrationMainFormBuilder {

  static constructForm(
    fb: UntypedFormBuilder,
    passengerClassesArray: ISimpleData[]): UntypedFormGroup {

    const seatConfigFormGroup = new UntypedFormGroup({}, {validators: seatingConfigurationValidator});

    passengerClassesArray.forEach((item: ISimpleData) => {
      seatConfigFormGroup.addControl(item.code, new UntypedFormGroup({
        code: new UntypedFormControl(item.code),
        description: new UntypedFormControl({ value: null, disabled: true })
      }));
    });

    return fb.group({
      acTypeId: ['', Validators.required],
      registration: ['', Validators.required],
      name: [''],
      description: ['', Validators.required],
      calibrationId: ['', Validators.required],
      mobNr: [''],
      deliveryDate: [null],
      mtow: ['', Validators.required],
      selcal: [''],
      seatingConfig: seatConfigFormGroup,
      noseCode: ['']
    });
  }
}
