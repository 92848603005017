<div class="ui-version">UI Version {{ version }}</div>
<div class="container mt-3 pt-3">
  <div class="row">
    <div class="col-12">
      <span class="form-field-subheader">ASM Upload</span>
    </div>
    <div class="col-12 mt-3">
      <app-asm-upload></app-asm-upload>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <span class="form-field-subheader">Bulk ASM Upload</span>
    </div>
    <div class="col-12 mt-3">
      <multi-app-asm-upload></multi-app-asm-upload>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="form-field-subheader">Arcid Upload</span>
    </div>
    <div class="col-12 mt-3">
      <app-arcid-upload></app-arcid-upload>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="form-field-subheader">CTOT Upload</span>
    </div>
    <div class="col-12 mt-3">
      <app-ctot-upload></app-ctot-upload>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <span class="form-field-subheader">MVT Upload</span>
    </div>
    <div class="col-12 mt-3">
      <app-mvt-upload></app-mvt-upload>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <span class="form-field-subheader">Excel Upload</span>
    </div>
    <div class="col-12 mt-3">
      <app-excel-upload></app-excel-upload>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <span class="form-field-subheader">ACARS Upload</span>
    </div>
    <div class="col-12 mt-3">
      <multi-app-acars-upload></multi-app-acars-upload>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <span class="form-field-subheader">PTM Upload</span>
    </div>
    <div class="col-12 mt-3">
      <app-ptm-upload></app-ptm-upload>
    </div>
  </div>
  <div class="row mt-3 pt-3">
    <div class="col-12">
      <span class="form-field-subheader">Database actions</span>
    </div>
    <div class="col-12 mt-3">
      <app-database-actions></app-database-actions>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-12">
      <span class="form-field-subheader">Turnaround Actions</span>
    </div>
    <div class="col-12">
      <button class="btn button-blue" (click)="changeTurnaround('start')">start</button>
      <button class="btn button-blue" (click)="changeTurnaround('pause')">pause</button>
      <button class="btn button-blue" (click)="changeTurnaround('resume')">resume</button>
      <button class="btn button-blue" (click)="changeTurnaround('finish')">finish</button>
    </div>
  </div> -->
</div>
