import {Component} from '@angular/core';
import {BehaviorSubject, forkJoin, mergeMap, of, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {IGsesModel} from '../../../../shared/models/gses.model';
import {IProcessesModel} from '../../../../shared/models/processes.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IGenericContainerObject} from '../../../../shared/models/genericContainerObject.model';
import {IGse} from '../../../../shared/models/gse.model';
import {DefinedGses} from '../../../../shared/constants/defined-gses';
import {GseService} from '../../../../services/gse.service';
import {ProcessesService} from '../../../../services/processes.service';
import {ToastService} from '../../../../services/toast.service';
import {TitleCasePipe} from '@angular/common';
import {take} from 'rxjs/operators';
import {IAirport} from '../../../../shared/models/airport.model';
import {AirportsService} from '../../../../services/airports.service';
import {TimeFromMinutesPipe} from '../../../../shared/pipes/time-from-minutes.pipe';
import {
  SlaGseEditAirportDialogComponent
} from '../dialogs/sla-gse-edit-airport-dialog/sla-gse-edit-airport-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IAcType} from '../../../../shared/models/ac-type.model';
import {AcTypesService} from '../../../../services/ac-types.service';
import {AirportsTestTagPipe} from '../../../../shared/pipes/airports-test-tag.pipe';
import {Access, PermissionUIMasks} from "../../../../shared/constants/enums";
import {PermissionService} from "../../../../services/permission.service";

type SlaGseAirportTableData = {
  airportId: number;
  iata: string;
  sla: string;
  gseType: string;
  processTime: string;
  applicable: string;
  exceptions: string;
};

@Component({
  selector: 'app-settings-sla-gse-edit-first-level',
  templateUrl: './settings-sla-gse-edit-first-level.component.html',
  styleUrls: ['./settings-sla-gse-edit-first-level.component.scss']
})
export class SettingsSlaGseEditFirstLevelComponent {

  gseId: number;
  gses: IGsesModel[];
  processes: IProcessesModel[];
  currentGse: IGsesModel;
  form = new UntypedFormGroup({
    processName: new UntypedFormControl(null, Validators.required),
    maxTime: new UntypedFormControl(0, [Validators.required, Validators.min(0)]),
    gseRequired: new UntypedFormControl(true),
    isSla: new UntypedFormControl(true),
    gseType: new UntypedFormControl(null),
  });
  definedGses: IGenericContainerObject<IGse> = DefinedGses.IGENERIC_CONTAINER_OBJECT;
  isBusy = false;
  searchText: string = '';
  airports: IAirport[];
  filteredAirports: IAirport[];
  tableData: SlaGseAirportTableData[];
  acTypes: IAcType[];
  pageAccess: Access;

  unsubscribe$ = new Subject();

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  constructor(private acTypesService: AcTypesService, private modalService: NgbModal, private timeFromMinutesPipe: TimeFromMinutesPipe, private router: Router, private route: ActivatedRoute, private gsesService: GseService, private processesService: ProcessesService, private toastService: ToastService, private titlePipe: TitleCasePipe, private airportsService: AirportsService, private permissionService: PermissionService) {
    this.gseId = Number((this.route.params as BehaviorSubject<any>).value.gseId) || null;
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_SLA_AND_GSES_MANAGEMENT).then((pageAccess) => {
      this.pageAccess = pageAccess;
      if (!this.pageAccess) {
        return;
      }
      forkJoin([this.gsesService.fetchGses(), this.acTypesService.fetchAcTypes(), this.processesService.fetchProcesses(), this.airportsService.fetchAirports()]).subscribe((results) => {
        this.gses = results[0];
        if (this.gseId) {
          this.currentGse = this.gses.find((gse) => gse.id === this.gseId);
          if (!this.currentGse) {
            this.router.navigate(['..'], {relativeTo: this.route});
            return;
          }
        }
        this.acTypes = results[1];
        this.processes = results[2];
        this.airports = results[3];
        this.filterAirports();
        this.buildTable();

        if (this.currentGse) {
          this.form.patchValue({
            processName: this.processes.find((process) => process.id === this.currentGse.processId).title,
            maxTime: this.currentGse.maximumProcessTimeInMinutes,
            gseRequired: this.currentGse.gseIdentifier?.length,
            isSla: this.currentGse.isSla,
            gseType: this.currentGse.gseIdentifier,
          });
        }
        if (pageAccess !== Access.RW) {
          this.form.disable();
        }
      });
    });

  }

  processTitle?: string;

  get title() {
    if (!this.currentGse) {
      return '';
    }
    if (this.processTitle) {
      return this.processTitle;
    }
    const title = this.processes.find((process) => process.id === this.currentGse.processId)?.title || '';
    this.processTitle = title;
    return title;
  }

  saveClicked() {
    if (this.currentGse) {
      this.isBusy = true;
      this.gsesService.saveGses({
        id: this.currentGse.id,
        isSla: this.form.value.isSla,
        processId: this.currentGse.processId,
        isApplicable: true,
        isActive: true,
        isNone: !this.form.value.hasGse,
        gseIdentifier: this.form.value.gseRequired ? this.form.value.gseType || 'None' : 'None',
        maximumProcessTimeInMinutes: this.form.value.maxTime,
        airportId: null,
        acTypeId: null,
        title: this.form.value.gseType?.length ? this.titlePipe.transform(this.form.value.gseType.replace('_', ' ')) : 'None',
      }).subscribe((res) => {
        this.isBusy = false;
        this.gsesService.fetchGses().subscribe((results) => {
          this.gses = results;
          this.buildTable();
        });
        this.toastService.showSuccess('Save successful!');
      });
      return;
    }
    this.isBusy = true;
    this.processesService.saveProcess({
      title: this.form.value.processName,
      isActive: true,
      internalName: this.form.value.processName,
      lastChangedAt: new Date(),
    }).pipe(mergeMap((result) => {
      if (!result.id) {
        this.toastService.showError('Error saving process');
        return of(null);
      }
      return this.gsesService.saveGses({
        isSla: this.form.value.isSla,
        processId: result.id,
        isActive: true,
        isNone: !this.form.value.hasGse,
        gseIdentifier: this.form.value.gseType || 'None',
        maximumProcessTimeInMinutes: this.form.value.maxTime,
        airportId: null,
        acTypeId: null,
        isApplicable: true,
        title: this.form.value.gseType?.length ? this.titlePipe.transform(this.form.value.gseType.replace('_', ' ')) : 'None',
      });
    })).subscribe((res) => {
      this.currentGse = res;
      this.isBusy = false;
      this.processesService.fetchProcesses().subscribe((results) => {
        this.processes = results;
      });
      this.gsesService.fetchGses().subscribe((results) => {
        this.gses = results;
      });
      this.buildTable();
      this.toastService.showSuccess('Save successful!');
    });
  }

  getIconClass(gseTypeName: string): string {
    return DefinedGses[gseTypeName]?.class || '';
  }

  filterAirports() {
    const term = this.searchText.toLowerCase();
    this.filteredAirports = this.airports.filter((airport) => airport.iata.toLowerCase().includes(term) || airport.icao.toLowerCase().includes(term) || airport.title.toLowerCase().includes(term));
    this.buildTable();
  }

  buildTable() {
    if (!this.currentGse || !this.acTypes || !this.filteredAirports.length) {
      this.tableData = [];
      return;
    }
    const tempArray: SlaGseAirportTableData[] = [];
    for (const filteredAirport of this.filteredAirports) {
      const relatedGses = this.gses.filter((gse) => gse.airportId === filteredAirport.id && gse.processId === this.currentGse.processId && gse.isActive);
      if (!relatedGses.length) {
        tempArray.push({
          airportId: filteredAirport.id,
          applicable: 'Yes',
          exceptions: 'No',
          gseType: this.currentGse.title,
          sla: this.currentGse.isSla ? 'SLA' : 'Non-SLA',
          iata: filteredAirport.iata,
          processTime: this.timeFromMinutesPipe.transform(this.currentGse.maximumProcessTimeInMinutes),
        });
        continue;
      }
      const applicableGses = relatedGses.filter((gse) => gse.isApplicable);
      const mixed = !relatedGses.every((val) => val.isApplicable === this.currentGse.isApplicable);
      const times = relatedGses.map((obj) => obj.maximumProcessTimeInMinutes);
      times.push(this.currentGse.maximumProcessTimeInMinutes);
      const shortestTime = Math.min(...times);
      const longestTime = Math.max(...times);
      const gses = [...relatedGses];
      if (relatedGses.length !== this.acTypes.length) {
        gses.push(this.currentGse);
      }
      const sameAgreement = gses.every((gse, i, arr) => gse.isSla === arr[0].isSla)
      tempArray.push({
        airportId: filteredAirport.id,
        applicable: applicableGses.length ? (mixed ? 'Partially' : 'Yes') : mixed && relatedGses.length !== this.acTypes.length ? 'Partially' : 'No',
        exceptions: 'Yes',
        sla: sameAgreement ? (relatedGses[0].isSla ? 'SLA' : 'Non-SLA') : 'SLA & Non-SLA',
        iata: filteredAirport.iata,
        processTime: shortestTime === longestTime ? this.timeFromMinutesPipe.transform(this.currentGse.maximumProcessTimeInMinutes) : this.timeFromMinutesPipe.transform(shortestTime) + ' - ' + this.timeFromMinutesPipe.transform(longestTime),
        gseType: relatedGses[0].title,
      })
    }
    this.tableData = tempArray;
  }

  onEdit(airportId: number) {
    const airport = this.airports.find((port) => port.id === airportId);
    if (!airport) {
      return;
    }
    const modalRef = this.modalService.open(SlaGseEditAirportDialogComponent, {size: 'xl'});
    const modal = modalRef.componentInstance as SlaGseEditAirportDialogComponent;
    if (this.pageAccess !== Access.RW) {
      modal.readonly = true;
    }
    modal.title = `Manage Turnaround and GSEs for ${airport.iata}`;
    modal.airport = airport;
    modal.gse = this.currentGse;

    modalRef.dismissed.pipe((take(1))).subscribe((reason) => {
      console.log('Reason:', reason);
      if (reason === true) {
        this.gsesService.fetchGses().subscribe((results) => {
          this.gses = results;
          this.buildTable();
        });
      }

    });
  }

  public readonly AirportsTestTagPipe = AirportsTestTagPipe;
  public readonly Access = Access;
}
