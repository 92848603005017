import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'utcVariationVisualizer'
})
export class UtcVariationVisualizerPipe implements PipeTransform {

  transform(value?: string, showOperator: boolean = false): string {
    if (!value) {
      return '-';
    }

    let returnString = showOperator ? '+' : '';

    if (value === '0' || value === '-0') {
      return '00:00';
    } else if (value?.startsWith('-')) {
      returnString = showOperator ? '-' : '';
      value = value?.replace('-', '');
    }

    if (value?.length === 3) {
      returnString += '0' + value?.slice(0, 1) + ':' + value?.slice(1);
    } else if (value?.length === 4) {
      returnString += value?.slice(0, 2) + ':' + value?.slice(2);
    }

    return returnString;
  }

}
