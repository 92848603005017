<div class="item">
  <div class="itemHeaderContainer rounded-circle" [ngClass]="getBgCol()" [ngStyle]="{ 'opacity': hideCircle ? 0 : 1 }">
    <app-indicator-dot *ngIf="indicator && !indicatorType"></app-indicator-dot>
    <app-indicator-text *ngIf="indicator && indicatorType === 'text'" [text]="indicatorText"></app-indicator-text>
    <i *ngIf="textHeader" class="demo-icon rounded-circle item-header" [ngClass]="getCol()">{{textHeader}}</i>
    <!-- <i *ngIf="iconHeader" class="demo-icon rounded-circle item-header {{iconHeader}}"></i> -->
    <i *ngIf="iconHeader" class="demo-icon circle-icon {{iconHeader}} rounded-circle item-icon-header" [ngClass]="rotate"></i>
  </div>
  <span class="item-text" [ngStyle]="{ 'text-align': this.textAlign }">{{text ? text : '-'}}</span>
  <span class="item-text" [ngStyle]="{ 'text-align': this.textAlign }">{{ secondaryText ? secondaryText : '' }}</span>
  <span class="item-text" [ngStyle]="{ 'text-align': this.textAlign }">{{ thirdText ? thirdText : '' }}</span>
</div>
