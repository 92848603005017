import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {IGenericContainerObject} from '../../models/genericContainerObject.model';
import {IUser} from '../../models/user.model';
import {ChatService} from '../../../services/chat.service';
import {AirportsService} from "../../../services/airports.service";
import {IAirport} from "../../models/airport.model";
import {Access, PermissionUIMasks} from "../../constants/enums";
import {PermissionService} from "../../../services/permission.service";

@Component({
  selector: 'app-chat-area',
  templateUrl: './chat-area.component.html',
  styleUrls: ['./chat-area.component.scss']
})
export class ChatAreaComponent implements OnInit, OnChanges {
  @Input() user: IUser;
  @Input() canClose = true;
  @Output() closeChatAction = new EventEmitter();
  chatGroupId: number;

  users: IGenericContainerObject<IUser> = {};
  airports?: IAirport[];
  access: Record<number, Access>;
  pageAccess: Access;

  constructor(private userService: UserService, public chatService: ChatService, private airportService: AirportsService, private permissionService: PermissionService) {
  }

  ngOnInit() {
    this.userService.fetchUsers().subscribe((result) => {
      for (const user of result) {
        this.users[user.id] = user;
      }
    });
    this.airportService.fetchAirports().subscribe((result) => {
      this.airports = result;
      this.ngOnChanges(null);
    });
    this.permissionService.getPermissionAccessForAllAirports(PermissionUIMasks.WEB_CHAT).then((access) => {
      this.access = access;
      this.ngOnChanges(null);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.airports || !this.user || !this.access) {
      return;
    }
    const airport = this.airports.find((airport) => airport.iata === this.user.location);
    this.pageAccess = this.access?.[-1] ?? this.access?.[airport.id] ?? null;
  }
}
