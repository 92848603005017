<div *ngIf="!ownMessage" class="message-item-container">
<!--  <div class="sender">-->
<!--    {{ user.firstname }} {{ user.lastname }}-->
<!--  </div>-->
  <div class="flex flex-row">
<!--    <app-user-photo class="align-self-center p-1" [size]="36" [user]="user" circleBackground="#F2F2F2" textColor="black"></app-user-photo>-->
    <div class="message-container rounded-lg deleted" *ngIf="!message.isActive">
      <div class="message-text text-muted">
        This message has been deleted
      </div>
    </div>
    <div class="message-container rounded-lg" *ngIf="message.isActive">
      <div class="message-text dark-blue" *ngIf="message.content !== '👍'">
        {{ message.content }}
        <span *ngIf="message.createdAt !== message.lastChangedAt" class="edited">(edited)</span>
      </div>
      <div class="like" *ngIf="message.content === '👍'">
        <fa-icon [icon]="thumbsUpIcon" class="avitium-color" size="3x"></fa-icon>
        <span *ngIf="message.createdAt !== message.lastChangedAt" class="edited">(edited)</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="ownMessage" class="own-message-item-container">
  <div class="flex flex-row">

    <div ngbDropdown class="d-flex justify-content-center float-end cursor-pointer align-self-center">
<!--      <fa-icon [icon]="dotsIcon" ngbDropdownToggle></fa-icon>-->
<!--      <i class="demo-icon icon-icons8-more options cursor-pointer" ngbDropdownToggle></i>-->
      <fa-icon [icon]="ellipsisIcon" [classes]="['align-self-center']" [styles]="{ 'color': '#a6a6a6', 'margin-right': '10px' }" ngbDropdownToggle></fa-icon>
      <div ngbDropdownMenu class="message-options">
        <button ngbDropdownItem (click)="editMessage()" data-test="edit-message-button">Edit message</button>
        <button ngbDropdownItem (click)="deleteMessage()" data-test="delete-message-button">Delete message</button>
      </div>
    </div>
    <div class="message-container rounded-lg deleted" *ngIf="!message.isActive">
      <div class="message-text text-muted">
        This message has been deleted
      </div>
    </div>
    <div class="message-container rounded-lg own-message-bg" *ngIf="message.isActive">
      <div class="message-text dark-blue" *ngIf="message.content !== '👍'">
        {{ message.content }}
        <span *ngIf="message.createdAt !== message.lastChangedAt" class="edited">(edited)</span>
      </div>
      <div class="like" *ngIf="message.content === '👍'">
        <fa-icon [icon]="thumbsUpIcon" class="avitium-color" size="3x"></fa-icon>
        <span *ngIf="message.createdAt !== message.lastChangedAt" class="edited">(edited)</span>
      </div>
    </div>
  </div>
</div>
