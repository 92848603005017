<div class="bg-white flex flex-row vcard-container mx-3 light-blue-border">
  <div id="profile" class="profile">
    <app-indicator-dot *ngIf="indicated" position="top-left"></app-indicator-dot>
    <img alt="user-photo" *ngIf="user?.photoUrl" src="{{user?.photoUrl}}" class="rounded-circle avatar">
    <div *ngIf="!user?.photoUrl" class="rounded-circle name-avatar"><div class="name-avatar-text">{{ user?.firstname.charAt(0) }}{{ user?.lastname.charAt(0) }}</div></div>
  </div>
  <div id="info" class="info">
    <span>{{user?.firstname}} {{user?.lastname}}</span>
    <span class="airport">{{user?.location}}</span>
    <span class="role text-muted">{{ user?.role | i18nSelect: userRoleObject.DISPLAY_TITLE_MAP }}</span>
  </div>
  <div id="actions" class="actions">
    <div class="d-grid gap-2 chat-now">
      <app-indicator-text *ngIf="numberOfUnreadMessages > 0" [text]="numberOfUnreadMessages?.toString()" size40="true" marginLeft="0" marginTop="0" width="20px" height="20px"></app-indicator-text>
      <button class="btn btn-outline-primary w-100" (click)="openChatAction.emit(user)" [attr.data-test]="'chat-now-button-id-' + user?.id">Chat Now</button>
    </div>
  </div>
</div>
