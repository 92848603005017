<!--<div class="settings-container flex flex-column">-->
<!--  <div class="user-list" *ngFor="let user of userContainer | keyvalue">-->
<!--    <app-settings-list-->
<!--      [opened]="selected === user?.key"-->
<!--      (editClicked)="doEdit(user?.key)"-->
<!--      (deleteClicked)="doDelete(user?.key)">-->

<!--      <app-settings-user-management-list-item-->
<!--        [opened]="selected === user?.key"-->
<!--        [user]="user?.value"-->
<!--        [airportTitle]="user?.value?.location && airportContainer && user.value.location !== 'HQ' ? airportContainer[user.value.location] ? airportContainer[user.value.location]['title'] : user?.value?.location : user?.value?.location"-->
<!--        (click)="selected = user?.key">-->
<!--      </app-settings-user-management-list-item>-->

<!--    </app-settings-list>-->
<!--  </div>-->
<!--  <app-add-row (addClicked)="doAdd()"></app-add-row>-->
<!--</div>-->

<div class="position-relative h-100" *ngIf="pageAccess === null">
  <app-access-denied></app-access-denied>
</div>
<ng-container *ngIf="pageAccess">
  <app-settings-header text="User Management" [showSaveButton]="false"></app-settings-header>
  <app-settings-card (onAddClicked)="doAdd()" sectionTitle="Users" addText="Add User"
                     [canAdd]="pageAccess === Access.RW">
    <div class="flex flex-column gap-1">
      <app-search-bar [(text)]="searchText" (textChange)="filterUsers()" width="250px"></app-search-bar>
      <app-settings-data-table [testTagPipe]="UsersTestTagPipe" class="mt-3"
                               [columns]="['Name', 'Surname', 'Role', 'Location', 'Tel.Nr.', 'Email']"
                               [data]="filteredUsers"
                               [variableNames]="['firstname', 'lastname', 'userGroup', 'location', 'phone', 'email']"
                               (onEditClicked)="doEdit($event)" (onDeleteClicked)="doDelete($event)"
                               [canDelete]="pageAccess === Access.RW" [viewOnlyMode]="pageAccess === Access.R">
        <ng-template propName="userGroup" let-value>
          {{ userGroupsKV[value]?.title ?? '-' }}
        </ng-template>
      </app-settings-data-table>
    </div>
  </app-settings-card>
</ng-container>
