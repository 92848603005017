<div class="main-settings-container">
  <div class="left-menu">

    <ul ngbNav [activeId]="activeNavItem" class="nav-list light-blue-border user-select-none">
      <ng-container *ngFor="let link of links">
        <li [ngClass]="{ 'disabled': link.disabled }">
          <a ngbNavLink [ngbNavItem]="link.link" [routerLink]="link.link" routerLinkActive="active"
             [ngClass]="{ 'disabled pointer-events-none': link.disabled }"
             [attr.data-test]="'settings-link-' + link.link">
            <div class="image-container">
              <img *ngIf="link.imgSrc" [src]="link.imgSrc" [width]="link.width || 28" [height]="link.height || 28">
              <ng-container *ngIf="link.svgCode">
                {{ link.svgCode }}
              </ng-container>
            </div>
            <div>{{ link.title }}</div>
          </a>
        </li>
      </ng-container>

    </ul>
  </div>
  <div class="flex-grow-1 overflow-hidden">
    <router-outlet (activate)="onActivate()"></router-outlet>
  </div>
</div>


