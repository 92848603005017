import {Component, Input, OnInit} from '@angular/core';
import * as dayjs from 'dayjs';
import { Subject, interval, takeUntil } from 'rxjs';


@Component({
  selector: 'app-ta-process',
  templateUrl: './ta-process.component.html',
  styleUrls: ['./ta-process.component.scss']
})
export class TaProcessComponent implements OnInit {

  @Input() done: boolean;
  @Input() name: string;
  @Input() startTime: Date;
  @Input() endTime: Date;
  @Input() maxProcessMinutes: number;
  unsubscribe$ = new Subject();

  @Input() isSla: boolean;

  processTimePercentage: number;
  completedProcessTime: any;

  constructor() { }

  ngOnInit() {
    this.calculateProcessTimePercentage();
    // interval(30000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
    //   this.calculateProcessTimePercentage();
    // });
  }

  calculateProcessTimePercentage() {
    let currentSeconds: number;
    const maxProcessSeconds = this.maxProcessMinutes * 60;
    if (this.startTime !== null ) {
      if (this.endTime !== null) {
        currentSeconds = dayjs(this.endTime).diff(this.startTime, "seconds");
      } else {
        currentSeconds = dayjs().utc().diff(this.startTime, "seconds");
      }

      this.processTimePercentage = +((currentSeconds/maxProcessSeconds) * 100).toFixed(2);
      this.completedProcessTime = currentSeconds;


    } else {
      this.processTimePercentage = 0;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

}
