import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { States } from '../../../constants/state.constants';
import { ISimpleData } from '../../../models/simpleData.model';
import { FlightPairService } from '../../../../services/flight-pair.service';
import dayjs from 'dayjs';
import { IPairOverview } from '../../../models/pair-overview.model';
import { IPairDetail } from '../../../models/pair-detail.model';
import { IAlertsModel } from '../../../models/alerts.model';

@Component({
  selector: 'app-leg-list-sql',
  templateUrl: './leg-list-sql.component.html',
  styleUrls: ['./leg-list-sql.component.scss']
})
export class LegListSqlComponent implements OnInit {

  lastTopQueryKey: string;
  lastBottomQueryKey: string;
  isLoadingTop: boolean;
  isLoadingBottom: boolean;
  lastListLoadAction = '';
  list: IPairOverview[] = [];

  @Input() hasNextPage?: boolean
  @Input() page?: number;
  @Input() refreshSql: EventEmitter<any>;

  _pairOverviewList: IPairOverview[];
  get pairOverviewList() {
    return this._pairOverviewList;
  }
  @Input('pairOverviewList')
  set pairOverviewList(val: IPairOverview[]) {
    this.alerts = [];

    if (!val || val.length === 0 || !this.flightPairService) {
      this._pairOverviewList = [];
      return;
    }
    this.hasNextPage = val.length > 20;
    if(val.length > 20) {
      val = val.slice(0, 20);
    }

    this._pairOverviewList = val.map((item: IPairOverview) => {
      // item.arrivalLeg = this._legList?.find(x => x.id === item.arrivalLegId);
      // item.departureLeg = this._legList?.find(x => x.id === item.departureLegId);
      if (item.ctot) {
        item.ctot = dayjs(item.ctot).toDate();
      }
      return item;
    });

    this.isLoadingTop = false;
    this.isLoadingBottom = false;
  }

  @Input() timezone: string;
  @Input() userRole: string;
  @Input() passengerClasses: ISimpleData[];
  @Input() activePairIndex: string;
  @Output() resetPairIndex = new EventEmitter();

  @Output() setPairIndex = new EventEmitter();
  @Output() getFlightsBefore = new EventEmitter();
  @Output() getFlightsAfter = new EventEmitter();
  @Output() setActiveFlight = new EventEmitter<IPairDetail>();

  alerts: IAlertsModel[] = [];

  states = States;
  selectedPair: IPairDetail;

  setSelectedPair(pair: IPairDetail) {
    this.selectedPair = pair;
    this.setActiveFlight.emit(pair);
  }

  constructor(
    private flightPairService: FlightPairService) {
  }

  ngOnInit() {
  }

  doResetPairIndex() {
    this.resetPairIndex.emit();
  }

  doSetPairIndex(pairKey: string) {
    if (!pairKey || this.activePairIndex === pairKey) {
      this.activePairIndex = '';
    } else {
      this.activePairIndex = pairKey;
    }
    this.setPairIndex.emit(this.activePairIndex);
  }

  onScrollDown(): void {
    console.log('scrolled down');
    console.log('leglist is ' + (this.pairOverviewList) ? 'not null' : 'null');
    this.lastListLoadAction = 'next';
    if (this.pairOverviewList && this.pairOverviewList.length > 0) {
      this.isLoadingBottom = true;
      this.getFlightsAfter.emit(this.pairOverviewList[this.pairOverviewList.length - 1]);
      console.log('is loading bottom');
    } else {
      // this.getFlightsAfter.emit(null);
      console.log('is loading next but no search key is given because list is empty');
    }
  }

  onScrollUp(): void {
    console.log('scrolled up');
    console.log('leglist is ' + (this.pairOverviewList) ? 'not null' : 'null');
    this.lastListLoadAction = 'before';
    if (this.pairOverviewList && this.pairOverviewList.length > 0) {
      this.isLoadingTop = true;
      this.getFlightsBefore.emit(this.pairOverviewList[0]);
      console.log('is loading top');
    } else {
      // this.getFlightsBefore.emit(null);
      console.log('is loading before but no search key is given because list is empty');
    }
  }

  paginateBackward() {
    this.onScrollUp();
  }

  paginateForward() {
    this.onScrollDown();
  }
}
