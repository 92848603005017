import { Pipe, PipeTransform } from '@angular/core';
import { IPairDetail } from '../models/pair-detail.model';
import { IPairOverview } from '../models/pair-overview.model';
import { IPairsModel } from '../models/pairs.model';
import { TestTagsUtils } from '../utils/test-tags.utils';

@Pipe({
  name: 'pairTestAttribute'
})
export class PairTestAttributePipe implements PipeTransform {

  transform(value: IPairDetail | IPairOverview | IPairsModel, ...args: unknown[]): string {
    return TestTagsUtils.getPairTestTag(value);
  }

}
