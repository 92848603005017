<div class="position-relative h-100" *ngIf="pageAccess === null">
  <app-access-denied></app-access-denied>
</div>
<ng-container *ngIf="form">
  <app-settings-header text="General Settings" [showSaveButton]="true" [textSize]="20" (onSaveClicked)="save()"
                       [saveButtonDisabled]="form.invalid || pageAccess !== Access.RW"
                       [spinner]="isBusy"></app-settings-header>
  <form [formGroup]="form" class="settings-container mt-3 flex flex-column avitium-scrollbar">
    <div class="flex flex-column gap-2 light-blue-border p-3">
      <div class="dark-blue sub-title">General</div>
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="main-base" class="form-label">Airline Main Base</label>
          <input type="email" class="form-control" id="main-base" placeholder="" formControlName="airlineMainBase"
                 data-test="edit-general-settings-airline-base-input">
        </div>
        <div class="flex-basis-30">
          <label for="date-format" class="form-label">Date Format</label>
          <select class="form-select light-blue-border dark-blue" id="date-format" formControlName="dateFormat"
                  data-test="edit-general-settings-date-format-select">
            <option>DD/MM/YYYY</option>
            <option>YYYY/MM/DD</option>
          </select>
        </div>
        <div class="flex-basis-30">
          <label for="turnaround-time-type" class="form-label">Turnaround Time Type</label>
          <select class="form-select light-blue-border dark-blue" id="turnaround-time-type"
                  formControlName="turnaroundTimeType" data-test="edit-general-settings-turnaround-type-select">
            <option *ngFor="let type of turnaroundTypes.LIST" value="{{type}}">
              {{ type | titlecase }} Turnaround Time
            </option>
          </select>
        </div>
      </div>
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="turnaround-threshold" class="form-label">Turnaround Threshold</label>
          <ngb-timepicker appTimepickerFocus formControlName="turnaroundThreshold" id="turnaround-threshold"
                          [spinners]="false"
                          data-test="edit-general-settings-turnaround-threshold-time-picker"></ngb-timepicker>
        </div>
        <div class="flex-basis-30">
          <label for="minimum-passenger-connection-time" class="form-label">Minimum Passenger Connection Time</label>
          <ngb-timepicker appTimepickerFocus id="minimum-passenger-connection-time"
                          formControlName="minimumPassengerConnectionTimeInMinutes" [spinners]="false"
                          data-test="edit-general-settings-minimum-passenger-connection-time-picker"></ngb-timepicker>
        </div>
        <div class="flex-basis-30"></div>
      </div>
    </div>
    <app-settings-card (onAddClicked)="openManageAirlineCodes()" sectionTitle="Airline Codes" addText="Add Airline Code"
                       [canAdd]="pageAccess === Access.RW"
                       informationTooltip="At least one Airline Designator should be entered as scheduled"
                       data-test="airline-designators-card">
      <app-settings-data-table [testTagPipe]="AirlineDesignatorTestTagPipe" class="mt-3"
                               [columns]="['Code', 'Description', 'Schedule', 'Operate']" [data]="airlineDesignators"
                               [variableNames]="['code', 'description', 'allowForSsim', 'allowAcOperation']"
                               (onEditClicked)="openManageAirlineCodes($event)"
                               (onDeleteClicked)="deleteAirlineCode($event)"
                               [canEdit]="pageAccess === Access.RW"
                               [canDelete]="pageAccess === Access.RW">
        <ng-template propName="allowForSsim" let-value>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ff0000" class="bi bi-x-circle-fill"
               viewBox="0 0 16 16" *ngIf="!value">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00ff00" class="bi bi-check-circle-fill"
               viewBox="0 0 16 16" *ngIf="value">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
          </svg>
        </ng-template>

        <ng-template propName="allowAcOperation" let-value>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ff0000" class="bi bi-x-circle-fill"
               viewBox="0 0 16 16" *ngIf="!value">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00ff00" class="bi bi-check-circle-fill"
               viewBox="0 0 16 16" *ngIf="value">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
          </svg>
        </ng-template>
      </app-settings-data-table>
    </app-settings-card>
    <app-settings-card (onAddClicked)="openManagePassengerClasses()" sectionTitle="Passenger Classes"
                       addText="Add Passenger Class" [canAdd]="pageAccess === Access.RW">
      <app-settings-data-table [testTagPipe]="PassengerClassesTestTagPipe" class="mt-3"
                               [columns]="['Code', 'Description']" [data]="passengerClasses"
                               [variableNames]="['code', 'description']"
                               (onEditClicked)="openManagePassengerClasses($event)"
                               (onDeleteClicked)="deletePassengerClass($event)"
                               [canEdit]="pageAccess === Access.RW"
                               [canDelete]="pageAccess === Access.RW"></app-settings-data-table>
    </app-settings-card>
    <app-settings-card (onAddClicked)="openManagePassengerCategories()" sectionTitle="Passenger Category"
                       addText="Add Passenger Category" [canAdd]="pageAccess === Access.RW">
      <app-settings-data-table [testTagPipe]="PassengerClassesTestTagPipe" class="mt-3"
                               [columns]="['Code', 'Description']" [data]="passengerCategories"
                               [variableNames]="['code', 'description']"
                               (onEditClicked)="openManagePassengerCategories($event)"
                               (onDeleteClicked)="deletePassengerCategory($event)"
                               [canEdit]="pageAccess === Access.RW"
                               [canDelete]="pageAccess === Access.RW"></app-settings-data-table>
    </app-settings-card>
    <app-settings-card (onAddClicked)="openManageFlightServiceTypes()" sectionTitle="Flight Service Types"
                       addText="Add Flight Service Type" [canAdd]="pageAccess === Access.RW">
      <app-settings-data-table [testTagPipe]="FlightServiceTypesTestTagPipe" class="mt-3"
                               [columns]="['Code', 'Description']" [data]="flightServiceTypes"
                               [variableNames]="['code', 'description']"
                               (onEditClicked)="openManageFlightServiceTypes($event)"
                               (onDeleteClicked)="deleteFlightServiceType($event)"
                               [canEdit]="pageAccess === Access.RW"
                               [canDelete]="pageAccess === Access.RW"></app-settings-data-table>
    </app-settings-card>
    <app-settings-card (onAddClicked)="openManageDelayCodes()" sectionTitle="Delay Codes" addText="Add Delay Code"
                       [canAdd]="pageAccess === Access.RW">
      <div class="flex flex-column">
        <div>
          <label class="dark-blue" for="delay-page-size">Results per page</label>
          <select id="delay-page-size" class="form-select light-blue-border page-size-select"
                  [(ngModel)]="delayCodePageSize" [ngModelOptions]="{standalone: true}">
            <option [ngValue]="25">25</option>
            <option [ngValue]="50">50</option>
            <option [ngValue]="150">150</option>
            <option [ngValue]="500">500</option>
            <option [ngValue]="1000">1000</option>
          </select>
        </div>
        <app-settings-data-table [testTagPipe]="DelayCodesTestTagPipe" [newItemsPerPage]="delayCodePageSize"
                                 class="mt-3" [columns]="['Code', 'Description']" [data]="delayCodes"
                                 [variableNames]="['code', 'description']"
                                 (onEditClicked)="openManageDelayCodes($event)"
                                 (onDeleteClicked)="deleteDelayCode($event)"
                                 [canEdit]="pageAccess === Access.RW"
                                 [canDelete]="pageAccess === Access.RW"></app-settings-data-table>
      </div>

    </app-settings-card>
    <app-settings-card (onAddClicked)="openMeasurementUnits()" sectionTitle="Measurement Units"
                       addText="Add Measurement Unit" [canAdd]="pageAccess === Access.RW">
      <app-settings-data-table [testTagPipe]="MeasurementUnitsTestTagPipe" class="mt-3"
                               [columns]="['Code', 'Description']" [data]="measurementUnits"
                               [variableNames]="['code', 'description']" (onEditClicked)="openMeasurementUnits($event)"
                               (onDeleteClicked)="deleteMeasureUnit($event)"
                               [canEdit]="pageAccess === Access.RW"
                               [canDelete]="pageAccess === Access.RW"></app-settings-data-table>
    </app-settings-card>
  </form>

</ng-container>
