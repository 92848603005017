import {Component, Input, OnInit} from '@angular/core';
import {States} from '../../constants/state.constants';
import {StatesResolver} from '../../utils/states.resolver';
import { TextAlign } from 'chart.js';

@Component({
  selector: 'app-icon-text-item-small',
  templateUrl: './icon-text-item-small.component.html',
  styleUrls: ['./icon-text-item-small.component.scss']
})
export class IconTextItemSmallComponent implements OnInit {

  @Input() iconHeader: string;
  @Input() textHeader: string;
  @Input() text: string;
  @Input() secondaryText: string;
  @Input() thirdText: string;
  @Input() rotate: string;
  @Input() state: States;
  @Input() hideCircle: boolean;
  @Input() textAlign: TextAlign;

  @Input() indicator: boolean;
  @Input() indicatorType: string;
  @Input() indicatorText: string;

  constructor() {
  }

  ngOnInit() {
  }

  getCol() {
    return StatesResolver.getFontColorClassByState(this.state);
  }

  getBgCol() {
    return StatesResolver.getBgColorClassByState(this.state);
  }


}
