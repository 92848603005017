<div class="p-2 w-100 flex flex-column gap-4">
  <app-settings-header text="Network Changes" [showSaveButton]="false" [textSize]="20"></app-settings-header>
  <div class="light-blue-border p-2 h-full flex flex-column">
    <app-settings-header text="Time Changes" [showSaveButton]="false" [textSize]="20"></app-settings-header>
    <app-settings-data-table class="mt-3"
                             [columns]="['Date', 'Flight Nr.', 'From/To', 'OLD STD', 'OLD STA', 'NEW STD', 'NEW STA']"
                             [data]="rescheduleChanges"
                             [variableNames]="['date', 'flightNr', 'fromTo', 'oldStd', 'oldSta', 'newStd', 'newSta' ]"
                             [showDelete]="false" [showEdit]="false" [showView]="false">
    </app-settings-data-table>
    <div class="buttons flex justify-content-end gap-3">
      <button class="btn avitium-bg-color text-white" [disabled]="!rescheduleChanges?.length"
              (click)="undoChanges(GanttChangesType.RESCHEDULE)">Undo
      </button>
      <button class="btn avitium-bg-color text-white" [disabled]="!rescheduleChanges?.length"
              (click)="save(GanttChangesType.RESCHEDULE)">Save
      </button>
    </div>
  </div>

  <div class="light-blue-border p-2 h-full flex flex-column">
    <app-settings-header text="Tail Changes" [showSaveButton]="false" [textSize]="20"></app-settings-header>
    <app-settings-data-table class="mt-3"
                             [columns]="['Date', 'Flight Nr.', 'From/To', 'OLD TAIL', 'NEW TAIL']"
                             [data]="registrationChanges"
                             [variableNames]="['date', 'flightNr', 'fromTo', 'oldTail', 'newTail']"
                             [showDelete]="false" [showEdit]="false" [showView]="false">
    </app-settings-data-table>
    <div class="buttons flex justify-content-end gap-3">
      <button class="btn avitium-bg-color text-white" [disabled]="!registrationChanges?.length"
              (click)="undoChanges(GanttChangesType.CHANGE_REGISTRATION)">Undo
      </button>
      <button class="btn avitium-bg-color text-white" [disabled]="!registrationChanges?.length"
              (click)="save(GanttChangesType.CHANGE_REGISTRATION)">Save
      </button>
    </div>
  </div>

  <div class="light-blue-border p-2 h-full flex flex-column">
    <app-settings-header text="Cancellations" [showSaveButton]="false" [textSize]="20"></app-settings-header>
    <app-settings-data-table class="mt-3"
                             [columns]="['Date', 'Flight Nr.', 'From/To']"
                             [data]="cancellationChanges"
                             [variableNames]="['date', 'flightNr', 'fromTo']"
                             [showDelete]="false" [showEdit]="false" [showView]="false">
    </app-settings-data-table>
    <div class="buttons flex justify-content-end gap-3">
      <button class="btn avitium-bg-color text-white" [disabled]="!this.cancellationChanges?.length"
              (click)="undoChanges(GanttChangesType.CANCELLATION)">Undo
      </button>
      <button class="btn avitium-bg-color text-white" [disabled]="!cancellationChanges?.length"
              (click)="save(GanttChangesType.CANCELLATION)">Save
      </button>
    </div>
  </div>
</div>
