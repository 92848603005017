import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  TemplateRef
} from '@angular/core';
import {AccordionTemplateDirective} from "../../directives/accordion-template.directive";

@Component({
  selector: 'app-custom-accordion',
  templateUrl: './custom-accordion.component.html',
  styleUrls: ['./custom-accordion.component.scss']
})
export class CustomAccordionComponent implements OnInit, AfterContentInit, AfterViewInit {
  @Input() showSwitch: boolean = false;
  @Input() showArrow: boolean = true;
  @Input() switchId: string;
  @Input() title?: string;
  @Input() isOpen: boolean = false;
  @Input() switchStatus: boolean = false;
  @ContentChildren(AccordionTemplateDirective) templates: QueryList<AccordionTemplateDirective>;

  templatesMap: Map<string, TemplateRef<any>> = new Map<string, TemplateRef<any>>();
  contentHeight: number = 0;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    if (!this.templates) {
      return;
    }
    this.templates.forEach(template => {
      this.templatesMap.set(template.accordionTemplate, template.template);
    });
  }

  getTemplate(type: string): TemplateRef<any> {
    return this.templatesMap.get(type);
  }

  toggleAccordion(evt: MouseEvent) {
    const target = evt.target as HTMLElement;
    if (target.nodeName.toLowerCase() === 'input' || target.nodeName.toLowerCase() === 'label') {
      return;
    }
    this.isOpen = !this.isOpen;
  }

  ngAfterViewInit() {
    // Access the rendered element's height
    const contentContainer = this.elementRef.nativeElement.querySelectorAll('.child-content');
    for (const elem of contentContainer) {
      this.contentHeight += elem.scrollHeight;
    }

  }
}
