import { Pipe, PipeTransform } from '@angular/core';
import { isPairDetail } from '../models/pair-detail.model';
import { isPairOverview } from '../utils/utils';

@Pipe({
  name: 'flightNumber'
})
export class FlightNumberPipe implements PipeTransform {

  transform(pair: unknown, ...args: unknown[]): string {
    if(!pair) {
      return 'undefined';
    }
    if(isPairDetail(pair)) {
      if(pair.arrivalLeg?.std && pair.departureLeg?.std) {
        if (pair.departureLeg?.atd || pair.arrivalLeg?.ata) {
          return pair.departureLeg?.airlineDesignator + pair.departureLeg?.flightNumber;
        }
        return pair.arrivalLeg?.airlineDesignator + pair.arrivalLeg?.flightNumber;
      }
      if (pair.arrivalLeg?.std) {
        return pair.arrivalLeg?.airlineDesignator + pair.arrivalLeg?.flightNumber;
      }
      return pair.departureLeg?.airlineDesignator + pair.departureLeg?.flightNumber;
    }
    if (isPairOverview(pair)) {
      if (pair.flightNumberArriving && pair.flightNumberDeparting) {
        if (pair.departureLegAtd || pair.arrivalLegAta) {
          return pair.airlineDesignator + pair.flightNumberDeparting;
        }
        return pair.airlineDesignator + pair.flightNumberArriving;
      }
      if (pair.flightNumberArriving) {
        return pair.airlineDesignator + pair.flightNumberArriving;
      }
      return pair.airlineDesignator + pair.flightNumberDeparting;
    }
    return 'undefined';
  }
}
