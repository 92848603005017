import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ILegsModel} from "../../../shared/models/legs.model";
import {IUserLegAssignmentModel} from "../../../shared/models/user-leg-assignment.model";

@Component({
  selector: 'app-flight-list-item',
  templateUrl: './flight-list-item.component.html',
  styleUrls: ['./flight-list-item.component.scss']
})
export class FlightListItemComponent implements OnInit {

  @Input() leg: ILegsModel;
  @Input() assignedLegData: IUserLegAssignmentModel;
  @Input() isSelected: boolean;
  @Input() deleteInProgress: boolean;
  @Output() unassignedClicked = new EventEmitter<number>();
  @Output() itemClicked = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

}
