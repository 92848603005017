import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import {IUser} from '../../models/user.model';
import {NavigationEnd, Router} from '@angular/router';
import {PermissionService} from "../../../services/permission.service";
import {Access, PermissionUIMasks} from "../../constants/enums";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-small-chat-contacts-container',
  templateUrl: './small-chat-contacts-container.component.html',
  styleUrls: ['./small-chat-contacts-container.component.scss']
})
export class SmallChatContactsContainerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() flightStations: string[];
  @Input() chatUser: IUser;
  @Input() defaultText = 'Select a pair to see available contacts';
  @Input() showContacts = false;

  searchText: string = '';

  stationsChangedEvent = new EventEmitter<void>();
  chatOpened: boolean;
  selectedStations: string[];
  chatAccess: Access;

  constructor(private router: Router, private permissionService: PermissionService) {
  }

  unsubscribe$ = new Subject<void>();

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.flightStations && changes.flightStations.currentValue) {
      this.selectedStations = [...this.flightStations];
      this.stationsChangedEvent.emit();
    }
  }

  ngOnInit(): void {
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_CHAT).then((chatAccess) => {
      this.chatAccess = chatAccess;
      if (!chatAccess) {
        return;
      }
      this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.selectedStations = [];
        }
      });
    });
  }

  changeChatState(state: boolean, data: IUser) {
    this.chatUser = data;
    this.chatOpened = state;
  }

  stationSelected(station: string) {
    const index = this.selectedStations.findIndex((item) => item === station);
    if (index !== -1) {
      this.selectedStations.splice(index, 1);
      this.stationsChangedEvent.emit();
      return;
    }
    this.selectedStations.push(station);
    this.stationsChangedEvent.emit();
  }
}
