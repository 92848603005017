import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {States} from '../../constants/state.constants';
import {StatesResolver} from '../../utils/states.resolver';

@Component({
  selector: 'app-icon-text-item',
  templateUrl: './icon-text-item.component.html',
  styleUrls: ['./icon-text-item.component.scss']
})
export class IconTextItemComponent implements OnInit, OnChanges {

  @Input() iconHeader: string;
  @Input() textHeader: string;
  @Input() text: string;
  @Input() rotate: string;
  @Input() state: States;
  @Input() contentType: string;

  @Input() indicator: boolean;
  @Input() indicatorType: string;
  @Input() indicatorText: string;

  @Input() textClass: string;
  @Input() slcMessage: boolean;
  @Input() taInProgress?: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.text) {
      if (!changes.text.currentValue.includes('<br>')) {
        this.text += "<br>&nbsp;";
      }
    }
  }

  constructor() {
  }

  ngOnInit() {
  }

  getCol() {
    return StatesResolver.getFontColorClassByState(this.state);
  }

  getBgCol() {
    return StatesResolver.getBgColorClassByState(this.state);
  }


}
