import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-memo-delete-form',
  templateUrl: './memo-delete-form.component.html',
  styleUrls: ['./memo-delete-form.component.scss']
})
export class MemoDeleteFormComponent implements OnInit, OnDestroy {

  @Input() data?: {
    arrivalLegId: number,
    departureLegId: number
  };
  @Input() outerDeleteClicked: Observable<void>;
  @Output() statusChanged = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();

  arrivalLegId: number;
  departureLegId: number;
  form: UntypedFormGroup;
  destroySubject = new Subject();

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    if (this.data) {
      this.arrivalLegId = this.data.arrivalLegId;
      this.departureLegId = this.data.departureLegId;
    }

    this.generateForm(this.fb);
    this.handleStatusChanged();
    this.patchForm();
    this.outerDeleteClicked.pipe(take(1)).subscribe(() => this.deleteClicked.emit(this.form ? this.form.getRawValue().flightType : null));
  }

  ngOnDestroy(): void {
    this.destroySubject.next(null);
    this.destroySubject.complete();
  }

  generateForm(fb: UntypedFormBuilder) {
    this.form = fb.group({
      flightType: [null, Validators.required]
    });
  }

  patchForm() {
    if (!this.arrivalLegId) {
      this.form.patchValue({
        flightType: 'departure'
      });
      this.form.markAsDirty();
      this.form.get('flightType').disable();
    }

    if (!this.departureLegId) {
      this.form.patchValue({
        flightType: 'arrival'
      });
      this.form.markAsDirty();
      this.form.get('flightType').disable();
    }
  }

  handleStatusChanged() {
    this.statusChanged.emit(this.form.status);
    this.form.statusChanges.pipe(takeUntil(this.destroySubject)).subscribe(item => this.statusChanged.emit(item));
  }
}
