import { Pipe, PipeTransform } from '@angular/core';
import { ISimpleData } from '../models/simpleData.model';

@Pipe({
  name: 'measurementUnitsTestTag'
})
export class MeasurementUnitsTestTagPipe implements PipeTransform {

  transform(value: ISimpleData, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `measurement-unit-${value?.code}`;
  }

}
