import {Component, OnInit} from '@angular/core';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {AlertSeverity} from '../../shared/constants/alert-severity.enum';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {interval, Subject, takeUntil} from 'rxjs';
import {IUser} from '../../shared/models/user.model';
import {IAlertsModel} from '../../shared/models/alerts.model';
import {stationsFromAlert} from '../../shared/utils/utils';
import {PermissionService} from "../../services/permission.service";
import {PermissionUIMasks} from "../../shared/constants/enums";
import {StaticUserGroupConstants} from "../../shared/constants/static-user-group.constants";
import {AlertService} from "../../services/alert.service";
import dayjs from "dayjs";
import {SortDirection} from "../../shared/constants/table-sort.types";

@Component({
  selector: 'app-alerts-new',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsNewComponent implements OnInit {
  localAlertSeverity = AlertSeverity;
  severityFilters: AlertSeverity[] = [AlertSeverity.CRITICAL, AlertSeverity.INTERMEDIATE, AlertSeverity.LOW];
  exclamationMarkIcon = faExclamationCircle;
  currentUser: IUser;
  unsubscribe$ = new Subject();
  activeAlert: IAlertsModel;
  stations: string[] = [];
  hasPermission: boolean;
  alerts: IAlertsModel[];
  filteredAlerts: IAlertsModel[] = [];
  alertsToRemind: { [alertId: number]: number } = {};
  sortOrder: SortDirection = 'desc';


  activeAlertChanged(alert: IAlertsModel) {
    this.stations = stationsFromAlert(alert);
    this.activeAlert = alert;
  }

  formGroup = new UntypedFormGroup({
    searchText: new UntypedFormControl(''),
  });

  constructor(private authService: AuthService, private permissionService: PermissionService, public alertService: AlertService) {
    if (this.authService.userSubject.value?.userGroup === StaticUserGroupConstants.STR_TO_ID.ADMIN) {
      this.hasPermission = true;
      this.refetchAlerts();
      return;
    }
    permissionService.getPermissionAccess(PermissionUIMasks.WEB_CHAT).then(() => {
      const result = permissionService.permissions.find((perm) => {
        return perm.uiMask.includes('WEB_ALERTS_') && perm.access
      });
      this.hasPermission = !!result;
      if (this.hasPermission) {
        this.refetchAlerts();
      }
    });
    interval(10000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.refetchAlerts();
    });
  }

  ngOnInit(): void {
    this.subscribeToCurrentUser();
    const alertsToRemindStr = localStorage.getItem('alerts_to_remind');
    if (alertsToRemindStr) {
      this.alertsToRemind = JSON.parse(localStorage.getItem('alerts_to_remind'));
    }
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateFilteredList();
    })
  }


  subscribeToCurrentUser() {
    this.authService.userSubject.asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => this.currentUser = user);
  }

  severitySwitched(severity: AlertSeverity) {
    const index = this.severityFilters.findIndex((item) => item === severity);
    if (index !== -1) {
      this.severityFilters.splice(index, 1);
      this.updateFilteredList();
      return;
    }
    this.severityFilters.push(severity);
    this.updateFilteredList();
  }

  refetchAlerts() {
    this.alertService.getAlerts().subscribe((results) => {
      this.alerts = results.filter((alert) => {
        if (alert.__leg__) {
          if (!this.permissionService.getPermissionAccess(PermissionUIMasks['WEB_ALERTS_' + this.alertService.alertTypes[alert.alertTypeId]?.title], alert.__leg__.arrivalStationId)) {
            return false;
          }
          if (!this.permissionService.getPermissionAccess(PermissionUIMasks['WEB_ALERTS_' + this.alertService.alertTypes[alert.alertTypeId]?.title], alert.__leg__.departureStationId)) {
            return false;
          }
        } else if (alert.__pair__) {
          if (!this.permissionService.getPermissionAccess(PermissionUIMasks['WEB_ALERTS_' + this.alertService.alertTypes[alert.alertTypeId]?.title], alert.__pair__.__arrivalLegModel__?.arrivalStationId ?? alert.__pair__.__departureLegModel__?.departureStationId)) {
            return false;
          }
        }

        return alert.alertTypeId !== 9 || !!alert.__pair__.__departureLegModel__
      });
      this.updateFilteredList();
    });
  }

  updateFilteredList(): void {
    const search: string = this.formGroup.value.searchText;
    this.filteredAlerts.splice(0, this.filteredAlerts.length);
    this.filteredAlerts.push(...this.alerts.filter((x) => (this.alertsToRemind ? (this.alertsToRemind[x.id] == null || this.alertsToRemind[x.id] < dayjs().unix()) : true) && (this.alertService.alertTypes[x.alertTypeId]?.description.toLowerCase().includes(search.toLowerCase()) || x.__leg__?.flightNumber?.toLowerCase().includes(search.toLowerCase()) || x.__pair__?.__arrivalLegModel__?.flightNumber?.toLowerCase()?.includes(search.toLowerCase()) || x.__pair__?.__departureLegModel__?.flightNumber?.toLowerCase()?.includes(search.toLowerCase())) && (this.severityFilters ? this.severityFilters.includes(this.alertService.alertTypes[x.alertTypeId].severity) : true)));
    const sortOrderFactor = this.sortOrder === 'asc' ? 1 : -1;
    this.filteredAlerts.sort((a, b) => (a.occuredAt > b.occuredAt ? 1 : -1) * sortOrderFactor);
  }

  remindLater(data: { id: number; hours: number; minutes: number }) {
    this.alertsToRemind[data.id] = dayjs().add(data.hours, 'hours').add(data.minutes, 'minutes').unix();
    localStorage.setItem('alerts_to_remind', JSON.stringify(this.alertsToRemind));
    this.updateFilteredList();
  }

  sortChanged() {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    this.updateFilteredList();
  }
}
