import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-restore-cancel-row',
  templateUrl: './restore-cancel-row.component.html',
  styleUrls: ['./restore-cancel-row.component.scss']
})
export class RestoreCancelRowComponent implements OnInit {

  @Input() isSaveDisabled: boolean;
  @Input() isRestoreDisabled: boolean;
  @Input() showSpinner: boolean;
  @Output() restoreClicked = new EventEmitter();
  @Output() saveClicked = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

}
