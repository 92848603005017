export class StaticUserGroupConstants {
  static IDS = {
    1: 'ADMIN',
    2: 'DUTY_MANAGER',
    3: 'OPS_CONTROLLER',
    4: 'RAMP_AGENT',
    5: 'OBSERVER',
  };

  static STR_TO_ID = {
    ADMIN: 1,
    DUTY_MANAGER: 2,
    OPS_CONTROLLER: 3,
    RAMP_AGENT: 4,
    OBSERVER: 5,
  };
}
