import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AcRegistrationsService} from "../../services/ac-registrations.service";
import {IAcRegistration} from "../../shared/models/ac-registration.model";
import {LegsService} from "../../services/legs.service";
import {debounceTime, forkJoin, Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {IUser} from "../../shared/models/user.model";
import {takeUntil} from "rxjs/operators";
import {AirportsService} from "../../services/airports.service";
import {StaticUserGroupConstants} from "../../shared/constants/static-user-group.constants";
import {PairsService} from "../../services/pairs.service";
import {IPairsModel} from "../../shared/models/pairs.model";
import {IPairGantt} from "../../shared/models/pair-gantt.model";
import {IGanttChartData} from "../../shared/models/gantt-chart-data.model";
import {AcTypesService} from "../../services/ac-types.service";
import {IDelayCode} from "../../shared/models/delay-code.model";
import {IGeneralSettingsModel} from "../../shared/models/general-settings.model";
import {GeneralSettingsService} from "../../services/general-settings.service";
import {IAlertsModel} from "../../shared/models/alerts.model";
import {GanttSliderTab, PermissionUIMasks} from "../../shared/constants/enums";
import {ICancellation, IRescheduleChange, ITailChange} from "../../shared/models/gantt.models";
import {PermissionService} from "../../services/permission.service";
import {AlertService} from "../../services/alert.service";
import {IGanttChanges} from "../../shared/models/gantt-changes.model";
import {ISimpleData} from "../../shared/models/simpleData.model";
import {IPassengerCategory} from "../../shared/models/passenger-categories.model";
import {GanttSliderComponent} from "./gantt-slider/gantt-slider.component";
import {IAcType} from "../../shared/models/ac-type.model";


@Component({
  selector: 'app-gantt-page',
  templateUrl: './gantt-page.component.html',
  styleUrls: ['./gantt-page.component.scss']
})
export class GanttPageComponent implements OnInit, OnDestroy {
  registrations: IAcRegistration[];
  legs: IGanttChartData = {};
  filterForm: UntypedFormGroup;
  user: IUser;
  stations: string[] = [];
  pairs: IPairsModel[];
  timespanHours: number[] = [12, 24, 48, 72];
  loadingData = true;
  acTypes: Record<number, string> = {};
  acTypesArray: IAcType[] = [];
  delayCodes: IDelayCode[];
  delayCodesKV: Record<number, IDelayCode>;
  dateFormat = 'DD/MM/YYYY';
  generalSettings: IGeneralSettingsModel;

  unsubscribe$ = new Subject<void>();

  alerts: IAlertsModel[];

  registrationChanges: ITailChange[] = [];
  rescheduleChanges: IRescheduleChange[] = [];
  cancellationChanges: ICancellation[] = [];


  alertPermission?: boolean;
  changes: IGanttChanges = {registrationChange: {}, reschedule: {}, cancellations: []};
  passengerClasses: ISimpleData[];
  passengerCategories: IPassengerCategory[];
  selectedSectors: Record<number, boolean> = {};
  selectedSectorsId: number[] = [];
  selectedPairId: number;
  acRegistrationFilter: string = null;
  acTypeFilter: string = null;

  @ViewChild('slider') slider: GanttSliderComponent;


  constructor(private acRegistrationService: AcRegistrationsService, private legService: LegsService, private authService: AuthService, private airportService: AirportsService, private fb: UntypedFormBuilder, private pairService: PairsService, private acTypeService: AcTypesService, private generalSettingsService: GeneralSettingsService, private permissionService: PermissionService, public alertService: AlertService, public cdr: ChangeDetectorRef) {
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.authService.userSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((user: IUser) => {
      this.user = user;
      if (user) {
        if (this.authService.userSubject.value?.userGroup === StaticUserGroupConstants.STR_TO_ID.ADMIN) {
          this.alertPermission = true;
          this.refetchAlerts();
          return;
        }
        this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_CHAT).then(() => {
          const result = this.permissionService.permissions.find((perm) => {
            return perm.uiMask.includes('WEB_ALERTS_') && perm.access
          });
          this.alertPermission = !!result;
          if (this.alertPermission) {
            this.refetchAlerts();
          }
        });
      }
    });
    this.filterForm = this.createForm();
    if (this.user && this.user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER) {
      this.filterForm.patchValue({
        station: this.user.location
      });
      this.filterForm.get('station').disable();
    }
    this.filterForm.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(250)).subscribe((value) => {
      this.refreshData();
    });
    forkJoin([this.acRegistrationService.fetchAcRegistrations(), this.airportService.fetchAirports(), this.pairService.getPairsForGanttByFilter(this.filterForm.value), this.acTypeService.fetchAcTypes(), this.generalSettingsService.getGeneralSettings(), this.generalSettingsService.getPassengerClasses(), this.generalSettingsService.getPassengerCategories(), this.generalSettingsService.getDelayCodes()]).subscribe(([registrations, airports, pairs, acTypes, generalSettings, passengerClasses, passengerCategories, delayCodes]) => {
      this.registrations = registrations;
      this.stations.push(...(airports.map((val) => val.iata)));
      this.onDataFetched(pairs);
      this.acTypesArray = acTypes;
      this.acTypes = {};
      for (const acType of acTypes) {
        this.acTypes[acType.id] = acType.alias;
      }
      this.generalSettings = generalSettings?.[0];
      if (this.generalSettings) {
        this.dateFormat = this.generalSettings.dateFormat;
      }
      this.passengerClasses = passengerClasses;
      this.passengerCategories = passengerCategories;
      this.delayCodes = delayCodes;
      this.delayCodesKV = {};
      for (const code of this.delayCodes) {
        this.delayCodesKV[code.id] = code;
      }
    });
  }

  onDataFetched(pairs: IPairGantt[]) {
    this.legs = {};
    for (const pair of pairs) {
      if (pair.__arrivalLegModel__) {
        if (!this.legs[pair.arrivalLegId]) {
          this.legs[pair.arrivalLegId] = {leg: pair.__arrivalLegModel__};
        }
        this.legs[pair.arrivalLegId].arrival = pair;
        if (!this.legs[pair.arrivalLegId].hasAlerts && pair.alerts?.find((alert) => alert.legId === pair.arrivalLegId || alert.pairId === pair.id)) {
          this.legs[pair.arrivalLegId].hasAlerts = true;
        }
      }

      if (pair.__departureLegModel__) {
        if (!this.legs[pair.departureLegId]) {
          this.legs[pair.departureLegId] = {leg: pair.__departureLegModel__};
        }
        this.legs[pair.departureLegId].departure = pair;
        if (!this.legs[pair.departureLegId].hasAlerts && pair.alerts?.find((alert) => alert.legId === pair.departureLegId || alert.pairId === pair.id)) {
          this.legs[pair.departureLegId].hasAlerts = true;
        }
      }
    }
    this.loadingData = false;
  }


  createForm(): UntypedFormGroup {
    return this.fb.group({
      timespan: [12],
      station: [this.user?.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER ? {
        value: null,
        disabled: true
      } : null],
      status: [null],
      alerts: [null],
      showCancelled: [false],
      showUnassigned: [false],
      flightNumber: [''],
    });
  }

  refreshData() {
    this.loadingData = true;
    this.pairService.getPairsForGanttByFilter(this.filterForm.value).subscribe((pairs) => {
      this.onDataFetched(pairs);
    });
  }

  refetchAlerts() {
    this.alertService.getAlerts().subscribe((results) => {
      this.alerts = results.filter((alert) => {
        if (alert.__leg__) {
          if (!this.permissionService.getPermissionAccess(PermissionUIMasks['WEB_ALERTS_' + this.alertService.alertTypes[alert.alertTypeId]?.title], alert.__leg__.arrivalStationId)) {
            return false;
          }
          if (!this.permissionService.getPermissionAccess(PermissionUIMasks['WEB_ALERTS_' + this.alertService.alertTypes[alert.alertTypeId]?.title], alert.__leg__.departureStationId)) {
            return false;
          }
        } else if (alert.__pair__) {
          if (!this.permissionService.getPermissionAccess(PermissionUIMasks['WEB_ALERTS_' + this.alertService.alertTypes[alert.alertTypeId]?.title], alert.__pair__.__arrivalLegModel__?.arrivalStationId ?? alert.__pair__.__departureLegModel__?.departureStationId)) {
            return false;
          }
        }

        return alert.alertTypeId !== 9 || !!alert.__pair__.__departureLegModel__
      });
    });
  }

  protected readonly GanttSliderTab = GanttSliderTab;

  onPairSelected(pairId: number) {
    if (!pairId || !this.slider) {
      return;
    }
    this.slider.sliderOpen = true;
    this.slider.sliderTab = GanttSliderTab.PROCESSES;
  }
}
