<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="confirm-turnaround-close-button">
  </button>
</div>
<div class="modal-body">
  Are you sure you want to resume the turnaround process?
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()" data-test="confirm-turnaround-cancel-button">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" (click)="onSaveClick()" data-test="confirm-turnaround-save-button">Yes, do it
  </button>
</div>
