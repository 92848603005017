import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { ToastService } from './toast.service';
import { ITransferFile } from '../shared/models/transfer-file.model';

@Injectable({
  providedIn: 'root'
})
export class TransferFileService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  sendFile(content: ITransferFile): Observable<any> {
    return this.httpClient.post(`${environment.api_base_url}transfer-file/send`, content, {}).pipe(
      map((item: IResponse<boolean>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || null;
      }),
      catchError(() => {
        this.toastService.showError("Error sending file");
        return of(null);
      })
    );
  }
}
