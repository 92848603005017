import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

export class FlightsIssueNewInformationFormBuilder {

  static constructForm(
    fb: UntypedFormBuilder): UntypedFormGroup {

    return fb.group({
      flightType: ['arrival', Validators.required],
      newInformationDate: ['', Validators.required],
      newInformationTime: ['', Validators.required],
      delayCode: ['', Validators.required]
    });
  }
}
