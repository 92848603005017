<!--<div class="exit-screen-page-container">-->
<!--    <div class="flex flex-row flex-wrap mb-3">-->
<!--      <div class="exit-screen-content-page">-->
<!--        <form [formGroup]="filterForm">-->

<!--        <div class="row" style="padding:2rem;display:flex">-->
<!--            <div class="col-2">-->
<!--                <select  class="form-control form-select" id="type" formControlName="type">-->
<!--                    <option  [value]="null" disabled selected hidden>Message Type</option>-->
<!--                    <option value="MVT">MVT</option>-->
<!--                    <option value="ASM">ASM</option>-->
<!--                </select>-->
<!--            </div>-->
<!--            <div class="col-2">-->
<!--              <select class="form-control form-select" id="subtype" formControlName="subtype">-->
<!--                <option  [value]="null" disabled selected hidden>Sub-Message Type</option>-->
<!--                <ng-container *ngIf="filterForm.value?.type === 'MVT'">-->
<!--                  <option value="ED">ED</option>-->
<!--                  <option value="AD">AD</option>-->
<!--                  <option value="AA">AA</option>-->
<!--                  <option value="NI">NI</option>-->
<!--                </ng-container>-->
<!--                <ng-container *ngIf="filterForm.value?.type === 'ASM'">-->
<!--                  <option value="CNL">CNL</option>-->
<!--                  <option value="EQT">EQT</option>-->
<!--                  <option value="NEW">NEW</option>-->
<!--                  <option value="CON">CON</option>-->
<!--                  <option value="TIM">TIM</option>-->
<!--                  <option value="RRT">RRT</option>-->
<!--                </ng-container>-->


<!--              </select>-->
<!--            </div>-->
<!--            <div class="col-2">-->
<!--              <div class="filterItem">-->
<!--                <input class="form-control custom-input" placeholder="Flight Number" id="flightNumber" formControlName="flightNumber"/>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-2">-->
<!--              <div class="input-group">-->
<!--                <input class="form-control" [placeholder]="datePlaceholder"-->
<!--                       name="dp" formControlName="date" ngbDatepicker #dp="ngbDatepicker" (ngModelChange)="fixDateFormat()">-->
<!--                <div class="input-group-append">-->
<!--                  <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar date-selector-btn"-->
<!--                          (click)="dp.toggle()" type="button"></button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-2">-->
<!--              <select class="form-control form-select" id="ac-registration" formControlName="acRegistration">-->
<!--                  <option  [value]="null" disabled selected hidden>Ac Registration</option>-->
<!--                  <option *ngFor="let acRegistration of acRegistrations | keyvalue" [value]="acRegistration.value?.registration">{{acRegistration.value?.registration}}</option>-->
<!--              </select>-->
<!--            </div>-->
<!--            <div class="col-1">-->
<!--              <select class="form-control form-select" id="status" formControlName="status">-->
<!--                  <option  [value]="null" disabled selected hidden>Status</option>-->
<!--                  <option [value]="false">Successful</option>-->
<!--                  <option [value]="true">Unsuccessful</option>-->
<!--              </select>-->
<!--            </div>-->
<!--            <div class="col-1">-->
<!--              <select class="form-control form-select" id="category" formControlName="category">-->
<!--                  <option  [value]="null" disabled selected hidden>Category</option>-->
<!--                  <option value="Incoming">Incoming</option>-->
<!--                  <option value="Outgoing">Outgoing</option>-->
<!--              </select>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->

<!--        &lt;!&ndash; <div class="row" style="padding: 2rem;">-->
<!--            <div class="w-100">-->
<!--              <form class="flex search-section" [formGroup]="form">-->
<!--                <div class="me-2 input-group has-search search-box">-->
<!--                  <img src="content/images/icons/search.svg" width="26" height="26" class="form-control-feedback" alt="search">-->
<!--                  <input type="text" class="form-control search-bar" placeholder="Search by Flight Number, Ac Registration, etc..." formControlName="searchText" >-->
<!--                </div>-->
<!--                <div class="ms-2">-->
<!--                    <button type="button" (click)="searchMessages()" class="btn btn-secondary">Search</button>-->
<!--                </div>-->
<!--              </form>-->
<!--            </div>-->
<!--        </div> &ndash;&gt;-->
<!--        <div class="row mt-2">-->
<!--            <table class="table">-->
<!--                <thead>-->
<!--                  <tr>-->
<!--                    <th scope="col">#</th>-->
<!--                    <th scope="col">Category</th>-->
<!--                    <th scope="col">Date</th>-->
<!--                    <th scope="col">Message Type</th>-->
<!--                    <th scope="col">Message Subtype</th>-->
<!--                    <th scope="col">Flight Number</th>-->
<!--                    <th scope="col">Ac Registration</th>-->
<!--                    <th scope="col">Status</th>-->
<!--                  </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                    <tr *ngFor="let message of messages ">-->
<!--                        <th scope="row">{{this.messages.indexOf(message)+1+((this.page -1) * 20)}}</th>-->
<!--                        <td>{{message.category}}</td>-->
<!--                        <td>{{message.datetime | date:'EEEE, MMMM d, y HH:mm'}}</td>-->
<!--                        <td>{{message.type}}</td>-->
<!--                        <td>{{message.subtype}}</td>-->
<!--                        <td>{{message.flightNumber}}</td>-->
<!--                        <td>{{message.acRegistration}}</td>-->
<!--                        <div *ngIf="!message.isError">-->
<!--                          <td><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="green" class="bi bi-check" viewBox="0 0 16 16">-->
<!--                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>-->
<!--                          </svg>-->
<!--                          </td>-->
<!--                        </div>-->
<!--                        <div *ngIf="message.isError">-->
<!--                          <td><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-x" viewBox="0 0 16 16">-->
<!--                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>-->
<!--                          </svg>-->
<!--                          </td>-->
<!--                        </div>-->
<!--                    </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--              &lt;!&ndash; <ngb-pagination-->
<!--              [(page)]="page"-->
<!--              [pageSize]="pageSize"-->
<!--              [collectionSize]="messages?.length"-->
<!--              >-->
<!--          </ngb-pagination> &ndash;&gt;-->
<!--          <div *ngIf="messages && messages.length > 0" class="filter-container mx-3 flex flex-row justify-content-around">-->
<!--            <button class="btn btn-outline-dark flex-basis-20 d-flex align-items-center justify-content-around"-->
<!--                    (click)="previousPage()" [ngClass]="{ 'hidden': page === 1 }">-->

<!--              <svg class="bi bi-caret-left-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"-->
<!--                   xmlns="http://www.w3.org/2000/svg">-->
<!--                <path-->
<!--                  d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 00-1.659-.753l-5.48 4.796a1 1 0 000 1.506z"/>-->
<!--              </svg>-->

<!--              Previous Flights-->
<!--            </button>-->


<!--            <button class="btn btn-outline-dark flex-basis-20 d-flex align-items-center justify-content-around"-->
<!--                    (click)="nextPage()" [ngClass]="{ 'hidden':  !hasNextPage }">-->
<!--              Next Flights-->

<!--              <svg class="bi bi-caret-right-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"-->
<!--                   xmlns="http://www.w3.org/2000/svg">-->
<!--                <path-->
<!--                  d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 011.659-.753l5.48 4.796a1 1 0 010 1.506z"/>-->
<!--              </svg>-->

<!--            </button>-->

<!--            &lt;!&ndash; <button class="btn btn-outline-light pagination-button flex-basis-20" (click)="paginateForward()">next</button> &ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--</div>-->

<div class="main-container p-4 mt-3 position-relative" *ngIf="filterForm">
  <app-access-denied *ngIf="!listAccess"></app-access-denied>
  <div class="flex flex-column gap-3">
    <div class="flex justify-content-between gap-2" [formGroup]="filterForm">
      <select class="form-control form-select control-blue-bg" id="type" formControlName="type"
              data-test="exit-screen-list-message-type-select">
        <option [ngValue]="null" selected data-test="message-type-null">Message Type</option>
        <option value="MVT" data-test="message-type-mvt">MVT</option>
        <option value="ASM" data-test="message-type-asm">ASM</option>
      </select>

      <select class="form-control form-select control-blue-bg" id="subtype" formControlName="subtype"
              data-test="exit-screen-list-sub-message-type-select">
        <option [ngValue]="null" selected data-test="sub-message-type-null">Sub-Message Type</option>
        <ng-container *ngIf="filterForm.value?.type === 'MVT'">
          <option data-test="sub-message-type-ed">ED</option>
          <option data-test="sub-message-type-ad">AD</option>
          <option data-test="sub-message-type-aa">AA</option>
          <option data-test="sub-message-type-ni">NI</option>
        </ng-container>
        <ng-container *ngIf="filterForm.value?.type === 'ASM'">
          <option data-test="sub-message-type-cnl">CNL</option>
          <option data-test="sub-message-type-eqt">EQT</option>
          <option data-test="sub-message-type-new">NEW</option>
          <option data-test="sub-message-type-con">CON</option>
          <option data-test="sub-message-type-tim">TIM</option>
          <option data-test="sub-message-type-rrt">RRT</option>
        </ng-container>
      </select>

      <input class="form-control custom-input control-blue-bg" placeholder="Flight Number" id="flightNumber"
             formControlName="flightNumber" data-test="exit-screen-list-flight-number-search-input"/>

      <div class="input-group">
        <input class="form-control control-blue-bg" [placeholder]="datePlaceholder"
               name="dp" formControlName="date" ngbDatepicker #dp="ngbDatepicker"
               data-test="exit-screen-list-date-input">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar date-selector-btn"
                  data-test="exit-screen-list-calendar-button"
                  (click)="dp.toggle()" type="button"></button>
        </div>
      </div>

      <ng-select placeholder="A/C Registration" class="new-select flex-shrink-0" id="ac-registration"
                 formControlName="acRegistration" data-test="exit-screen-list-ac-registration-select"
                 [inputAttrs]="{ 'data-test': 'ac-registration-input' }">
        <ng-option *ngFor="let acRegistration of acRegistrations"
                   [value]="acRegistration.registration">{{ acRegistration.registration }}
        </ng-option>
        <ng-template ng-option-tmp let-item="item">
          <span class="ng-option-label" [attr.data-test]="'ac-registration-' + item">{{ item }}}</span>
        </ng-template>
      </ng-select>

      <select class="form-control form-select control-blue-bg" id="status" formControlName="status"
              data-test="exit-screen-list-status-select">
        <option [ngValue]="null" selected data-test="status-null">Status</option>
        <option [value]="false" data-test="status-successful">Successful</option>
        <option [value]="true" data-test="status-unsuccessful">Unsuccessful</option>
      </select>

      <select class="form-control form-select control-blue-bg" id="category" formControlName="category"
              data-test="exit-screen-list-category-select">
        <option [ngValue]="null" selected data-test="category-null">Category</option>
        <option value="Incoming" data-test="category-incoming">Incoming</option>
        <option value="Outgoing" data-test="category-outgoing">Outgoing</option>
      </select>
    </div>

    <app-settings-card>
      <div class="flex flex-column">
        <app-settings-data-table [testTagPipe]="MessageTestTagPipe" [showEdit]="false" [showDelete]="false"
                                 showView="true" [data]="messages"
                                 [variableNames]="['id', 'category', 'datetime', 'type', 'subtype', 'flightNumber', 'acRegistration', 'isError']"
                                 [columns]="['Nr.', 'Category', 'Creation Date', 'Message Type', 'Sub Type', 'Flight Nr', 'A/C Registration', 'Status']"
                                 showDelete="false" showEdit="false">
          <ng-template propName="isError" let-value>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ff0000" class="bi bi-x-circle-fill"
                 viewBox="0 0 16 16" *ngIf="value">
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00ff00"
                 class="bi bi-check-circle-fill" viewBox="0 0 16 16" *ngIf="!value">
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </ng-template>

          <ng-template propName="datetime" let-value>
            {{ dayjs.utc(value).format('DD.MM.YYYY, HH:mm') }}
          </ng-template>
        </app-settings-data-table>

        <div class="flex justify-content-between pagination-buttons">
          <app-pagination-button text="Previous" [disabled]="page === 1" (onClick)="previousPage()"
                                 data-test="exit-screen-list-previous-page-button"></app-pagination-button>
          <app-pagination-button text="Next" [disabled]="!hasNextPage" (onClick)="nextPage();"
                                 data-test="exit-screen-list-next-page-button"></app-pagination-button>
        </div>
      </div>
    </app-settings-card>
  </div>
</div>
