<table class="table table-striped">
  <thead>
  <tr class="dark-blue">
    <ng-container *ngFor="let column of columns">
      <th scope="col" *ngIf="sortableColumns[column]; else defaultHeader" [sortable]="sortableColumns[column]"
          (sort)="onSort($event)">
        <div [ngbTooltip]="columnTooltips?.[column] || ''">{{ column }}</div>
      </th>
      <ng-template #defaultHeader>
        <th scope="col">
          <div [ngbTooltip]="columnTooltips?.[column] || ''">{{ column }}</div>
        </th>
      </ng-template>
    </ng-container>


    <th scope="col"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let dataElement of data | slice:0:maxLength; trackBy: trackByFn; index as i" class="dark-blue"
      [attr.data-test]="dataElement | testTag:testTagPipe">
    <td *ngFor="let varName of variableNames">
      <ng-container
        *ngTemplateOutlet="getTemplate(varName); context: { $implicit: dataElement[varName], obj: dataElement }">
      </ng-container>
      <ng-container *ngIf="!getTemplate(varName)">{{ dataElement[varName] }}</ng-container>


    </td>
    <td class="text-end gap-1">
      <ng-container
        *ngIf="viewOnlyMode && !viewModePerRow || viewModePerRow && viewModeCheck(dataElement) && (isEditable ? !isEditable(dataElement) : true); else defaultMode">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#002060"
             class="bi bi-eye cursor-pointer me-1"
             viewBox="0 0 16 16" (click)="emitEditClicked(dataElement)">
          <path
            d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
        </svg>
      </ng-container>
      <ng-template #defaultMode>
        <img class="me-1"
             [ngClass]="{ 'opacity-50': !canEdit || (isEditable && !isEditable(dataElement)) || canEditPropertyCheck ? !dataElement[canEditPropertyCheck] : false, 'cursor-pointer': canEdit && (isEditable ? isEditable(dataElement) : true) && (canEditPropertyCheck ? dataElement[canEditPropertyCheck] : true) }"
             src="content/images/icons/pencil.svg" (click)="emitEditClicked(dataElement)" alt="Edit" *ngIf="showEdit"
             data-test="edit-button">
      </ng-template>

      <img
        [ngClass]="{ 'opacity-50': !canDelete || (isDeletable && !isDeletable(dataElement)), 'cursor-pointer': canDelete && (isDeletable ? isDeletable(dataElement) : true) }"
        src="content/images/icons/trash3.svg"
        (click)="emitDeleteClicked(dataElement)" alt="Delete" *ngIf="showDelete" data-test="delete-button">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#002060"
           class="bi bi-file-text cursor-pointer" viewBox="0 0 16 16" *ngIf="showView"
           (click)="emitViewClicked(dataElement)">
        <path
          d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
        <path
          d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
      </svg>
    </td>
  </tr>
  </tbody>
</table>
<div class="text-center cursor-pointer show-more" (click)="showMore()" *ngIf="data?.length > maxLength"
     data-test="show-more-button">Show more
</div>
