<div class="position-relative h-100" *ngIf="pageAccess === null">
  <app-access-denied></app-access-denied>
</div>

<app-settings-header [backButton]="true" backButtonUrl=".."
                     [text]="roleId ? 'Edit ' + (userGroupsKV[roleId]?.title ?? '')  : 'Add Role'"
                     [showSaveButton]="true" [textSize]="20"
                     (onSaveClicked)="onSaveClick()"
                     [saveButtonDisabled]="form?.invalid || isBusy || pageAccess === Access.R"
                     [spinner]="isBusy"></app-settings-header>

<div class="flex gap-4 mt-3 dark-blue" *ngIf="form && airportsForm" [formGroup]="form">
  <ngb-alert *ngIf="warningText" [dismissible]="false">{{ warningText }}</ngb-alert>
  <div class="airport-section light-blue-border flex flex-column">
    <div class="flex justify-content-between p-3 titlebar">
      <div class="fw-bold fs-18">Select Airport(s)
        <svg
          ngbTooltip="For the arriving destination of the arriving sector and the departing destination of the departing sector of pairs"
          xmlns="http://www.w3.org/2000/svg" width="14" height="14" class="bi bi-info-circle-fill position-absolute"
          fill="#4285F6" viewBox="0 0 16 16">
          <path
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
        </svg>
      </div>

    </div>

    <div class="p-3 flex flex-column gap-2 airport-list avitium-scrollbar" [formGroup]="airportsForm">
      <div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="all-airports"
                 [attr.data-test]="'airports-all'"
                 [disabled]="pageAccess === Access.R || inheritedAirport" [(ngModel)]="allAirports"
                 [ngModelOptions]="{ standalone: true }" (ngModelChange)="allAirportsChanged()">
          <label class="form-check-label dark-blue" for="all-airports">All airports</label>
        </div>
      </div>
      <div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="inherit"
                 [attr.data-test]="'airport-inherit'"
                 [disabled]="pageAccess === Access.R || allAirports" [(ngModel)]="inheritedAirport"
                 [ngModelOptions]="{ standalone: true }" (ngModelChange)="inheritedAirportChanged()">
          <label class="form-check-label dark-blue" for="inherit"> Inherit from User</label>
        </div>
      </div>
      <div *ngFor="let airport of airports">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" [attr.id]="airport.iata"
                 [formControlName]="airport.iata" [attr.data-test]="'airport-' + airport.iata"
                 (change)="selectedAirportsChanged($event)">
          <label class="form-check-label dark-blue" [attr.for]="airport.iata"> {{ airport.iata }}
            - {{ airport.title | titlecase }}</label>
        </div>
      </div>
    </div>
  </div>


  <div class="permission-list-section light-blue-border flex flex-column">
    <div class="flex justify-content-between p-3 titlebar">
      <div class="fw-bold fs-18">Enter Permissions for selected Airports & Role
      </div>
      <div class="align-self-center select-all cursor-pointer" [ngClass]="{ disabled: pageAccess !== Access.RW }"
           (click)="selectAllPermissions()">Select All
      </div>
    </div>

    <div class="p-3 flex flex-column gap-2 overflow-auto avitium-scrollbar">
      <app-custom-accordion title="Web Application" switchId="web-app-switch">
        <ng-template accordionTemplate="body">
          <app-custom-accordion title="Flights">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-all-flights"
                         data-test="read-all-flights-switch" [(ngModel)]="readAllFlights"
                         [ngModelOptions]="{ standalone: true }"
                         (change)="readAllFlightsChanged($event)" [disabled]="pageAccess === Access.R">
                  <label class="form-check-label dark-blue" for="read-all-flights">Select Read All</label>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="write-all-flights"
                         data-test="write-all-flights-switch" [(ngModel)]="writeAllFlights"
                         [ngModelOptions]="{ standalone: true }" (change)="writeAllFlightsChanged($event)"
                         [disabled]="pageAccess === Access.R">
                  <label class="form-check-label dark-blue" for="write-all-flights">Select Write All</label>
                </div>
              </div>

            </ng-template>
            <ng-template accordionTemplate="body">
              <table class="table table-striped">
                <tbody>
                <tr formGroupName="WEB_FLIGHTS_ARRIVING_INFORMATION">
                  <td>
                    Arriving Flight Information
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="arriving-flight-info-read"
                               data-test="arriving-flight-info-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="arriving-flight-info-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="arriving-flight-info-write"
                               data-test="arriving-flight-info-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="arriving-flight-info-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_FLIGHTS_DEPARTING_INFORMATION">
                  <td>
                    Departing Flight Information
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="departing-flight-info-read"
                               data-test="departing-flight-info-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="departing-flight-info-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="departing-flight-info-write"
                               data-test="departing-flight-info-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="departing-flight-info-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_FLIGHTS_TURNAROUND_PROCESSES_AND_GSES">
                  <td>
                    Turnaround Processes & GSEs
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="turnaround-gses-read"
                               data-test="turnaround-gses-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="turnaround-gses-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="turnaround-gses-write"
                               data-test="turnaround-gses-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="turnaround-gses-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_FLIGHTS_MEMOS">
                  <td>
                    Memos
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="memos-read"
                               data-test="memos-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="memos-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="memos-write"
                               data-test="memos-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="memos-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_FLIGHTS_ISSUE_ETD">
                  <td>
                    Issue ETD
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="issue-etd-write"
                               data-test="issue-etd-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="issue-etd-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_FLIGHTS_ISSUE_NI">
                  <td>
                    Issue New Information
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="issue-ni-write"
                               data-test="issue-ni-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="issue-ni-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </app-custom-accordion>
          <app-custom-accordion title="Alerts">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-all-alerts"
                         data-test="read-all-alerts-switch" [(ngModel)]="readAllAlerts"
                         [ngModelOptions]="{ standalone: true }"
                         (change)="readAllAlertsChanged($event)" [disabled]="pageAccess === Access.R">
                  <label class="form-check-label dark-blue" for="read-all-alerts">Select Read All</label>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="write-all-alerts"
                         data-test="write-all-alerts-switch" [(ngModel)]="writeAllAlerts"
                         [ngModelOptions]="{ standalone: true }" (change)="writeAllAlertsChanged($event)"
                         [disabled]="pageAccess === Access.R">
                  <label class="form-check-label dark-blue" for="write-all-alerts">Select Write All</label>
                </div>
              </div>

            </ng-template>
            <ng-template accordionTemplate="body">
              <table class="table table-striped">
                <tbody>
                <tr *ngFor="let alertType of alertTypes">
                  <td>
                    {{ alertType.description }}
                  </td>
                  <td>
                    <div class="flex justify-content-between switches"
                         [formGroupName]="formGroupNameFromAlertType(alertType)">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" [id]="alertType.title + '-read'"
                               [attr.data-test]="alertType.title + '-read-switch'" formControlName="read">
                        <label class="form-check-label dark-blue" [for]="alertType.title + '-read'">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" [id]="alertType.title + '-write'"
                               [attr.data-test]="alertType.title + '-write-switch'" formControlName="write">
                        <label class="form-check-label dark-blue" [for]="alertType.title + '-write'">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </app-custom-accordion>
          <app-custom-accordion title="Ramp Agent Assignment" [showArrow]="false">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches" formGroupName="WEB_RAMP_AGENT_ASSIGNMENT">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-ramp-agent-assignment"
                         data-test="read-ramp-agent-assignment-switch" formControlName="read">
                  <label class="form-check-label dark-blue" for="read-ramp-agent-assignment">Read</label>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="write-ramp-agent-assignment"
                         data-test="write-ramp-agent-assignment-switch" formControlName="write">
                  <label class="form-check-label dark-blue" for="write-ramp-agent-assignment">Write</label>
                </div>
              </div>

            </ng-template>
          </app-custom-accordion>
          <app-custom-accordion title="Reports Cockpit">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-all-reports"
                         data-test="read-all-reports-switch" [(ngModel)]="readAllReportsCockpit"
                         [ngModelOptions]="{ standalone: true }"
                         (change)="readAllReportsCockpitChanged($event)" [disabled]="pageAccess === Access.R">
                  <label class="form-check-label dark-blue" for="read-all-reports">Select Read All</label>
                </div>
                <div class="form-check form-switch">
                </div>
              </div>

            </ng-template>
            <ng-template accordionTemplate="body">
              <table class="table table-striped">
                <tbody>
                <tr formGroupName="WEB_REPORTS_OPERATIONS">
                  <td>
                    Operations
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="reports-operations-read"
                               formControlName="read"
                               data-test="reports-operations-read-switch">
                        <label class="form-check-label dark-blue" for="reports-operations-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>

                <tr formGroupName="WEB_REPORTS_PUNCTUALITY">
                  <td>
                    Punctuality
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="reports-punctuality-read"
                               data-test="reports-punctuality-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="reports-punctuality-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>

                <tr formGroupName="WEB_REPORTS_PASSENGERS">
                  <td>
                    Passengers
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="reports-passengers-read"
                               data-test="reports-passengers-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="reports-passengers-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>

                <tr formGroupName="WEB_REPORTS_UTILIZATION">
                  <td>
                    Utilization
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="reports-utilization-read"
                               data-test="reports-utilization-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="reports-utilization-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </app-custom-accordion>
          <app-custom-accordion title="Reports List">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-all-reports-list"
                         data-test="read-all-reports-list-switch" [(ngModel)]="readAllReportsList"
                         [ngModelOptions]="{ standalone: true }"
                         (change)="readAllReportsListChanged($event)" [disabled]="pageAccess === Access.R">
                  <label class="form-check-label dark-blue" for="read-all-reports-list">Select Read All</label>
                </div>
                <div class="form-check form-switch">
                </div>
              </div>

            </ng-template>
            <ng-template accordionTemplate="body">
              <table class="table table-striped">
                <tbody>
                <tr formGroupName="WEB_REPORTS_FLIGHT_DETAILS">
                  <td>
                    Flight Details
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="reports-flight-details-read"
                               data-test="reports-flight-details-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="reports-flight-details-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_REPORTS_FLIGHT_PAIRINGS">
                  <td>
                    Flight Pairings
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="reports-flight-pairings-read"
                               data-test="reports-flight-pairings-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="reports-flight-pairings-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_REPORTS_USERS_REPORT">
                  <td>
                    Users Report
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="reports-users-report-read"
                               data-test="reports-users-report-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="reports-users-report-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_REPORTS_GSE_UTILIZATION">
                  <td>
                    GSE Utilization per Airport
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="reports-gse-utilization-read"
                               data-test="reports-gse-utilization-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="reports-gse-utilization-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_REPORTS_LIST_PROCESSES">
                  <td>
                    List of SLA/Non-SLA Processes
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="reports-processes-list-read"
                               data-test="reports-processes-list-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="reports-processes-list-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_REPORTS_FLIGHTS_MEMOS">
                  <td>
                    Flights Memos
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="reports-flight-memos-read"
                               data-test="reports-flight-memos-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="reports-flight-memos-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_REPORTS_TURNAROUND_GROUND_DIFFERENCES">
                  <td>
                    Turnaround/ground time differences
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch"
                               id="reports-turnaround-ground-time-differences-read"
                               data-test="reports-turnaround-ground-time-differences-read-switch"
                               formControlName="read">
                        <label class="form-check-label dark-blue" for="reports-turnaround-ground-time-differences-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </app-custom-accordion>
          <app-custom-accordion title="Message Creation">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-all-messages"
                         data-test="read-all-messages-switch" [(ngModel)]="readAllMessageCreation"
                         [ngModelOptions]="{ standalone: true }"
                         (change)="readAllMessageCreationChanged($event)" [disabled]="pageAccess === Access.R">
                  <label class="form-check-label dark-blue" for="read-all-messages">Select Read All</label>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="write-all-messages"
                         data-test="write-all-messages-switch" [(ngModel)]="writeAllMessageCreation"
                         [ngModelOptions]="{ standalone: true }" (change)="writeAllMessageCreationChanged($event)"
                         [disabled]="pageAccess === Access.R">
                  <label class="form-check-label dark-blue" for="write-all-messages">Select Write All</label>
                </div>
              </div>

            </ng-template>
            <ng-template accordionTemplate="body">
              <table class="table table-striped">
                <tbody>
                <tr formGroupName="WEB_MESSAGE_CREATION_ASM">
                  <td>
                    ASM Creation
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="asm-write"
                               data-test="asm-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="asm-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_MESSAGE_CREATION_MVT">
                  <td>
                    MVT Creation
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="mvt-write"
                               data-test="mvt-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="mvt-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_MESSAGE_CREATION_LIST">
                  <td>
                    List of Messages
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="message-list-read"
                               data-test="message-list-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="message-list-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </app-custom-accordion>
          <app-custom-accordion title="Settings">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-all-settings"
                         data-test="read-all-settings-switch" [(ngModel)]="readAllSettings"
                         [ngModelOptions]="{ standalone: true }"
                         (change)="readAllSettingsChanged($event)" [disabled]="pageAccess === Access.R">
                  <label class="form-check-label dark-blue" for="read-all-settings">Select Read All</label>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="write-all-settings"
                         data-test="write-all-settings-switch" [(ngModel)]="writeAllSettings"
                         [ngModelOptions]="{ standalone: true }" (change)="writeAllSettingsChanged($event)"
                         [disabled]="pageAccess === Access.R">
                  <label class="form-check-label dark-blue" for="write-all-settings">Select Write All</label>
                </div>
              </div>

            </ng-template>
            <ng-template accordionTemplate="body">
              <table class="table table-striped">
                <tbody>
                <tr formGroupName="WEB_SETTINGS_GENERAL_SETTINGS">
                  <td>
                    General Settings
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="general-settings-read"
                               data-test="general-settings-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="general-settings-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="general-settings-write"
                               data-test="general-settings-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="general-settings-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_SETTINGS_USER_MANAGEMENT">
                  <td>
                    User Management
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="user-management-read"
                               data-test="user-management-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="user-management-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="user-management-write"
                               data-test="user-management-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="user-management-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_SETTINGS_ROLE_MANAGEMENT">
                  <td>
                    Role Management
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="role-management-read"
                               data-test="role-management-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="role-management-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="role-management-write"
                               data-test="role-management-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="role-management-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_SETTINGS_FLIGHTS_SCHEDULE_MANAGEMENT">
                  <td>
                    Flight Schedule Management
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch"
                               id="flight-schedule-management-write"
                               data-test="flight-schedule-management-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="flight-schedule-management-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_SETTINGS_AC_TYPE_MANAGEMENT">
                  <td>
                    AC Type Management
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="ac-type-management-read"
                               data-test="ac-type-management-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="ac-type-management-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="ac-type-management-write"
                               data-test="ac-type-management-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="ac-type-management-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_SETTINGS_AC_REGISTRATION_MANAGEMENT">
                  <td>
                    AC Registration Management
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch"
                               id="ac-registration-management-read"
                               data-test="ac-registration-management-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="ac-registration-management-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch"
                               id="ac-registration-management-write"
                               data-test="ac-registration-management-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="ac-registration-management-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_SETTINGS_AIRPORT_MANAGEMENT">
                  <td>
                    Airport Management
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="airport-management-read"
                               data-test="airport-management-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="airport-management-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="airport-management-write"
                               data-test="airport-management-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="airport-management-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_SETTINGS_SLA_AND_GSES_MANAGEMENT">
                  <td>
                    SLA and GSE Management
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="sla-and-gse-management-read"
                               data-test="sla-and-gse-management-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="sla-and-gse-management-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="sla-and-gse-management-write"
                               data-test="sla-and-gse-management-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="sla-and-gse-management-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_SETTINGS_INTERFACE_MANAGEMENT">
                  <td>
                    Interface Management
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="interface-management-read"
                               data-test="interface-management-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="interface-management-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="interface-management-write"
                               data-test="interface-management-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="interface-management-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>
                <tr formGroupName="WEB_SETTINGS_ALERT_MANAGEMENT">
                  <td>
                    Alert Management
                  </td>
                  <td>
                    <div class="flex justify-content-between switches">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="alert-management-read"
                               data-test="alert-management-read-switch" formControlName="read">
                        <label class="form-check-label dark-blue" for="alert-management-read">Read</label>
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="alert-management-write"
                               data-test="alert-management-read-write" formControlName="write">
                        <label class="form-check-label dark-blue" for="alert-management-write">Write</label>
                      </div>
                      <div class="spacer"></div>
                    </div>
                  </td>
                </tr>

                </tbody>
              </table>
            </ng-template>
          </app-custom-accordion>
          <app-custom-accordion title="Chat" [showArrow]="false">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches" formGroupName="WEB_CHAT">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-chat"
                         data-test="read-chat-switch" formControlName="read">
                  <label class="form-check-label dark-blue" for="read-chat">Read</label>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="write-chat"
                         data-test="write-chat-switch" formControlName="write">
                  <label class="form-check-label dark-blue" for="write-chat">Write</label>
                </div>
              </div>

            </ng-template>
          </app-custom-accordion>
        </ng-template>
      </app-custom-accordion>
      <app-custom-accordion title="Mobile Application" switchId="mobile-app-switch">
        <ng-template accordionTemplate="inner-body">
          <div class="flex gap-2 switches">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="read-all-mobile"
                     data-test="read-all-mobile-switch" [(ngModel)]="readAllMobile"
                     [ngModelOptions]="{ standalone: true }"
                     (change)="readAllMobileChanged($event)" [disabled]="pageAccess === Access.R">
              <label class="form-check-label dark-blue" for="read-all-mobile">Select Read All</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="write-all-mobile"
                     data-test="write-all-mobile-switch" [(ngModel)]="writeAllMobile"
                     [ngModelOptions]="{ standalone: true }" (change)="writeAllMobileChanged($event)"
                     [disabled]="pageAccess === Access.R">
              <label class="form-check-label dark-blue" for="write-all-mobile">Select Write All</label>
            </div>
          </div>
        </ng-template>
        <ng-template accordionTemplate="body">
          <app-custom-accordion title="Start Turnaround" [showArrow]="false">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches" formGroupName="MOBILE_START_TURNAROUND">
                <div class="form-check form-switch">
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="write-turnaround"
                         data-test="write-turnaround-switch" formControlName="write">
                  <label class="form-check-label dark-blue" for="write-turnaround">Write</label>
                </div>
              </div>
            </ng-template>
          </app-custom-accordion>
          <app-custom-accordion title="Flight Info" [showArrow]="false">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches" formGroupName="MOBILE_FLIGHT_INFO">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-flight-info"
                         data-test="read-flight-info-switch" formControlName="read">
                  <label class="form-check-label dark-blue" for="read-flight-info">Read</label>
                </div>
                <div class="form-check form-switch">
                </div>
              </div>
            </ng-template>
          </app-custom-accordion>
          <app-custom-accordion title="Processes" [showArrow]="false">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches" formGroupName="MOBILE_PROCESSES">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-processes"
                         formControlName="read" data-test="read-processes-switch">
                  <label class="form-check-label dark-blue" for="read-processes">Read</label>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="write-processes"
                         formControlName="write" data-test="write-processes-switch">
                  <label class="form-check-label dark-blue" for="write-processes">Write</label>
                </div>
              </div>
            </ng-template>
          </app-custom-accordion>
          <app-custom-accordion title="Loading Info" [showArrow]="false">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches" formGroupName="MOBILE_LOADING_INFO">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-loading-info"
                         data-test="read-loading-info-switch" formControlName="read">
                  <label class="form-check-label dark-blue" for="read-loading-info">Read</label>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="write-loading-info"
                         data-test="write-loading-info-switch" formControlName="write">
                  <label class="form-check-label dark-blue" for="read-loading-info">Write</label>
                </div>
              </div>
            </ng-template>
          </app-custom-accordion>
          <app-custom-accordion title="Times" [showArrow]="false">
            <ng-template accordionTemplate="inner-body">
              <div class="flex gap-2 switches" formGroupName="MOBILE_TIMES">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="read-times"
                         data-test="read-times-switch" formControlName="read">
                  <label class="form-check-label dark-blue" for="read-times">Read</label>
                </div>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="write-times"
                         data-test="write-times-switch" formControlName="write">
                  <label class="form-check-label dark-blue" for="write-times">Write</label>
                </div>
              </div>
            </ng-template>
          </app-custom-accordion>
        </ng-template>
      </app-custom-accordion>
    </div>

  </div>
</div>
