<div class="position-relative h-100" *ngIf="pageAccess === null">
  <app-access-denied></app-access-denied>
</div>
<app-settings-header text="Airport Management" [showSaveButton]="false" [textSize]="20"></app-settings-header>
<app-settings-card (onAddClicked)="doAdd()" sectionTitle="Airports" addText="Add Airport"
                   [canAdd]="pageAccess === Access.RW">
  <div class="flex flex-column gap-1">
    <app-search-bar [(text)]="searchText" (textChange)="filterAirports()" width="250px"></app-search-bar>
    <app-settings-data-table [testTagPipe]="AirportsTestTagPipe" class="mt-3"
                             [columns]="['IATA', 'Country', 'Name', 'ICAO']" [data]="filteredAirports"
                             [variableNames]="['iata', 'country', 'title', 'icao']" (onEditClicked)="doEdit($event)"
                             (onDeleteClicked)="doDelete($event)"
                             [canDelete]="pageAccess === Access.RW"
                             [viewModePerRow]="true"
                             [viewModeCheck]="canViewCheck"
                             [isEditable]="isEditable" [isDeletable]="isEditable"></app-settings-data-table>
  </div>
</app-settings-card>
