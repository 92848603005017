<div class="flex flex-column">
  <div class="flex justify-content-between">
    <div class="ps-3 font-14" data-test="turnaround-status-text">
      <span class="ps-3">Boarding Process</span>
      <!-- <span *ngIf="!turnaroundProgress || '-' === turnaroundProgress.text" class="ps-3">Tunaround has not started yet 17:59 remaining to TOBT/ETD/STD</span>
      <span *ngIf="'-' !== turnaroundProgress.text" class="ps-3">{{turnaroundProgress?.text}}</span> -->
    </div>
    <div class="pe-3 text-end font-14" data-test="turnaround-total-time">
      <span class="pe-3">{{ currentPassengers }} / {{ maxPassengers }} in total</span>
      <!-- <span class="pe-3" *ngIf="!turnaroundProgress || '-' === turnaroundProgress.total">--:-- in total</span> -->
    </div>
  </div>

  <div class="col-12 mt-1">
    <ngb-progressbar
      [value]="turnaroundProgressPercentage"
      [striped]="true"
      [type]="turnaroundProgressBarColor"
    ></ngb-progressbar>
  </div>
</div>
