import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeneralSettingsService } from '../../../services/general-settings.service';

@Component({
  selector: 'app-date-picker-range-popup',
  templateUrl: './date-picker-range-popup.component.html',
  styleUrls: ['./date-picker-range-popup.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: DatePickerRangePopupComponent
    },
  ]
})
export class DatePickerRangePopupComponent implements OnInit, ControlValueAccessor {
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  formGroup: UntypedFormGroup;
  dateFormat: string;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private generalSettingsService: GeneralSettingsService) {
    this.dateFormat = this.generalSettingsService.generalSettings.value.dateFormat;
    // this.fromDate = calendar.getPrev(this.calendar.getToday(), 'd', 1)
    this.toDate = calendar.getToday();
    this.fromDate = calendar.getToday();
  }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      from: new UntypedFormControl(this.fromDate),
      to: new UntypedFormControl(this.toDate)
    });
    
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.formGroup.get('from').setValue(this.fromDate);
    this.formGroup.get('to').setValue(this.toDate);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) &&
      date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) { return this.toDate && date.after(this.fromDate) && date.before(this.toDate); }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) ||
      this.isHovered(date);
  }

  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  writeValue(value: any): void {
    if(value) {
      this.formGroup.setValue(value);
    }
  }
}
