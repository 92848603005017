import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {IUser} from "../../../shared/models/user.model";
import {IAirport} from "../../../shared/models/airport.model";
import {UserRoles} from "../../../shared/constants/user-roles.constants";
import {ToastService} from "../../../services/toast.service";
import {UserService} from "../../../services/user.service";
import {CountriesService} from "../../../services/countries.service";
import {BehaviorSubject, filter, forkJoin, Subject} from "rxjs";
import {AirportsService} from "../../../services/airports.service";
import {AuthService} from "../../../services/auth.service";
import {ActivatedRoute} from "@angular/router";
import {take, takeUntil} from "rxjs/operators";
import {BasicPermissionsService} from "../../../services/basic-permissions.service";
import {IBasicPermissionsModel} from "../../../shared/models/basic-permissions.model";
import {IBasicPermissionApplicationModel} from "../../../shared/models/basic-permission-application.model";
import {IAlertTypesModel} from "../../../shared/models/alert-types.model";
import {AlertService} from "../../../services/alert.service";
import {IPermissionSaveModel} from "../../../shared/models/permission-save.model";
import {Access, PermissionUIMasks} from "../../../shared/constants/enums";
import {AlertConstants} from "../../../shared/constants/alert.constants";
import {IUserGroupsModel} from "../../../shared/models/user-group.model";
import {PermissionService} from "../../../services/permission.service";

@Component({
  selector: 'app-manage-role',
  templateUrl: './manage-role.component.html',
  styleUrls: ['./manage-role.component.scss']
})
export class ManageRoleComponent implements OnInit {
  form?: UntypedFormGroup;
  airportsForm?: UntypedFormGroup;
  isBusy = false;
  user?: IUser;
  userRoleObject = UserRoles;
  airports?: IAirport[];
  airportsKV?: Record<string, IAirport> = {};
  roleId: number;
  userGroupsKV: Record<number, IUserGroupsModel> = {};
  basicPermissions: IBasicPermissionsModel[] = [];
  permissionApplications: IBasicPermissionApplicationModel[] = [];
  inheritedAirport: boolean;
  allAirports: boolean;

  basicPermissionsForSelectedFlights: IBasicPermissionsModel[] = [];
  basicPermissionApplicationsForSelectedFlights: IBasicPermissionApplicationModel[] = [];
  alertTypes: IAlertTypesModel[];
  selectedAirports: number[] = [];
  unsubscribe$ = new Subject();
  readAllFlights: boolean = false;
  writeAllFlights: boolean = false;
  readAllAlerts: boolean = false;
  writeAllAlerts: boolean = false;
  readAllReportsCockpit: boolean = false;
  readAllReportsList: boolean = false;
  readAllMessageCreation: boolean = false;
  writeAllMessageCreation: boolean = false;
  readAllSettings: boolean = false;
  writeAllSettings: boolean = false;
  readAllMobile: boolean = false;
  writeAllMobile: boolean = false;
  warningText: string;
  pageAccess: Access;

  constructor(private toastService: ToastService, private userService: UserService, private countriesService: CountriesService, private airportService: AirportsService, private authService: AuthService, private route: ActivatedRoute, private basicPermissionsService: BasicPermissionsService, private alertService: AlertService, private fb: UntypedFormBuilder, private permissionService: PermissionService) {
    const params = (this.route.params as BehaviorSubject<any>).value.id;
    if (params) {
      const paramsMap = params.split('_');
      this.roleId = paramsMap[0];
      if (paramsMap[1]) {
        for (const airport of paramsMap[1].split('|')) {
          this.selectedAirports.push(Number(airport));
        }
        if (this.selectedAirports.includes(0)) {
          this.inheritedAirport = true;
        }
        if (this.selectedAirports.includes(-1)) {
          this.allAirports = true;
        }
      }
    }
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_ROLE_MANAGEMENT).then((pageAccess) => {
      this.pageAccess = pageAccess;

      if (!this.pageAccess) {
        return;
      }
      if (this.roleId <= 6) {
        this.pageAccess = Access.R;
      }
      const observables = [this.authService.userSubject.asObservable().pipe(filter((val) => !!val), take(1)), this.airportService.fetchAirports(), this.alertService.getAlertTypes()];
      if (this.roleId) {
        observables.push(this.basicPermissionsService.fetchBasicPermissions({
          userGroupId: this.roleId,
          isActive: true,
        }));
        observables.push(this.basicPermissionsService.fetchBasicPermissionsApplication({
          userGroupId: this.roleId,
          isActive: true,
        }));
        observables.push(userService.fetchUserGroups());
      }
      forkJoin(observables).subscribe(([user, airports, alertTypes, permissions, permissionApplications, userGroups]) => {
        this.user = user;
        this.airports = airports;
        for (const airport of airports) {
          this.airportsKV[airport.iata] = airport;
        }
        this.basicPermissions = permissions;
        const alertTypesToIgnore: number[] = [AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_ATA, AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_TOT, AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_LND, AlertConstants.STR_TO_ID.ASSIGN_RAMP_AGENT_CRITICAL]
        const modifiedAlertTypes: IAlertTypesModel[] = (alertTypes as IAlertTypesModel[]).filter((type) => !alertTypesToIgnore.includes(type.id));
        modifiedAlertTypes.find((alert) => alert.id === AlertConstants.STR_TO_ID.MVT_ACARS_DIFFERENCE_IN_ATD).description = "Time Difference between MVT and ACARS of same event times";
        this.alertTypes = modifiedAlertTypes;
        this.permissionApplications = permissionApplications;
        if (this.selectedAirports?.length) {
          this.basicPermissionApplicationsForSelectedFlights = this.permissionApplications.filter((perm) => {
            if (this.selectedAirports.includes(-1)) {
              return perm.airportId === null;
            }
            return this.selectedAirports.includes(perm.airportId)
          });
          const basicPermissionIds = this.basicPermissionApplicationsForSelectedFlights.map((perm) => perm.basicPermissionId);
          this.basicPermissionsForSelectedFlights = this.basicPermissions.filter((p) => basicPermissionIds.includes(p.id));
        }
        if (userGroups) {
          for (const userGroup of userGroups) {
            this.userGroupsKV[userGroup.id] = userGroup;
          }
        }
        this.createForm();
        this.createAirportForm();
      });
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  createForm() {
    this.form = this.fb.group({});
    for (const mask in PermissionUIMasks) {
      let controls: { read: boolean, write: boolean } = {read: true, write: true};
      switch (mask as PermissionUIMasks) {
        case PermissionUIMasks.WEB_FLIGHTS_ISSUE_ETD:
        case PermissionUIMasks.WEB_FLIGHTS_ISSUE_NI:
        case PermissionUIMasks.WEB_MESSAGE_CREATION_ASM:
        case PermissionUIMasks.WEB_MESSAGE_CREATION_MVT:
        case PermissionUIMasks.WEB_SETTINGS_FLIGHTS_SCHEDULE_MANAGEMENT:
        case PermissionUIMasks.MOBILE_START_TURNAROUND:
          controls.read = false;
          break;
        case PermissionUIMasks.WEB_REPORTS_OPERATIONS:
        case PermissionUIMasks.WEB_REPORTS_PUNCTUALITY:
        case PermissionUIMasks.WEB_REPORTS_PASSENGERS:
        case PermissionUIMasks.WEB_REPORTS_UTILIZATION:
        case PermissionUIMasks.WEB_REPORTS_FLIGHT_DETAILS:
        case PermissionUIMasks.WEB_REPORTS_FLIGHT_PAIRINGS:
        case PermissionUIMasks.WEB_REPORTS_USERS_REPORT:
        case PermissionUIMasks.WEB_REPORTS_GSE_UTILIZATION:
        case PermissionUIMasks.WEB_REPORTS_LIST_PROCESSES:
        case PermissionUIMasks.WEB_REPORTS_FLIGHTS_MEMOS:
        case PermissionUIMasks.WEB_REPORTS_TURNAROUND_GROUND_DIFFERENCES:
        case PermissionUIMasks.WEB_MESSAGE_CREATION_LIST:
        case PermissionUIMasks.MOBILE_FLIGHT_INFO:
          controls.write = false;
          break;
      }
      this.form.addControl(mask, this.makeReadWriteFormGroup(controls));
      if (controls.read) {
        this.form.get(mask).get('read')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((evt) => this.readSwitchChanged(evt, this.form.get(mask) as UntypedFormGroup));
      }
      if (controls.write) {
        this.form.get(mask).get('write')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((evt) => this.writeSwitchChanged(evt, this.form.get(mask) as UntypedFormGroup));
      }

    }
    if (this.roleId) {
      this.patchForm();
    }
    if (this.pageAccess === Access.R) {
      this.form.disable();
      return;
    }
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.onFormChanged());
    this.onFormChanged();
  }

  writeSwitchChanged(newValue: boolean, form: UntypedFormGroup) {
    if (!form.get('read')) {
      return;
    }
    if (newValue && !form.value.read) {
      form.patchValue({
        read: true
      });
    }
  }

  readSwitchChanged(newValue: boolean, form: UntypedFormGroup) {
    if (!form.get('write')) {
      return;
    }
    if (!newValue && form.value.write) {
      form.patchValue({
        write: false
      });
    }
  }

  patchForm() {
    if (!this.form) {
      return;
    }

    this.form.enable({onlySelf: false, emitEvent: true})
    for (const data of this.basicPermissionsForSelectedFlights) {
      if (data.access) {
        this.form.get(data.uiMask).patchValue({
          read: true,
          write: data.access.includes('W')
        });
      }
    }
    let newReadAllFlights: boolean = true;
    let newWriteAllFlights: boolean = true;
    let newReadAllAlerts: boolean = true;
    let newWriteAllAlerts: boolean = true;
    let newReadAllReportsCockpit: boolean = true;
    let newReadAllReportsList: boolean = true;
    let newReadAllMessageCreation: boolean = true;
    let newWriteAllMessageCreation: boolean = true;
    let newReadAllSettings: boolean = true;
    let newWriteAllSettings: boolean = true;
    let newReadAllMobile: boolean = true;
    let newWriteAllMobile: boolean = true;
    for (const key in this.form.controls) {
      if (this.form.get(key).get('read') && !this.form.value[key].read) {
        if (key.startsWith('WEB_FLIGHTS_')) {
          newReadAllFlights = false;
        } else if (key.startsWith('WEB_ALERTS_')) {
          newReadAllAlerts = false;
        } else if (key === 'WEB_REPORTS_OPERATIONS' ||
          key === 'WEB_REPORTS_PUNCTUALITY' ||
          key === 'WEB_REPORTS_PASSENGERS' ||
          key === 'WEB_REPORTS_UTILIZATION') {
          newReadAllReportsCockpit = false;
        } else if (key.startsWith('WEB_REPORTS')) {
          newReadAllReportsList = false;
        } else if (key.startsWith('WEB_SETTINGS')) {
          newReadAllSettings = false;
        } else if (key.startsWith('WEB_MESSAGE_CREATION')) {
          newReadAllMessageCreation = false;
        } else if (key.startsWith('MOBILE_')) {
          newReadAllMobile = false;
        }
      }
      if (this.form.get(key).get('write') && !this.form.value[key].write) {
        if (key.startsWith('WEB_FLIGHTS_')) {
          newWriteAllFlights = false;
        } else if (key.startsWith('WEB_ALERTS_')) {
          newWriteAllAlerts = false;
        } else if (key.startsWith('WEB_SETTINGS')) {
          newWriteAllSettings = false;
        } else if (key.startsWith('WEB_MESSAGE_CREATION')) {
          newWriteAllMessageCreation = false;
        } else if (key.startsWith('MOBILE_')) {
          newWriteAllMobile = false;
        }
      }
    }

    this.readAllFlights = newReadAllFlights;
    this.writeAllFlights = newWriteAllFlights;
    this.readAllAlerts = newReadAllAlerts;
    this.writeAllAlerts = newWriteAllAlerts;
    this.readAllReportsCockpit = newReadAllReportsCockpit;
    this.readAllReportsList = newReadAllReportsList;
    this.readAllMessageCreation = newReadAllMessageCreation;
    this.writeAllMessageCreation = newWriteAllMessageCreation;
    this.readAllSettings = newReadAllSettings;
    this.writeAllSettings = newWriteAllSettings;
    this.readAllMobile = newReadAllMobile;
    this.writeAllMobile = newWriteAllMobile;
  }

  ngOnInit(): void {

  }

  onSaveClick() {
    if (!this.form) {
      return;
    }
    const airportIds: number[] = [];
    if (!this.allAirports) {
      for (const iata in this.airportsForm.value) {
        if (this.airportsForm.value[iata]) {
          airportIds.push(this.airports.find((airport) => airport.iata === iata)?.id);
        }
      }
      if (this.inheritedAirport) {
        airportIds.push(0);
      }
    } else {
      airportIds.push(-1);
    }
    if (!airportIds?.length) {
      this.toastService.showWarning('No airports selected. Save aborted');
      return;
    }
    this.isBusy = true;
    this.warningText = null;
    const data: IPermissionSaveModel = {
      airports: airportIds,
      userGroupId: this.roleId,
      uiMasks: {},
    };
    for (const key in this.form.controls) {
      const val = this.form.controls[key].value as { read: boolean, write: boolean };
      if (!val.write && !val.read) {
        continue;
      }
      data.uiMasks[key] = val.write ? Access.RW : Access.R;
    }
    this.basicPermissionsService.savePermissions(data).subscribe((res) => {
      this.isBusy = false;
      if (!res) {
        return;
      }
      this.toastService.showSuccess('Permissions have been saved');
    });
  }

  selectAllAirports() {
    // this.selectedAirports = this.airports.map((airport) => airport.id);
    // this.selectedAirportsChanged(null);
    if (this.pageAccess === Access.R) {
      return;
    }
    for (const control in this.airportsForm.controls) {
      this.airportsForm.controls[control].setValue(true);
    }
  }

  selectAllPermissions() {
    if (this.pageAccess !== Access.RW) {
      return;
    }
    const newVal = {...this.form.getRawValue()};
    this.setValueToTrue(newVal);
    this.form.patchValue(newVal);
    for (let key of Object.keys(this)) {
      if (key.startsWith('readAll') || key.startsWith('writeAll')) {
        this[key] = true;
      }
    }
  }

  setValueToTrue(obj: Record<any, any>) {
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        this.setValueToTrue(obj[key]);
      } else {
        obj[key] = true;
      }
    }
  }

  onFormChanged() {
    const value = this.form.value;
    if (!value[PermissionUIMasks.WEB_FLIGHTS_DEPARTING_INFORMATION].write) {
      const switches = this.getWriteSwitchesForMasks([PermissionUIMasks.WEB_FLIGHTS_ISSUE_ETD, PermissionUIMasks.WEB_FLIGHTS_ISSUE_NI]);
      for (const switchCtrl of switches) {
        this.disableSwitchAndSetFalse(switchCtrl);
      }
    } else {
      const switches = this.getWriteSwitchesForMasks([PermissionUIMasks.WEB_FLIGHTS_ISSUE_ETD, PermissionUIMasks.WEB_FLIGHTS_ISSUE_NI]);
      for (const switchCtrl of switches) {
        switchCtrl.enable({emitEvent: false});
      }

    }
    const issueEtdRelatedSwitches = this.getWriteSwitchesForMasks([PermissionUIMasks.WEB_ALERTS_UNPAIRED_SECTOR,
      PermissionUIMasks.WEB_ALERTS_INADEQUATE_TURNAROUND_TIME,
      PermissionUIMasks.WEB_ALERTS_REQUEST_FOR_EXTENSION,
      PermissionUIMasks.WEB_ALERTS_OVERLAPPING_SECTORS,
      PermissionUIMasks.WEB_ALERTS_CTOT_VIOLATION,
      PermissionUIMasks.WEB_ALERTS_EARLY_DEPARTURE,
      PermissionUIMasks.WEB_ALERTS_AIRCRAFT_DIVERSION,
      PermissionUIMasks.WEB_ALERTS_AIRCRAFT_RETURN_FROM_AIRBORNE,
      PermissionUIMasks.WEB_ALERTS_AIRCRAFT_RETURN_TO_RAMP]);
    if (!value[PermissionUIMasks.WEB_FLIGHTS_ISSUE_ETD]?.write) {
      for (const switchCtrl of issueEtdRelatedSwitches) {
        this.disableSwitchAndSetFalse(switchCtrl);
      }
    } else {
      for (const switchCtrl of issueEtdRelatedSwitches) {
        switchCtrl.enable({emitEvent: false});
      }
    }
    if (!value[PermissionUIMasks.WEB_FLIGHTS_ARRIVING_INFORMATION].write && !value[PermissionUIMasks.WEB_FLIGHTS_DEPARTING_INFORMATION].write) {
      this.disableSwitchAndSetFalse(this.form.get(PermissionUIMasks.WEB_ALERTS_NO_ACTUAL_TIMES_ON_SECTOR).get('write'));
    } else {
      this.form.get(PermissionUIMasks.WEB_ALERTS_NO_ACTUAL_TIMES_ON_SECTOR).get('write').enable({emitEvent: false});
    }

    if (!value[PermissionUIMasks.WEB_RAMP_AGENT_ASSIGNMENT].write) {
      this.disableSwitchAndSetFalse(this.form.get(PermissionUIMasks.WEB_ALERTS_ASSIGN_RAMP_AGENT).get('write'));
    } else {
      this.form.get(PermissionUIMasks.WEB_ALERTS_ASSIGN_RAMP_AGENT).get('write').enable({emitEvent: false});
    }
  }

  getWriteSwitchesForMasks(masks: PermissionUIMasks[]): AbstractControl[] {
    const switches: AbstractControl[] = [];
    for (const mask of masks) {
      switches.push(this.form.get(mask).get('write'));
    }
    return switches;
  }

  disableSwitchAndSetFalse(switchControl: AbstractControl) {
    switchControl.disable({
      emitEvent: false,
      onlySelf: true
    });
    switchControl.setValue(false, {emitEvent: false});
  }

  makeReadWriteFormGroup(value = {read: true, write: true}) {
    const group = this.fb.group({});
    if (value?.write) {
      group.addControl('write', this.fb.control(null));
    }
    if (value?.read) {
      group.addControl('read', this.fb.control(null));
    }
    return group;
  }

  selectedAirportsChanged(evt: Event) {
    if (evt) {
      const airport = evt.target as HTMLInputElement;
      if (!airport.checked) {
        return;
      }
    }
    const selectedAirports: string[] = [];
    for (const key in this.airportsForm.value) {
      if (this.airportsForm.value[key]) {
        if (this.selectedAirports.includes(this.airportsKV[key].id)) {
          continue;
        }
        selectedAirports.push(key);
      }
    }
    const airportsWithConflict: string[] = [];
    for (const airport of selectedAirports) {
      if (this.permissionApplications.findIndex((p) => p.airportId === this.airportsKV[airport]?.id) !== -1) {
        airportsWithConflict.push(airport);
      }
    }
    if (airportsWithConflict?.length) {
      this.warningText = 'Airports ' + airportsWithConflict.join(', ') + ' already have some rules defined. If you save now, those rules will be overwritten by the current rules';
    } else {
      this.warningText = null;
    }

  }

  formGroupNameFromAlertType(alertType: IAlertTypesModel): string {
    return 'WEB_ALERTS_' + alertType.title;
  }

  readAllFlightsChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask.includes('WEB_FLIGHTS_')) {
        this.form.get(mask).get('read')?.setValue(input.checked);
        if (!input.checked) {
          this.form.get(mask).get('write')?.setValue(false);
          this.writeAllFlights = false;
        }
      }
    }
  }

  writeAllFlightsChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask.includes('WEB_FLIGHTS_')) {
        this.form.get(mask).get('write')?.setValue(input.checked);
        if (input.checked) {
          this.form.get(mask).get('read')?.setValue(true);
          this.readAllFlights = true;
        }
      }
    }
  }

  readAllAlertsChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask.includes('WEB_ALERTS_')) {
        this.form.get(mask).get('read')?.setValue(input.checked);
        if (!input.checked) {
          this.form.get(mask).get('write')?.setValue(false);
          this.writeAllAlerts = false;
        }
      }
    }
  }

  writeAllAlertsChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask.includes('WEB_ALERTS_')) {
        this.form.get(mask).get('write')?.setValue(input.checked);
        if (input.checked) {
          this.form.get(mask).get('read')?.setValue(true);
          this.readAllAlerts = true;
        }
      }
    }
  }

  readAllMessageCreationChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask.includes('WEB_MESSAGE_CREATION')) {
        this.form.get(mask).get('read')?.setValue(input.checked);
      }
    }
  }

  writeAllMessageCreationChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask.includes('WEB_MESSAGE_CREATION')) {
        this.form.get(mask).get('write')?.setValue(input.checked);
      }
    }
  }

  readAllReportsCockpitChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask === 'WEB_REPORTS_OPERATIONS' || mask === 'WEB_REPORTS_PUNCTUALITY' || mask === 'WEB_REPORTS_PASSENGERS' || mask === 'WEB_REPORTS_UTILIZATION') {
        this.form.get(mask).get('read')?.setValue(input.checked);
      }
    }
  }

  readAllReportsListChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask.includes('WEB_REPORTS') && !(mask === 'WEB_REPORTS_OPERATIONS' || mask === 'WEB_REPORTS_PUNCTUALITY' || mask === 'WEB_REPORTS_PASSENGERS' || mask === 'WEB_REPORTS_UTILIZATION')) {
        this.form.get(mask).get('read')?.setValue(input.checked);
      }
    }
  }

  readAllSettingsChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask.includes('WEB_SETTINGS_')) {
        this.form.get(mask).get('read')?.setValue(input.checked);
        if (!input.checked) {
          this.form.get(mask).get('write')?.setValue(false);
          this.writeAllSettings = false;
        }
      }
    }
  }

  writeAllSettingsChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask.includes('WEB_SETTINGS_')) {
        this.form.get(mask).get('write')?.setValue(input.checked);
        if (input.checked) {
          this.form.get(mask).get('read')?.setValue(true);
          this.readAllSettings = true;
        }
      }
    }
  }

  readAllMobileChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask.includes('MOBILE_')) {
        this.form.get(mask).get('read')?.setValue(input.checked);
        if (!input.checked) {
          this.form.get(mask).get('write')?.setValue(false);
          this.writeAllMobile = false;
        }
      }
    }
  }

  writeAllMobileChanged(evt: Event) {
    const input: HTMLInputElement = evt.target as HTMLInputElement;
    for (const mask in PermissionUIMasks) {
      if (mask.includes('MOBILE_')) {
        this.form.get(mask).get('write')?.setValue(input.checked);
        if (input.checked) {
          this.form.get(mask).get('read')?.setValue(true);
          this.readAllMobile = true;
        }
      }
    }
  }

  private createAirportForm() {
    this.airportsForm = new UntypedFormGroup({});
    for (const airport of this.airports) {
      this.airportsForm.addControl(airport.iata, new UntypedFormControl({
        value: this.selectedAirports?.includes(airport.id),
        disabled: this.pageAccess === Access.R
      }));
    }
    if (this.allAirports || this.inheritedAirport) {
      this.airportsForm.disable();
    }
  }

  public readonly Access = Access;

  allAirportsChanged() {
    if (this.allAirports) {
      this.airportsForm.disable();
      return;
    }
    this.airportsForm.enable();
  }

  inheritedAirportChanged() {
    if (this.inheritedAirport) {
      this.airportsForm.disable();
      return;
    }
    this.airportsForm.enable();
  }
}
