import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAirportTerminals } from '../../../../../shared/models/airport-terminals.model';
import { UntypedFormGroup } from '@angular/forms';
import { AirportsService } from '../../../../../services/airports.service';
import { ToastService } from '../../../../../services/toast.service';
import { take } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../../../../shared/components/delete-modal/delete-modal.component';
import { AddEditAirportTerminalDialogComponent } from '../add-edit-airport-terminal-dialog/add-edit-airport-terminal-dialog.component';

@Component({
  selector: 'app-manage-airport-terminal-dialog',
  templateUrl: './manage-airport-terminal-dialog.component.html',
  styleUrls: ['./manage-airport-terminal-dialog.component.scss']
})
export class ManageAirportTerminalDialogComponent implements OnInit {

  title = "Manage Airport Terminals"
  @Input() data?: { [p: number]: IAirportTerminals };
  @Input() dataForm?: UntypedFormGroup;
  @Input() airportId?: number;
  @Input() readonly = true;
  @Output() nonClosingSaveAction = new EventEmitter();
  @Output() closeWindow = new EventEmitter();
  selected?: string | any;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private airportsService: AirportsService, private toastService: ToastService) { }

  ngOnInit() {
    this.fetchTerminals();
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  fetchTerminals() {
    this.airportsService.fetchAirportTerminals(this.airportId).subscribe((result) => {
      const data = {};
      for (const item of result) {
        data[item.id] = item;
      }
      this.data = data;
    });
  }

  doAdd() {
    const modalRef = this.modalService.open(AddEditAirportTerminalDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'Add Airport Terminal';
    modalRef.componentInstance.terminalData = {
      airportId: this.airportId,
    };
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchTerminals();
    })
  }

  doEdit(item: number) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(AddEditAirportTerminalDialogComponent, { size: 'lg'});
    modalRef.componentInstance.title = 'Edit Airport Terminal';
    modalRef.componentInstance.terminalData = this.data[item];
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchTerminals();
    })
  }

  doDelete(item: number) {
    if (!item) {
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Airport Terminal ' + item;

    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.airportsService.deleteAirportTerminal(item).subscribe((result) => {
        if(result) {
          this.toastService.showSuccess("The terminal has been deleted.");
          this.fetchTerminals();
        }
      });
    });
  }
}
