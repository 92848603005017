import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  PipeTransform,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import {PropNameDirective} from '../../directives/prop-name.directive';
import {SortDirection, SortEvent} from '../../constants/table-sort.types';
import {NgbdSortableHeader} from '../../directives/sortable-table-header.directive';

@Component({
  selector: 'app-settings-data-table',
  templateUrl: './settings-data-table.component.html',
  styleUrls: ['./settings-data-table.component.scss'],
})
export class SettingsDataTableComponent implements OnInit, OnChanges {
  @ContentChildren(PropNameDirective) templates: QueryList<PropNameDirective>;
  @Input() columns: string[] = [];
  @Input() sortableColumns: { [columnName: string]: string } = {};
  @Input() variableNames: string[] = [];
  @Input() data: any[];
  @Input() emitProperty: string = 'id';
  @Input() canEdit = true;
  @Input() showEdit = true;
  @Input() canDelete: boolean = true;
  @Input() showDelete: boolean = true;
  @Input() showView: boolean = false;
  @Input() canEditPropertyCheck?: string;
  @Input() initialSort: { [columnName: string]: SortDirection };
  @Input() columnTooltips: { [columnName: string]: string };
  @Output() onEditClicked = new EventEmitter<number>();
  @Output() onDeleteClicked = new EventEmitter<number>();
  @Output() onViewClicked = new EventEmitter<number>();
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @Input() maxLength: number = 25;
  @Input() newItemsPerPage: number = 25;
  @Input() testTagPipe: PipeTransform;
  @Input() isEditable: (obj: any) => boolean;
  @Input() isDeletable: (obj: any) => boolean;
  @Input() customCSS?: string;
  @Input() viewOnlyMode = false;
  @Input() viewModePerRow?: boolean;
  @Input() viewModeCheck?: (obj: any) => boolean;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    if (this.customCSS) {
      const styleElement = this.renderer.createElement('style');
      styleElement.innerHTML = this.customCSS;
      this.renderer.appendChild(this.elRef.nativeElement, styleElement);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.newItemsPerPage && !changes.newItemsPerPage.firstChange) {
      if (this.maxLength < changes.newItemsPerPage.currentValue) {
        this.maxLength = changes.newItemsPerPage.currentValue;
      }
    }
  }

  getTemplate(propName: string): TemplateRef<any> | null {
    const item = this.templates.find(tmpl => tmpl.propName === propName);
    return item ? item.tmpl : null;
  }

  showMore() {
    this.maxLength += this.newItemsPerPage;
  }

  trackByFn(index: number) {
    return index;
  }

  emitDeleteClicked(dataElement: any) {
    if (!this.canDelete || this.isDeletable && !this.isDeletable(dataElement)) {
      return;
    }
    this.onDeleteClicked.emit(dataElement[this.emitProperty]);
  }

  emitEditClicked(dataElement: any) {
    if (!this.canEdit || this.isEditable && !this.isEditable(dataElement) && (!this.viewOnlyMode && !this.viewModeCheck(dataElement)) || this.canEditPropertyCheck && !dataElement[this.canEditPropertyCheck] || this.isEditable && !this.isEditable(dataElement) && this.viewModePerRow && !this.viewModeCheck(dataElement)) {
      return;
    }
    this.onEditClicked.emit(dataElement[this.emitProperty]);
  }

  compare(v1: string | number, v2: string | number): number {
    return (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (!this.data) {
      return direction === 'asc' ? 1 : -1;
    }
    if (direction === '' || column === '') {
      this.data.sort((a, b) => a?.id > b?.id ? 1 : -1);
    } else {
      this.data.sort((a, b) => {
        const res = this.compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  emitViewClicked(dataElement: any) {
    if (!this.showView) {
      return;
    }
    this.onViewClicked.emit(dataElement[this.emitProperty]);
  }
}

