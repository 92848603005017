import {Component, OnInit} from '@angular/core';
import {TurnaroundType} from '../../../shared/constants/turnaround-type.constants';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  SettingsGeneralMainFormBuilder
} from '../../../shared/forms/formBuilders/settings/settings-general-main-form-builder';
import {GeneralSettingsService} from '../../../services/general-settings.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TimeFromMinutesPipe} from '../../../shared/pipes/time-from-minutes.pipe';
import {MinutesFromTimePipe} from '../../../shared/pipes/minutes-from-time.pipe';
import {ToastService} from '../../../services/toast.service';
import {IGeneralSettingsModel} from '../../../shared/models/general-settings.model';
import {IAirlineDesignator} from '../../../shared/models/airline-designator.model';
import {
  AddEditAirlineDesignatorsDialogComponent
} from '../settings-general-settings/dialogs/add-edit-airline-designators-dialog/add-edit-airline-designators-dialog.component';
import {DeleteModalComponent} from '../../../shared/components/delete-modal/delete-modal.component';
import {ISimpleData} from '../../../shared/models/simpleData.model';
import {take} from 'rxjs/operators';
import {
  AddEditPassengerClassesDialogComponent
} from '../settings-general-settings/dialogs/add-edit-passenger-classes-dialog/add-edit-passenger-classes-dialog.component';
import {
  AddEditFlightServiceTypesDialogComponent
} from '../settings-general-settings/dialogs/add-edit-flight-services-types-dialog/add-edit-flight-service-types-dialog.component';
import {
  AddEditDelayCodeDialogComponent
} from '../settings-general-settings/dialogs/add-edit-delay-code-dialog/add-edit-delay-code-dialog.component';
import {
  AddEditMeasurementUnitsDialogComponent
} from '../settings-general-settings/dialogs/add-edit-measurement-units-dialog/add-edit-measurement-units-dialog.component';
import {forkJoin} from 'rxjs';
import {IDelayCode} from '../../../shared/models/delay-code.model';
import {AirlineDesignatorTestTagPipe} from '../../../shared/pipes/airline-designator-test-tag.pipe';
import {PassengerClassesTestTagPipe} from '../../../shared/pipes/passenger-classes-test-tag.pipe';
import {FlightServiceTypesTestTagPipe} from '../../../shared/pipes/flight-service-types-test-tag.pipe';
import {DelayCodesTestTagPipe} from '../../../shared/pipes/delay-codes-test-tag.pipe';
import {MeasurementUnitsTestTagPipe} from '../../../shared/pipes/measurement-units-test-tag.pipe';
import {
  AddEditPassengerCategoriesDialogComponent
} from '../settings-general-settings/dialogs/add-edit-passenger-categories-dialog/add-edit-passenger-categories-dialog.component';
import {PermissionService} from "../../../services/permission.service";
import {Access, PermissionUIMasks} from "../../../shared/constants/enums";

@Component({
  selector: 'app-settings-general-settings-new',
  templateUrl: './settings-general-settings-new.component.html',
  styleUrls: ['./settings-general-settings-new.component.scss']
})
export class SettingsGeneralSettingsNewComponent implements OnInit {
  turnaroundTypes = TurnaroundType;
  form?: UntypedFormGroup;
  isBusy = false;
  dateFormat: string;
  delayCodePageSize = 25;
  data: IGeneralSettingsModel;
  airlineDesignators?: IAirlineDesignator[] = [];
  passengerClasses?: ISimpleData[] = [];
  flightServiceTypes?: ISimpleData[] = [];
  delayCodes?: IDelayCode[] = [];
  measurementUnits?: ISimpleData[] = [];
  passengerCategories?: ISimpleData[] = [];
  pageAccess: Access;

  constructor(private generalSettingsService: GeneralSettingsService, private modalService: NgbModal, private fb: UntypedFormBuilder,
              private timeFromMinutesPipe: TimeFromMinutesPipe, private minutesFromTimePipe: MinutesFromTimePipe,
              private toastService: ToastService, private permissionService: PermissionService) {
  }

  ngOnInit(): void {
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_GENERAL_SETTINGS).then((pageAccess) => {
      this.pageAccess = pageAccess;
      if (!this.pageAccess) {
        return;
      }
      forkJoin([this.generalSettingsService.getGeneralSettings(), this.generalSettingsService.getMeasurementUnits(), this.generalSettingsService.getDelayCodes(), this.generalSettingsService.getFlightServiceTypes(), this.generalSettingsService.getPassengerClasses(), this.generalSettingsService.getAirlineDesignators(), this.generalSettingsService.getPassengerCategories()]).subscribe((results) => {
        const settings = results[0]?.[0];
        this.dateFormat = settings?.dateFormat;
        this.data = {...settings};
        const passengerTime = settings.minimumPassengerConnectionTimeInMinutes;
        const threshold = settings.turnaroundThresholdInMinutes;

        this.measurementUnits = results[1];
        this.delayCodes = results[2];
        this.flightServiceTypes = results[3];
        this.passengerClasses = results[4];
        this.airlineDesignators = results[5];
        this.passengerCategories = results[6];

        this.constructForm();
        this.form?.patchValue({
          ...settings,
          minimumPassengerConnectionTimeInMinutes: undefined,
          turnaroundThreshold: undefined
        });
        this.form?.get('minimumPassengerConnectionTimeInMinutes').setValue(this.timeFromMinutesPipe.transform(passengerTime));
        this.form?.get('turnaroundThreshold').setValue(this.timeFromMinutesPipe.transform(threshold));
      });
    });

  }

  constructForm() {
    this.form = SettingsGeneralMainFormBuilder.constructForm(
      this.fb,
      this.dateFormat,
    );
    if (this.pageAccess !== Access.RW) {
      this.form.disable();
    }
  }


  save() {
    if (this.form.invalid) {
      return;
    }
    this.isBusy = true;
    this.generalSettingsService.saveGeneralSettings({
      ...this.data,
      airlineMainBase: this.form.get('airlineMainBase').value,
      dateFormat: this.form.get('dateFormat').value,
      turnaroundTimeType: this.form.get('turnaroundTimeType').value,
      turnaroundThresholdInMinutes: this.minutesFromTimePipe.transform(this.form.get('turnaroundThreshold').value),
      minimumPassengerConnectionTimeInMinutes: this.minutesFromTimePipe.transform(this.form.get('minimumPassengerConnectionTimeInMinutes').value),
    }).subscribe((result) => {
      if (result?.id) {
        this.toastService.showSuccess("General settings have been saved");
      }
      this.isBusy = false;
    });
  }

  openManagePassengerCategories(categoryId?: number) {
    const modalRef = this.modalService.open(AddEditPassengerCategoriesDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as AddEditPassengerCategoriesDialogComponent;
    if (categoryId) {
      modal.passengerCategory = this.passengerCategories.find((pClass) => pClass.id === categoryId);
    }
    modalRef.dismissed.pipe(take(1)).subscribe((reason) => {
      if (reason === true) {
        this.generalSettingsService.getPassengerCategories().subscribe((results) => {
          this.passengerCategories = results;
        });
      }
    });
  }

  deletePassengerCategory(categoryId?: number) {
    const modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as DeleteModalComponent;
    modal.term = 'passenger category';
    modal.startDeletion.subscribe(() => {
      this.generalSettingsService.deletePassengerCategory(categoryId).subscribe(() => {
        this.toastService.showSuccess('Passenger category has been deleted');
        this.generalSettingsService.getPassengerCategories().subscribe((results) => {
          this.passengerCategories = results;
        });
      });
    });
  }

  openManageAirlineCodes(airlineDesignatorId?: number) {
    const modalRef = this.modalService.open(AddEditAirlineDesignatorsDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as AddEditAirlineDesignatorsDialogComponent;
    if (airlineDesignatorId) {
      modal.airlineDesignator = this.airlineDesignators.find((des) => des.id === airlineDesignatorId);
    }
    modalRef.componentInstance.title = 'Manage Airline Codes';
    modalRef.componentInstance.readonly = false;
    modalRef.dismissed.subscribe((reason) => {
      if (reason === true) {
        this.generalSettingsService.getAirlineDesignators().subscribe((results) => {
          this.airlineDesignators = results;
        });
      }
    })
  }

  deleteAirlineCode(airlineDesignatorId: number) {
    const modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as DeleteModalComponent;
    modal.term = 'airline code';
    modal.startDeletion.subscribe((reason) => {
      this.generalSettingsService.deleteAirlineDesignator(airlineDesignatorId).subscribe(() => {
        this.generalSettingsService.getAirlineDesignators().subscribe((results) => {
          this.airlineDesignators = results;
        });
      });
    });
  }

  deleteDelayCode(delayCodeId: number) {
    const modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as DeleteModalComponent;
    modal.term = 'delay code';
    modal.startDeletion.subscribe((reason) => {
      this.generalSettingsService.deleteDelayCode(delayCodeId).subscribe(() => {
        this.generalSettingsService.getDelayCodes().subscribe((results) => {
          this.delayCodes = results;
        });
      });
    });
  }

  openManagePassengerClasses(passengerClassId?: number) {
    const modalRef = this.modalService.open(AddEditPassengerClassesDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as AddEditPassengerClassesDialogComponent;
    if (passengerClassId) {
      modal.passengerClass = this.passengerClasses.find((pClass) => pClass.id === passengerClassId);
    }
    modalRef.dismissed.pipe(take(1)).subscribe((reason) => {
      if (reason === true) {
        this.generalSettingsService.getPassengerClasses().subscribe((results) => {
          this.passengerClasses = results;
        });
      }
    });
  }

  deletePassengerClass(passengerClassId?: number) {
    const modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as DeleteModalComponent;
    modal.term = 'passenger class';
    modal.startDeletion.subscribe(() => {
      this.generalSettingsService.deletePassengerClass(passengerClassId).subscribe(() => {
        this.generalSettingsService.getPassengerClasses().subscribe((results) => {
          this.passengerClasses = results;
        });
      });
    });
  }

  deleteFlightServiceType(flightServiceTypeId?: number) {
    const modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as DeleteModalComponent;
    modal.term = 'flight service type';
    modal.startDeletion.subscribe(() => {
      this.generalSettingsService.deleteFlightServiceType(flightServiceTypeId).subscribe(() => {
        this.generalSettingsService.getFlightServiceTypes().subscribe((results) => {
          this.flightServiceTypes = results;
        });
      });
    });
  }

  openManageFlightServiceTypes(flightServiceTypeId?: number) {
    const modalRef = this.modalService.open(AddEditFlightServiceTypesDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as AddEditFlightServiceTypesDialogComponent;
    if (flightServiceTypeId) {
      modal.flightServiceType = this.flightServiceTypes.find((flightServiceType) => flightServiceType.id === flightServiceTypeId);
    }
    modalRef.dismissed.pipe(take(1)).subscribe((reason) => {
      if (reason === true) {
        this.generalSettingsService.getFlightServiceTypes().subscribe((results) => {
          this.flightServiceTypes = results;
        });
      }
    });
  }

  openManageDelayCodes(delayCodeId?: number) {
    const modalRef = this.modalService.open(AddEditDelayCodeDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as AddEditDelayCodeDialogComponent;
    modal.designators = this.airlineDesignators;
    if (delayCodeId) {
      modal.delayCode = this.delayCodes.find((delayCode) => delayCode.id === delayCodeId);
    }
    modalRef.dismissed.pipe(take(1)).subscribe((reason) => {
      if (reason === true) {
        this.generalSettingsService.getDelayCodes().subscribe((results) => {
          this.delayCodes = results;
        });
      }
    });
  }

  openMeasurementUnits(measurementUnitId?: number) {
    const modalRef = this.modalService.open(AddEditMeasurementUnitsDialogComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as AddEditMeasurementUnitsDialogComponent;
    if (measurementUnitId) {
      modal.measurementUnit = this.measurementUnits.find((des) => des.id === measurementUnitId);
    }
    modalRef.dismissed.subscribe((reason) => {
      if (reason === true) {
        this.generalSettingsService.getMeasurementUnits().subscribe((results) => {
          this.measurementUnits = results;
        });
      }
    })
  }

  deleteMeasureUnit(measurementUnitId: number) {
    const modalRef = this.modalService.open(DeleteModalComponent, {size: 'lg'});
    const modal = modalRef.componentInstance as DeleteModalComponent;
    modal.term = 'measurement unit';
    modal.startDeletion.subscribe((reason) => {
      this.generalSettingsService.deleteMeasurementUnit(measurementUnitId).subscribe(() => {
        this.generalSettingsService.getMeasurementUnits().subscribe((results) => {
          this.measurementUnits = results;
        });
      });
    });
  }

  public readonly AirlineDesignatorTestTagPipe = AirlineDesignatorTestTagPipe;
  public readonly PassengerClassesTestTagPipe = PassengerClassesTestTagPipe;
  public readonly FlightServiceTypesTestTagPipe = FlightServiceTypesTestTagPipe;
  public readonly DelayCodesTestTagPipe = DelayCodesTestTagPipe;
  public readonly MeasurementUnitsTestTagPipe = MeasurementUnitsTestTagPipe;
  public readonly Access = Access;
}
