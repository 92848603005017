import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast.service';
import { PtmService } from '../../../services/ptm.service';

@Component({
  selector: 'app-ptm-upload',
  templateUrl: './ptm-upload.component.html',
  styleUrls: ['./ptm-upload.component.scss']
})
export class PtmUploadComponent implements OnInit {

  file: File;
  fileContent: any;
  fileName: string;
  isBusy = false;


  constructor(private modalService: NgbModal, private fns: AngularFireFunctions, private ptmService: PtmService, private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    console.log(files.item(0));

    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      this.fileContent = fileReader.result;
      this.file = files.item(0);
    };

    if (files.item(0) != null && files.item(0).size > 0) {
      console.log('start read file');
      this.fileName = files[0].name;
      fileReader.readAsText(files.item(0));
    } else {
      console.log('file is empty');
    }
  }

callFunction() {
  this.isBusy = true;
  this.ptmService.uploadPtm({ fileContent: this.fileContent, fileName: this.fileName }).subscribe((res) => {
    this.isBusy = false;
    if (res) {
     this.toastService.showSuccess('PTM file has been processed successfully!');
    }
  });
}

}

