<div class="modal-body p-4 w-100 dark-blue">
  <div class="flex">
    <div class="flex flex-column gap-2 w-60" *ngIf="showContent">
      <strong class="fs-5">Current pair:</strong>
      <div class="mt-2 fs-5">{{ pairDetail?.arrivalLeg?.departureStation }} - {{ pairDetail?.arrivalLeg?.arrivalStation }} / {{ pairDetail?.departureLeg?.departureStation }} - {{ pairDetail?.departureLeg?.arrivalStation }} </div>
      <app-leg
        [flightPairKey]="pairOverviewModel?.id?.toString()"
        [pairOverview]="pairOverviewModel"
        [timezone]="timezone"
        [inEditPairMode]="true"
        [attr.data-test]="pairOverviewModel | pairTestAttribute"
      ></app-leg>
    </div>
    <div class="flex flex-column gap-2 w-40" *ngIf="showContent" data-test="issue-etd">
      <strong class="fs-5 issue-etd-text">Issue ETD for Flight {{ pairDetail.departureLeg?.airlineDesignator + pairDetail.departureLeg?.flightNumber }}</strong>
      <form [formGroup]="form" class="mt-4">
        <div class="container mt-4">
          <div class="row">
            <div class="col-12">
              <div class="container">
                <div class="row">
                  <div class="col-3 form-field-header">
                    New ETD*
                  </div>
                  <div class="col-5">
                    <div class="mb-3">
                      <div class="input-group">
                        <input class="form-control" [placeholder]="dateFormat"
                               name="dp" formControlName="etdDate" ngbDatepicker #d="ngbDatepicker" data-test="issue-etd-calendar-input">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar h-100" (click)="d.toggle()" type="button" data-test="issue-etd-delay-calendar-button"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 pl-fix">
                    <ngb-timepicker appTimepickerFocus name="newEtdTime" formControlName="newEtdTime" [spinners]="false" data-test="issue-etd-alert-etd-time-picker"></ngb-timepicker>
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="row mt-6">
                  <div class="col-3 form-field-header">
                    Delay Code*
                  </div>
                  <div class="col-5">
                    <div class="mb-3">
                      <div class="mb-3">
                        <ng-select formControlName="delayCode" data-test="delay-code-select" [inputAttrs]="{ 'data-test': 'delay-code-input' }">
                          <ng-option *ngFor="let delayCode of delayCodes" [value]="delayCode.id">{{delayCode.code}} {{ delayCode.description}}</ng-option>
                          <ng-template ng-option-tmp let-item="item">
                            <span class="ng-option-label" [attr.data-test]="'delay-code-' + item">{{ delayCodesKV?.[item]?.code }} {{ delayCodesKV?.[item]?.description }}</span>
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 pl-fix">
                    <div class="mb-3">
                      <input type="text" class="form-control" id="arrival-flight-passenger-gate" placeholder="" [value]="delayInMinutes | timeFromMinutes" disabled data-test="issue-etd-delay-minutes-input">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <ng-container *ngIf="alertService.alertTypes[alert.alertTypeId].title === alertTypes.REQUEST_FOR_EXTENSION; else casualButtons">
    <button type="button" class="btn btn-outline-danger settings-modal-button" (click)="declineEtdRequest()" *ngIf="alertService.alertTypes[alert.alertTypeId].title === alertTypes.REQUEST_FOR_EXTENSION" [disabled]="isAccepting || isDeclining" data-test="issue-etd-decline-button">
      {{ isDeclining ? '' : 'Decline request' }}
      <app-loading-spinner *ngIf="isDeclining"></app-loading-spinner>
    </button>
    <button type="button" class="btn btn-success settings-modal-button" (click)="saveClicked()" [disabled]="!form || form?.invalid || isAccepting || isDeclining" data-test="issue-etd-save-button-1">
      {{ isAccepting ? '' : 'Issue ETD' }}
      <app-loading-spinner *ngIf="isAccepting"></app-loading-spinner>
    </button>
  </ng-container>
  <ng-template #casualButtons>
    <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()" data-test="issue-etd-close-button">Close</button>
    <button type="button" class="btn button-blue settings-modal-button" (click)="saveClicked()" [disabled]="!form || form?.invalid || isAccepting" data-test="issue-etd-save-button-2">
      {{ isAccepting ? '' : 'Save' }}
      <app-loading-spinner *ngIf="isAccepting"></app-loading-spinner>
    </button>
  </ng-template>

</div>
