import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {GeneralSettingsService} from '../../../../../services/general-settings.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from '../../../../../services/toast.service';
import {UserRoles} from '../../../../../shared/constants/user-roles.constants';
import {IUser} from '../../../../../shared/models/user.model';
import {IGenericContainerObject} from '../../../../../shared/models/genericContainerObject.model';
import {IGlobalCountry} from '../../../../../shared/models/global-country.model';
import {UserService} from '../../../../../services/user.service';
import {debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, Subject} from 'rxjs';
import {IAirport} from '../../../../../shared/models/airport.model';
import {IAirlineDesignator} from '../../../../../shared/models/airline-designator.model';
import {CountriesService} from '../../../../../services/countries.service';
import {IUserGroupsModel} from "../../../../../shared/models/user-group.model";

@Component({
  selector: 'app-add-edit-user-dialog',
  templateUrl: './add-edit-user-dialog.component.html',
  styleUrls: ['./add-edit-user-dialog.component.scss']
})
export class AddEditUserDialogComponent implements OnInit, OnDestroy {

  form?: UntypedFormGroup;
  isBusy = false;
  title?: string;
  user?: IUser;
  countryList?: IGlobalCountry[];
  airportContainer?: IAirport[];
  airlineDesignators: IAirlineDesignator[];
  // Country with name as key
  countriesKV?: IGenericContainerObject<IGlobalCountry> = {};
  userRoleObject = UserRoles;
  locationListObject: { [key: string]: string } = {};
  userGroups: IUserGroupsModel[];
  userGroupsKV: Record<number, IUserGroupsModel> = {};
  readonly: boolean;

  constructor(private generalSettingsService: GeneralSettingsService, private activeModal: NgbActiveModal, private toastService: ToastService, private userService: UserService, private countriesService: CountriesService) {
    this.countriesService.fetchCountries().subscribe((countries) => {
      this.countryList = countries;
      for (const country of countries) {
        this.countriesKV[country.title] = country;
      }
    });
    this.userService.fetchUserGroups().subscribe((res) => {
      this.userGroups = res;
      for (const group of res) {
        this.userGroupsKV[group.id] = group;
      }
    })
  }

  unsubscribe$ = new Subject();

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      firstname: new UntypedFormControl(this.user?.firstname || '', Validators.required),
      lastname: new UntypedFormControl(this.user?.lastname || '', Validators.required),
      email: new UntypedFormControl(this.user?.email || '', [Validators.required, Validators.pattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
      username: new UntypedFormControl(this.user?.username || ''),
      location: new UntypedFormControl(this.user?.location || '', Validators.required),
      phone: new UntypedFormControl(this.user?.phone || '', Validators.pattern(/^\+[1-9]\d{1,14}$/)),
      airline: new UntypedFormControl(this.user?.airline || 'Default Airline', Validators.required),
      country: new UntypedFormControl(this.countryList.find((country) => country.id === this.user?.countryId)?.title || null),
      airlineDesignatorId: new UntypedFormControl(this.user?.airlineDesignatorId || Object.values(this.airlineDesignators)[0].id || null),
      userGroup: new UntypedFormControl(this.user?.userGroup || null, Validators.required),
    });
    if (this.readonly) {
      this.form.disable();
    }
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    const data = {
      ...this.form.value,
      id: this.user?.id,
      countryId: this.countriesKV[this.form.value.country]?.id || null,
      role: this.userGroupsKV[this.form.value?.userGroup]?.title ?? '-',
    };
    this.isBusy = true;
    this.userService.saveUser(data).subscribe((result) => {
      if (result?.id) {
        this.toastService.showSuccess("User has been saved");
        this.closeModal();
      }
      this.isBusy = false;
    });

  }

  searchCountry: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.countryList.map((country) => country.title).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  onCountryBlur() {
    this.form.get('country').setValue(this.form.value.country.toUpperCase());
    if (this.form?.value?.country?.length && !this.countriesKV[this.form.value.country]?.id) {
      this.form.get('country').setErrors({
        error: true,
      });
    }
  }
}
