import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { IMemoModel } from '../shared/models/memo.model';
import { IWeatherMetarModel } from '../shared/models/weather-metar.model';
import { IWeatherTafModel } from '../shared/models/weather-taf.model';
import { Searchable } from '../shared/utils/searchable.type';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  getWeatherMetarData(filter: Searchable<IWeatherMetarModel> = { isActive: true }): Observable<IWeatherMetarModel[]> {
    return this.httpClient.post(`${environment.api_base_url}weather-metar/search`, filter, {}).pipe(
      map((item: IResponse<IWeatherMetarModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  getWeatherTafData(filter: Searchable<IWeatherTafModel> = { isActive: true }): Observable<IWeatherTafModel[]> {
    return this.httpClient.post(`${environment.api_base_url}weather-taf/search`, filter, {}).pipe(
      map((item: IResponse<IWeatherTafModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }



}
