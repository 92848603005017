<ng-container [formGroup]="form" *ngIf="form">
  <div class="flex flex-column gap-3">
    <div class="flex justify-content-between">
      <div class="dark-blue fw-bold align-self-center">
        Flight: {{ leg?.airlineDesignator }} {{ leg?.flightNumber }}
        | {{ leg?.std | date:'d MMM':'UTC' }}
        | {{ leg?.departureStation }} {{ leg?.std | date: 'HH:mm':'UTC' }}
        - {{ leg?.arrivalStation }} {{ leg?.sta | date: 'HH:mm':'UTC' }}
        | {{ leg?.acType }}
      </div>
      <div class="flex gap-3 flight-info-button-container">
        <button class="btn btn-outline-secondary form-button" type="button" (click)="restoreForm()"
                data-test="restore-button" [disabled]="isBusy || form.disabled">Restore
        </button>
        <button class="btn button-blue form-button" type="button" (click)="saveForm()"
                [disabled]="form.disabled || isBusy" data-test="save-button">{{ isBusy ? '' : 'Save' }}
          <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
        </button>
      </div>
    </div>
    <app-gantt-section-tab #actualTimeTab text="Actual Time Information"
                           [isOpen]="realTimeInfoOpenTab === actualTimeTab.text"
                           (onClicked)="realTimeInfoTabClicked($event)">
      <div class="flex flex-column gap-3">
        <div class="flex justify-content-between">
          <div class="half align-self-center">
            <div class="form-field-header">Estimated Time of Departure ETD
            </div>
          </div>
          <div class="half flex gap-3">
            <div class="input-group date-picker-container">
              <input class="form-control light-blue-border" [placeholder]="dateFormat"
                     name="dp" formControlName="etdDate" ngbDatepicker #d="ngbDatepicker"
                     data-test="change-arrival-flight-details-etd-date-input">
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar light-blue-border"
                  (click)="d.toggle()" type="button" [disabled]="form.get('etdDate')?.disabled"
                  data-test="gantt-etd-date-calendar-button"></button>
              </div>
            </div>
            <ngb-timepicker appTimepickerFocus formControlName="etdTime" [spinners]="false"
                            data-test="gantt-etd-time-picker"></ngb-timepicker>
          </div>
        </div>

        <div class="flex justify-content-between">
          <div class="half align-self-center">
            <div class="form-field-header">Actual Time of Departure ATD

            </div>
          </div>
          <div class="half flex gap-3">
            <div class="input-group date-picker-container">
              <input class="form-control light-blue-border" [placeholder]="dateFormat"
                     name="dp" formControlName="atdDate" ngbDatepicker
                     #d="ngbDatepicker"
                     data-test="gantt-atd-date-input">
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar light-blue-border"
                  (click)="d.toggle()" type="button" [disabled]="form.get('atdDate')?.disabled"
                  data-test="gantt-atd-date-calendar-button"></button>
              </div>
            </div>
            <ngb-timepicker appTimepickerFocus formControlName="atdTime" [spinners]="false"
                            data-test="gantt-atd-time-picker"></ngb-timepicker>
          </div>
        </div>

        <div class="flex justify-content-between">
          <div class="flex half justify-content-between">
            <div class="form-field-header align-self-center">Takeoff Time
            </div>
            <ngb-timepicker appTimepickerFocus formControlName="totTime" [spinners]="false"
                            data-test="gantt-tot-time-picker"></ngb-timepicker>
          </div>

          <div class="flex half justify-content-between">
            <div class="form-field-header align-self-center">ETA
            </div>
            <ngb-timepicker appTimepickerFocus formControlName="etaTime" [spinners]="false"
                            data-test="gantt-eta-time-picker"></ngb-timepicker>
          </div>

        </div>

        <div class="flex justify-content-between">
          <div class="flex half justify-content-between">
            <div class="form-field-header align-self-center">Landing Time
            </div>
            <ngb-timepicker appTimepickerFocus formControlName="lndTime" [spinners]="false"
                            data-test="gantt-land-time-picker"></ngb-timepicker>
          </div>

          <div class="flex half justify-content-between">
            <div class="form-field-header align-self-center">ATA
            </div>
            <ngb-timepicker appTimepickerFocus formControlName="ataTime" [spinners]="false"
                            data-test="gantt-ata-time-picker"></ngb-timepicker>
          </div>

        </div>

        <div class="flex justify-content-between">
          <div class="flex half justify-content-between">
            <div class="form-field-header align-self-center">Next Info
            </div>
            <ngb-timepicker appTimepickerFocus formControlName="niTime" [spinners]="false"
                            data-test="gantt-next-info-time-picker"></ngb-timepicker>
          </div>

        </div>

        <div class="flex flex-column gap-3">
          <div class="form-field-header">
            Departure Delays
          </div>
          <form class="flex gap-4" [formGroup]="delay.value"
                *ngFor="let delay of departureDelayListNew | keyvalue; let i = index;">
            <div class="delay-code-select">
              <ng-select formControlName="delayCode"
                         [attr.data-test]="'change-arrival-flight-details-departure-delay-code-' + i">
                <ng-option *ngFor="let code of delayCodes" [value]="code.id">{{ code.code }} {{ code.description }}
                </ng-option>
                <ng-template ng-option-tmp let-item="item">
                    <span class="ng-option-label"
                          [attr.data-test]="'delay-code-' + item">{{ delayCodesKV[item]?.code }} {{ delayCodesKV[item]?.description }}</span>
                </ng-template>
              </ng-select>
            </div>
            <div class="delay-time-input">
              <app-time-input [centerText]="true" formControlName="time" (focusLost)="getRemainingDelay()"
                              [attr.data-test]="'change-arrival-flight-details-departure-delay-time-' + i"></app-time-input>
            </div>
          </form>
        </div>
        <div class="flex flex-column gap-3">
          <div class="form-field-header">
            Arrival Delays
          </div>
          <form class="flex gap-4" [formGroup]="delay.value"
                *ngFor="let delay of arrivalDelayListNew | keyvalue; let i = index">
            <div class="delay-code-select">
              <ng-select id="arrival-flight-arrival-delay-code" formControlName="delayCode"
                         [attr.data-test]="'change-arrival-flight-details-arrival-delay-code-' + i">
                <ng-option *ngFor="let code of delayCodes" [value]="code.id">{{ code.code }} {{ code.description }}
                </ng-option>
                <ng-template ng-option-tmp let-item="item">
                    <span class="ng-option-label"
                          [attr.data-test]="'delay-code-' + item">{{ delayCodesKV[item]?.code }} {{ delayCodesKV[item]?.description }}</span>
                </ng-template>
              </ng-select>
            </div>
            <div class="delay-time-input">
              <app-time-input [centerText]="true" formControlName="time" (focusLost)="getRemainingDelay()"
                              [attr.data-test]="'change-arrival-flight-details-arrival-delay-time-' + i"></app-time-input>
            </div>
          </form>
          <div class="row">
            <div class="delay-info-box">
              <ng-container
                *ngIf="maxArrivalDelayMinutes > 0 && currentArrivalDelayMinutes !== maxArrivalDelayMinutes">
                <div class="row">
                    <span class="delay-error">The delay minutes you entered ({{ currentArrivalDelayMinutes }}) do not match the current flight delay time ({{ maxArrivalDelayMinutes }}
                      ) </span>
                </div>
              </ng-container>
              <ng-container
                *ngIf="maxDepartureDelayMinutes > 0 && currentDepartureDelayMinutes !== maxDepartureDelayMinutes">
                <div class="row">
                    <span class="delay-error">The delay minutes you entered ({{ currentDepartureDelayMinutes }}) do not match the current flight delay time ({{ maxDepartureDelayMinutes }}
                      ) </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

      </div>
    </app-gantt-section-tab>

    <app-gantt-section-tab #gateTab text="Gate Information" [isOpen]="realTimeInfoOpenTab === gateTab.text"
                           (onClicked)="realTimeInfoTabClicked($event)">
      <div class="flex align-items-center justify-content-between">
        <div class="form-field-header">
          Passenger Gate
        </div>
        <input type="text" class="form-control light-blue-border small-input"
               placeholder=""
               formControlName="gate" data-test="gantt-flight-info-gate-input">

        <div class="form-field-header">
          Aircraft Stand / Gate
        </div>
        <input type="text" class="form-control light-blue-border small-input"
               placeholder=""
               formControlName="stand" data-test="gantt-flight-info-stand-input">
      </div>

    </app-gantt-section-tab>

    <app-gantt-section-tab #passengersTab text="Passenger Information"
                           [isOpen]="realTimeInfoOpenTab === passengersTab.text"
                           (onClicked)="realTimeInfoTabClicked($event)">
      <div class="flex flex-column gap-3">
        <div class="flex flex-column gap-2" formGroupName="estimatedPassengers">
          <div class="form-field-header align-self-start">
            Estimated Passengers
          </div>
          <div class="three-col-grid">
            <div class="flex gap-3 align-items-center text-gray passenger-class"
                 *ngFor="let pClass of passengerClasses" [formGroupName]="pClass.code">
              <div>{{ pClass.code }} <span>({{ seatingConfigurations?.[pClass.code] || '0' }})</span></div>
              <input type="text" class="form-control light-blue-border passenger-input"
                     id="estimated-passengers-class-{{pClass.code}}" placeholder="" formControlName="description"
                     [attr.data-test]="'estimated-passengers-class-' + pClass.code">
            </div>
          </div>
        </div>

        <div class="flex flex-column gap-2" formGroupName="actualPassengers">
          <div class="form-field-header align-self-start">
            Actual Passengers
          </div>
          <div class="three-col-grid">
            <div class="flex gap-3 align-items-center text-gray passenger-class"
                 *ngFor="let pClass of passengerClasses" [formGroupName]="pClass.code">
              <div>{{ pClass.code }} <span>({{ seatingConfigurations?.[pClass.code] || '0' }})</span></div>
              <input type="text" class="form-control light-blue-border passenger-input"
                     id="actual-passengers-class-{{pClass.code}}" placeholder="" formControlName="description"
                     [attr.data-test]="'actual-passengers-class-' + pClass.code">
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="flex flex-column gap-2 col-6">
            <div class="form-field-header align-self-start">
              Connecting Passengers
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" class="bi bi-info-circle-fill"
                   *ngIf="passengerDataLength"
                   fill="#4285F6" viewBox="0 0 16 16" [ngbTooltip]="tooltipContent"
                   [placement]="['right-top', 'right', 'right-bottom', 'auto']"
                   container="body"
                   tooltipClass="connecting-passengers-tooltip">
                <path
                  d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
              </svg>
              <ng-template #tooltipContent>
                <div class="connecting-passengers-grid">
                  <ng-container *ngFor="let i of passengerData | keyvalue">
                    <div class="flight-number">{{ i.key.split('-')[0] }}</div>
                    <div class="date">{{ leg?.std | date:'d MMM' | uppercase }}</div>
                    <div class="station">{{ i.key.split('-')[1] }}</div>
                    <div class="passengers">{{ i.value.passengers }}</div>
                    <div class="bag-count">{{ i.value.bags }}</div>
                    <div class="time">{{ i.value.time }}</div>
                  </ng-container>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="col-6">
            <div class="flex flex-column gap-2 passenger-categories  skip-invalid-border" formGroupName="categories">
              <div class="form-field-header">
                Passenger Categories
              </div>
              <div class="flex justify-content-between align-items-center text-gray category-name skip-invalid-border"
                   *ngFor="let category of passengerCategories" [formGroupName]="category?.code">
                <div>{{ category.description }}</div>
                <input type="text" class="form-control light-blue-border passenger-input" placeholder=""
                       [attr.data-test]="category.description + '-passenger-category-input'" formControlName="amount">
              </div>
            </div>

          </div>

        </div>
      </div>
    </app-gantt-section-tab>

    <app-gantt-section-tab #ctotTab text="Air Traffic Control Information"
                           [isOpen]="realTimeInfoOpenTab === ctotTab.text"
                           (onClicked)="realTimeInfoTabClicked($event)">
      <div class="flex gap-3 align-items-center">
        <div class="form-field-header">
          Calculated Take Off Time (CTOT)
        </div>
        <div class="flex justify-content-end">
          <ngb-timepicker appTimepickerFocus formControlName="ctotTime" [spinners]="false"
                          [readonlyInputs]="true"
                          data-test="gantt-flight-ctot-time-picker"></ngb-timepicker>
        </div>
      </div>
    </app-gantt-section-tab>

    <app-gantt-section-tab #weatherTab text="Weather Information"
                           [isOpen]="realTimeInfoOpenTab === weatherTab.text"
                           (onClicked)="realTimeInfoTabClicked($event)">
      <div class="flex flex-column gap-3">
        <div *ngFor="let station of interactingStations">
          <div class="fw-bold dark-blue">METAR/TAF {{ station }}:</div>
          <div> {{ metarData[station]?.content }}</div>
          <br>
          <div> {{ tafData[station]?.content }}</div>
        </div>
      </div>

    </app-gantt-section-tab>

    <app-gantt-section-tab #crewTab text="Crew Information"
                           [isOpen]="realTimeInfoOpenTab === crewTab.text"
                           (onClicked)="realTimeInfoTabClicked($event)">
      <div class="flex flex-column">
        <div class="fw-bold dark-blue">Flight
          ({{ leg?.airlineDesignator }} {{ leg?.flightNumber }} {{ leg?.departureStation }} - {{ leg?.arrivalStation }})
        </div>
        <div class="dark-blue">Flight Deck:</div>
        <div class="flex">
          <div class="form-field-header col-5">Captain:</div>
          <div class="dark-blue col-6">George Ellis</div>
        </div>
        <div class="flex">
          <div class="form-field-header col-5">First Officer:</div>
          <div class="dark-blue col-6">Johan Skift</div>
        </div>
        <div class="dark-blue">Cabin Crew:</div>
        <div class="flex">
          <div class="form-field-header col-5">Sr. Flight Attendant:</div>
          <div class="dark-blue col-6">Maria Antouanetta</div>
        </div>
        <div class="flex">
          <div class="form-field-header col-5">Flight Attendant:</div>
          <div class="dark-blue col-6">Philipp Morris</div>
        </div>
        <div class="flex">
          <div class="form-field-header col-5">Flight Attendant:</div>
          <div class="dark-blue col-6">Monica Belluci</div>
        </div>
        <div class="flex">
          <div class="form-field-header col-5">Flight Attendant:</div>
          <div class="dark-blue col-6">Bob Ross</div>
        </div>
      </div>

    </app-gantt-section-tab>

    <app-gantt-section-tab #lirTab text="Loading Instruction Report"
                           [isOpen]="realTimeInfoOpenTab === lirTab.text"
                           (onClicked)="realTimeInfoTabClicked($event)">
      <div class="flex flex-column gap-3">
        <img src="content/images/plane-nose.png" alt="Plane nose" width="130" class="align-self-center">
        <div class="flex justify-content-between">
          <div class="light-blue-border loading-box"><strong>21</strong>
            VIE/PAG/140A/C/<strong>3700</strong>/
            PEM.EAT
          </div>
          <div class="light-blue-border loading-box"><strong>22</strong>
            TRANSIT: ATH/PAG 250A
            /C/<strong>2543</strong>/AOG.FKT.COMAIL
          </div>
        </div>
        <div class="flex justify-content-between">
          <div class="light-blue-border loading-box"><strong>41</strong>L
            VIE/AKE 24501 IR/B/<strong>500</strong>

          </div>
          <div class="light-blue-border loading-box"><strong>41</strong>R
            VIE/AKE 25001 IR/B/<strong>500</strong>

          </div>
        </div>
        <div class="flex justify-content-between">
          <div class="light-blue-border loading-box"><strong>42</strong>L
            VIE/AKE 24501 IR/B/<strong>400</strong>

          </div>
        </div>
      </div>


    </app-gantt-section-tab>

    <app-gantt-section-tab #actionsTab text="Return to Ramp / Return from Airborne / Divert Flight"
                           [isOpen]="realTimeInfoOpenTab === actionsTab.text"
                           (onClicked)="realTimeInfoTabClicked($event)">
      <div class="flex justify-content-between col-12">
        <button class="btn btn-danger btn-size"
                [disabled]="!leg.takeOffTime || form.disabled || arrivalAccess !== Access.RW || departureAccess !== Access.RW"
                (click)="divertFlight(leg)"
                data-test="change-arrival-flight-details-divert-flight-button">Divert Flight
        </button>
        <button class="btn btn-danger btn-size"
                [disabled]="!leg.atd || form.disabled || arrivalAccess !== Access.RW || departureAccess !== Access.RW"
                (click)="returnToRamp(leg)"
                data-test="change-flight-details-rtr-button">Return to Ramp
        </button>
        <button class="btn btn-danger btn-size"
                [disabled]="!leg.atd || form.disabled || arrivalAccess !== Access.RW || departureAccess !== Access.RW"
                (click)="returnFromAirborne(leg)"
                data-test="change-flight-details-rfa-button">Return from Airborne
        </button>
      </div>

    </app-gantt-section-tab>
  </div>


</ng-container>
