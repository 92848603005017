<div class="flex flex-column w-100 text-center">
  <div class="alert-header flex dark-blue fw-bold light-blue-border">
    <div class="col-3">Alert Severity</div>
    <div class="col-3">Description</div>
    <div class="col-3">Occurred At
      <fa-icon [icon]="sortOrder === 'asc' ? ascIcon : descIcon" (click)="changeSort()"
               [classes]="['cursor-pointer']"></fa-icon>
    </div>
    <div class="col-3">Sectors Affected</div>
  </div>
  <app-alert-list-new-item [timezone]="this.timezoneService.getTimezone() |async" [alertList]="this"
                           *ngFor="let item of alertList; trackBy: alertTrack" (editClicked)="editClicked($event)"
                           (resolvedClicked)="markAsResolvedClick($event)" (remindLaterClick)="remindLater($event)"
                           [alert]="item" [activeAlertIndex]="activeAlertIndex"
                           (toggleClicked)="doSetAlertIndex(item?.id)" class="flex flex-column mb-3"
                           [attr.data-test]="item | alertTestAttribute"></app-alert-list-new-item>
</div>
