<div class="container request-container my-3 py-3 avitium-bg-color colorWhite light" data-test="issue-etd-request">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12 text-center fs-30">
        Incoming Issue ETD request from Ramp
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center font-20" data-test="issue-etd-extension-minutes">
        Please extend the departure time by <b>{{extendEtdRequest?.extensionInMinutes}} minutes</b>!
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 text-center font-20" data-test="issue-etd-reason">
        Reason: {{extendEtdRequest?.reason}}
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 text-center font-20">
        New ETD: <b>{{newEtd ? (newEtd | customDate:this.dateFormat + ' - HH:mm':timezone) : ''}}</b>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center font-20">
        <span>Select delay code: </span>
        <ng-select formControlName="delayCode" data-test="issue-etd-request-delay-code-select">
          <ng-option *ngFor="let code of delayCodeList" [value]="code.id">{{code.code}} {{code.description}}</ng-option>
          <ng-template ng-option-tmp let-item="item">
            <span class="ng-option-label" [attr.data-test]="'issue-etd-delay-code-' + item">{{ delayCodesKV?.[item]?.code }} {{ delayCodesKV?.[item]?.description }}</span>
          </ng-template>
      </ng-select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 button-container">
        <div>
          <button class="btn btn-outline-danger" (click)="declineClicked()" data-test="issue-etd-decline-button">Decline</button>
        </div>
        <div>
          <button class="btn btn-success" type="button" [disabled]="form.invalid || isBusy" (click)="acceptClicked()" data-test="issue-etd-accept-button">
            {{ isBusy ? '' : 'Issue ETD' }}
            <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
