<div class="flex justify-content-between">
  <div class="settings-container flex flex-column">
    <div class="settings-row font-size-20">
      <div class="custom-file">
        <input type="file" class="custom-file-input" id="file" (change)="handleFileInput($event.target.files)" multiple data-test="asm-multi-file-input">
      </div>
    </div>

    <div class="settings-row font-size-20">
      <button type="button" class="btn add-button button-blue flex-basis-20 border-radius-5" (click)="upload()" [disabled]="!fileList.length || isBusy" data-test="asm-multi-upload-button">
        {{ isBusy ? '' : 'Proceed' }}
        <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
      </button>
    </div>
  </div>
  <!-- File List -->
  <ul class="list-group w-40" data-test="bulk-asm-file-list">
    <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let fileObj of fileList | keyvalue" [attr.data-test]="'file-' + fileObj.value.file.name">
        <span>{{ fileObj.value.file.name }}</span>
        <fa-icon [icon]="successIcon" [classes]="['text-success']" *ngIf="fileObj.value.status === 'success'"></fa-icon>
        <fa-icon [icon]="errorIcon" [classes]="['text-danger']" *ngIf="fileObj.value.status === 'error'"></fa-icon>
          <div class="spinner-border spinner-border-sm text-primary d-inline" role="status" *ngIf="fileObj.value.status === 'processing'">
            <span class="visually-hidden">Loading...</span>
          </div>
    </li>
  </ul>
</div>

