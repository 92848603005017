import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ISimpleData } from '../../../models/simpleData.model';
import * as dayjs from 'dayjs';
import { dayjsToNgbDate } from '../../../utils/utils';

export class SettingsAcTypeMainFormBuilder {

  static constructForm(
    fb: UntypedFormBuilder,
    passengerClassesArray: ISimpleData[],
    maxVersion: number): UntypedFormGroup {
    const seatConfigFormGroup = new UntypedFormGroup({
      seats: new UntypedFormArray([], validConfigValidator()),
    });

    const seatsArray = seatConfigFormGroup.get('seats') as UntypedFormArray;

    for (let i= 1; i<= maxVersion; i++) {
       const fg = new UntypedFormGroup({
         validFrom: new UntypedFormControl(dayjsToNgbDate(dayjs.utc())),
         validTo: new UntypedFormControl(),
         operatedBy: new UntypedFormControl()
       }, {});
      passengerClassesArray.forEach((item: ISimpleData) => {
        fg.addControl(item.code, new UntypedFormGroup({
          code: new UntypedFormControl(item.code),
          description: new UntypedFormControl(),
          id: new UntypedFormControl(),
        }));
      });
      seatsArray.push(fg);
    }


    function validConfigValidator(): ValidationErrors | null {
      return (configArray: UntypedFormArray): ValidationErrors | null => {
        let isValid = false;

        for (const control of configArray.controls) {
          if(isValid) {
            break;
          }
          const group: UntypedFormGroup = control as UntypedFormGroup;
          for (const passengerClassCode of passengerClassesArray.map((pc) => pc.code)) {
            if(+group.controls[passengerClassCode].value?.description) {
              isValid = true;
              break;
            }
          }
        }

        return isValid ? null : { 'noSeat': true };
      };
    }

    return fb.group({
      alias: ['', Validators.required],
      description: [null],
      calibrationId: ['', Validators.required],
      fireCategory: [null],
      iata: ['', Validators.compose([Validators.required, Validators.pattern('[\\d\\w]{3}')])],
      icao: ['', Validators.required],
      mtow: [null],
      defaultMinTurnaroundTimeInMinutes: ['', Validators.compose([Validators.required, Validators.pattern('\\d{2}[:]\\d{2}')])],
      seatingConfig: seatConfigFormGroup,
      wakeTurbulence: [null]
    });


  }
}
