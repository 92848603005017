<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
<ng-container *ngIf="form"><form [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Airline Main Base
            </div>
            <div class="col-6">
              <div class="mb-3">
                <input type="text" class="form-control" id="settings-ac-type-ac-type" placeholder="" formControlName="airlineMainBase">
              </div>
            </div>
          </div>
        </div>
        <div class="container h-54">
          <div class="row">
            <div class="col-6 form-field-header">
              Airline Codes
            </div>
            <div class="col-6">
              <div class="container px-0">
                <div class="row">
                  <div class="col-9">
                    <input type="text" class="form-control hidden" id="settings-general-airline-codes" placeholder="" value="" disabled>
                  </div>
                  <div class="col-3 flex align-items-center">
                    <i class="demo-icon icon-icons8-edit-file fs-30 clickable" (click)="openManageAirlineCodes()"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container h-54">
          <div class="row">
            <div class="col-6 form-field-header">
              Passenger Classes
            </div>
            <div class="col-6">
              <div class="container px-0">
                <div class="row">
                  <div class="col-9">
                    <input type="text" class="form-control hidden" id="settings-general-passenger-classes" placeholder="" value="" disabled>
                  </div>
                  <div class="col-3 flex align-items-center">
                    <i class="demo-icon icon-icons8-edit-file fs-30 clickable" (click)="openManagePassengerClasses()"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container h-54">
          <div class="row">
            <div class="col-6 form-field-header">
              Flight Service Types
            </div>
            <div class="col-6">
              <div class="container px-0">
                <div class="row">
                  <div class="col-9">
                    <input type="text" class="form-control hidden" id="settings-general-flight-service-types" placeholder="" value="" disabled>
                  </div>
                  <div class="col-3 flex align-items-center">
                    <i class="demo-icon icon-icons8-edit-file fs-30 clickable" (click)="openManageFlightServiceTypes()"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container h-54">
          <div class="row">
            <div class="col-6 form-field-header">
              Delay Codes
            </div>
            <div class="col-6">
              <div class="container px-0">
                <div class="row">
                  <div class="col-9">
                    <input type="text" class="form-control hidden" id="settings-general-delay-codes" placeholder="" value="" disabled>
                  </div>
                  <div class="col-3 flex align-items-center">
                    <i class="demo-icon icon-icons8-edit-file fs-30 clickable" (click)="openManageDelayCodes()"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 border-start">
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Date Format
            </div>
            <div class="col-6">
              <div class="mb-3">
                <div class="mb-3">
                  <select class="form-control form-select" id="settings-ac-type-calibration" formControlName="dateFormat">
                    <option>DD/MM/YYYY</option>
                    <option>YYYY/MM/DD</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container h-54">
          <div class="row">
            <div class="col-6 form-field-header">
              Measurement Units
            </div>
            <div class="col-6">
              <div class="container px-0">
                <div class="row">
                  <div class="col-9">
                    <input type="text" class="form-control hidden" id="settings-general-measurement-units" placeholder="" value="" disabled>
                  </div>
                  <div class="col-3 flex align-items-center">
                    <i class="demo-icon icon-icons8-edit-file fs-30 clickable" (click)="openManageMeasurementUnits()"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-6 form-field-header">
              Turnaround Time Type
            </div>
            <div class="col-6">
              <div class="mb-3">
                <div class="mb-3">
                  <select class="form-control form-select" id="settings-general-turnaround-time-type" formControlName="turnaroundTimeType">
                    <option *ngFor="let type of turnaroundTypes.LIST" value="{{type}}">
                      {{type | titlecase}} Turnaround Time
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container h-54">
          <div class="row">
            <div class="col-6 form-field-header">
              Turnaround Threshold
            </div>
            <div class="col-6">
              <div class="container px-0">
                <div class="row">
                  <div class="col-9">
                    <input type="text" class="form-control hidden" id="settings-general-turnaround-threshold" placeholder="" value="" disabled>
                  </div>
                  <div class="col-3 flex align-items-center">
                    <i class="demo-icon icon-icons8-edit-file fs-30" [ngClass]="{'clickable' : turnaroundTimeTypeValue === turnaroundTypes.STANDARD, 'notClickable': turnaroundTimeTypeValue !== turnaroundTypes.STANDARD}" (click)="openManageTurnaroundThreshold()"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container h-54">
          <div class="row">
            <div class="col-6 form-field-header">
              Minimum Passenger Connection Time
            </div>
            <div class="col-6">
              <ngb-timepicker appTimepickerFocus formControlName="minimumPassengerConnectionTimeInMinutes" [spinners]="false" name="bla"></ngb-timepicker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="form?.disabled || form?.invalid || isBusy">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
