<div class="flex contact gap-3" [ngClass]="{ 'selected': activeIndex === chatGroup?.chatGroupId }">
  <app-user-photo class="p-1" [user]="user" [size]="50" circleBackground="#F2F2F2" textColor="black"></app-user-photo>
  <div class="flex flex-column align-self-center name-last-message-col gap-1">
    <div class="name dark-blue" data-test="contact-info">{{ user?.firstname }} {{ user?.lastname }}
      ({{ user?.location }})
    </div>
    <div class="last-message" [ngClass]="{ 'fw-bold': numberOfUnreadMessages > 0 }">
      <div class="last-message-text" data-test="last-message"
           [ngClass]="{ deleted: chatGroup?.lastMessage?.isActive === false }">
        {{ chatGroup?.lastMessage?.content ? (chatGroup?.lastMessage?.isActive ? chatGroup.lastMessage.content : '*Message Deleted*') : 'No history' }}
      </div>
      <div class="last-message-datetime" data-test="last-message-datetime">
        {{ chatGroup?.lastMessage ? (chatGroup?.lastMessage?.lastChangedAt | date:"- dd/MM/YYYY, HH:mm":timezone) : '' }}
      </div>
    </div>
    <!--    <div class="location">Airport: {{ user?.location }}</div>-->
    <!--    <div class="role">{{ user?.role | i18nSelect: userRoleObject.DISPLAY_TITLE_MAP }}</div>-->
  </div>
  <div class="check-icon">
    <img *ngIf="sentAreUnread" src="content/images/icons/check2-circle.svg" width="20" height="20" alt="check"/>
  </div>
</div>
