import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ChatService} from '../../../../../services/chat.service';
import {IChatMessageModel} from '../../../../models/chat-message.model';
import {firstValueFrom, interval, Observable, Subject, Subscription} from 'rxjs';
import dayjs from 'dayjs';
import {TimezoneService} from '../../../../../services/timezone.service';
import {IUser} from '../../../../models/user.model';
import {AuthService} from '../../../../../services/auth.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-chat-session',
  templateUrl: './chat-session.component.html',
  styleUrls: ['./chat-session.component.scss']
})
export class ChatSessionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() chatGroupId: number;
  @Output() chatGroupIdChange = new EventEmitter<number>();
  @Input() user: IUser;
  @Input() readonly: boolean;
  conversation: IChatMessageModel[] = [];
  timezone$: Observable<string>;
  destroy$ = new Subject();
  getHistory$: Subscription;

  constructor(private chatService: ChatService, private tzService: TimezoneService, public authService: AuthService) {
  }

  dateDiff(date1: Date, date2: Date): number {
    return Math.abs(dayjs(date1).diff(date2, 'minutes'));
  }

  ngOnInit() {
    this.timezone$ = this.tzService.getTimezone();
    if (!this.chatGroupId) {
      this.searchGroup();
      return;
    }
    this.getHistory();
    this.getHistory$ = interval(5000).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.getHistory();
    });
  }

  searchGroup() {
    this.findGroup([this.authService.user.id, this.user.id]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('Changes:', changes);
    if (changes.user && changes.chatGroupId) {
      this.conversation = [];
      if (!changes.user.firstChange && !changes.chatGroupId.firstChange) {
        this.getHistory();
        this.getHistory$?.unsubscribe();
        this.getHistory$ = interval(5000).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.getHistory();
        });
      }
    }
    // if (changes.user && !changes.chatGroupId) {
    //   if (changes.user.previousValue?.id !== changes.user.currentValue?.id) {
    //     this.conversation = [];
    //     this.searchGroup();
    //   }
    // }
    // if (changes.chatGroupId && changes.chatGroupId.previousValue !== changes.chatGroupId.currentValue && !changes.user) {
    //   if (this.chatGroupId) {
    //     this.getHistory();
    //   }
    // }
  }

  async findGroup(memberIds: number[]): Promise<void> {
    this.chatGroupId = await firstValueFrom(this.chatService.getChatGroup(memberIds));
    this.getHistory();
    this.getHistory$?.unsubscribe();
    this.getHistory$ = interval(5000).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.getHistory();
    });
    if (this.chatGroupId) {
      this.chatGroupIdChange.emit(this.chatGroupId);
    }

  }

  async getHistory() {
    if (!this.chatGroupId) {
      return;
    }
    const groupId = this.chatGroupId;
    const conversationData = await firstValueFrom(this.chatService.getChatHistory(this.chatGroupId, this.conversation.length ? this.conversation[0].id : undefined));
    if (this.chatGroupId != groupId) {
      // Conversation changed
      return;
    }
    conversationData.reverse();
    this.conversation.unshift(...conversationData);
  }

}
