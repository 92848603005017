import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IGenericContainerObject} from '../../../../../shared/models/genericContainerObject.model';
import {take} from 'rxjs';
import {SlaGseFunctionService} from '../../../../../services/functions/sla-gse-function.service';
import {SlaGseLevelActypeDialogComponent} from '../sla-gse-level-actype-dialog/sla-gse-level-actype-dialog.component';
import {ProcessesService} from '../../../../../services/processes.service';
import {IAcType} from '../../../../../shared/models/ac-type.model';
import {IProcessesModel} from '../../../../../shared/models/processes.model';
import {IGsesModel} from '../../../../../shared/models/gses.model';
import {GseService} from '../../../../../services/gse.service';
import {IAirport} from '../../../../../shared/models/airport.model';
import {DeleteModalComponent} from '../../../../../shared/components/delete-modal/delete-modal.component';

@Component({
  selector: 'app-sla-gse-level-process-dialog',
  templateUrl: './sla-gse-level-process-dialog.component.html',
  styleUrls: ['./sla-gse-level-process-dialog.component.scss']
})
export class SlaGseLevelProcessDialogComponent implements OnInit {

  @Input() title?: string;
  isNew?: boolean;
  gseList?: IGenericContainerObject<IGsesModel>;
  filteredGseList?: IGenericContainerObject<IAcType>;
  acTypeList: IGenericContainerObject<IAcType>;
  airportList: IGenericContainerObject<IAirport>;
  isSaved = !this.isNew;
  process: IProcessesModel;

  form?: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private modalService: NgbModal, private slaGseFunctionService: SlaGseFunctionService, private activeModal: NgbActiveModal, private processesService: ProcessesService, private gsesService: GseService) {
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  ngOnInit() {
    this.isSaved = !this.isNew;
    if(!this.gseList)
    {
      this.gseList = {};
    }
    if(!this.isNew) {
      this.fetchExceptions();
    }
    else {
      this.filteredGseList = {};
      this.filteredGseList[0] = {
        id: 0,
        alias: 'Default A/C Types'
      }
    }

    this.form = this.fb.group({
      processName: [this.process?.title, Validators.required]
    });

  }

  ngOnDestroy(): void {
  }


  addAcType() {
    if(!this.isSaved)
    {
      return;
    }
    this.doAdd();
  }

  doAdd() {
    if(!this.isSaved)
    {
      return;
    }
    const modalRef = this.modalService.open(SlaGseLevelActypeDialogComponent, { size: 'lg' });
    modalRef.componentInstance.title = `Add SLA / GSE ${this.process?.title} / new A/C Type`;
    const modal = modalRef.componentInstance as SlaGseLevelActypeDialogComponent;
    modal.isNew = true;
    modal.acTypeList = this.acTypeList;
    modal.airportList = this.airportList;
    modal.process = this.process;
    modal.gseList = this.gseList;

    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchExceptions();
    });
  }


  async editAcType(acTypeIdStr?: string) {
    const acTypeId = +acTypeIdStr;
    if(!this.isSaved)
    {
      return;
    }

    if (!acTypeId && acTypeId !== 0 || !this.acTypeList || !this.acTypeList[acTypeId] && acTypeId !== 0) {
      console.log(`AcType ${acTypeId} was not found! `, this.acTypeList);
      return;
    }

    const acType = this.acTypeList[acTypeId] ?? { id: 0, alias: 'Default A/C Types' };

    const modalRef = this.modalService.open(SlaGseLevelActypeDialogComponent, { size: 'lg' });
    modalRef.componentInstance.title = `Edit SLA / GSE ${this.process?.title} / ${acType.alias}`;
    const modal = modalRef.componentInstance as SlaGseLevelActypeDialogComponent;
    modal.isNew = false;
    modal.acTypeId = acTypeId;
    modal.acTypeList = this.acTypeList;
    modal.gseList = this.gseList;
    modal.airportList = this.airportList;
    modal.process = this.process;
    modalRef.dismissed.pipe(take(1)).subscribe(() => {
      this.fetchExceptions();
    });
    return;
  }

  async deleteItem(acTypeIdStr?: string) {
    const acTypeId = +acTypeIdStr;
    if (!this.process || !acTypeId) {
      console.log('cannot delete because one or more args are empty');
      return;
    }

    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.term = 'Exception for AC Type ' + this.acTypeList[acTypeId].alias;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.gsesService.deleteGses(this.process.id, acTypeId).subscribe((result) => {
        if(result) {
          this.fetchExceptions();
        }
      });
      // const gseIdsToDelete: number[] = [];
      // const gseObservables: Observable<any>[] = [];
      // for (const gse of Object.values(this.gseList)) {
      //   if(gse.processId === this.process.id && gse.acTypeId === acTypeId) {
      //     gseIdsToDelete.push(gse.id);
      //   }
      // }
      // if(!gseIdsToDelete.length) {
      //   console.warn('GSE ID was not found!');
      // }
      // for (const gseId of gseIdsToDelete) {
      //   gseObservables.push(this.gsesService.deleteGses(gseId));
      // }
      // forkJoin(gseObservables).subscribe((result) => {
      //   if(result) {
      //     for (const gseId of gseIdsToDelete) {
      //       delete this.gseList[gseId];
      //     }
      //     delete this.filteredGseList[acTypeId];
      //   }
      // });
    });
  }

  async saveButtonClicked() {
    if (this.isNew && this.form?.invalid) {
      this.form?.markAsTouched();
      return;
    } else if (this.isNew && this.form?.valid) {
      await this.saveWithDefaultValues();
      await this.createDefaultGseEntry();
      this.isSaved = true;
    }
  }

  createDefaultGseEntry()
  {
    return new Promise((resolve, reject) => {
      if(!this.process) {
        reject();
      }
      this.gsesService.saveGses({
        processId: this.process.id,
        gseIdentifier: '',
        isSla: true,
        title: 'none',
        isNone: true,
        acTypeId: null,
        airportId: null,
        validFrom: new Date(),
        validTo: null,
        maximumProcessTimeInMinutes: 0,
      }).subscribe((result) => {
        if(!result)
        {
          reject();
        }
        this.gseList[result.id] = result;
        this.filteredGseList[0] = result;
        resolve(null);
      });
    });
  }

  saveWithDefaultValues(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.form?.invalid) {
        this.form.markAsTouched();
        reject();
      }

      this.processesService.saveProcess({
        title: this.form?.value?.processName,
        internalName: this.form?.value?.processName
      }).subscribe((result) => {

        this.process = result;
        resolve(null);
      });
    });
  }

  fetchExceptions() {
    this.gsesService.fetchGses({ isActive: true, processId: this.process?.id }).subscribe((result) => {
      if(!this.gseList) {
        this.gseList = {};
      }
      this.filteredGseList = {};
      for (const gse of result) {
        this.gseList[gse.id] = gse;
        if(!gse.acTypeId) {
          this.filteredGseList[0] = gse;
          continue;
        }
        if(!this.filteredGseList[gse.acTypeId]) {
          this.filteredGseList[gse.acTypeId] = this.acTypeList[gse.acTypeId];
        }
      }
    });
  }
}
