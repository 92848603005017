<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="container h-54">
            <div class="row">
              <div class="col-6 form-field-header">
                Manage Turnaround Threshold
              </div>
              <div class="col-6 flex justify-content-end">
                <div *ngIf="timePicker" class="mb-3">
                  <div class="mb-3">
                    <ngb-timepicker appTimepickerFocus [formControl]="timePicker" [spinners]="false" name="time"></ngb-timepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-settings-view-tolerance-threshold-bar topLabel1="Minimum Turnaround Time" topLabel2="Standard Turnaround Time" bottomLabel2="Turnaround Threshold at {{timePicker?.value || '-'}}" [turnaroundType]="settings?.turnaroundTimeType"></app-settings-view-tolerance-threshold-bar>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()" [disabled]="isBusy">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
