import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { IConnectingPassengersModel } from '../shared/models/connecting-passengers.model';

@Injectable({
  providedIn: 'root'
})
export class ConnectingPassengersService {

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }



  getconnectingPassengers(filter: Partial<IConnectingPassengersModel> = { isActive: true }): Observable<any> {
    return this.httpClient.post(`${environment.api_base_url}connecting-passengers/search`, filter, {}).pipe(
      map((item: IResponse<IConnectingPassengersModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  getconnectingPassengersForReports(filter: any): Observable<IConnectingPassengersModel[]> {
    return this.httpClient.post(`${environment.api_base_url}connecting-passengers/reports-search`, filter, {}).pipe(
      map((item: IResponse<IConnectingPassengersModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

}
