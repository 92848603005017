<div class="list-item-column">
  <div class="list-item-row-header">
    <div class="flex-basis-50 text-block">{{item?.validFrom | date:'longDate'}} - {{item?.validTo | date:'longDate'}}</div>
    <div class="flex-basis-50 text-block text-end">{{item?.isUtc ? 'UTC' : 'Local'}}</div>
  </div>
  <div class="list-item-row-header">
    <div class="flex-basis-50 text-block text-start">
      {{item?.weekday?.mon ? 'Mon ' : ''}}
      {{item?.weekday?.tue ? 'Tue ' : ''}}
      {{item?.weekday?.wed ? 'Wed ' : ''}}
      {{item?.weekday?.thu ? 'Thu ' : ''}}
      {{item?.weekday?.fri ? 'Fri ' : ''}}
      {{item?.weekday?.sat ? 'Sat ' : ''}}
      {{item?.weekday?.sun ? 'Sun' : ''}}
      </div>
    <div class="flex-basis-50 text-block text-end">closed from {{item?.curfewEndTime + ' until ' + item?.curfewStartTime}}</div>
  </div>
</div>
