import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EditPairDialogComponent} from '../edit-pair-dialog/edit-pair-dialog.component';
import {firstValueFrom, Subject} from 'rxjs';
import {AlertService} from '../../../services/alert.service';
import {IAlertsModel} from '../../../shared/models/alerts.model';
import dayjs from 'dayjs';
import {TimezoneService} from '../../../services/timezone.service';
import {IUser} from '../../../shared/models/user.model';
import {SortDirection} from '../../../shared/constants/table-sort.types';
import {faSortNumericDown, faSortNumericUp} from '@fortawesome/free-solid-svg-icons';
import {AlertConstants} from "../../../shared/constants/alert.constants";
import {Router} from "@angular/router";
import {PermissionService} from "../../../services/permission.service";
import {UserLegAssignmentService} from "../../../services/user-leg-assignment.service";

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss'],
})
export class AlertListComponent implements OnInit, OnDestroy {
  @Input() user: IUser;
  @Input() alertList: IAlertsModel[];
  @Output() setAlertIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeAlertChanged: EventEmitter<IAlertsModel> = new EventEmitter();
  @Output() refetchRequired: EventEmitter<void> = new EventEmitter<void>();
  @Output() localFilteringRequired: EventEmitter<void> = new EventEmitter<void>();
  @Output() sortChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() remindMeLaterClicked: EventEmitter<{ id: number; hours: number; minutes: number }> = new EventEmitter();
  @Output() markedAsResolved: EventEmitter<void> = new EventEmitter();
  activeAlertIndex = -1;
  unsubscribe$ = new Subject();
  @Input() sortOrder: SortDirection = 'desc';

  ascIcon = faSortNumericUp;
  descIcon = faSortNumericDown;

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }


  constructor(private modalService: NgbModal, public alertService: AlertService, public timezoneService: TimezoneService, private router: Router, private userLegAssignmentService: UserLegAssignmentService, private permissionService: PermissionService) {
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
  }


  doSetAlertIndex(pairKey: number) {
    if (!pairKey || this.activeAlertIndex === pairKey) {
      this.activeAlertIndex = -1;
    } else {
      this.activeAlertIndex = pairKey;
    }
    this.setAlertIndex.emit(this.activeAlertIndex);
    if (this.activeAlertIndex === -1) {
      this.activeAlertChanged.emit(null);
      return;
    }
    const activeAlert = this.alertList.find((alert) => alert.id === this.activeAlertIndex);
    this.activeAlertChanged.emit(activeAlert);
  }

  editClicked(alert: IAlertsModel) {
    if (alert.alertTypeId === AlertConstants.STR_TO_ID.ASSIGN_RAMP_AGENT || alert.alertTypeId === AlertConstants.STR_TO_ID.ASSIGN_RAMP_AGENT_CRITICAL) {
      this.userLegAssignmentService.getUserLegAssignment({
        arrivalLegId: alert.legId
      }).subscribe((res) => {
        this.router.navigate(['/assign'], {state: {userId: res?.[0]?.userId}});
      });

      return;
    }
    if (alert.alertTypeId === 1) {
      const modalRef = this.modalService.open(EditPairDialogComponent, {size: 'lg'});
      const modal = modalRef.componentInstance as EditPairDialogComponent;
      modal.alert = alert;
    } else {
      const modalRef = this.modalService.open(EditPairDialogComponent, {size: 'xxl'});
      const modal = modalRef.componentInstance as EditPairDialogComponent;
      modal.alert = alert;
    }
  }

  async markAsResolvedClick(alertId: number) {
    const alert = this.alertList.find((x) => x.id === alertId);
    //await firstValueFrom(this.alertService.saveAlert({ ...alert, isResolved: true, resolvedAt: dayjs().utc().toDate(), isActive: false }));
    await firstValueFrom(this.alertService.markAsResolvedAlert({
      ...alert,
      isResolved: true,
      resolvedAt: dayjs().utc().toDate(),
      isActive: false
    }))
    this.refetchRequired.emit();
  }


  remindLater(data: { id: number; hours: number; minutes: number }) {
    this.remindMeLaterClicked.emit(data);
  }

  alertTrack(index: number, model: IAlertsModel): number {
    return model.id;
  }

  public changeSort() {
    this.sortChanged.emit();
  }
}
