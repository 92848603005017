import {Pipe, PipeTransform} from '@angular/core';
import {HasAccess} from "../constants/has-access.enum";

@Pipe({
  name: 'roleManagementTestTag'
})
export class RoleManagementTestTagPipe implements PipeTransform {

  transform(value: {
    role: string;
    airports: string;
    web: { read: HasAccess, write: HasAccess },
    mobile: { read: HasAccess, write: HasAccess },
    id: string,
  }, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `${value.role}_${value.airports.replace(', ', '-')}`;
  }

}
