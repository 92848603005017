import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import { AsmService } from '../../../services/asm.service';
import { ToastService } from '../../../services/toast.service';
import { firstValueFrom } from 'rxjs';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { AcarsService } from '../../../services/acars.service';

@Component({
  selector: 'multi-app-acars-upload',
  templateUrl: './multi-acars-upload.component.html',
  styleUrls: ['./multi-acars-upload.component.scss']
})
export class MultiAcarsUploadComponent implements OnInit {

  successIcon = faCheck;
  errorIcon = faX;
  isBusy = false;

  fileList: { file: File, status: 'success' | 'error' | 'processing' | 'pending' }[] = [];

  constructor(private acarsService: AcarsService, private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    this.fileList = [];
    for (let i = 0; i < files.length; i++) {
      this.fileList.push({ file: files.item(i), status: 'pending'});
    }
  }

  async getFileContent(file: File) {
    if (!file || file.size === 0) {
      return Promise.resolve(null);
    }
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        return resolve(fileReader.result);
      };
      fileReader.readAsText(file);
    });
  }

async upload() {
  this.isBusy = true;
  for (const fileObj of this.fileList) {
    fileObj.status = 'processing';
    const fileContent = await this.getFileContent(fileObj.file);
    const result = await firstValueFrom(this.acarsService.uploadACARS({ fileContent, fileName: fileObj.file.name }));
    if (result) {
      this.toastService.showSuccess('ACARS file has been processed successfully!');
    }
    fileObj.status = result ? 'success' : 'error';
  }
  this.isBusy = false;
}

}
