import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../../../services/toast.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ISlaGseLevelGse } from '../../../../../shared/models/sla-gse-level.model';
import { IGenericContainerObject } from '../../../../../shared/models/genericContainerObject.model';
import { IGse } from '../../../../../shared/models/gse.model';
import { DefinedGses } from '../../../../../shared/constants/defined-gses';
import { take } from 'rxjs';
import { ISettingsSlaGseLevelRequest } from '../../../../../shared/models/requests/settings-sla-gse-level-request.model';
import { SlaGseLevel } from '../../../../../shared/constants/slaGseLevel.constants';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-sla-gse-level-gse-dialog',
  templateUrl: './sla-gse-level-gse-dialog.component.html',
  styleUrls: ['./sla-gse-level-gse-dialog.component.scss']
})
export class SlaGseLevelGseDialogComponent implements OnInit {

  @Input() title?: string;
  formGroup?: UntypedFormGroup;

  @Input() data?: any;

  isNew?: boolean;
  processName?: string;
  acTypeName?: string;
  airportName?: string;
  gse?: ISlaGseLevelGse;

  definedGses: IGenericContainerObject<IGse> = DefinedGses.IGENERIC_CONTAINER_OBJECT;


  form?: UntypedFormGroup;

  hasGse?: boolean;

  constructor(private fb: UntypedFormBuilder, private fns: AngularFireFunctions, private activeModal: NgbActiveModal, private modalService: NgbModal) {
  }

  ngOnInit() {

    this.isNew = this.data.isNew || true;
    this.processName = this.data.process || '';
    this.acTypeName = this.data.acType || '';
    this.airportName = this.data.airport || '';
    this.gse = this.data.gse || {

      title: 'None',
      isSla: true,
      isNone: true,
      maxProcessTimeInNumbers: 0
    };

    this.form = this.fb.group({
      title: [null, Validators.required],
      // gseIdentifier: [null, Validators.required],
      isSla: [false, Validators.required],
      maxProcessTimeInNumbers: [0, Validators.required],
      dateTimeFrom: [null],
      dateTimeTo: [null],
      isNone: [false, Validators.required],
      hasGse: [false]
    });

    if (this.gse) {
      console.log('this.gse value: ', this.gse);
      this.form.patchValue(
        {
          ...this.gse,
          hasGse: !this.gse.isNone
        }
      );
      console.log('form after patch: ', this.form.value);
      this.hasGse = !this.form.value.isNone;
    } else {
      this.form.patchValue({
        hasGse: true
      });
      this.hasGse = true;
    }

    // this.form?.get('hasGse')?.valueChanges.pipe(takeUntil(this.destroySubject))
    //   .subscribe((hasGse: boolean) => {
    //     this.hasGse = hasGse;
    //     if (hasGse) {
    //       this.form?.patchValue({
    //         title: null,
    //         isSla: false,
    //         isNone: false
    //       });
    //     } else {
    //       this.form?.patchValue({
    //         title: 'None',
    //         isSla: true,
    //         isNone: true
    //       });
    //     }
    //   });
  }

  ngOnDestroy(): void {
  }


  saveButtonClicked() {
    if (this.isNew && this.form?.invalid) {
      this.form.markAsTouched();
      return;
    } else if (this.isNew && this.form?.valid) {
      this.updateData();
      // this.saveClicked.emit();
    }
  }

  updateData() {
    if (this.form?.invalid) {
      return;
    }

    const formVal = this.form?.value as ISlaGseLevelGse;

    const newGse: ISlaGseLevelGse = {
      title: formVal.title,
      gseIdentifier: formVal.title,
      isSla: formVal.isSla,
      maxProcessTimeInNumbers: formVal.maxProcessTimeInNumbers,
      dateTimeFrom: formVal.dateTimeFrom,
      dateTimeTo: formVal.dateTimeTo,
      isNone: formVal.isNone
    };

    const request: ISettingsSlaGseLevelRequest = {
      slaGseLevelType: SlaGseLevel.GSE,
      process: this.processName,
      acType: this.acTypeName,
      airport: this.airportName,
      gse: newGse.title,
      newValue: newGse
    };

    console.log('request: ', request);

    const callable = this.fns.httpsCallable('updateSlaAndGseByRequest');
    const data$ = callable(request);
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    if (this.isNew && this.formGroup?.invalid) {
      this.formGroup?.markAsTouched();
      return;
    } else if (this.isNew && this.formGroup?.valid) {
      //this.saveWithDefaultValues();
    }
  }
}
