<div class="modal-header">
  <span class="modal-title">Add Role</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()"
          data-test="edit-role-code-close-button">
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="form">
    <form [formGroup]="form">
      <div class="flex flex-column">
        <div class="flex justify-content-evenly">
          <div class="col-8 title">
            Name
          </div>
        </div>

        <div class="flex justify-content-evenly">
          <div class="col-8 form-field-header">
            <input type="text" class="form-control" id="role-name" placeholder=""
                   formControlName="name" data-test="edit-name-input" [ngClass]="{ 'ng-invalid': form?.invalid }">
          </div>
        </div>

        <div class="mt-2 text-danger text-center" *ngIf="form.errors?.nameExists">
          This role name already exists
        </div>

      </div>
    </form>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()"
          data-test="add-role-cancel-button">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="form?.disabled || form?.invalid || isBusy" data-test="add-role-save-button">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
