import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-edit-airport-turnaround-times-form',
  templateUrl: './add-edit-att-form.component.html',
  styleUrls: ['./add-edit-att-form.component.scss']
})
export class AddEditAirportTurnaroundTimesFormComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  constructor() { }

  ngOnInit(): void {
  }

}
