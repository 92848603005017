<ng-container *ngIf="!fetching; else updating">
  <ng-container *ngIf="selectedPairId > 0 || selectedPairId === undefined || selectedPairId === null; else unsaved">
    <app-leg-list-sql [passengerClasses]="passengerClasses" [pairOverviewList]="pairList"
                      [timezone]="timezone" [hasNextPage]="false" [page]="1"
                      [activePairIndex]="activeIndex" [refreshSql]="refetchFlightsRequired"></app-leg-list-sql>
  </ng-container>

</ng-container>

<ng-template #updating>
  <div class="flex justify-content-center dark-blue mt-5 fs-4 gap-3">
    <div class="fw-bold">Getting latest data, please wait...</div>
    <app-loading-spinner color="#4285F6"></app-loading-spinner>
  </div>
</ng-template>

<ng-template #unsaved>
  <div class="flex justify-content-center dark-blue mt-5 fs-4 gap-3">
    <div class="fw-bold">This pair will be created after you save your changes in network changes section.</div>
  </div>
</ng-template>
