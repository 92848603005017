<div class="d-flex justify-content-center align-items-center vh-100 vw-100">
  <div class="custom-container">
    <div class="login-card bg-white round-left shaded p-3 flex-basis-40">
      <div class="col-12 modal-header justify-content-center">
        <!-- <h2>Login</h2> -->
        <object data="../../../content/images/Avitium_Ramp_Watch_normal.svg" type="image/svg+xml"
                alt="avitium ramp watch logo" width="178.98px" height="43px">
          <!-- Für Browser ohne SVG-Unterstützung -->
        </object>
      </div>
      <div class="flex flex-column h-100">
        <form [formGroup]="form">
          <div class="mb-3">
            <label for="userInput" class="form-field-content">Email address</label>
            <input type="text" formControlName="username" class="form-control text-center" id="userInput"
                   autocomplete="ramp-watch login username" (keydown)="onKeyPress($event)" data-test="email-input">
          </div>
          <div class="mb-3">
            <label for="pwInput" class="form-field-content">Password</label>
            <input type="password" formControlName="password" class="form-control text-center" id="pwInput"
                   autocomplete="ramp-watch login current-password" (keydown)="onKeyPress($event)"
                   data-test="password-input">
          </div>
        </form>
        <div class="fs-16">
          <p>
            <ngb-alert *ngIf="responseError || (user && user.role !== userRoles.RAMP_AGENT)" type="danger"
                       data-test="error-alert"
                       [dismissible]="false">
              <strong>Error!</strong> You are not allowed to proceed because either the given credentials are
              incorrect or you do not have the permission!
            </ngb-alert>

            <ngb-alert *ngIf="!responseError && !user" type="info" [dismissible]="false" data-test="info-alert">
              <strong>Info!</strong> Please enter your credentials to proceed to the duty managers / ops controllers
              application.
            </ngb-alert>
          </p>
        </div>
        <div class="sign-in">
          <div class="d-grid">
            <button class="btn button-blue " [disabled]="form.invalid" (click)="signIn()" data-test="sign-in-button">
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-basis-60 shaded">
      <img src="content/images/acAtRamp.png" class="image" alt="Airplane">
    </div>
  </div>
</div>
