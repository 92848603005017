<div class="light-blue-border flex flex-column p-3 user-select-none">
  <div class="flex justify-content-between cursor-pointer" (click)="onClicked.emit(text)">
    <div class="fw-bold dark-blue">{{ text }}</div>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#A6A6A6" class="bi bi-caret-down-fill arrow"
         [ngClass]="{ 'open': isOpen}"
         viewBox="0 0 16 16">
      <path
        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
    </svg>
  </div>
  <div class="gantt-slider-content" [ngClass]="{ open: isOpen, 'mt-3': isOpen }">
    <ng-container *ngIf="!hide">
      <ng-content></ng-content>
    </ng-container>
  </div>

</div>
