import { Pipe, PipeTransform } from '@angular/core';
import { ISimpleData } from '../models/simpleData.model';

@Pipe({
  name: 'flightServiceTypesTestTag'
})
export class FlightServiceTypesTestTagPipe implements PipeTransform {

  transform(value: ISimpleData, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `flight-service-type-${value?.code}`;
  }

}
