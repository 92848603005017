<ng-container *ngIf="form && this.passengerClasses?.length && this.airlineDesignators?.length">
  <app-settings-header [backButton]="true" backButtonUrl=".."
                       [text]="acRegistrationId ? 'Edit Aircraft Registration ' + acRegistration?.registration : 'Add Aircraft Registration'"
                       [showSaveButton]="true" [textSize]="20" (onSaveClicked)="onSaveClick()"
                       [saveButtonDisabled]="form.invalid || pageAccess !== Access.RW"
                       [spinner]="isBusy"></app-settings-header>
  <div class="settings-container mt-3 flex flex-column avitium-scrollbar">
    <app-settings-card sectionTitle="General" [formGroup]="form" data-test="general-card">
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="acTypeId" class="form-label">A/C Type</label>
          <select class="form-control form-select" id="acTypeId" formControlName="acTypeId"
                  data-test="settings-ac-registration-ac-type-select">
            <ng-container *ngFor="let acType of acTypes">
              <option [value]="acType.id" [attr.data-test]="'ac-type-option-' + acType.alias">{{ acType?.alias }}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="flex-basis-30">
          <label for="registration" class="form-label">Registration</label>
          <input type="text" class="form-control" id="registration" formControlName="registration"
                 data-test="settings-ac-registration-registration-input">
        </div>
        <div class="flex-basis-30">
          <label for="description" class="form-label">Description</label>
          <input type="text" class="form-control" id="description" formControlName="description"
                 data-test="settings-ac-registration-description-input">
        </div>
      </div>
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="description" class="form-label">Aircraft Name</label>
          <input type="text" class="form-control" id="name" formControlName="name"
                 data-test="settings-ac-registration-name-input">
        </div>
        <div class="flex-basis-30">
          <label for="mtow" class="form-label">MTOW</label>
          <input type="text" class="form-control" id="mtow" formControlName="mtow"
                 data-test="settings-ac-registration-mtow-input">
        </div>
        <div class="flex-basis-30">
          <label for="calibration" class="form-label">Calibration</label>
          <select class="form-select light-blue-border dark-blue" id="calibration" formControlName="calibrationId"
                  data-test="settings-ac-registration-calibration-select">
            <option *ngFor="let unit of measurementUnits" [ngValue]="unit.id"
                    [attr.data-test]="'measurement-unit-' + unit.code">
              {{ unit.code }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="mobNr" class="form-label">Mobile Number</label>
          <input type="text" class="form-control" id="mobNr" formControlName="mobNr"
                 data-test="settings-ac-registration-mobnr-input">
        </div>
        <div class="flex-basis-30">
          <label for="deliveryDate" class="form-label">Aircraft Delivery Date</label>
          <div class="input-group">
            <input class="form-control" placeholder="please select date" id="deliveryDate"
                   name="dp" formControlName="deliveryDate" ngbDatepicker #d="ngbDatepicker"
                   data-test="settings-ac-registration-delivery-date-input">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar h-100"
                      (click)="d.toggle()" type="button" data-test="settings-ac-registration-calendar-button"></button>
            </div>
          </div>
          <!--        <input type="text" class="form-control" id="deliveryDate" formControlName="deliveryDate">-->
        </div>
        <div class="flex-basis-30">
          <label for="selcal" class="form-label">Selcal</label>
          <input type="text" class="form-control" id="selcal" formControlName="selcal"
                 data-test="settings-ac-registration-selcal-input">
        </div>
      </div>
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="noseCode" class="form-label">Nose Code</label>
          <input type="text" class="form-control" id="noseCode" formControlName="noseCode"
                 data-test="settings-ac-registration-nose-code-input">
        </div>
        <div class="flex-basis-30">
        </div>
        <div class="flex-basis-30">
        </div>
      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Seating Configuration"
                       informationTooltip="At least one seating configuration has to be entered"
                       data-test="seating-configurations-card">
      <div class="flex flex-column gap-2">
        <div class="seating-grid">
          <div class="flex flex-column gap-1">
            <div>AC Type Seating Configurations</div>
            <div>
              <select class="form-control form-select" id="settings-ac-registration-ac-type-seats" [(ngModel)]="acSeat"
                      (ngModelChange)="acSeatSelected($event)"
                      data-test="settings-ac-registration-ac-type-seating-config-select"
                      [disabled]="pageAccess !== Access.RW">
                <ng-container *ngFor="let seat of acAvailableSeats | keyvalue">
                  <option [ngValue]="seat.key"
                          [attr.data-test]="acAvailableSeats | seatingConfigurationsForAcTypesTestTag:+seat.key">{{ getAcSeatList(seat.key) }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="flex flex-column gap-1">
            <div>Operated By</div>
            <div>
              <select class="form-control form-select" id="settings-ac-type-seating-config-airline-designator"
                      [ngModel]="operatedBy" (ngModelChange)="operatedByChanged($event)" disabled
                      data-test="settings-ac-registration-airline-designator-select"
                      [disabled]="pageAccess !== Access.RW">
                <ng-container *ngFor="let loc of airlineDesignators">
                  <option [ngValue]="loc?.id"
                          [attr.data-test]="'settings-airline-des-' + loc.code">{{ loc?.code }} {{ loc?.description }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
          <div></div>
        </div>


        <ng-container [formGroup]="form">
          <div class="seating-grid dark-blue">
            <ng-container formGroupName="seatingConfig">
              <div *ngFor="let passClass of this.passengerClasses | keyvalue" class="flex flex-column">
                <div>
                  {{ passClass?.value?.code }}
                </div>
                <ng-container [formGroupName]="passClass?.value?.code">
                  <input type="number" min="0" class="form-control"
                         id="settings-ac-type-seating-config-{{passClass?.key}}" formControlName="description"
                         [attr.data-test]="'settings-ac-registration-seating-code-' + passClass?.value?.code">
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>

      </div>
    </app-settings-card>
  </div>
</ng-container>
