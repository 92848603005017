import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAirportTerminals } from '../../../../../shared/models/airport-terminals.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AirportsService } from '../../../../../services/airports.service';
import { ToastService } from '../../../../../services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-edit-airport-terminal-dialog',
  templateUrl: './add-edit-airport-terminal-dialog.component.html',
  styleUrls: ['./add-edit-airport-terminal-dialog.component.scss']
})
export class AddEditAirportTerminalDialogComponent implements OnInit {

  title = "Add Airport Terminals";
  @Input() terminalData?: IAirportTerminals;
  @Input() outerSaveClicked?: EventEmitter<any>;
  @Output() saveClicked = new EventEmitter();
  formGroup: UntypedFormGroup;
  isBusy = false;

  constructor(private airportService: AirportsService, private toastService: ToastService, private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.terminalData?.name || '', Validators.required),
      alias: new UntypedFormControl(this.terminalData?.alias || '', Validators.required)
    });
    // this.outerSaveClicked?.pipe(take(1)).subscribe(() => {
    //
    // })
  }

  closeModal(reason?: any) {
    this.activeModal.dismiss(reason);
  }

  onSaveClick() {
    const data: IAirportTerminals = { ...this.terminalData, alias: this.formGroup.get('alias').value, name: this.formGroup.get('name').value };
    this.isBusy = true;
    this.airportService.saveAirportTerminal(data).subscribe((result) => {
      if(result?.id) {
        this.toastService.showSuccess("Terminal has been saved");
        this.terminalData.name = data.name;
        this.terminalData.alias = data.alias;
        this.closeModal(true);
      }
      this.isBusy = false;
    });
  }
}
