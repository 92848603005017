import { Pipe, PipeTransform } from '@angular/core';
import { IAcRegistration } from '../models/ac-registration.model';

@Pipe({
  name: 'acRegistrationsTestTag'
})
export class AcRegistrationsTestTagPipe implements PipeTransform {

  transform(value: IAcRegistration, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `ac-registration-${value?.registration}`;
  }

}
