<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal('Cross click')">
    <!--<span aria-hidden="true" class="colorWhite">&times;</span> -->
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-12 form-field-header text-success" *ngIf="fileIsValid && !isLoading">
        SSIM File is valid
      </div>
      <div class="col-12 form-field-header text-danger" *ngIf="!fileIsValid && !isLoading">
        SSIM File is invalid
      </div>
      <div class="col-12 form-field-header" *ngIf="isLoading">
        Parsing SSIM File, please wait... <app-loading-spinner color="#000000"></app-loading-spinner>
      </div>
      <ng-container *ngIf="highRisk && highRisk.length > 0">
        <div class="col-12 form-field-header text-high-risk">
          High risk [{{warnings.length}}]:
        </div>
        <div class="col-12 form-field-content" *ngFor="let highRisk of highRisk; let i = index" [attr.data-test]="'ssim-highrisk-' + i">
          {{highRisk}}
        </div>
      </ng-container>
      <ng-container *ngIf="warnings && warnings.length > 0">
        <div class="col-12 form-field-header text-warning">
          Warnings [{{warnings.length}}]:
        </div>
        <div class="col-12 form-field-content" *ngFor="let warning of warnings; let i = index" [attr.data-test]="'ssim-warning-' + i">
          {{warning}}
        </div>
      </ng-container>
      <ng-container *ngIf="errors && errors.length > 0">
        <div class="col-12 form-field-header text-danger" *ngIf="errors">
          Errors [{{errors.length}}]:
        </div>
        <div class="col-12 form-field-content" *ngFor="let error of errors; let i = index" [attr.data-test]="'ssim-error-' + i">
          {{error}}
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal('Close click')" data-test="validate-ssim-cancel-button">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus [disabled]="errors && errors.length || isBusy || isLoading" (click)="onUploadClick()" data-test="validate-ssim-upload-button">{{ isBusy ? '' : 'Upload'}}
    <div *ngIf="isBusy" class="spinner-border text-light spinner" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </button>
</div>
