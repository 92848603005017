import { Pipe, PipeTransform } from '@angular/core';
import { ISimpleData } from '../models/simpleData.model';

@Pipe({
  name: 'passengerClassesTestTag'
})
export class PassengerClassesTestTagPipe implements PipeTransform {

  transform(value: ISimpleData, ..._args: unknown[]): string {
    if (!value) {
      return 'invalid-value';
    }
    return `passenger-class-${value?.code}`;
  }

}
