import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from '../../../../../services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { DefinedGses } from '../../../../../shared/constants/defined-gses';
import { ISlaGse } from '../../../../../shared/models/sla-gse.model';
import { take, takeUntil } from 'rxjs/operators';
import { SettingsSlaGseMainFormBuilder } from '../../../../../shared/forms/formBuilders/settings/settings-sla-gse-main-form-builder';
import { IGenericContainerObject } from '../../../../../shared/models/genericContainerObject.model';
import { IAirport } from '../../../../../shared/models/airport.model';
import { IAcType } from '../../../../../shared/models/ac-type.model';
import { IGse } from '../../../../../shared/models/gse.model';
import { IGsesModel } from '../../../../../shared/models/gses.model';
import { GseService } from '../../../../../services/gse.service';
import { IProcessesModel } from '../../../../../shared/models/processes.model';

@Component({
  selector: 'app-add-edit-sla-gse-dialog',
  templateUrl: './add-edit-sla-gse-dialog.component.html',
  styleUrls: ['./add-edit-sla-gse-dialog.component.scss']
})
export class AddEditSlaGseDialogComponent implements OnInit {

  @Input() title?: string;
  isBusy = false;
  formGroup?: UntypedFormGroup;
  isNew?: boolean;
  definedGses: IGenericContainerObject<IGse> = DefinedGses.IGENERIC_CONTAINER_OBJECT;

  process: IProcessesModel;
  gse: IGsesModel;
  acTypeId?: number;
  airportId?: number;
  airportList?: IGenericContainerObject<IAirport>;
  acTypeList?: IGenericContainerObject<IAcType>;

  constructor(private toastService: ToastService, private activeModal: NgbActiveModal, private gseService: GseService) { }


  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    //const gseIdentifier = this.formGroup.get('title').value.replace(' ', '_').toUpperCase();
    const realTitle = DefinedGses[this.formGroup.get('title').value].title;
    this.isBusy = true;
    const data = {
      maximumProcessTimeInMinutes: this.formGroup.value.maximumProcessTimeInMinutes,
      title: realTitle,
      isNone: !this.formGroup.get('hasGse').value,
      acTypeId: this.acTypeId !== 0 ? this.acTypeId : null,
      airportId: this.airportId !== 0 ? this.airportId : null,
      gseIdentifier:  this.formGroup.value.title.length ? this.formGroup.value.title : '',
      id: this.isNew ? undefined : this.gse?.id,
      processId: this.process?.id,
      isSla: this.formGroup.value.isSla,
    }
    this.gseService.saveGses(data).subscribe((result) => {
      if(result) {
        this.toastService.showSuccess('GSE data have been saved');
        this.closeModal();
      }
      this.isBusy = false;
    })
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      isSla: new UntypedFormControl(this.gse?.isSla ?? false),
      hasGse: new UntypedFormControl(this.gse ? this.gse.isNone === false : false),
      title: new UntypedFormControl(this.gse ? this.gse.title : '', Validators.required),
      maximumProcessTimeInMinutes: new UntypedFormControl(this.gse?.maximumProcessTimeInMinutes ?? 0, [Validators.required, Validators.min(0)]),
    });

  }
}
