import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {IUser} from '../../../models/user.model';
import {IGenericContainerObject} from '../../../models/genericContainerObject.model';
import {UserService} from '../../../../services/user.service';
import {AuthService} from '../../../../services/auth.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ChatService} from '../../../../services/chat.service';
import {IMyChatGroups} from '../../../models/my-chat-groups.interface';
import {StaticUserGroupConstants} from "../../../constants/static-user-group.constants";


@Component({
  selector: 'app-vcard-list',
  templateUrl: './vcard-list.component.html',
  styleUrls: ['./vcard-list.component.scss']
})
export class VcardListComponent implements OnInit, OnDestroy, OnChanges {

  @Output() openChatAction = new EventEmitter<IUser>();
  destroy$ = new Subject();

  users: IGenericContainerObject<IUser> = {};

  filteredUsers: IUser[] = [];

  @Input() flightStations: string[];
  @Input() selectedStations: string[];
  @Input() refreshListener: EventEmitter<void>;
  @Input() searchText: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.flightStations || changes.searchText) {
      this.updateList();
    }
  }

  constructor(private userService: UserService, private authService: AuthService, public chatService: ChatService) {
  }

  getChatGroup(user: IUser): IMyChatGroups {
    return Object.values(this.chatService.messageGroups).find((group) => group.userId === user.id);
  }

  getChatGroupWithLocalData(user: IUser): IMyChatGroups {
    return Object.values(this.chatService.myMessageGroups).find((group) => group.chatGroupId === this.getChatGroup(user)?.chatGroupId);
  }

  updateList() {
    this.filteredUsers.splice(0, this.filteredUsers.length);
    for (const user of Object.values(this.users)) {
      if (this.searchText?.length && !user.firstname.toLowerCase().concat(user.lastname.toLowerCase()).includes(this.searchText?.toLowerCase())) {
        continue;
      }
      if (this.flightStations.length) {
        if (!this.flightStations.includes(user.location) || user.userGroup !== StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT && user.userGroup !== StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER || this.authService.user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER && user.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT && user.location !== this.authService.user.location) {
          continue;
        }
      }
      if (this.selectedStations.includes(user.location)) {
        this.filteredUsers.push(user);
      }
    }
  }

  ngOnInit() {
    this.userService.fetchUsers().subscribe((result) => {
      for (const user of result) {
        if (user.id === this.authService.user.id) {
          continue;
        }
        this.users[user.id] = user;
      }
      this.updateList();
    });
    this.refreshListener?.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateList();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  protected readonly Object = Object;
}
