import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {SettingsMenuModel} from '../../shared/models/settings-menu.model';
import {PermissionService} from "../../services/permission.service";
import {PermissionUIMasks} from "../../shared/constants/enums";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

  links: SettingsMenuModel[];
  activeNavItem: string;

  constructor(public router: Router, permissionService: PermissionService) {
    Promise.all([permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_GENERAL_SETTINGS), permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_USER_MANAGEMENT), permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_ROLE_MANAGEMENT), permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_FLIGHTS_SCHEDULE_MANAGEMENT), permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_AC_TYPE_MANAGEMENT), permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_AC_REGISTRATION_MANAGEMENT), permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_AIRPORT_MANAGEMENT), permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_SLA_AND_GSES_MANAGEMENT), permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_INTERFACE_MANAGEMENT), permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_ALERT_MANAGEMENT)]).then(([generalSettings, userManagement, roleManagement, flightSchedule, acTypeManagement, acRegManagement, airportManagement, slaAndGseManagement, interfaceManagement, alertManagement]) => {
      this.links = [
        {
          title: 'General Settings',
          link: 'general',
          imgSrc: 'content/images/icons/settings-general-settings.svg',
          disabled: !generalSettings
        },
        {
          title: 'User Management',
          link: 'users',
          imgSrc: 'content/images/icons/settings-user-management.svg',
          disabled: !userManagement
        },
        {
          title: 'Role Management',
          link: 'role-management',
          imgSrc: 'content/images/icons/person-bounding-box.svg',
          disabled: !roleManagement
        },
        {
          title: 'Flight Schedule Management',
          link: 'ssim',
          imgSrc: 'content/images/icons/gses/3 departing.svg',
          disabled: !flightSchedule
        },
        {
          title: 'A/C Type Management',
          link: 'ac-types',
          imgSrc: 'content/images/icons/settings-plane.svg',
          disabled: !acTypeManagement
        },
        {
          title: 'A/C Registration Management',
          link: 'ac-registrations',
          imgSrc: 'content/images/icons/settings-plane-front.svg',
          width: 35, disabled: !acRegManagement

        },
        {
          title: 'Airport Management',
          link: 'airports',
          imgSrc: 'content/images/icons/settings-airport-management.svg',
          disabled: !airportManagement
        },
        {
          title: 'SLA and GSE Management',
          link: 'sla-gse',
          imgSrc: 'content/images/icons/settings-sla-gse.svg',
          disabled: !slaAndGseManagement
        },
        {
          title: 'Interface Management',
          link: 'interfaces',
          imgSrc: 'content/images/icons/database-fill-up.svg',
          width: 24,
          height: 24, disabled: !interfaceManagement
        },
        {
          title: 'Alert Management',
          link: 'alerts',
          imgSrc: 'content/images/icons/alert.svg',
          width: 24,
          height: 24,
          disabled: !alertManagement
        },
      ];
    });
  }

  onActivate() {
    window.scroll(0, 0);
  }
}
