<form *ngIf="form" [formGroup]="form">
  <app-access-denied *ngIf="!accessType"></app-access-denied>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="h-54">
          <div class="row mb-3">
            <div class="col-1 fw-bold dark-blue">
              Active
            </div>
            <div class="col-2 fw-bold dark-blue">
              Process
            </div>
            <div class="col-4  fw-bold dark-blue">
              GSE
            </div>
            <div class="col-1  fw-bold dark-blue">
              Amount
            </div>
            <div class="col-2 text-center fw-bold dark-blue">
              SLA/Non SLA
            </div>
            <div class="col-2  fw-bold dark-blue">
              max. time
            </div>
          </div>
        </div>
        <ng-container *ngFor="let process of processList | keyvalue">
          <div class="h-54" [formGroupName]="process?.value?.id.toString()"
               [attr.data-test]="'process-' + process?.value?.internalName">
            <div class="row">
              <div class="form-switch col-1  text-center">
                <input class="form-check-input" type="checkbox" role="switch" [id]="process?.value?.title + '_inUse'"
                       formControlName="inUse" data-test="change-turnaround-processes-in-use-check">
              </div>
              <div class="col-2 process-name ">
                {{ process?.value?.title }}
              </div>
              <div class="col-3 mb-3 ">
                <select class="form-control form-select light-blue-border dark-blue" id="search-flight-ac-type"
                        formControlName="gseType" (ngModelChange)="gseTypeSelectionChanged(process?.value?.id, $event)"
                        data-test="change-turnaround-processes-gse-type-select">
                  <option *ngFor="let gse of process?.value?.gses | keyvalue" [value]="gse?.value?.id"
                          [attr.data-test]="'gse-' + gse.value.title">
                    {{ definedGses[gse.value.gseIdentifier]?.title || gse.value.title }}
                  </option>
                </select>
              </div>
              <div class="col-1 ">
                <i *ngIf="process?.value?.selectedGse?.isNone === false"
                   [class]="'demo-icon ' + getIconClass(process?.value?.selectedGse?.gseIdentifier) + ' fs-30'"></i>
              </div>
              <div class="col-1  ">
                <input *ngIf="process?.value?.selectedGse?.isNone === false" type="number"
                       class="form-control light-blue-border dark-blue" [id]="process?.value?.title + '_amount'"
                       formControlName="amount" min="1" data-test="change-turnaround-processes-amount-input"/>
              </div>
              <div class="col-2  text-center ">
                <!-- <input type="checkbox" class="" [id]="process?.value?.title + '_isSLA'" formControlName="isSla"/> -->
                <ng-container *ngIf="process?.value?.selectedGse">
                  <div class="position-relative">
                    <div class="position-absolute w-100 d-flex justify-content-center">
                      <span *ngIf="process?.value?.selectedGse?.isSla" class="sla-indicator sla fs-15">SLA</span>
                      <span *ngIf="!process?.value?.selectedGse?.isSla"
                            class="sla-indicator non-sla fs-15">Non-SLA</span>
                    </div>
                  </div>
                </ng-container>

              </div>
              <div class="col-2 process-name ">
                <span>
                  {{ process?.value?.selectedGse?.maximumProcessTimeInMinutes | timeFromMinutes }}
                </span>
              </div>

            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-restore-cancel-row [isSaveDisabled]="form.invalid || isBusy" [showSpinner]="isBusy"
                              (restoreClicked)="restoreForm()" (saveClicked)="saveForm()"></app-restore-cancel-row>
    </div>
  </div>
</form>

<div *ngIf="!form" class="container">
  <div class="row">
    <div class="col-12">
      <div class="container">
        <div class="row mb-3">
          <div class="col-1  ">
            Active
          </div>
          <div class="col-3  ">
            Process
          </div>
          <div class="col-3  ">
            GSE
          </div>
          <div class="col-2  ">
            Amount
          </div>
          <div class="col-2  ">
            is SLA
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="container h-54"></div>
          <div class="container h-54"></div>
          <div class="container h-54"></div>
          <div class="container h-54"></div>
          <div class="container h-54"></div>
          <div class="container h-54"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-restore-cancel-row [isSaveDisabled]="true"></app-restore-cancel-row>
    </div>
  </div>
</div>
