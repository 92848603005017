import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IExtendTurnaroundRequest } from '../../../../models/turnaround.model';
import { SettingsService } from '../../../../../services/settings.service';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISimpleData } from '../../../../models/simpleData.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { TimezoneService } from '../../../../../services/timezone.service';
import { AuthService } from '../../../../../services/auth.service';
import { IUser } from '../../../../models/user.model';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { PairTurnaroundTimeLogsService } from '../../../../../services/pair-turnaround-time-logs.service';
import { StatusType } from '../../../../constants/turnaround-extend-request-status.constant';
import * as dayjs from 'dayjs';
import { IPairDetail } from '../../../../models/pair-detail.model';
import { LegsService } from '../../../../../services/legs.service';
import { PairLegTimesLogService } from '../../../../../services/pair-leg-times-log.service';
import { ToastService } from '../../../../../services/toast.service';
import { DelayService } from '../../../../../services/delay.service';
import { TimeTypesService } from '../../../../../services/time-types.service';
import { calculateETADate } from 'src/app/shared/utils/utils';
import { TurnaroundService } from '../../../../../services/turnaround.service';
import { IPairOverview } from '../../../../models/pair-overview.model';
import { IGenericContainerObject } from '../../../../models/genericContainerObject.model';

@Component({
  selector: 'app-issue-etd-request',
  templateUrl: './issue-etd-request.component.html',
  styleUrls: ['./issue-etd-request.component.scss']
})
export class IssueEtdRequestComponent implements OnInit, OnDestroy {

  @Input() extendEtdRequest: IExtendTurnaroundRequest;
  @Input() pair: IPairDetail;
  @Input() pairOverview?: IPairOverview;
  @Input() timezone: string;
  @Input() refreshSql: EventEmitter<any>;

  form: UntypedFormGroup;

  user: IUser;

  delayCodeList: ISimpleData[];
  delayCodesKV: IGenericContainerObject<ISimpleData> = {};
  unsubscribe$ = new Subject();

  newEtd: Date;
  dateFormat = 'dd/mm/yyyy';
  isBusy = false;


  constructor(
    private fb: UntypedFormBuilder,
    private settingsService: SettingsService,
    private legService: LegsService,
    private timezoneService: TimezoneService,
    private authService: AuthService,
    private fns: AngularFireFunctions,
    private generalSettingsService: GeneralSettingsService,
    private pairTurnaroundTimeLogsService: PairTurnaroundTimeLogsService,
    private pairLegTimesLogService: PairLegTimesLogService,
    private toastService: ToastService,
    private delayService: DelayService,
    private timeTypesService: TimeTypesService,
    private turnaroundService: TurnaroundService) {
  }

  ngOnInit(): void {
    this.generalSettingsService.generalSettings.pipe(takeUntil(this.unsubscribe$)).subscribe((settings) => {
      this.dateFormat = settings.dateFormat;
    })

    // this.settingsService.getDelayCodes().pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((codeList: ISimpleData[]) => {
    //     this.delayCodeList = codeList;
    //   });
    //
    // this.timezoneService.getTimezone().pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((tz: string) => this.timezone = tz);

    if(!Object.keys(this.timeTypesService.timeTypesKV).length) {
      this.timeTypesService.getTimeTypes().subscribe((result) => {
        if (result?.length) {
          result.forEach((item) => {
            this.timeTypesService.timeTypesKV[item.identifier] = item;
          });
        }
      })
    }

    this.generalSettingsService.getDelayCodes().subscribe((result) => {
      this.delayCodeList = result;
      for (const delay of result) {
        this.delayCodesKV[delay.id] = delay;
      }

    });

    this.createForm();
    this.subscribeToCurrentUser();
    this.calcNewEtd();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  createForm() {
    this.form = this.fb.group({
      delayCode: ['', Validators.required]
    });
  }

  subscribeToCurrentUser() {
    this.authService.userSubject.asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => this.user = user);
  }

  calcNewEtd() {
    if (!this.extendEtdRequest || !this.extendEtdRequest.extensionInMinutes) {
      return;
    }
    this.newEtd = dayjs(this.pair?.departureLeg?.etd ?? this.pair?.departureLeg?.std).utc(false).add(this.extendEtdRequest.extensionInMinutes, 'minutes').toDate();
  }



  declineClicked() {
    this.turnaroundService.respondToRequest({
      accepted: false,
      pairId: this.pair?.id,
    }).subscribe((res) => {
      if (res) {
        this.toastService.showSuccess('ETD Extension was declined');
        this.pair.turnaround.lastExtendEtdRequest = null;
        if (this.pairOverview) {
          this.pairOverview.hasExtensionRequest = false;
        }
      }
    });
  }

  async acceptClicked() {
    this.isBusy = true;
    const leg = this.pair.departureLeg;
    const date = dayjs(leg.etd ?? leg.std).utc().add(this.extendEtdRequest.extensionInMinutes, 'minutes').toDate();
    const eta = calculateETADate(leg);
    const timeTypes = await firstValueFrom(this.timeTypesService.getTimeTypes());
    this.turnaroundService.respondToRequest({
      accepted: true,
      pairId: this.pair?.id,
      leg: {...leg, etd: date, tod: date, eta: eta.toDate() },
      turnaroundTimeLog: { reason: this.extendEtdRequest.reason, turnaroundTimeInMinutes: this.extendEtdRequest.extensionInMinutes, suggestedAt: new Date(), suggestedBy: this.extendEtdRequest.requestUserId, answeredAt: new Date(), answeredBy: this.user.id, timeTypeId: timeTypes.find((item) => item.identifier === 'ETD').id, pairId: this.pair.id, status: StatusType.ACCEPTED, delayCodeId: this.form.value.delayCode },
    }).subscribe((res) => {
      if (res) {
        this.toastService.showSuccess('ETD Extension was granted');
        this.refreshSql?.emit();
      }
    });

  }
}
