import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map, Observable, of} from 'rxjs';
import {ToastService} from './toast.service';
import {environment} from '../../environments/environment';
import {IResponse} from '../shared/models/IResponse.model';
import {IAlertsModel} from '../shared/models/alerts.model';
import {IAlertTypesModel} from '../shared/models/alert-types.model';
import {IGenericContainerObject} from '../shared/models/genericContainerObject.model';
import {Searchable} from "../shared/utils/searchable.type";
import {IAlertTypeSettings} from "../shared/models/alert-type-settings.model";

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alertTypes: IGenericContainerObject<IAlertTypesModel> = {};

  constructor(private httpClient: HttpClient, private toastService: ToastService) {
    this.getAlertTypes().subscribe((results) => {
      results.forEach((alertType) => {
        this.alertTypes[alertType.id] = alertType;
      });
    });
  }

  getAlerts(): Observable<IAlertsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}alerts/search`, {
      isActive: true,
      isResolved: false
    }, {}).pipe(
      map((item: IResponse<IAlertsModel[]>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  getAlertTypes(filter: Searchable<IAlertTypesModel> = {isActive: true}): Observable<IAlertTypesModel[]> {
    return this.httpClient.post(`${environment.api_base_url}alert-types/search`, filter, {}).pipe(
      map((item: IResponse<IAlertTypesModel[]>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  saveAlert(alert: IAlertsModel): Observable<IAlertsModel> {
    return this.httpClient.patch(`${environment.api_base_url}alerts/${alert.id}`, alert, {}).pipe(
      map((item: IResponse<IAlertsModel>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving Alert");
        return of({});
      })
    );
  }

  markAsResolvedAlert(alert: IAlertsModel): Observable<IAlertsModel> {
    console.log('Mark as Resolved was Called');

    return this.httpClient.post(`${environment.api_base_url}alerts/resolve/${alert.id}`, alert, {}).pipe(
      map((item: IResponse<IAlertsModel>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving Alert");
        return of({});
      })
    );
  }

  saveAlertSettings(data: IAlertTypeSettings): Observable<IAlertTypeSettings> {
    let obs: Observable<IResponse<IAlertTypeSettings>> = this.httpClient.post(`${environment.api_base_url}alert-types-settings/create`, data, {});
    if (data.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}alert-types-settings/${data.id}`, data, {});
    }
    return obs.pipe(
      map((item: IResponse<IAlertTypeSettings>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        this.toastService.showError("Error saving alert settings");
        return of({});
      })
    );
  }

  getAlertTypeSettings(filter: Searchable<IAlertTypeSettings> = {isActive: true}): Observable<IAlertTypeSettings[]> {
    return this.httpClient.post(`${environment.api_base_url}alert-types-settings/search`, filter, {}).pipe(
      map((item: IResponse<IAlertTypeSettings[]>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }
}
