<div class="modal-header">
  <span class="modal-title">{{ title }}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="close-modal">
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Firstname*
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airport-contact-firstname" placeholder=""
                         formControlName="firstname" data-test="edit-airport-contact-firstname-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Lastname*
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airport-contact-lastname" placeholder=""
                         formControlName="lastname" data-test="edit-airport-contact-lastname-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Phone
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airport-contact-phone" placeholder=""
                         formControlName="phone" data-test="edit-airport-contact-phone-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Mobile
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airport-contact-mobile" placeholder=""
                         formControlName="mobile" data-test="edit-airport-contact-mobile-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Remarks
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airport-contact-remarks" placeholder=""
                         formControlName="remarks" data-test="edit-airport-contact-remarks-input">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 border-start">
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Company*
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airport-contact-company" placeholder=""
                         formControlName="company" data-test="edit-airport-contact-company-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Department
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airport-contact-department" placeholder=""
                         formControlName="department" data-test="edit-airport-contact-department-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                Email
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="email" class="form-control" id="settings-airport-contact-email" placeholder=""
                         formControlName="email" data-test="edit-airport-contact-email-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                VHF Frequency
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airport-contact-vhf" placeholder=""
                         formControlName="vhf" data-test="edit-airport-contact-vhf-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                SITA Address 1
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airport-contact-sita-address-1" placeholder=""
                         formControlName="sita1" data-test="edit-airport-contact-sita1-input">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 form-field-header">
                SITA Address 2
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <input type="text" class="form-control" id="settings-airport-contact-sita-address-2" placeholder=""
                         formControlName="sita2" data-test="edit-airport-contact-sita2-input">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()"
          data-test="edit-airport-contact-cancel-button">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          data-test="edit-airport-contact-save-button"
          [disabled]="form?.disabled || form?.invalid || isBusy || readonly">{{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
