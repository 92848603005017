<ng-container *ngIf="this.turnaroundTime">

<app-settings-header [backButton]="true" backButtonUrl="../.." text="Edit Turnaround Times for {{ acType?.icao }} in {{ airport?.iata }}" [showSaveButton]="true" (onSaveClicked)="onSaveClick()" [saveButtonDisabled]="isBusy" [spinner]="isBusy"></app-settings-header>
<div class="flex flex-column settings-container avitium-scrollbar">
<app-settings-card sectionTitle="Turnaround Times: Domestic to Domestic">
  <div class="flex flex-column dark-blue gap-3">
    <div>Default Turnaround Time</div>
    <div class="flex gap-3" >
      <input type="text" [(ngModel)]="turnaroundTime" name="domdom" class="form-control light-blue-border default-turnaround-time" data-test="edit-turnaround-default-time-domdom-input">
      <button type="button" class="btn button-blue apply-button"  (click)="applyTimeToAll(flightTypes.DOMDOM)" data-test="edit-turnaround-apply-to-all-domdom">Apply to all Service types</button>
    </div>
    <ng-container *ngIf="forms[flightTypes.DOMDOM]">
      <div class="overflow-auto avitium-scrollbar skip-invalid-border" [formGroup]="forms[flightTypes.DOMDOM]">
        <table class="table table-striped turnaround-table dark-blue">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"  class="text-start" *ngFor="let flightServiceType of forms[flightTypes.DOMDOM]?.controls | keyvalue">
              Outbound {{flightServiceType?.key}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let flightServiceType of forms[flightTypes.DOMDOM]?.controls | keyvalue" [formGroupName]="flightServiceType.key">
            <td>Inbound {{flightServiceType?.key}}</td>
            <td *ngFor="let serviceType of flightServiceType.value?.controls | keyvalue" [formGroupName]="serviceType?.key">
              <!--                {{ forms[flightTypes.DOMDOM]?.controls[flightServiceType?.value?.id][serviceType?.value?.id] | timeFromMinutes }}-->
              <input type="text" class="form-control time-input" id="arrival-flight-passenger-gate-{{flightServiceType?.key}}-{{serviceType?.key}}"
                     placeholder="" formControlName="time" pattern="[0-9][0-9]\:[0-9][0-9]" [attr.data-test]="'edit-turnaround-' + flightServiceType?.key + '-' + serviceType?.key + '-input'">
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</app-settings-card>

<app-settings-card sectionTitle="Turnaround Times: Domestic to International">
  <div class="flex flex-column dark-blue gap-3">
    <div>Default Turnaround Time</div>
    <div class="flex gap-3" >
      <input type="text" [(ngModel)]="turnaroundTime" name="domint" class="form-control light-blue-border default-turnaround-time" data-test="edit-turnaround-default-time-domint-input">
      <button type="button" class="btn button-blue apply-button" (click)="applyTimeToAll(flightTypes.DOMINT)" data-test="edit-turnaround-apply-to-all-domint">Apply to all Service types</button>
    </div>
    <ng-container *ngIf="forms[flightTypes.DOMINT]">
      <div class="overflow-auto avitium-scrollbar skip-invalid-border" [formGroup]="forms[flightTypes.DOMINT]">
        <table class="table table-striped turnaround-table dark-blue">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" *ngFor="let flightServiceType of forms[flightTypes.DOMINT]?.controls | keyvalue">
              Outbound {{flightServiceType?.key}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let flightServiceType of forms[flightTypes.DOMINT]?.controls | keyvalue" [formGroupName]="flightServiceType.key">
            <td>Inbound {{flightServiceType?.key}}</td>
            <td *ngFor="let serviceType of flightServiceType.value?.controls | keyvalue" [formGroupName]="serviceType?.key">
              <!--                {{ forms[flightTypes.DOMDOM]?.controls[flightServiceType?.value?.id][serviceType?.value?.id] | timeFromMinutes }}-->
              <input type="text" class="form-control time-input" id="arrival-flight-passenger-gate-{{flightServiceType?.key}}-{{serviceType?.key}}"
                     placeholder="" formControlName="time" pattern="[0-9][0-9]\:[0-9][0-9]" [attr.data-test]="'edit-turnaround-' + flightServiceType?.key + '-' + serviceType?.key + '-input'">
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</app-settings-card>

<app-settings-card sectionTitle="Turnaround Times: International to International">
  <div class="flex flex-column dark-blue gap-3">
    <div>Default Turnaround Time</div>
    <div class="flex gap-3">
      <input type="text" [(ngModel)]="turnaroundTime" name="intint" class="form-control light-blue-border default-turnaround-time" data-test="edit-turnaround-default-time-intint-input">
      <button type="button" class="btn button-blue apply-button" (click)="applyTimeToAll(flightTypes.INTINT)" data-test="edit-turnaround-apply-to-all-intint">Apply to all Service types</button>
    </div>
    <ng-container *ngIf="forms[flightTypes.INTINT]">
      <div class="overflow-auto avitium-scrollbar skip-invalid-border" [formGroup]="forms[flightTypes.INTINT]">
        <table class="table table-striped turnaround-table dark-blue">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" *ngFor="let flightServiceType of forms[flightTypes.INTINT]?.controls | keyvalue">
              Outbound {{flightServiceType?.key}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let flightServiceType of forms[flightTypes.INTINT]?.controls | keyvalue" [formGroupName]="flightServiceType.key">
            <td>Inbound {{flightServiceType?.key}}</td>
            <td *ngFor="let serviceType of flightServiceType.value?.controls | keyvalue" [formGroupName]="serviceType?.key">
              <!--                {{ forms[flightTypes.DOMDOM]?.controls[flightServiceType?.value?.id][serviceType?.value?.id] | timeFromMinutes }}-->
              <input type="text" class="form-control time-input" id="arrival-flight-passenger-gate-{{flightServiceType?.key}}-{{serviceType?.key}}"
                     placeholder="" formControlName="time" pattern="[0-9][0-9]\:[0-9][0-9]" [attr.data-test]="'edit-turnaround-' + flightServiceType?.key + '-' + serviceType?.key + '-input'">

            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</app-settings-card>

<app-settings-card sectionTitle="Turnaround Times: International to Domestic">
  <div class="flex flex-column dark-blue gap-3">
    <div>Default Turnaround Time</div>
    <div class="flex gap-3">
      <input type="text" [(ngModel)]="turnaroundTime" name="intdom" class="form-control light-blue-border default-turnaround-time" data-test="edit-turnaround-default-time-intdom-input">
      <button type="button" class="btn button-blue apply-button" (click)="applyTimeToAll(flightTypes.INTDOM)" data-test="edit-turnaround-apply-to-all-intdom">Apply to all Service types</button>
    </div>
    <ng-container *ngIf="forms[flightTypes.INTDOM]">
      <div class="overflow-auto avitium-scrollbar skip-invalid-border" [formGroup]="forms[flightTypes.INTDOM]">
        <table class="table table-striped turnaround-table dark-blue">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" *ngFor="let flightServiceType of forms[flightTypes.INTDOM]?.controls | keyvalue">
              Outbound {{flightServiceType?.key}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let flightServiceType of forms[flightTypes.INTDOM]?.controls | keyvalue" [formGroupName]="flightServiceType.key">
            <td>Inbound {{flightServiceType?.key}}</td>
            <td *ngFor="let serviceType of flightServiceType.value?.controls | keyvalue" [formGroupName]="serviceType?.key">
              <!--                {{ forms[flightTypes.DOMDOM]?.controls[flightServiceType?.value?.id][serviceType?.value?.id] | timeFromMinutes }}-->
              <input type="text" class="form-control time-input" id="arrival-flight-passenger-gate-{{flightServiceType?.key}}-{{serviceType?.key}}"
                     placeholder="" formControlName="time" pattern="[0-9][0-9]\:[0-9][0-9]" [attr.data-test]="'edit-turnaround-' + flightServiceType?.key + '-' + serviceType?.key + '-input'">
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</app-settings-card>

</div>
</ng-container>
