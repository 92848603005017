import {Injectable} from '@angular/core';
import {BasicPermissionsService} from "./basic-permissions.service";
import {AuthService} from "./auth.service";
import {IBasicPermissionsModel} from "../shared/models/basic-permissions.model";
import {IBasicPermissionApplicationModel} from "../shared/models/basic-permission-application.model";
import {IUser} from "../shared/models/user.model";
import {forkJoin} from "rxjs";
import {Access, PermissionUIMasks} from "../shared/constants/enums";
import {AirportsService} from "./airports.service";
import {StaticUserGroupConstants} from "../shared/constants/static-user-group.constants";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  permissions: IBasicPermissionsModel[] = [];
  permissionApplications: IBasicPermissionApplicationModel[] = [];
  permissionsLoaded = false;

  constructor(private basicPermissionService: BasicPermissionsService, private authService: AuthService, private airportService: AirportsService) {
    this.authService.userSubject.subscribe((user: IUser) => {
      if (user) {
        forkJoin([this.basicPermissionService.fetchBasicPermissions({
          userGroupId: user.userGroup,
          isActive: true
        }), this.basicPermissionService.fetchBasicPermissionsApplication({
          userGroupId: user.userGroup,
          isActive: true
        })]).subscribe(([basicPermissions, basicPermissionApplication]) => {
          this.permissions = basicPermissions;
          this.permissionApplications = basicPermissionApplication;
          this.permissionsLoaded = true;
        });
        return;
      }
      this.permissionsLoaded = false;
      this.permissionApplications = [];
      this.permissions = [];
    });
  }

  async getPermissionAccess(mask: PermissionUIMasks, airport?: number): Promise<Access> {
    if (this.authService.userSubject?.value?.userGroup === StaticUserGroupConstants.STR_TO_ID.ADMIN) {
      return Access.RW;
    }
    if (!this.permissionsLoaded) {
      while (!this.permissionsLoaded) {
        await new Promise((res) => setTimeout(res, 50));
      }
    }
    if (airport) {
      const entries = this.permissionApplications.filter((perm) => perm.name.includes(mask) && (perm.airportId === airport || perm.airportId === null || perm.airportId === 0 && airport === this.airportService.airports.value.find((airport) => airport.iata === this.authService.userSubject.value.location).id));
      if (!entries?.length) {
        return null;
      }
      const basicPermissionIds: number[] = entries.map((p) => p.basicPermissionId)
      const permissions = this.permissions.filter((perm) => basicPermissionIds.includes(perm.id));
      permissions.sort((a, b) => {
        if (a.access === 'RW') return -1;
        if (b.access === 'RW') return 1;
        if (a.access === 'R') return -1;
        if (b.access === 'R') return 1;
        if (a.access === null) return 1;
        if (b.access === null) return -1;
        return 0;
      });
      return permissions[0]?.access ?? null;
    }
    const entries = this.permissions.filter((perm) => perm.uiMask === mask);
    entries.sort((a, b) => {
      if (a.access === 'RW') return -1;
      if (b.access === 'RW') return 1;
      if (a.access === 'R') return -1;
      if (b.access === 'R') return 1;
      if (a.access === null) return 1;
      if (b.access === null) return -1;
      return 0;
    });
    return entries[0]?.access ?? null;
  }

  /**
   * @returns If a permission is assigned to "All airports", the airport ID is going to be -1
   */
  async getPermissionAccessForAllAirports(mask: PermissionUIMasks): Promise<Record<number, Access>> {
    if (this.authService.userSubject?.value?.userGroup === StaticUserGroupConstants.STR_TO_ID.ADMIN) {
      return {'-1': Access.RW};
    }
    if (!this.permissionsLoaded) {
      while (!this.permissionsLoaded) {
        await new Promise((res) => setTimeout(res, 50));
      }
    }
    const entries = this.permissionApplications.filter((perm) => perm.name.includes(mask));
    const result: Record<number, Access> = {};
    for (const entry of entries) {
      if (!entry?.airportId) {
        result[-1] = this.permissions.find((perm) => perm.id === entry.basicPermissionId)?.access;
        continue;
      }
      result[entry.airportId] = this.permissions.find((perm) => perm.id === entry.basicPermissionId)?.access;
    }
    return result;
  }
}
