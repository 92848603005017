<div class="bg-white flex flex-row chatHeader light-blue-border">
  <div class="participantsNames text-center flex gap-3 p-2">
    <app-user-photo circleBackground="#F2F2F2" textColor="black" class="align-self-center p-1" [size]="50"
                    [user]="user"></app-user-photo>
    <div class="participantsNames text-center">
      <span>{{ user.firstname }} {{ user.lastname }} ({{ user.location }})</span>
      <h6 class="text-muted">{{ user.role }}</h6>
    </div>
  </div>
  <div id="actions">
    <i class="demo-icon icon-icons8-delete avitium-color close-icon" (click)="close()" *ngIf="canClose"></i>
  </div>
</div>
