import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {debounceTime, Observable, Subject, takeUntil} from 'rxjs';
import {IInterfaceMessageModel} from '../../../shared/models/interfaceMessage.model';
import {InterfaceMessageService} from '../../../services/interface-message.service';
import {
  IInterfaceMessageFilter,
  InterfaceMessageFilter
} from '../../../shared/models/filters/interface-message-filter.model';
import {AcRegistrationsService} from '../../../services/ac-registrations.service';
import {IAcRegistration} from '../../../shared/models/ac-registration.model';
import {ngbDateToDayjs, ngbDateToFormat} from '../../../shared/utils/utils';
import * as dayjs from 'dayjs';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {MessageTestTagPipe} from '../../../shared/pipes/message-test-tag.pipe';
import {Access, PermissionUIMasks} from "../../../shared/constants/enums";
import {PermissionService} from "../../../services/permission.service";

@Component({
  selector: 'app-exit-screen-list',
  templateUrl: './exit-screen-list.component.html',
  styleUrls: ['./exit-screen-list.component.scss']
})

export class ExitScreenListComponent implements OnInit {
  messages$: Observable<IInterfaceMessageModel[]>;
  page = 1;
  hasNextPage = true;
  pageSize = 20;
  filterForm: UntypedFormGroup;
  datePlaceholder = 'DD/MM/YYYY';
  date: string;
  unsubscribe = new Subject;
  acRegistrations: IAcRegistration[];
  form: UntypedFormGroup = new UntypedFormGroup({searchText: new UntypedFormControl('')})
  messages: IInterfaceMessageModel[] = [];
  filteredMessages: IInterfaceMessageModel[];
  dayjs = dayjs;
  listAccess: Access;

  constructor(private interfaceMessageService: InterfaceMessageService, private fb: UntypedFormBuilder, private acRegistrationsService: AcRegistrationsService, private permissionService: PermissionService
  ) {
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_MESSAGE_CREATION_LIST).then((listAccess) => {
      this.listAccess = listAccess;
      if (!listAccess) {
        this.filterForm = this.createForm();
        return;
      }
      this.acRegistrationsService.getAcRegistrations().subscribe((result) => {
        this.acRegistrations = result
      });
      this.getMessages('');
      this.filterForm = this.createForm();
      this.onChanges(this.filterForm);
    });

  }

  fixDateFormat() {
    this.filterForm.value.date = this.filterForm.getRawValue().date ? ngbDateToDayjs(this.filterForm.getRawValue().date).format('DDMMMYY') : null;
  }

  nextPage() {
    if (!this.hasNextPage) {
      return;
    }
    this.page++;
    const filters = this.filterForm.value;
    this.getMessages(filters);
    window.scroll(0, 0);
  }

  previousPage() {
    if (this.page < 2) {
      return;
    }
    this.page--;
    const filters = this.filterForm.value;
    this.getMessages(filters);
    window.scroll(0, 0);
  }

  getMessages(filters) {
    this.interfaceMessageService.getInterfaceMessages(this.page, filters).subscribe((result) => {
      this.messages = result
      this.hasNextPage = this.messages.length > this.pageSize;

    });

  }

  // searchMessages() {
  //      const filters = this.form.value.searchText;
  //      console.log('filtres', filters)
  //      this.interfaceMessageService.getInterfaceMessages(this.page, filters).subscribe((result) => {
  //       this.messages = result
  //       this.hasNextPage = this.messages.length > this.pageSize;
  //     });
  // }

  onChanges(form: UntypedFormGroup) {
    form.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(1000)).subscribe((val: IInterfaceMessageFilter) => {
      if (val.date) {
        val.date = ngbDateToFormat(val.date as unknown as NgbDateStruct, 'DDMMMYY');
      }
      const values = new InterfaceMessageFilter(val);
      this.getMessages(values);
    });
    form.get('type').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      form.patchValue({subtype: null});
    })
  }

  createForm(): UntypedFormGroup {
    return this.fb.group({
      type: [null],
      subtype: [null],
      category: [null],
      status: [null],
      date: [null],
      acRegistration: [null],
      flightNumber: [''],
    });
  }

  public readonly MessageTestTagPipe = MessageTestTagPipe;
}
