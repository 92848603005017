import { Component, OnDestroy, OnInit } from '@angular/core';
import { Chart, ChartConfiguration, } from 'chart.js';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { IUser } from '../../shared/models/user.model';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AirportsService } from '../../services/airports.service';
import { IAirport } from '../../shared/models/airport.model';
import { IAcType } from '../../shared/models/ac-type.model';
import { AcTypesService } from '../../services/ac-types.service';
import { ILegsModel } from '../../shared/models/legs.model';
import { LegsService } from '../../services/legs.service';
import * as dayjs from 'dayjs';
import { PairsService } from '../../services/pairs.service';
import { IPairsModel } from '../../shared/models/pairs.model';
import { extractSeatingConfigurations, ngbDateToDayjs } from '../../shared/utils/utils';
import { SeatingConfigurationService } from '../../services/seating-configuration.service';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { LegDelaysLogService } from '../../services/leg-delays-log.service';
import { PairLegTimesLogService } from '../../services/pair-leg-times-log.service';
import { GseService } from '../../services/gse.service';
import { PairsProcessesService } from '../../services/pairs-processes.service';
import { ProcessesService } from '../../services/processes.service';
import * as XLSX from 'xlsx/xlsx.mjs';
import {IGsesModel} from '../../shared/models/gses.model';
import {Searchable} from '../../shared/utils/searchable.type';
import {PermissionService} from "../../services/permission.service";
import {Access, PermissionUIMasks} from "../../shared/constants/enums";
import {StaticUserGroupConstants} from "../../shared/constants/static-user-group.constants";
import { ConnectingPassengersService } from '../../services/connecting-passengers.service';
import { IConnectingPassengersModel } from '../../shared/models/connecting-passengers.model';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  operationsChartLabel: string;
  airports: IAirport[];
  acTypes: IAcType[];
  legs: ILegsModel[];
  connectingPassengers: IConnectingPassengersModel[];
  pairs: IPairsModel[];
  filterDayFrom;
  filterDayTo;
  filterStation: string;
  filterAcType: string;
  punctualityGraphData = [];
  totalDelayMinutes: number;
  totalFlights: number;
  startFilterDay;
  endFilterDay;
  fileName: string;
  sheetName: string;
  checkIcon: IconDefinition = faCheck;
  dotsIcon: IconDefinition = faEllipsisVertical;
  financeFiltersChanged = false;
  punctualityLabels = [];
  punctualityData: ChartConfiguration['data'] = { datasets: [], labels: [] };
  punctualityConfig: ChartConfiguration = {
    type: 'bar',
    data: null,
  };
  punctualityOptions: ChartConfiguration['options'] = {};
  passengerLabels = [];
  passengerData: ChartConfiguration['data'] = { datasets: [], labels: [] };
  passengerConfig: ChartConfiguration = {
    type: 'bar',
    data: null,
  };
  passengerOptions: ChartConfiguration['options'] = {};
  operationLabels = [];
  operationData: ChartConfiguration['data'] = { datasets: [], labels: [] };
  operationConfig: ChartConfiguration = {
    type: 'bar',
    data: null,
  };
  operationOptions: ChartConfiguration['options'] = {};
  utilizationLabels = [];
  utilizationData: ChartConfiguration['data'] = { datasets: [], labels: [] };
  utilizationConfig: ChartConfiguration = {
    type: 'bar',
    data: null,
  };
  utilizationOptions: ChartConfiguration['options'] = {};

  scaleTextFont: ChartConfiguration['options']['font'] = {
    size: 16,
  };
  user: IUser;
  minimumPassengerConnectionTime: number;
  unsubscribe = new Subject<void>();
  operationsAccess?: Access;
  punctualityAccess?: Access;
  passengersAccess?: Access;
  utilizationAccess?: Access;

  constructor(
    private authService: AuthService,
    private airportsService: AirportsService,
    private acTypesService: AcTypesService,
    private legsService: LegsService,
    private pairsService: PairsService,
    private seatConfigService: SeatingConfigurationService,
    private generalSettingsService: GeneralSettingsService,
    private legDelayLogsService: LegDelaysLogService,
    private pairLegTimesLogService: PairLegTimesLogService,
    private gseService: GseService,
    private pairProcessesService: PairsProcessesService,
    private processService: ProcessesService,
    private permissionService: PermissionService,
    private connectingPassengersService: ConnectingPassengersService,
  ) {
    this.user = authService.user;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    Promise.all([this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_REPORTS_OPERATIONS), this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_REPORTS_PUNCTUALITY), this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_REPORTS_PASSENGERS), this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_REPORTS_UTILIZATION)]).then(([operationsAccess, punctualityAccess, passengersAccess, utilizationAccess]) => {
      this.operationsAccess = operationsAccess;
      this.punctualityAccess = punctualityAccess;
      this.passengersAccess = passengersAccess;
      this.utilizationAccess = utilizationAccess;
      this.createForm();
      const hasSomePermissions = [operationsAccess,
        punctualityAccess,
        passengersAccess,
        utilizationAccess].some((perm) => perm !== null);
      if (!hasSomePermissions) {
        return;
      }
      this.airportsService.fetchAirports().subscribe((result) => {
        if (this.user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER) {
          this.airports = [result.find((airport) => airport.iata === this.user.location)];
        } else {
          this.airports = result;
        }
      });
      this.acTypesService.acTypes.subscribe((result) => {
        this.acTypes = result
      });

      if (this.operationsAccess) {
        this.formGroup.get('operations').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
          setTimeout(() => {
            this.buildOperationsGraph();
          }, 1)
          this.buildOperationsGraph();
        });
      }
      if (this.punctualityAccess) {
        this.formGroup.get('punctuality').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
          setTimeout(() => {
            this.buildPunctualityGraph();
          }, 1)
        });
        this.buildPunctualityGraph();
      }
      if (this.passengersAccess) {
        this.formGroup.get('passengers').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
          this.buildPassengerGraph();
        });
        this.buildPassengerGraph();
      }
      if (this.utilizationAccess) {
        this.formGroup.get('utilization').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
          this.buildUtilizationGraph();
        });
        this.buildUtilizationGraph();
      }

      Chart.register(ChartDataLabels);
      Chart.defaults.set('plugins.datalabels', {
        display: false,
        color: '#fff',
        font: {
          size: '20rem'
        }
      });
    });


  }

  createForm() {
    this.formGroup = new UntypedFormGroup({
      operations: new UntypedFormGroup({
        type: new UntypedFormControl('1'),
        date: new UntypedFormControl(null),
        airport: this.user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER ? new UntypedFormControl({
          value: this.user.location,
          disabled: true
        }) : new UntypedFormControl(null),
        acType: new UntypedFormControl(null)
      }),
      punctuality: new UntypedFormGroup({
        type: new UntypedFormControl('1'),
        date: new UntypedFormControl(null),
        airport: this.user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER ? new UntypedFormControl({
          value: this.user.location,
          disabled: true
        }) : new UntypedFormControl(null),
      }),
      passengers: new UntypedFormGroup({
        type: new UntypedFormControl('1'),
        date: new UntypedFormControl(null),
        airport: this.user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER ? new UntypedFormControl({
          value: this.user.location,
          disabled: true
        }) : new UntypedFormControl(null),
        acType: new UntypedFormControl(null)
      }),
      utilization: new UntypedFormGroup({
        type: new UntypedFormControl('1'),
        date: new UntypedFormControl(null),
        airport: this.user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER ? new UntypedFormControl({
          value: this.user.location,
          disabled: true
        }) : new UntypedFormControl(null),
        acType: new UntypedFormControl(null)
      }),
    });
  }

  buildPunctualityGraph() {
    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    let graphData = [];
    let onTimeCount = 0;
    let delayedCount = 0;
    switch (this.formGroup?.get('punctuality').value.type) {
      case "1": // On Time Performance
        if (this.formGroup.get('punctuality').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('punctuality').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('punctuality').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('punctuality').value.airport;

        this.legsService.getLegsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          timeType: 'atd'
        }).subscribe((result) => {
          this.legs = result;
          for (const leg of this.legs) {
            if (dayjs(leg.atd).isAfter(dayjs(leg.std).add(5, 'minute'))) {
              delayedCount = delayedCount + 1;
            } else {
              onTimeCount = onTimeCount + 1;

            }
          }

          graphData.push(onTimeCount);
          graphData.push(delayedCount);

          this.punctualityLabels = ['On Time', 'Delayed'];
          this.punctualityData = {
            labels: this.punctualityLabels,
            datasets: [{
              label: 'On Time Performance',
              backgroundColor: ['#5b9bd5', '#ff00ff'],
              hoverBackgroundColor: ['#5b9bd5', '#ff00ff'],
              borderColor: ['#5b9bd5', '#ff00ff'],
              hoverBorderWidth: 0,
              data: graphData
            }],
          };
          this.punctualityConfig = {
            type: 'doughnut',
            data: this.punctualityData,
            plugins: [ChartDataLabels],
          };
          this.punctualityOptions = {
            aspectRatio: 2,
            plugins: {
              datalabels: {
                display: true,
              }
            }
          };
        });
        break;
      case "2": // Arrival Punctiality
        if (this.formGroup.get('punctuality').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('punctuality').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('punctuality').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('punctuality').value.airport;

        this.legsService.getLegsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          timeType: 'ata'
        }).subscribe((result) => {
          this.legs = result;
          for (const leg of this.legs) {
            if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
              delayedCount = delayedCount + 1;
            } else {
              onTimeCount = onTimeCount + 1;
            }
          }

          graphData.push(onTimeCount);
          graphData.push(delayedCount);

          console.log('GRAPH DATA ARRAY :', graphData)
          this.punctualityLabels = ['On Time', 'Arrival Delayed'];
          this.punctualityData = {
            labels: this.punctualityLabels,
            datasets: [{
              label: 'Arrival Punctuality',
              backgroundColor: ['#5b9bd5', '#ed7d31'],
              hoverBackgroundColor: ['#5b9bd5', '#ed7d31'],
              borderColor: ['#5b9bd5', '#ed7d31'],
              hoverBorderWidth: 0,
              data: graphData
            }],
          };
          this.punctualityConfig = {
            type: 'doughnut',
            data: this.punctualityData,
            options: {
              aspectRatio: 2,
              plugins: {
                datalabels: {
                  display: true,
                }
              }
            },
          };
          this.punctualityOptions = {
            aspectRatio: 2,
            plugins: {
              datalabels: {
                display: true,
              }
            }
          };
        });
        break;
      case "3": // Delayed per Delay Code
        const displayMinutesArray = [];
        const displayCodesArray = [];
        let delayMinutesPerCode: { [code: string]: number } = {};

        if (this.formGroup.get('punctuality').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('punctuality').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('punctuality').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('punctuality').value.airport;

        this.legsService.getLegsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          timeType: 'atd'
        }).subscribe(async (result) => {
          this.legs = result;
          const legIdsInLegList = this.legs.map((leg) => leg.id);

          const legDelayLogs = await firstValueFrom(this.legDelayLogsService.getLegDelayLogs({
            isActive: [true],
            legId: legIdsInLegList
          }));
          const delayCodes = await firstValueFrom(this.generalSettingsService.getDelayCodes());
          const codesInDelayCodesList = delayCodes.map((delayCode) => delayCode.id);

          for (const delayLog of legDelayLogs) {
            const delayCodesWeNeed = delayCodes.filter((delaycode) => delayLog.delayCodeId === delaycode.id);
            delayMinutesPerCode[delayCodesWeNeed[0].code] = (delayMinutesPerCode[delayCodesWeNeed[0].code] || 0) + (Number(delayLog.minutes) || 0);
          }
          for (const [key, value] of Object.entries(delayMinutesPerCode)) {
            displayCodesArray.push(key);
            displayMinutesArray.push(value)
          }
          ;

          this.punctualityData = {
            labels: displayCodesArray,
            datasets: [{
              label: 'Delayed per Delay Code',
              backgroundColor: ['#5b9bd5', '#ed7d31'],
              hoverBackgroundColor: ['#5b9bd5', '#ed7d31'],
              borderColor: ['#5b9bd5', '#ed7d31'],
              hoverBorderWidth: 0,
              data: displayMinutesArray,
            }],
          };
          this.punctualityConfig = {
            type: 'bar',
            data: this.punctualityData,
          };
          this.punctualityOptions = {
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Delay Code',
                  font: this.scaleTextFont,
                }
              },
              y: {
                title: {
                  display: true,
                  text: 'Minutes',
                  font: this.scaleTextFont,
                }
              }
            }
          }
        });
        break;
      case "4": // Total Departure Delay

        if (this.formGroup.get('punctuality').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('punctuality').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('punctuality').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('punctuality').value.airport;

        this.legsService.getLegsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          timeType: 'atd'
        }).subscribe(async (result) => {
          this.legs = result.filter((leg) => dayjs(leg.atd).isAfter(dayjs(leg.std).add(5, 'minute')));
          const legIdsInLegList = this.legs.map((leg) => leg.id);
          const pairLegDelayLogs = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({
            isActive: true,
            legId: legIdsInLegList,
            timeTypeId: 6
          }));
          this.totalDelayMinutes = 0;
          pairLegDelayLogs.sort((a, b) => {
            return b.id - a.id
          })
          for (const pairLegDelay of pairLegDelayLogs) {
            if (legIdsInLegList.includes(pairLegDelay.legId)) {
              const index = legIdsInLegList.indexOf(pairLegDelay.legId);
              legIdsInLegList.splice(index, 1);

              this.totalDelayMinutes = this.totalDelayMinutes + pairLegDelay.totalDelayInMinutes

            }
          }
          this.totalFlights = this.legs.length;
          this.punctualityData = {
            datasets: [{
              label: 'Total Departure Delay',
              data: graphData
            }],
          };
        });
        break;
      case "5": // Total Arrival Delay

        if (this.formGroup.get('punctuality').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('punctuality').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('punctuality').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('punctuality').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('punctuality').value.airport;

        this.legsService.getLegsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          timeType: 'ata'
        }).subscribe(async (result) => {
          this.legs = result.filter((leg) => dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute')));
          const legIdsInLegList = this.legs.map((leg) => leg.id);
          const pairLegDelayLogs = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({
            isActive: true,
            legId: legIdsInLegList,
            timeTypeId: 7
          }));
          this.totalDelayMinutes = 0;
          pairLegDelayLogs.sort((a, b) => {
            return b.id - a.id
          })
          for (const pairLegDelay of pairLegDelayLogs) {
            if (legIdsInLegList.includes(pairLegDelay.legId)) {
              const index = legIdsInLegList.indexOf(pairLegDelay.legId);
              legIdsInLegList.splice(index, 1);

              this.totalDelayMinutes = this.totalDelayMinutes + pairLegDelay.totalDelayInMinutes

            }
          }
          this.totalFlights = this.legs.length;
          this.punctualityData = {
            datasets: [{
              label: 'Total Arrival Delay',
              data: graphData
            }],
          };

        });
        break;
    }
  }

  async buildPassengerGraph() {
    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    let graphData = [];

    const generalSettings = await firstValueFrom(this.generalSettingsService.getGeneralSettings());
    this.minimumPassengerConnectionTime = generalSettings[0].minimumPassengerConnectionTimeInMinutes;

    switch (this.formGroup?.get('passengers').value.type) {
      case "1": // Passenger Load Factor
        if (this.formGroup.get('passengers').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('passengers').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('passengers').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('passengers').value.airport;
        this.filterAcType = this.formGroup?.get('passengers').value.acType;

        this.legsService.getLegsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          acType: this.filterAcType,
          station: this.filterStation,
          timeType: 'atd'
        }).subscribe(async (result) => {
          this.legs = result;
          const acRegIdsArray = new Set<number>();
          const paxPerCode: { [code: string]: number } = {};
          const passClassLabels = [];
          const passClassCodes = [];
          const displayArray = [];
          const acRegCount = {};

          let totalPax = 0;
          const passengerClasses = await firstValueFrom(this.generalSettingsService.getPassengerClasses());
          for (const passClass of passengerClasses) {
            passClassLabels.push(passClass.description);
            passClassCodes.push(passClass.code);
          }
          for (const leg of this.legs) {
            if (!acRegCount[leg.acRegistrationId]) {
              acRegCount[leg.acRegistrationId] = 1;
            } else {
              acRegCount[leg.acRegistrationId]++;
            }
            acRegIdsArray.add(leg.acRegistrationId);

            if (leg.pax !== null) {
              extractSeatingConfigurations(leg.pax).forEach((item: string) => {
                const code = item.slice(0, 1);
                const amount = item.slice(1);

                for (const passClassCode of passClassCodes) {
                  if (code === passClassCode) {
                    paxPerCode[code] = (paxPerCode[code] || 0) + (Number(amount) || 0);
                  }
                }

                totalPax += +amount;
              });
            }

          }
          const seatConfigs = await firstValueFrom(this.seatConfigService.getSeatingConfigurationsByAcRegistrationId(Array.from(acRegIdsArray)));
          let totalScheduledSeats = 0;
          const seatPerCode: { [code: string]: number } = {};
          console.log('Ac Registrations Array', acRegIdsArray, 'Count of ac reg: ', acRegCount)
          for (const seat of seatConfigs) {
            totalScheduledSeats = (+seat.description) * acRegCount[seat.acRegistrationId] + totalScheduledSeats;
            seatPerCode[seat.code] = (seatPerCode[seat.code] || 0) + (Number(seat.description) || 0) * acRegCount[seat.acRegistrationId];
          }

          for (const [key, value] of Object.entries(paxPerCode)) {
            console.log('Key is: ', key, 'pass class codes are: ', passClassCodes);
            for (const passClassCode of passClassCodes) {
              if (key === passClassCode) {
                //console.log('Value to push',value/totalScheduledSeats);
                graphData.push((value / seatPerCode[key]) * 100)
                displayArray.push(key);
              }
            }
          }
          ;
          displayArray.push('Total')
          graphData.push((totalPax / totalScheduledSeats) * 100)
          console.log('PAX per Code:', paxPerCode, 'Seat per Code:', seatPerCode, 'Total Pax:', totalPax, 'Total Seats:', totalScheduledSeats);
          console.log('OMG: ', graphData)


          this.passengerData = {
            labels: displayArray,
            datasets: [{
              label: 'Passenger Load (%)',
              backgroundColor: ['#5b9bd5'],
              hoverBackgroundColor: ['#5b9bd5'],
              borderColor: ['#5b9bd5'],
              hoverBorderWidth: 0,
              data: graphData,
            }],
          };
          this.passengerConfig = {
            type: 'bar',
            data: this.passengerData,
            options: this.passengerOptions,
          };
          this.passengerOptions = {
            scales: {
              y: {
                ticks: {
                  callback: function (tickValue) {
                    return tickValue + '%';
                  },
                  stepSize: 2,
                },
                suggestedMin: 0,
                suggestedMax: 100,
                title: {
                  display: true,
                  text: 'Passenger Load',
                  font: this.scaleTextFont,
                },
                beginAtZero: false,
              },
              x: {
                title: {
                  display: true,
                  text: 'Classes',
                  font: this.scaleTextFont,
                }
              },
            }
          };
        });
        break;
      case "2": // # Delayed Passengers
        if (this.formGroup.get('passengers').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('passengers').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('passengers').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('passengers').value.airport;
        this.filterAcType = this.formGroup?.get('passengers').value.acType;


        this.legsService.getLegsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          acType: this.filterAcType,
          timeType: 'atd'
        }).subscribe(async (result) => {
          this.legs = result;
          const acRegIdsArray = new Set<number>();
          const paxPerCode: { [code: string]: number } = {};
          const passClassLabels = [];
          const passClassCodes = [];
          const displayArray = [];
          const acRegCount = {};

          let totalPax = 0;
          const passengerClasses = await firstValueFrom(this.generalSettingsService.getPassengerClasses());
          for (const passClass of passengerClasses) {
            passClassLabels.push(passClass.description);
            passClassCodes.push(passClass.code);
          }
          for (const leg of this.legs) {
            if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
              if (!acRegCount[leg.acRegistrationId]) {
                acRegCount[leg.acRegistrationId] = 1;
              } else {
                acRegCount[leg.acRegistrationId]++;

              }
              acRegIdsArray.add(leg.acRegistrationId);

              if (leg.pax !== null) {
                extractSeatingConfigurations(leg.pax).forEach((item: string) => {
                  const code = item.slice(0, 1);
                  const amount = item.slice(1);

                  for (const passClassCode of passClassCodes) {
                    if (code === passClassCode) {
                      paxPerCode[code] = (paxPerCode[code] || 0) + (Number(amount) || 0);
                    }
                  }

                  totalPax += +amount;
                });
              }
            }


          }

          for (const [key, value] of Object.entries(paxPerCode)) {
            for (const passClassCode of passClassCodes) {
              if (key === passClassCode) {
                graphData.push(value)
                displayArray.push(key);
              }
            }
          }
          ;
          displayArray.push('Total')
          graphData.push(totalPax)
          console.log('PAX per Code:', paxPerCode, 'Total Pax:', totalPax,);


          this.passengerData = {
            labels: displayArray,
            datasets: [{
              label: '# Delayed Passengers',
              backgroundColor: ['#5b9bd5'],
              hoverBackgroundColor: ['#5b9bd5'],
              borderColor: ['#5b9bd5'],
              hoverBorderWidth: 0,
              data: graphData
            }],
          };
          this.passengerConfig = {
            type: 'bar',
            data: this.passengerData
          };
          this.passengerOptions = {
            scales: {
              y: {
                max: Math.max(...graphData) + Math.round(0.1 * Math.max(...graphData)),
                // ticks: {
                //   stepSize: 500,
                // },
                // suggestedMin: 200,
                // suggestedMax: 2700,
                title: {
                  display: true,
                  text: 'Delayed Passengers',
                  font: this.scaleTextFont,
                },
                beginAtZero: false,
              },
              x: {
                title: {
                  display: true,
                  text: 'Classes',
                  font: this.scaleTextFont,
                }
              },
            }
          };
        });

        break;
      case "3": // % Delayed Passengers
        if (this.formGroup.get('passengers').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('passengers').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('passengers').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('passengers').value.airport;

        this.legsService.getLegsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          timeType: 'atd'
        }).subscribe(async (result) => {
          this.legs = result;
          const acRegIdsArray = new Set<number>();
          const delayedPaxPerCode: { [code: string]: number } = {};
          const totalPaxPerCode: { [code: string]: number } = {};
          const passClassLabels = [];
          const passClassCodes = [];
          const displayArray = [];
          const acRegCount = {};

          let totalDelayedPax = 0;
          let totalPax = 0;
          const passengerClasses = await firstValueFrom(this.generalSettingsService.getPassengerClasses());
          for (const passClass of passengerClasses) {
            passClassLabels.push(passClass.description);
            passClassCodes.push(passClass.code);
          }
          for (const leg of this.legs) {
            if (!acRegCount[leg.acRegistrationId]) {
              acRegCount[leg.acRegistrationId] = 1;
            } else {
              acRegCount[leg.acRegistrationId]++;
            }
            acRegIdsArray.add(leg.acRegistrationId);

            if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
              if (leg.pax !== null) {
                extractSeatingConfigurations(leg.pax).forEach((item: string) => {
                  const code = item.slice(0, 1);
                  const amount = item.slice(1);

                  for (const passClassCode of passClassCodes) {
                    if (code === passClassCode) {
                      delayedPaxPerCode[code] = (delayedPaxPerCode[code] || 0) + (Number(amount) || 0);
                    }
                  }

                  totalDelayedPax += +amount;
                });
              }
            }
            if (leg.pax !== null) {
              extractSeatingConfigurations(leg.pax).forEach((item: string) => {
                const code = item.slice(0, 1);
                const amount = item.slice(1);

                for (const passClassCode of passClassCodes) {
                  if (code === passClassCode) {
                    totalPaxPerCode[code] = (totalPaxPerCode[code] || 0) + (Number(amount) || 0);
                  }
                }
                totalPax += +amount;
              });
            }

          }

          for (const [key, value] of Object.entries(delayedPaxPerCode)) {
            for (const passClassCode of passClassCodes) {
              if (key === passClassCode) {
                graphData.push((value / totalPaxPerCode[key]) * 100)
                displayArray.push(key);
              }
            }
          }
          ;
          displayArray.push('Total')
          graphData.push((totalDelayedPax / totalPax) * 100)
          console.log('Delayed PAX per Code:', delayedPaxPerCode, 'Total PAX per Code:', totalPaxPerCode, 'Total Delyaed Pax:', totalDelayedPax, 'Total Pax:', totalPax,);


          this.passengerData = {
            labels: displayArray,
            datasets: [{
              label: '% Delayed Passengers',
              backgroundColor: ['#5b9bd5'],
              hoverBackgroundColor: ['#5b9bd5'],
              borderColor: ['#5b9bd5'],
              hoverBorderWidth: 0,
              data: graphData
            }],
          };
          this.passengerConfig = {
            type: 'bar',
            data: this.passengerData
          };
          this.passengerOptions = {
            scales: {
              y: {
                ticks: {
                  callback: function (tickValue) {
                    return tickValue + '%';
                  },
                  stepSize: 2,
                },
                suggestedMax: 100,
                title: {
                  display: true,
                  text: 'Delayed Passengers',
                  font: this.scaleTextFont,
                },
              },
              x: {
                title: {
                  display: true,
                  text: 'Classes',
                  font: this.scaleTextFont,
                }
              },
            }
          };
        });
        break;
      case "4": // Passenger Connections
        if (this.formGroup.get('passengers').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('passengers').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('passengers').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('passengers').value.airport;
        this.filterAcType = this.formGroup?.get('passengers').value.acType;
        this.connectingPassengersService.getconnectingPassengersForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          acType: this.filterAcType,
          station: this.filterStation,
          timeType: 'atd'
        }).subscribe(async (result) => {
          this.connectingPassengers = result;
          const paxPerCode: { [code: string]: number } = {};
          const passClassLabels = [];
          const passClassCodes = [];
          const connectingStations: { [iata: string]: { [seat: string]: any } } = {};
          const displayArrays: { [key: string]: any } = {};
          this.passengerData = {
            labels: this.passengerLabels,
            datasets: [],
          };


          const parentLegIdsToSearch = [];
          const legIdsToSearch = [];
          for (const passenger of this.connectingPassengers) {
            parentLegIdsToSearch.push(passenger.parentLegId);
            legIdsToSearch.push(passenger.legId);
          }

          const parentLegs = await firstValueFrom(this.legsService.getLegs({ id: parentLegIdsToSearch }));
          const normalLegs = await firstValueFrom(this.legsService.getLegs({ id: legIdsToSearch }));


          const passengerClasses = await firstValueFrom(this.generalSettingsService.getPassengerClasses());
          for (const passClass of passengerClasses) {
            passClassLabels.push(passClass.description);
            passClassCodes.push(passClass.code);
            displayArrays[`displayArray${passClass.code}`] = [];

          }
          for (const passenger of this.connectingPassengers) {

            const legTod = normalLegs.find((nLeg) => nLeg.id === passenger.legId).tod
            const calculatedGroundTime = dayjs(legTod).diff(dayjs(parentLegs.find((pLeg) => pLeg.id === passenger.parentLegId).toa));

            if (calculatedGroundTime >= this.minimumPassengerConnectionTime * 60000) {
              extractSeatingConfigurations(passenger.numberOfPassengers).forEach((item: string) => {
                const amount = item.slice(0, 1);
                const code = item.slice(1);

                for (const passClassCode of passClassCodes) {
                  if (code === passClassCode) {
                    if (!connectingStations[passenger.destinationAirport]) {
                      connectingStations[passenger.destinationAirport] = {}
                      paxPerCode[code] = (Number(amount) || 0);
                      connectingStations[passenger.destinationAirport][code] = paxPerCode[code]
                    } else {
                      paxPerCode[code] = (paxPerCode[code] || 0) + (Number(amount) || 0);
                      connectingStations[passenger.destinationAirport][code] = paxPerCode[code]
                    }
                  }
                }
              });
            }
          }
          console.log('final Connections: ', connectingStations)

          this.passengerLabels = Object.keys(connectingStations);


          const totalArray = []
          for (const [key, value] of Object.entries(connectingStations)) {
            console.log('Station is: ', key, 'Passengers are', value);
            let totalInNumber = 0;

            for (const passengerClass of passClassCodes) {
              console.log('Passenger Class is : ', passengerClass)
              if (passengerClass in value) {
                displayArrays[`displayArray${passengerClass}`].push(value[passengerClass]);
                totalInNumber = totalInNumber + value[passengerClass];
              } else {
                displayArrays[`displayArray${passengerClass}`].push(0);
              }
            }

            totalArray.push(totalInNumber);

          }
          for (const [key, value] of Object.entries(displayArrays)) {
            let colorIndex = Math.floor(Math.random() * 8)
            this.passengerData.datasets.push({
              label: key[key.length - 1],
              backgroundColor: [`#${colorIndex}b${Math.floor(Math.random() * 9)}bd${Math.floor(Math.random() * 9)}`],
              hoverBackgroundColor: ['#5b9bd5'],
              borderColor: [`#${colorIndex}b${Math.floor(Math.random() * 8)}bd${Math.floor(Math.random() * 8)}`],
              hoverBorderWidth: 0,
              data: value,
            })
          }
          this.passengerData.datasets.push({
            label: 'Total',
            backgroundColor: ['#F78871'],
            hoverBackgroundColor: ['#F78871'],
            borderColor: ['#F78871'],
            hoverBorderWidth: 0,
            data: totalArray,
          })

          console.log('display array', displayArrays)

          console.log('DATASST after ', this.passengerData.datasets)


          this.passengerConfig = {
            type: 'bar',
            data: this.passengerData,
            options: this.passengerOptions,
          };
          this.passengerOptions = {
            plugins: {
              title: {
                display: true,
                text: this.filterStation !== null ? `Connecting airport: ${this.filterStation}` : 'Select a Connecting Airport',
                font: {
                  size: 20,
                }
              }
            },
            scales: {
              y: {
                ticks: {
                  stepSize: 2,
                },
                title: {
                  display: true,
                  text: 'Connectiong Passengers #',
                  font: this.scaleTextFont,
                },
                beginAtZero: false,
              },
              x: {
                title: {
                  display: true,
                  text: 'Destination Airports',
                  font: this.scaleTextFont,
                }
              },
            }
          };
        });
        break;
      case "5": // Passenger Misconnections
        if (this.formGroup.get('passengers').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('passengers').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('passengers').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('passengers').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('passengers').value.airport;
        this.filterAcType = this.formGroup?.get('passengers').value.acType;

        this.connectingPassengersService.getconnectingPassengersForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          acType: this.filterAcType,
          station: this.filterStation,
          timeType: 'atd'
        }).subscribe(async (result) => {
          this.connectingPassengers = result;
          const paxPerCode: { [code: string]: number } = {};
          const passClassLabels = [];
          const passClassCodes = [];
          const connectingStations: { [iata: string]: { [seat: string]: any } } = {};
          const displayArrays: { [key: string]: any } = {};
          this.passengerData = {
            labels: this.passengerLabels,
            datasets: [],
          };


          const parentLegIdsToSearch = [];
          const legIdsToSearch = [];
          for (const passenger of this.connectingPassengers) {
            parentLegIdsToSearch.push(passenger.parentLegId);
            legIdsToSearch.push(passenger.legId);
          }

          const parentLegs = await firstValueFrom(this.legsService.getLegs({ id: parentLegIdsToSearch }));
          const normalLegs = await firstValueFrom(this.legsService.getLegs({ id: legIdsToSearch }));




          const passengerClasses = await firstValueFrom(this.generalSettingsService.getPassengerClasses());
          for (const passClass of passengerClasses) {
            passClassLabels.push(passClass.description);
            passClassCodes.push(passClass.code);
            displayArrays[`displayArray${passClass.code}`] = [];

          }
          for (const passenger of this.connectingPassengers) {

            const legTod = normalLegs.find((nLeg) => nLeg.id === passenger.legId).tod
            const calculatedGroundTime = dayjs(legTod).diff(dayjs(parentLegs.find((pLeg) => pLeg.id === passenger.parentLegId).toa));
            console.log('ground time : ', calculatedGroundTime, 'passcone time: ', this.minimumPassengerConnectionTime * 60000);

            if (calculatedGroundTime < this.minimumPassengerConnectionTime * 60000) {
              extractSeatingConfigurations(passenger.numberOfPassengers).forEach((item: string) => {
                const amount = item.slice(0, 1);
                const code = item.slice(1);

                for (const passClassCode of passClassCodes) {
                  if (code === passClassCode) {
                    if (!connectingStations[passenger.destinationAirport]) {
                      connectingStations[passenger.destinationAirport] = {}
                      paxPerCode[code] = (Number(amount) || 0);
                      connectingStations[passenger.destinationAirport][code] = paxPerCode[code]
                    } else {
                      paxPerCode[code] = (paxPerCode[code] || 0) + (Number(amount) || 0);
                      connectingStations[passenger.destinationAirport][code] = paxPerCode[code]
                    }
                  }
                }
              });
            }

          }

          console.log('final Missocections: ', connectingStations)

          this.passengerLabels = Object.keys(connectingStations);


          const totalArray = []
          for (const [key, value] of Object.entries(connectingStations)) {
            console.log('Station is: ', key, 'Passengers are', value);
            let totalInNumber = 0;

            for (const passengerClass of passClassCodes) {
              console.log('Passenger Class is : ', passengerClass)
              if (passengerClass in value) {
                displayArrays[`displayArray${passengerClass}`].push(value[passengerClass]);
                totalInNumber = totalInNumber + value[passengerClass];
              } else {
                displayArrays[`displayArray${passengerClass}`].push(0);
              }
            }

            totalArray.push(totalInNumber);

          }
          for (const [key, value] of Object.entries(displayArrays)) {
            let colorIndex = Math.floor(Math.random() * 8)
            this.passengerData.datasets.push({
              label: key[key.length - 1],
              backgroundColor: [`#${colorIndex}b${Math.floor(Math.random() * 9)}bd${Math.floor(Math.random() * 9)}`],
              hoverBackgroundColor: ['#5b9bd5'],
              borderColor: [`#${colorIndex}b${Math.floor(Math.random() * 8)}bd${Math.floor(Math.random() * 8)}`],
              hoverBorderWidth: 0,
              data: value,
            })
          }
          this.passengerData.datasets.push({
            label: 'Total',
            backgroundColor: ['#F78871'],
            hoverBackgroundColor: ['#F78871'],
            borderColor: ['#F78871'],
            hoverBorderWidth: 0,
            data: totalArray,
          })

          console.log('display array', displayArrays)

          console.log('DATASST after ', this.passengerData.datasets)


          this.passengerConfig = {
            type: 'bar',
            data: this.passengerData,
            options: this.passengerOptions,
          };
          this.passengerOptions = {
            plugins: {
              title: {
                display: true,
                text: `Connecting airport: ${this.filterStation}`,
                font: {
                  size: 20,
                }
              }
            },
            scales: {
              y: {
                ticks: {
                  stepSize: 2,
                },
                title: {
                  display: true,
                  text: 'Misconnectiong Passengers',
                  font: this.scaleTextFont,
                },
                beginAtZero: true,
              },
              x: {
                title: {
                  display: true,
                  text: 'Destination Airports',
                  font: this.scaleTextFont,
                }
              },
            }
          };
        });
        break;
    }
  }

  buildOperationsGraph() {

    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    let graphData = [];
    this.operationLabels = [];
    let operationLabels = [];
    let actualBlockTime = 0;
    let periodSelectedInDays: number;
    let processCount: { [title: string]: number } = {};
    let filterAcTypeId: number;
    let filterAirportId: number;

    switch (this.formGroup?.get('operations').value.type) {

      case "1": // Turnaround performance
        this.operationsChartLabel = 'Turnaround Performance';
        if (this.formGroup.get('operations').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('operations').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('operations').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;
        const onTimeArray = [];
        const goodArray = [];
        const midArray = [];
        const poorArray = [];


        this.pairsService.getPairsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          acType: this.filterAcType,
          withAtd: true
        }).subscribe((result) => {
          this.pairs = result;
          for (const pair of this.pairs) {
            let agt = dayjs(pair.__departureLegModel__.atd).diff(dayjs(pair.__arrivalLegModel__.ata));
            let sgt = dayjs(pair.__departureLegModel__.std).diff(dayjs(pair.__arrivalLegModel__.sta));
            console.log('AGT AND SGT FOR PAIR :', pair.id, 'are :', agt, 'and', sgt);
            let diff = Math.floor(agt / 60000) - Math.floor(sgt / 60000);
            if (agt && sgt) {
              if (agt <= sgt) {
                onTimeArray.push(pair);
              } else if (diff > 0 && diff <= 5) {
                goodArray.push(pair);
              } else if (diff > 5 && diff < 15) {
                midArray.push(pair);
              } else {
                poorArray.push(pair)
              }
            }
          }
          graphData.push((onTimeArray.length / this.pairs.length) * 100);
          graphData.push((goodArray.length / this.pairs.length) * 100);
          graphData.push((midArray.length / this.pairs.length) * 100);
          graphData.push((poorArray.length / this.pairs.length) * 100);
          console.log('GRAPH DATA ARRAY :', graphData)

          this.operationLabels = ['On Time', 'Good', 'Mid', 'Poor'];

          this.operationData = {
            labels: this.operationLabels,
            datasets: [{
              label: 'Turnaround Performance',
              backgroundColor: ['#5b9bd5', '#00e700', '#ffd966', '#e70000'],
              hoverBackgroundColor: ['#5b9bd5', '#00e700', '#ffd966', '#e70000'],
              borderColor: ['#5b9bd5', '#00e700', '#ffd966', '#e70000'],
              hoverBorderWidth: 0,
              data: graphData,
            }],
          };
          this.operationConfig = {
            type: 'bar',
            data: this.operationData,
            options: this.operationOptions,
          };
          this.operationOptions = {
            scales: {
              y: {
                ticks: {
                  callback: function (tickValue) {
                    return tickValue + '%';
                  },
                  stepSize: 2,
                },
                max: 100,
                title: {
                  display: true,
                  text: 'Percentage of Turnarounds',
                  font: this.scaleTextFont,
                },
              },
              x: {
                title: {
                  display: true,
                  text: 'Turnaround Performance',
                  font: this.scaleTextFont,
                }
              },
            }
          };

        });


        break;
      case "2": // Completed Non SLA Processes
        this.operationsChartLabel = 'Completed Non SLA Processes';

        if (this.formGroup.get('operations').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('operations').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('operations').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;
        console.log('FIlters(Station and ACType): ', this.filterStation, this.filterAcType)

        filterAcTypeId = this.filterAcType === null ? null : this.acTypes.filter((actype) => actype.iata === this.filterAcType)[0].id;
        filterAirportId = this.filterStation === null ? null : this.airports.filter((airport) => airport.iata === this.filterStation)[0].id;

        this.pairsService.getPairsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          acType: this.filterAcType,
          withAtd: true
        }).subscribe(async (result) => {
          this.pairs = result;
          console.log('Pairs are', result);
          const pairIds: number[] = [];
          for (const pair of this.pairs) {
            pairIds.push(pair.id)
          }
          ;
          const pairProcesses = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));
          const filteredPairProcesses = pairProcesses.filter((process) => process.startTime !== null);
          console.log('Pair Processes are', filteredPairProcesses);

          const gseIds: number[] = [];
          for (const pairProcess of filteredPairProcesses) {
            gseIds.push(pairProcess.gseId);
          }
          console.log('GSE Ids: ', gseIds)
          const gses = await firstValueFrom(this.gseService.fetchGses({ isActive: true, isSla: false, id: gseIds }));
          console.log('Non SLA GSES FOUND:', gses);

          for (const pairProcess of filteredPairProcesses) {
            const gseNonSLA = gses.find((gse) =>
              gse.id === pairProcess.gseId
            );
            if (gseNonSLA) {
              if (!processCount[gseNonSLA.title]) {
                processCount[gseNonSLA.title] = 1;
              } else {
                processCount[gseNonSLA.title]++;
              }
            }
          }

          let graphData = [];
          let operationLabels = [];

          console.log('process Counter', processCount)
          for (const [key, value] of Object.entries(processCount)) {
            graphData.push(value)
            operationLabels.push(key);
          }
          ;

          this.operationLabels = operationLabels;
          this.operationData = {
            labels: this.operationLabels,
            datasets: [{
              label: 'Completed Non SLA Processes',
              backgroundColor: ['#5b9bd5'],
              hoverBackgroundColor: ['#5b9bd5'],
              borderColor: ['#5b9bd5'],
              hoverBorderWidth: 0,
              data: graphData,
            }],
          };
          this.operationConfig = {
            type: 'bar',
            data: this.operationData,
            options: this.operationOptions,
          };
          this.operationOptions = {
            scales: {
              y: {
                ticks: {
                  stepSize: 2,
                },
                suggestedMax: 10,
                title: {
                  display: true,
                  text: 'GSE(s) Amount',
                  font: this.scaleTextFont,
                },
              },
              x: {
                title: {
                  display: true,
                  text: 'Processes',
                  font: this.scaleTextFont,
                }
              },
            }
          };
        });
        break;
      case "3": // Ongoing Non SLA Processes
        this.operationsChartLabel = 'Ongoing Non SLA Processes';
        if (this.formGroup.get('operations').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('operations').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('operations').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;
        console.log('FIlters(Station and ACType): ', this.filterStation, this.filterAcType)

        filterAcTypeId = this.filterAcType === null ? null : this.acTypes.filter((actype) => actype.iata === this.filterAcType)[0].id;
        filterAirportId = this.filterStation === null ? null : this.airports.filter((airport) => airport.iata === this.filterStation)[0].id;

        this.pairsService.getPairsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          acType: this.filterAcType,
          withAtd: false
        }).subscribe(async (result) => {
          this.pairs = result;
          console.log('Pairs are', result);
          const pairIds: number[] = [];
          for (const pair of this.pairs) {
            pairIds.push(pair.id)
          }
          ;
          const pairProcesses = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));
          const filteredPairProcesses = pairProcesses.filter((process) => process.startTime !== null);
          console.log('Pair Processes are', pairProcesses, filteredPairProcesses);

          const gseIds: number[] = [];
          for (const pairProcess of filteredPairProcesses) {
            gseIds.push(pairProcess.gseId);
          }
          console.log('GSE Ids: ', gseIds)

          const gses = await firstValueFrom(this.gseService.fetchGses({ isActive: true, isSla: false, id: gseIds }));
          console.log('Non SLA GSES FOUND:', gses);

          for (const pairProcess of filteredPairProcesses) {
            const gseNonSLA = gses.find((gse) =>
              gse.id === pairProcess.gseId
            );
            if (gseNonSLA) {
              if (!processCount[gseNonSLA.title]) {
                processCount[gseNonSLA.title] = 1;
              } else {
                processCount[gseNonSLA.title]++;
              }
            }
          }

          let graphData = [];
          let operationLabels = [];

          console.log('process Counter', processCount)
          for (const [key, value] of Object.entries(processCount)) {
            graphData.push(value)
            operationLabels.push(key);
          }
          ;

          this.operationLabels = operationLabels;
          this.operationData = {
            labels: this.operationLabels,
            datasets: [{
              label: 'Ongoing Non SLA Processes',
              backgroundColor: ['#5b9bd5'],
              hoverBackgroundColor: ['#5b9bd5'],
              borderColor: ['#5b9bd5'],
              hoverBorderWidth: 0,
              data: graphData,
            }],
          };
          this.operationConfig = {
            type: 'bar',
            data: this.operationData,
            options: this.operationOptions,
          };
          this.operationOptions = {
            scales: {
              y: {
                ticks: {
                  stepSize: 5,
                },
                suggestedMax: 10,
                title: {
                  display: true,
                  text: 'GSE(s) Amount',
                  font: this.scaleTextFont,
                },
              },
              x: {
                title: {
                  display: true,
                  text: 'Processes',
                  font: this.scaleTextFont,
                }
              },
            }
          };
        });
        break;
      case "4": //Total Process Performance
        this.operationsChartLabel = 'Total Process Performance';
        const processCountTimes: { [title: string]: { realSeconds: number, maxSeconds: number } } = {};

        if (this.formGroup.get('operations').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('operations').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('operations').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;

        this.pairsService.getPairsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          acType: this.filterAcType,
          withAtd: true
        }).subscribe(async (result) => {
          this.pairs = result;
          console.log('Pairs are', result);
          const pairIds: number[] = [];
          for (const pair of this.pairs) {
            pairIds.push(pair.id)
          }
          ;
          const pairProcesses = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));
          console.log('Pair Processes are', pairProcesses);

          const processIds: number[] = [];
          const gseIds: number[] = [];

          for (const pairProcess of pairProcesses) {
            processIds.push(pairProcess.processId);
            gseIds.push(pairProcess.gseId);
          }

          const filters: Searchable<IGsesModel> = { isActive: true, id: gseIds };
          const gses = await firstValueFrom(this.gseService.fetchGses(filters));
          const allProcesses = await firstValueFrom(this.processService.fetchProcesses());

          for (const pairProcess of pairProcesses) {
            if (pairProcess.endTime !== null) {
              const process = allProcesses.find((process) =>
                process.id === pairProcess.processId
              );
              if (!processCountTimes[process.title]) {
                processCountTimes[process.title] = {
                  realSeconds: dayjs(pairProcess.endTime).diff(pairProcess.startTime, 'seconds'),
                  maxSeconds: gses.find((g) => g.id === pairProcess.gseId).maximumProcessTimeInMinutes * 60
                }
              } else {
                processCountTimes[process.title].realSeconds = processCountTimes[process.title].realSeconds + dayjs(pairProcess.endTime).diff(pairProcess.startTime, 'seconds');
                processCountTimes[process.title].maxSeconds = processCountTimes[process.title].maxSeconds + gses.find((g) => g.id === pairProcess.gseId).maximumProcessTimeInMinutes * 60;

              }
            }
          }

          let graphData = [];
          let operationLabels = [];

          //const allProcesses = await firstValueFrom(this.processService.fetchProcesses());
          console.log('process Counter times', processCountTimes)
          for (const [key, value] of Object.entries(processCountTimes)) {
            graphData.push(value.realSeconds / value.maxSeconds <= 1 ? (value.realSeconds / value.maxSeconds) * 100 : (((value.realSeconds / value.maxSeconds) - 1) * 100) * (-1));
            operationLabels.push(key);
          }
          ;

          this.operationLabels = operationLabels;
          let colors = []
          for (let i = 0; i < graphData.length; i++) {
            let color;
            if (graphData[i] >= 0) {
              color = '#5b9bd5'
            } else {
              color = '#e70000'
            }
            colors[i] = color;
          }
          this.operationData = {
            labels: this.operationLabels,
            datasets: [{
              label: 'Total Process Performance',
              backgroundColor: colors,
              hoverBackgroundColor: colors,
              borderColor: colors,
              hoverBorderWidth: 0,
              data: graphData,
            }],
          };
          this.operationConfig = {
            type: 'bar',
            data: this.operationData,
            options: this.operationOptions,
          };
          this.operationOptions = {
            scales: {
              y: {
                ticks: {
                  callback: function (tickValue) {
                    return tickValue + '%';
                  },
                  stepSize: 5,
                },
                suggestedMax: 50,
                title: {
                  display: true,
                  text: 'Performance (%)',
                  font: this.scaleTextFont,
                },
              },
              x: {
                title: {
                  display: true,
                  text: 'Processes',
                  font: this.scaleTextFont,
                }
              },
            }
          };
        });
        break;
      case "5": //Process Performance Breakdown
        this.operationsChartLabel = 'Process Performance Breakdown';
        const processCountTimes2: { [title: string]: { realSeconds: number, maxSeconds: number } } = {};

        if (this.formGroup.get('operations').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('operations').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('operations').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;

        this.pairsService.getPairsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          acType: this.filterAcType,
          withAtd: true
        }).subscribe(async (result) => {
          this.pairs = result;
          const pairIds: number[] = [];
          for (const pair of this.pairs) {
            pairIds.push(pair.id)
          }
          ;
          const pairProcesses = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));

          const processIds: number[] = [];
          const gseIds: number[] = [];

          for (const pairProcess of pairProcesses) {
            processIds.push(pairProcess.processId);
            gseIds.push(pairProcess.gseId);
          }

          const filters: Searchable<IGsesModel> = { isActive: true, id: gseIds };
          const gses = await firstValueFrom(this.gseService.fetchGses(filters));
          const allProcesses = await firstValueFrom(this.processService.fetchProcesses());

          for (const pairProcess of pairProcesses) {
            if (pairProcess.endTime !== null) {
              const process = allProcesses.find((process) =>
                process.id === pairProcess.processId
              );
              if (!processCountTimes2[process.title]) {
                processCountTimes2[process.title] = {
                  realSeconds: dayjs(pairProcess.endTime).diff(pairProcess.startTime, 'seconds'),
                  maxSeconds: gses.find((g) => g.id === pairProcess.gseId).maximumProcessTimeInMinutes * 60
                }
              } else {
                processCountTimes2[process.title].realSeconds = processCountTimes2[process.title].realSeconds + dayjs(pairProcess.endTime).diff(pairProcess.startTime, 'seconds');
                processCountTimes2[process.title].maxSeconds = processCountTimes2[process.title].maxSeconds + gses.find((g) => g.id === pairProcess.gseId).maximumProcessTimeInMinutes * 60;

              }
            }
          }

          let graphData = [];
          let operationLabels = [];
          const secondGraphData = [];
          console.log('process Counter times', processCountTimes2)
          for (const [key, value] of Object.entries(processCountTimes2)) {
            graphData.push(value.realSeconds / 60);
            secondGraphData.push(value.maxSeconds / 60);
            operationLabels.push(key);
          }
          ;

          this.operationLabels = operationLabels;
          let colors = []
          for (let i = 0; i < secondGraphData.length; i++) {
            let color;
            if (graphData[i] <= secondGraphData[i]) {
              color = '#5b9bd5'
            } else {
              color = '#e70000'
            }
            colors[i] = color;
          }
          this.operationData = {
            labels: this.operationLabels,
            datasets: [{
              label: 'Actual Process Time',
              backgroundColor: colors,
              hoverBackgroundColor: colors,
              borderColor: colors,
              hoverBorderWidth: 0,
              data: graphData,
            },
            {
              label: 'Max Process Time',
              backgroundColor: ['#D3D3D3'],
              hoverBackgroundColor: ['#D3D3D3'],
              borderColor: ['#D3D3D3'],
              hoverBorderWidth: 0,
              data: secondGraphData,
            },
            ],
          };
          this.operationConfig = {
            type: 'bar',
            data: this.operationData,
            options: this.operationOptions,
          };
          this.operationOptions = {
            scales: {
              y: {
                ticks: {
                  stepSize: 5,
                },
                suggestedMax: 50,
                title: {
                  display: true,
                  text: 'Minutes',
                  font: this.scaleTextFont,
                },
              },
              x: {
                title: {
                  display: true,
                  text: 'Processes',
                  font: this.scaleTextFont,
                }
              },
            }
          };
        });
        break;
      case "6": // Completed Turnarounds-Amount of GSEs Used
        this.operationsChartLabel = 'Completed Turnarounds-Amount of GSEs Used';

        if (this.formGroup.get('operations').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('operations').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('operations').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }

        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;
        console.log('FIlters(Station and ACType): ', this.filterStation, this.filterAcType)

        filterAcTypeId = this.filterAcType === null ? null : this.acTypes.filter((actype) => actype.iata === this.filterAcType)[0].id;
        filterAirportId = this.filterStation === null ? null : this.airports.filter((airport) => airport.iata === this.filterStation)[0].id;

        this.pairsService.getPairsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          acType: this.filterAcType,
          withAtd: true
        }).subscribe(async (result) => {
          this.pairs = result;
          console.log('Pairs are', result);
          const pairIds: number[] = [];
          for (const pair of this.pairs) {
            pairIds.push(pair.id)
          }
          ;
          const pairProcesses = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));
          const filteredPairProcesses = pairProcesses.filter((process) => process.startTime !== null);
          console.log('Pair Processes are', filteredPairProcesses);

          const gseIds: number[] = [];
          for (const pairProcess of filteredPairProcesses) {
            gseIds.push(pairProcess.gseId);
          }
          console.log('GSE Ids: ', gseIds)
          const gses = await firstValueFrom(this.gseService.fetchGses({ isActive: true, id: gseIds }));
          console.log('GSES FOUND:', gses);

          for (const pairProcess of filteredPairProcesses) {
            const gse = gses.find((gse) =>
              gse.id === pairProcess.gseId
            );
            if (gse) {
              if (!processCount[gse.title]) {
                processCount[gse.title] = 1;
              } else {
                processCount[gse.title]++;
              }
            }
          }

          let graphData = [];
          let operationLabels = [];

          console.log('process Counter', processCount)
          for (const [key, value] of Object.entries(processCount)) {
            graphData.push(value)
            operationLabels.push(key);
          }
          ;

          this.operationLabels = operationLabels;
          this.operationData = {
            labels: this.operationLabels,
            datasets: [{
              label: 'Completed Turnarounds-Amount of GSEs Used',
              backgroundColor: ['#5b9bd5'],
              hoverBackgroundColor: ['#5b9bd5'],
              borderColor: ['#5b9bd5'],
              hoverBorderWidth: 0,
              data: graphData,
            }],
          };
          this.operationConfig = {
            type: 'bar',
            data: this.operationData,
            options: this.operationOptions,
          };
          this.operationOptions = {
            scales: {
              y: {
                ticks: {
                  stepSize: 2,
                },
                suggestedMax: 10,
                title: {
                  display: true,
                  text: 'GSE(s) Amount',
                  font: this.scaleTextFont,
                },
              },
              x: {
                title: {
                  display: true,
                  text: 'Processes',
                  font: this.scaleTextFont,
                }
              },
            }
          };
        });
        break;
    }
  }

  private buildUtilizationGraph() {

    const defaultStartDate = dayjs.utc(new Date()).startOf('day').format();
    const defaultEndDate = dayjs.utc(new Date()).endOf('day').format();
    let graphData = [];
    this.utilizationLabels = [];
    let utilizationLabels = [];
    let actualBlockTime = 0;
    let periodSelectedInDays: number;


    switch (this.formGroup?.get('utilization').value.type) {
      case "1": // AC Reg Utilization
        //this.operationsChartLabel = 'AC Reg Utilization';

        if (this.formGroup.get('operations').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('operations').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('operations').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('operations').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }
        // let actualBlockTime = 0;
        const blockTimePerAcReg: { [acReg: string]: number } = {};


        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;


        this.legsService.getLegsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          acType: this.filterAcType,
          timeType: 'atd'
        }).subscribe((result) => {
          this.legs = result;
          periodSelectedInDays = Math.round((dayjs(this.filterDayTo).diff(dayjs(this.filterDayFrom))) / 86400000);
          if (!periodSelectedInDays) {
            periodSelectedInDays = 1
          }
          for (const leg of this.legs) {
            if (leg.atd !== null && leg.ata !== null) {
              blockTimePerAcReg[leg.acRegistration] = ((dayjs(leg.ata).diff(dayjs(leg.atd))) / 3600000) / periodSelectedInDays + (blockTimePerAcReg[leg.acRegistration] || 0);
            }
            ;
          }
          ;

          for (const [key, value] of Object.entries(blockTimePerAcReg)) {
            graphData.push(value)
            utilizationLabels.push(key);
          }
          ;
          console.log('Time per ac reg', blockTimePerAcReg)


          this.utilizationData = {
            labels: utilizationLabels,
            datasets: [{
              label: 'AC Reg Utilization',
              backgroundColor: ['#5b9bd5'],
              hoverBackgroundColor: ['#5b9bd5'],
              borderColor: ['#5b9bd5'],
              hoverBorderWidth: 0,
              data: graphData
            }],
          };
          this.utilizationConfig = {
            type: 'bar',
            data: this.utilizationData
          };
          this.utilizationOptions = {
            scales: {
              y: {
                suggestedMin: 0,
                title: {
                  display: true,
                  text: 'AC Reg Utilization (Hours)',
                  font: this.scaleTextFont,
                },
                beginAtZero: false,
              },
              x: {
                title: {
                  display: true,
                  text: 'AC Registrations',
                  font: this.scaleTextFont,
                }
              },
            },
          };
        });
        break;
      case "2": // AC Type Utilization
        //this.operationsChartLabel = 'AC Type Utilization';

        if (this.formGroup.get('utilization').get('date').value === null) {
          //default dates
          this.filterDayFrom = defaultStartDate;
          this.filterDayTo = defaultEndDate;
        } else {
          this.filterDayFrom = this.formGroup.get('utilization').get('date').value.from !== null ? ngbDateToDayjs(this.formGroup.get('utilization').get('date').value?.from).set('hour', 0).set('minute', 0).set('second', 0).toDate() : null;
          this.filterDayTo = this.formGroup.get('utilization').get('date').value.to !== null ? ngbDateToDayjs(this.formGroup.get('utilization').get('date').value?.to).set('hour', 23).set('minute', 59).set('second', 59).toDate() : null;
        }
        const blockTimePerAcType: { [acType: string]: number } = {};


        this.filterStation = this.formGroup?.get('utilization').value.airport === 'all' ? null : this.formGroup?.get('utilization').value.airport;
        this.filterAcType = this.formGroup?.get('utilization').value.acType === 'all' ? null : this.formGroup?.get('utilization').value.acType;


        this.legsService.getLegsForReports({
          startDate: this.filterDayFrom,
          endDate: this.filterDayTo,
          station: this.filterStation,
          acType: this.filterAcType,
          timeType: 'atd'
        }).subscribe((result) => {
          this.legs = result;
          periodSelectedInDays = Math.round((dayjs(this.filterDayTo).diff(dayjs(this.filterDayFrom))) / 86400000);
          if (!periodSelectedInDays) {
            periodSelectedInDays = 1
          }

          for (const leg of this.legs) {
            if (leg.atd !== null && leg.ata !== null) {
              actualBlockTime = actualBlockTime + (dayjs(leg.ata).diff(dayjs(leg.atd))) / 3600000;
              blockTimePerAcType[leg.acType] = actualBlockTime / periodSelectedInDays;
            }
            ;
          }
          ;
          for (const [key, value] of Object.entries(blockTimePerAcType)) {
            graphData.push(value)
            utilizationLabels.push(key);
          }
          ;


          this.utilizationData = {
            labels: utilizationLabels,
            datasets: [{
              label: 'AC Type Utilization',
              backgroundColor: ['#5b9bd5'],
              hoverBackgroundColor: ['#5b9bd5'],
              borderColor: ['#5b9bd5'],
              hoverBorderWidth: 0,
              data: graphData
            }],
          };
          this.utilizationConfig = {
            type: 'bar',
            data: this.utilizationData
          };
          this.utilizationOptions = {
            scales: {
              y: {
                suggestedMin: 0,
                title: {
                  display: true,
                  text: 'AC Type Utilization (Hours)',
                  font: this.scaleTextFont,
                },
                beginAtZero: false,
              },
              x: {
                title: {
                  display: true,
                  text: 'AC Types',
                  font: this.scaleTextFont,
                }
              },
            },
          };
        });
        break;
    }
  }

  download(canvasId: string, graph, category: string) {
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    this.fillCanvasBackgroundWithColor(canvas, 'white');
    const downloadLink = document.createElement('a');
    downloadLink.href = canvas.toDataURL('image/png');
    this.createPNGFileName(graph, category)
    downloadLink.download = this.fileName;
    downloadLink.click();
  }

  fillCanvasBackgroundWithColor(canvas: HTMLCanvasElement, color: string) {
    const context = canvas.getContext('2d');
    context.save();
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.restore();
  }

  async createOperationsExcelFile(category, pairs, legs) {

    let data = [];
    let periodSelectedInDays;
    let pairIds: number[] = [];
    let gseIds: number[] = [];
    let processIds: number[] = [];
    let filterAcTypeId;
    let filterAirportId;
    let filters: Searchable<IGsesModel> = { isActive: true, id: gseIds };
    let actualMinutes;
    let processCounter: { [title: string]: number } = {};

    switch (category) {
      case 'Turnaround Performance':

        data.push(['Arriving Sector', 'Departing Sector', 'Turnaround Station', 'Actual Ground Time (Min.)', 'Standard Ground Time (Min.)', 'Turnaround Performance']);

        for (const pair of pairs) {
          let agt = dayjs(pair.__departureLegModel__.atd).diff(dayjs(pair.__arrivalLegModel__.ata));
          let sgt = dayjs(pair.__departureLegModel__.std).diff(dayjs(pair.__arrivalLegModel__.sta));
          let diff = Math.floor(agt / 60000) - Math.floor(sgt / 60000);
          if (agt && sgt) {
            if (agt <= sgt) {
              data.push([pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber, pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber, pair.__arrivalLegModel__.arrivalStation, Math.floor(agt / 60000).toString(), Math.floor(sgt / 60000).toString(), 'On Time']);
            } else if (diff > 0 && diff <= 5) {
              data.push([pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber, pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber, pair.__arrivalLegModel__.arrivalStation, Math.floor(agt / 60000).toString(), Math.floor(sgt / 60000).toString(), 'Good']);
            } else if (diff > 5 && diff < 15) {
              data.push([pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber, pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber, pair.__arrivalLegModel__.arrivalStation, Math.floor(agt / 60000).toString(), Math.floor(sgt / 60000).toString(), 'Mid']);
            } else {
              data.push([pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber, pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber, pair.__arrivalLegModel__.arrivalStation, Math.floor(agt / 60000).toString(), Math.floor(sgt / 60000).toString(), 'Poor']);
            }
          }
        }
        data.push([], [], ['Info for Calculations'], ['On Time', 'AGT <= SGT'], ['Good', 'AGT - SGT <= 5min.'], ['Mid', '15min. > AGT - SGT > 5min.'], ['Poor', 'AGT - SGT > 15min.'])
        this.createFileName('Operations', 'Turnaround Performance');
        this.sheetName = 'Turnaround Performance'
        break;

      case 'Completed Non SLA Processes':
        // const pairIds = [];
        const processCount: { [title: string]: number } = {};
        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;

        data.push(['Date', 'Arriving Sector', 'Departing Sector', 'Turnaround Station', 'Process']);

        for (const pair of this.pairs) {
          pairIds.push(pair.id)
        }
        console.log('Pair Ids: ', pairIds);
        const pairProcesses = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));
        const filteredPairProcessesCompleted = pairProcesses.filter((process) => process.startTime !== null);
        console.log('Pair Processes: ', pairIds);


        for (const pairProcess of pairProcesses) {
          gseIds.push(pairProcess.gseId);
        }

        const gses = await firstValueFrom(this.gseService.fetchGses({ isActive: true, isSla: false, id: gseIds }));
        for (const pair of this.pairs) {
          const pairProcessesOfPair = filteredPairProcessesCompleted.filter((pairProccess) => pairProccess.pairId === pair.id);
          for (const a of pairProcessesOfPair) {
            const gseNonSLA = gses.find((gse) =>
              gse.id === a.gseId
            );
            if (gseNonSLA) {
              data.push([
                dayjs(pair.__departureLegModel__.atd).format('DD-MM-YYYY'),
                pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber,
                pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber,
                pair.__departureLegModel__.departureStation,
                gseNonSLA.title
              ])
            }
          }

        }


        this.createFileName('Operations', 'Completed Non SLA Processes');
        this.sheetName = 'Completed Non SLA Processes'
        break;

      case 'Ongoing Non SLA Processes':
        // const pairIds = [];
        //const processCountOngoing: {[title:string]:number} = {};
        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;

        data.push(['Date', 'Arriving Sector', 'Departing Sector', 'Turnaround Station', 'Process']);

        for (const pair of this.pairs) {
          pairIds.push(pair.id)
        }
        ;
        const pairProcessesOngoing = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));
        const filteredPairProcessesOngoing = pairProcessesOngoing.filter((process) => process.startTime !== null);


        for (const pairProcess of filteredPairProcessesOngoing) {
          gseIds.push(pairProcess.gseId);
        }

        const gsesOngoing = await firstValueFrom(this.gseService.fetchGses({ isActive: true, isSla: false, id: gseIds }));
        for (const pair of this.pairs) {
          const pairProcessesOfPair = filteredPairProcessesOngoing.filter((pairProccess) => pairProccess.pairId === pair.id);
          for (const a of pairProcessesOfPair) {
            const gseNonSLA = gsesOngoing.find((gse) =>
              gse.id === a.gseId
            );
            if (gseNonSLA) {
              data.push([
                dayjs(pair.__arrivalLegModel__.ata).format('DD-MM-YYYY'),
                pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber,
                pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber,
                pair.__departureLegModel__.departureStation,
                gseNonSLA.title
              ])
            }
          }

        }


        this.createFileName('Operations', 'Ongoing Non SLA Processes');
        this.sheetName = 'Ongoing Non SLA Processes'
        break;

      case 'Total Process Performance':

        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;

        filterAcTypeId = this.formGroup?.get('operations').value.acType === 'all' ? null : this.acTypes.filter((actype) => actype.iata === this.filterAcType)[0].id;
        filterAirportId = this.formGroup?.get('operations').value.airport === 'all' ? null : this.airports.filter((airport) => airport.iata === this.filterStation)[0].id;

        data.push(['Date', 'Arriving Sector', 'Departing Sector', 'Turnaround Station', 'Process', 'Process Minutes', 'Process Max Minutes', 'Performance']);

        for (const pair of this.pairs) {
          pairIds.push(pair.id)
        }
        ;
        const pairProcesses2 = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));

        for (const pairProcess of pairProcesses2) {
          processIds.push(pairProcess.processId);
          gseIds.push(pairProcess.gseId);
        }

        const allProcesses = await firstValueFrom(this.processService.fetchProcesses());
        const gses2 = await firstValueFrom(this.gseService.fetchGses(filters));
        for (const pair of this.pairs) {
          const pairProcessesOfPair = pairProcesses2.filter((pairProccess) => pairProccess.pairId === pair.id);
          for (const a of pairProcessesOfPair) {
            let actualSeconds = 0;
            let maxMinutes = gses2.find((g) => g.id === a.gseId).maximumProcessTimeInMinutes
            if (a.endTime !== null) {
              actualSeconds = dayjs(a.endTime).diff(a.startTime, 'seconds');
              actualMinutes = this.convertSecondsToMinutes(actualSeconds);
              data.push([
                dayjs(pair.__departureLegModel__.atd).format('DD-MM-YYYY'),
                pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber,
                pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber,
                pair.__departureLegModel__.departureStation,
                allProcesses.find((p) => p.id === a.processId).title,
                actualMinutes,
                maxMinutes,
                actualSeconds / (maxMinutes * 60) <= 1 ? (actualSeconds / (maxMinutes * 60)) * 100 : (((actualSeconds / (maxMinutes * 60)) - 1) * 100) * (-1)

              ])
            }
          }
        }

        this.createFileName('Operations', 'Total Process Performance');
        this.sheetName = 'Total Process Performance'
        break;

      case 'Process Performance Breakdown':
        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;

        filterAcTypeId = !this.formGroup?.get('operations').value.acType ? null : this.acTypes.filter((actype) => actype.iata === this.filterAcType)[0].id;
        filterAirportId = !this.formGroup?.get('operations').value.airport ? null : this.airports.filter((airport) => airport.iata === this.filterStation)[0].id;

        data.push(['Date', 'Arriving Sector', 'Departing Sector', 'Turnaround Station', 'Process', 'Process Minutes', 'Process Max Minutes',]);

        for (const pair of this.pairs) {
          pairIds.push(pair.id)
        }
        ;
        const pairProcesses3 = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));

        for (const pairProcess of pairProcesses3) {
          processIds.push(pairProcess.processId);
          gseIds.push(pairProcess.gseId);
        }

        const allProcesses2 = await firstValueFrom(this.processService.fetchProcesses());
        const gses3 = await firstValueFrom(this.gseService.fetchGses(filters));
        for (const pair of this.pairs) {
          const pairProcessesOfPair = pairProcesses3.filter((pairProccess) => pairProccess.pairId === pair.id);
          for (const a of pairProcessesOfPair) {
            let actualSeconds = 0;
            let maxMinutes = gses3.find((g) => g.id === a.gseId).maximumProcessTimeInMinutes
            if (a.endTime !== null) {
              actualSeconds = dayjs(a.endTime).diff(a.startTime, 'seconds');
              actualMinutes = this.convertSecondsToMinutes(actualSeconds);
              data.push([
                dayjs(pair.__departureLegModel__.atd).format('DD-MM-YYYY'),
                pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber,
                pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber,
                pair.__departureLegModel__.departureStation,
                allProcesses2.find((p) => p.id === a.processId).title,
                actualMinutes,
                maxMinutes,
              ])
            }
          }

        }

        this.createFileName('Operations', 'Process Performance Breakdown');
        this.sheetName = 'Process Performance Breakdown'
        break;

      case 'Completed Turnarounds-Amount of GSEs Used':
        // const pairIds = [];
        this.filterStation = this.formGroup?.get('operations').value.airport;
        this.filterAcType = this.formGroup?.get('operations').value.acType;

        //filterAcTypeId = this.formGroup?.get('operations').value.acType === 'all' ? null : this.acTypes.filter((actype) => actype.iata === this.filterAcType)[0].id;
        //filterAirportId = this.formGroup?.get('operations').value.airport === 'all' ? null : this.airports.filter((airport) => airport.iata === this.filterStation)[0].id;

        data.push(['Date', 'Arriving Flight Number', 'Arriving Sector', 'Departing Flight Number', 'Departing Sector', 'Turnaround Time (ATA-ATD)', 'GSE(s) Used', 'GSE Amount']);

        for (const pair of this.pairs) {
          pairIds.push(pair.id)
        }
        ;
        const pairProcessesUsed = await firstValueFrom(this.pairProcessesService.getPairsProccessesByPairId(pairIds));
        const processesWithStartTime = pairProcessesUsed.filter((process) => process.startTime !== null);

        for (const pairProcess of processesWithStartTime) {
          gseIds.push(pairProcess.gseId);
        }

        const gses1 = await firstValueFrom(this.gseService.fetchGses({ isActive: true, id: gseIds }));
        for (const pair of this.pairs) {
          const pairProcessesOfPair = processesWithStartTime.filter((pairProccess) => pairProccess.pairId === pair.id);
          for (const a of pairProcessesOfPair) {
            const gse = gses1.find((gse) =>
              gse.id === a.gseId
            );
            if (gse) {
              data.push([
                dayjs(pair.__departureLegModel__.atd).format('DD-MM-YYYY'),
                pair.__arrivalLegModel__.airlineDesignator + pair.__arrivalLegModel__.flightNumber,
                pair.__arrivalLegModel__.departureStation + '-' + pair.__arrivalLegModel__.arrivalStation,
                pair.__departureLegModel__.airlineDesignator + pair.__departureLegModel__.flightNumber,
                pair.__departureLegModel__.departureStation + '-' + pair.__departureLegModel__.arrivalStation,
                dayjs.duration(dayjs(pair.__departureLegModel__.atd).diff(pair.__arrivalLegModel__.ata)).format('HH:mm'),
                gse.gseIdentifier,
                a.amount,
              ])
            }
          }

        }


        this.createFileName('Operations', 'Completed Turnarounds-GSEs Used');
        this.sheetName = 'Completed Turnarounds-GSEs Used'
        break;

      default:
        break;
    }
    this.createExcelFile(data, this.fileName, this.sheetName)
  }

  async createPunctualityExcelFile(category, legs) {

    let data = [];
    let periodSelectedInDays;
    let leginfo;
    let countOnTime = 0;
    let countDelayed = 0;
    let totalCountMinutes = 0;

    switch (category) {
      case 'On Time Performance':

        data.push(['Date', 'Sector', 'Departing Station', 'STD', 'ATD', 'Total Dep. Delay (Min)', 'Performance']);
        for (const leg of legs) {
          if (dayjs(leg.atd).isAfter(dayjs(leg.std).add(5, 'minute'))) {
            data.push([
              dayjs(leg.atd).format('DD-MM-YYYY'),
              leg.airlineDesignator + leg.flightNumber,
              leg.departureStation,
              dayjs(leg.std).format('HH:mm'),
              dayjs(leg.atd).format('HH:mm'),
              dayjs(leg.atd).diff(dayjs(leg.std), 'minutes'),
              'Delayed'
            ]);
            countDelayed++;
          } else {
            data.push([
              dayjs(leg.atd).format('DD-MM-YYYY'),
              leg.airlineDesignator + leg.flightNumber,
              leg.departureStation,
              dayjs(leg.std).format('HH:mm'),
              dayjs(leg.atd).format('HH:mm'),
              dayjs(leg.atd).diff(dayjs(leg.std), 'minutes'),
              'On Time'
            ]);
            countOnTime++;
          }
        }
        ;
        data.push([]);
        data.push(['Total On Time', countOnTime]);
        data.push(['Total Delayed', countDelayed]);
        this.createFileName('Punctuality', 'On Time Performance');
        this.sheetName = 'On Time Performance'


        break;

      case 'Arrival Punctuality':
        countDelayed = 0;
        countOnTime = 0;
        data.push(['Date', 'Sector', 'Arrival Station', 'STD', 'ATD', 'Total Arr. Delay (Min)', 'Performance']);
        for (const leg of legs) {
          if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
            data.push([
              dayjs(leg.ata).format('DD-MM-YYYY'),
              leg.airlineDesignator + leg.flightNumber,
              leg.arrivalStation,
              dayjs(leg.sta).format('HH:mm'),
              dayjs(leg.ata).format('HH:mm'),
              dayjs(leg.ata).diff(dayjs(leg.sta), 'minutes'),
              'Delayed'
            ]);
            countDelayed++;
          } else {
            data.push([
              dayjs(leg.ata).format('DD-MM-YYYY'),
              leg.airlineDesignator + leg.flightNumber,
              leg.arrivalStation,
              dayjs(leg.sta).format('HH:mm'),
              dayjs(leg.ata).format('HH:mm'),
              dayjs(leg.ata).diff(dayjs(leg.sta), 'minutes'),
              'On Time'
            ]);
            countOnTime++;
          }
        }
        ;
        data.push([]);
        data.push(['Total On Time', countOnTime]);
        data.push(['Total Delayed', countDelayed]);
        this.createFileName('Punctuality', 'Arrival Punctuality');
        this.sheetName = 'Arrival Punctuality'
        break;

      case 'Delayed per Delay Code':
        data.push(['Date', 'Sector', 'Delay Code', 'Delay Minutes']);
        const legIdsInLegList = legs.map((leg) => leg.id);

        const legDelayLogs = await firstValueFrom(this.legDelayLogsService.getLegDelayLogs({
          isActive: [true],
          legId: legIdsInLegList
        }));
        const delayCodes = await firstValueFrom(this.generalSettingsService.getDelayCodes());

        for (const delayLog of legDelayLogs) {
          const delayCodesWeNeed = delayCodes.filter((delaycode) => delayLog.delayCodeId === delaycode.id);
          leginfo = legs.find((leg) => leg.id === delayLog.legId);
          data.push([
            dayjs(leginfo.atd).format('DD-MM-YYYY'),
            leginfo.airlineDesignator + leginfo.flightNumber,
            delayCodesWeNeed[0].code,
            delayLog.minutes]);
        }
        data.push([]);
        this.createFileName('Punctuality', 'Delayed per Delay Code');
        this.sheetName = 'Delayed per Delay Code'
        break;

      case 'Total Departure Delay':
        legs.filter((leg) => dayjs(leg.atd).isAfter(dayjs(leg.std).add(5, 'minute')));
        totalCountMinutes = 0;
        data.push(['Date', 'Sector', 'Departure Station', 'Delay Minutes']);
        const legIdsInLegListD = legs.map((leg) => leg.id);

        const pairLegDelayLogsD = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({
          isActive: true,
          legId: legIdsInLegListD,
          timeTypeId: 6
        }));
        pairLegDelayLogsD.sort((a, b) => {
          return b.id - a.id
        })
        for (const pairLegDelay of pairLegDelayLogsD) {
          if (legIdsInLegListD.includes(pairLegDelay.legId)) {
            const index = legIdsInLegListD.indexOf(pairLegDelay.legId);
            legIdsInLegListD.splice(index, 1);
            leginfo = legs.find((leg) => leg.id === pairLegDelay.legId);
            data.push([
              dayjs(leginfo.atd).format('DD-MM-YYYY'),
              leginfo.airlineDesignator + leginfo.flightNumber,
              leginfo.departureStation,
              pairLegDelay.totalDelayInMinutes
            ]);
            totalCountMinutes = totalCountMinutes + pairLegDelay.totalDelayInMinutes;
          }
        }
        ;
        data.push([]);
        data.push(['Total Delay (Min)', totalCountMinutes]);
        data.push(['Total Sectors', legs.length])
        this.createFileName('Punctuality', 'Total Departure Delay');
        this.sheetName = 'Total Departure Delay'
        break;

      case 'Total Arrival Delay':
        legs.filter((leg) => dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute')));

        data.push(['Date', 'Sector', 'Arrival Station', 'Delay Minutes']);
        const legIdsInLegListA = legs.map((leg) => leg.id);

        const pairLegDelayLogsA = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({
          isActive: true,
          legId: legIdsInLegListA,
          timeTypeId: 7
        }));
        pairLegDelayLogsA.sort((a, b) => {
          return b.id - a.id
        })
        for (const pairLegDelay of pairLegDelayLogsA) {
          if (legIdsInLegListA.includes(pairLegDelay.legId)) {
            const index = legIdsInLegListA.indexOf(pairLegDelay.legId);
            legIdsInLegListA.splice(index, 1);
            leginfo = legs.find((leg) => leg.id === pairLegDelay.legId);
            data.push([
              dayjs(leginfo.atd).format('DD-MM-YYYY'),
              leginfo.airlineDesignator + leginfo.flightNumber,
              leginfo.arrivalStation,
              pairLegDelay.totalDelayInMinutes
            ]);
            totalCountMinutes = totalCountMinutes + pairLegDelay.totalDelayInMinutes;

          }
        }
        data.push([]);
        data.push(['Total Delay (Min)', totalCountMinutes]);
        data.push(['Total Sectors', legs.length])
        this.createFileName('Punctuality', 'Total Arrival Delay');
        this.sheetName = 'Total Arrival Delay'
        break;
      default:
        break;
    }

    this.createExcelFile(data, this.fileName, this.sheetName);

  }

  async createPassengersExcelFile(legs) {

    let data = [];
    let periodSelectedInDays;
    let leginfo;
    const acRegCount = {};
    let totalLoadFactorPerCode: { [code: string]: number } = {};
    let totalPaxPerCode: { [code: string]: number } = {};
    let totalSeatPerCode: { [code: string]: number } = {};
    let connectingStations: { [iata: string]: { [seat: string]: any } } = {};
    let parentLegIdsToSearch = [];
    let legIdsToSearch = [];

    let paxPerCode: { [code: string]: number } = {};
    const passClassCodes = [];

    const acRegIdsArray = new Set<number>();
    const seatPerCode: { [code: string]: number } = {};

    for (const leg of legs) {
      if (!acRegCount[leg.acRegistrationId]) {
        acRegCount[leg.acRegistrationId] = 1;
      } else {
        acRegCount[leg.acRegistrationId]++;
      }
      acRegIdsArray.add(leg.acRegistrationId);
    }
    const seatConfigs = await firstValueFrom(this.seatConfigService.getSeatingConfigurationsByAcRegistrationId(Array.from(acRegIdsArray)));
    const passengerClasses = await firstValueFrom(this.generalSettingsService.getPassengerClasses());

    switch (this.formGroup.value.passengers.type) {
      case '1':
        data.push(['Date', 'Sector', 'Ac Registration']);
        for (const passClass of passengerClasses) {
          passClassCodes.push(passClass.code);
          data[0].push('PAX ' + passClass.code);
        }
        for (const passClass of passengerClasses) {
          data[0].push('Offered Seats ' + passClass.code);
        }
        for (const leg of legs) {
          data.push([
            dayjs(leg.atd).format('DD-MM-YYYY'),
            leg.airlineDesignator + leg.flightNumber,
            leg.acRegistration
          ]);
          const index = data.length - 1;
          if (leg.pax !== null) {
            paxPerCode = {}
            console.log('The leg with flight number ', leg.flightNumber, ' has pax ', leg.pax);
            extractSeatingConfigurations(leg.pax).forEach((item: string) => {
              const code = item.slice(0, 1);
              const amount = item.slice(1);

              for (const passClassCode of passClassCodes) {
                if (code === passClassCode) {
                  paxPerCode[code] = Number(amount) || 0;
                  totalPaxPerCode[code] = (totalPaxPerCode[code] || 0) + (Number(amount) || 0);
                }
              }
            });
          } else {
            paxPerCode = {};
          }
          const seatsForLeg = seatConfigs.filter((seating) => seating.acRegistrationId === leg.acRegistrationId)
          for (const seat of seatsForLeg) {
            seatPerCode[seat.code] = Number(seat.description) || 0;
            totalSeatPerCode[seat.code] = (totalSeatPerCode[seat.code] || 0) + (Number(seat.description) || 0);

          }
          console.log(seatsForLeg)
          for (const pax of passClassCodes) {
            data[index].push(paxPerCode[pax] || 0);
          }
          ;
          for (const pax of passClassCodes) {
            data[index].push(seatPerCode[pax] || 0);
          }
          ;
        }
        ;
        data.push([], []);
        console.log('the totals are :', totalPaxPerCode, totalSeatPerCode, acRegCount);

        for (const passClass of passengerClasses) {
          data.push(['Load Factor ' + passClass.code, (totalPaxPerCode[passClass.code] / totalSeatPerCode[passClass.code] || 0) * 100]);
        }

        this.createFileName('Passengers', 'Passenger Load Factor');
        this.sheetName = 'Passenger Load Factor'
        break;

      case '2':
        let totalDelayedPassengers = 0;
        data.push(['Date', 'Sector', 'Ac Registration', 'STD', 'ATD',]);
        for (const passClass of passengerClasses) {
          passClassCodes.push(passClass.code);
          data[0].push('PAX ' + passClass.code);
        }
        ;
        data[0].push('Total Delayed Passengers')
        for (const leg of legs) {
          if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
            let totalPax = 0;
            const index = data.length;
            if (leg.pax !== null) {
              extractSeatingConfigurations(leg.pax).forEach((item: string) => {
                const code = item.slice(0, 1);
                const amount = item.slice(1);
                totalPax += +amount;
                totalDelayedPassengers += +amount;

                for (const passClassCode of passClassCodes) {
                  if (code === passClassCode) {
                    paxPerCode[code] = Number(amount) || 0;
                    totalPaxPerCode[code] = (totalPaxPerCode[code] || 0) + (Number(amount) || 0);
                  }
                }
              });
            } else {
              paxPerCode = {};
            }
            data.push([
              dayjs(leg.atd).format('DD-MM-YYYY'),
              leg.airlineDesignator + leg.flightNumber,
              leg.acRegistration,
              dayjs(leg.std).format('HH:mm'),
              dayjs(leg.atd).format('HH:mm'),
            ]);
            for (const paxCode of passClassCodes) {
              data[index].push(paxPerCode[paxCode] || 0);
            }
            ;
            data[index].push(totalPax)
          }
        }
        ;
        data.push([], []);
        for (const passClass of passengerClasses) {
          data.push(['Total Delayed Passengers ' + passClass.code, (totalPaxPerCode[passClass.code] || 0)]);
        }
        data.push(['Total Delayed Passengers', totalDelayedPassengers]);

        this.createFileName('Passengers', '# Delayed Passengers');
        this.sheetName = '# Delayed Passengers'
        break;

      case '3':
        data.push(['Date', 'Sector', 'Ac Registration']);
        let totalDelayedPaxPerCode: { [code: string]: number } = {};
        let allPaxPerCode: { [code: string]: number } = {};


        for (const passClass of passengerClasses) {
          passClassCodes.push(passClass.code);
          data[0].push('PAX ' + passClass.code);
        }
        for (const passClass of passengerClasses) {
          data[0].push('Offered Seats ' + passClass.code);
        }
        for (const leg of legs) {
          if (dayjs(leg.ata).isAfter(dayjs(leg.sta).add(5, 'minute'))) {
            data.push([
              dayjs(leg.atd).format('DD-MM-YYYY'),
              leg.airlineDesignator + leg.flightNumber,
              leg.acRegistration
            ]);
            const index = data.length - 1;
            if (leg.pax !== null) {
              paxPerCode = {};
              extractSeatingConfigurations(leg.pax).forEach((item: string) => {
                const code = item.slice(0, 1);
                const amount = item.slice(1);

                for (const passClassCode of passClassCodes) {
                  if (code === passClassCode) {
                    paxPerCode[code] = Number(amount) || 0;
                    totalDelayedPaxPerCode[code] = (totalDelayedPaxPerCode[code] || 0) + (Number(amount) || 0);
                  }
                }
              });
            } else {
              paxPerCode = {};
            }
            const seatsForLeg = seatConfigs.filter((seating) => seating.acRegistrationId === leg.acRegistrationId)
            for (const seat of seatsForLeg) {
              seatPerCode[seat.code] = Number(seat.description) || 0;
              totalSeatPerCode[seat.code] = (totalSeatPerCode[seat.code] || 0) + (Number(seat.description) || 0);

            }
            for (const pax of passClassCodes) {
              data[index].push(paxPerCode[pax] || 0);
            }
            ;
            for (const pax of passClassCodes) {
              data[index].push(seatPerCode[pax] || 0);
            }
            ;
          }
          if (leg.pax !== null) {
            extractSeatingConfigurations(leg.pax).forEach((item: string) => {
              const code = item.slice(0, 1);
              const amount = item.slice(1);

              for (const passClassCode of passClassCodes) {
                if (code === passClassCode) {
                  allPaxPerCode[code] = (allPaxPerCode[code] || 0) + (Number(amount) || 0);
                }
              }
            });
          }
        }
        ;
        data.push([], []);

        for (const passClass of passengerClasses) {
          data.push(['Percentage of Delayed Passengers ' + passClass.code, (totalDelayedPaxPerCode[passClass.code] / allPaxPerCode[passClass.code] || 0) * 100]);
        }
        this.createFileName('Passengers', '% Delayed Passengers');
        this.sheetName = '% Delayed Passengers'
        break;

      case '4':
        data.push(['Departure Date', 'Departure Airport', 'Incoming Flight Number', 'Connecting Airport', 'Connecting Date', 'Outgoing Flight Number', 'Arrival Airport']);
        for (const passClass of passengerClasses) {
          passClassCodes.push(passClass.code);
          data[0].push('PAX ' + passClass.code);
        }
        ;
        data[0].push('Total Bags', 'Actual Connection Time')

        for (const passenger of this.connectingPassengers) {
          extractSeatingConfigurations(passenger.numberOfPassengers).forEach((item: string) => {
            const amount = item.slice(0, 1);
            const code = item.slice(1);

            for (const passClassCode of passClassCodes) {
              if (code === passClassCode) {
                if (!connectingStations[passenger.destinationAirport]) {
                  connectingStations[passenger.destinationAirport] = {}
                  paxPerCode[code] = (Number(amount) || 0);
                  connectingStations[passenger.destinationAirport][code] = paxPerCode[code]
                } else {
                  paxPerCode[code] = (paxPerCode[code] || 0) + (Number(amount) || 0);
                  connectingStations[passenger.destinationAirport][code] = paxPerCode[code]
                }
              }
            }
          });
          parentLegIdsToSearch.push(passenger.parentLegId);
          legIdsToSearch.push(passenger.legId);
        }

        const parentLegsCon = await firstValueFrom(this.legsService.getLegs({ id: parentLegIdsToSearch }));
        const normalLegsCon = await firstValueFrom(this.legsService.getLegs({ id: legIdsToSearch }));


        for (const passenger of this.connectingPassengers) {
          const legTod = normalLegsCon.find((nLeg) => nLeg.id === passenger.legId).tod
          const calculatedGroundTime = dayjs(legTod).diff(dayjs(parentLegsCon.find((pLeg) => pLeg.id === passenger.parentLegId).toa));
          const headers = data[0];
          const destinationAirportIndex = headers.indexOf('Arrival Airport');
          const index2 = data.length;
          let rowIndex = -1;
          console.log('Calculated Ground Time', calculatedGroundTime, 'Minimum', this.minimumPassengerConnectionTime* 60000, 'isSmaller :',calculatedGroundTime < this.minimumPassengerConnectionTime * 60000)

          if (calculatedGroundTime >= this.minimumPassengerConnectionTime * 60000) {

            // Find if the name exists in the dataArray
            for (let i = 1; i < data.length; i++) {
              if (data[i][destinationAirportIndex] === passenger.destinationAirport) {
                rowIndex = i;
                break;
              }
            }

            if (rowIndex !== -1) {
              // Update existing row
              console.log('Update the row')
              for (const [key, value] of Object.entries(connectingStations[passenger.destinationAirport])) {
                const passClassIndex = headers.indexOf(`PAX ${key}`);
                data[rowIndex][passClassIndex] = value;
              }
            } else {
              // Add new row

              data.push([
                dayjs(parentLegsCon.find((pLeg) => pLeg.id === passenger.parentLegId).tod).format('DD/MM/YYYY'),
                parentLegsCon.find((pLeg) => pLeg.id === passenger.parentLegId).departureStation,
                passenger.parentLegFlightNumber,
                passenger.connectingAirport,
                passenger.dateOfOperation,
                passenger.connectingFlightNumber,
                passenger.destinationAirport,
              ])
              for (const [key, value] of Object.entries(connectingStations[passenger.destinationAirport])) {
                const passClassIndex = headers.indexOf(`PAX ${key}`);

                data[index2][passClassIndex] = value;

              }
              const legTod = normalLegsCon.find((nLeg) => nLeg.id === passenger.legId).tod
              const diffInMilliseconds = dayjs(legTod).diff(dayjs(parentLegsCon.find((pLeg) => pLeg.id === passenger.parentLegId).toa));
              const duration = dayjs.duration(diffInMilliseconds);
              const formattedDuration = `${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}`;

              data[index2].push(passenger.numberOfBags.split('B')[0])
              data[index2].push(formattedDuration)
            }
          }
        }

        this.createFileName('Passengers', 'Passenger Connections');
        this.sheetName = 'Passenger Connections'
        break;
      case '5':
        data.push(['Departure Date', 'Departure Airport', 'Incoming Flight Number', 'Connecting Airport', 'Connecting Date', 'Outgoing Flight Number', 'Arrival Airport']);
        for (const passClass of passengerClasses) {
          passClassCodes.push(passClass.code);
          data[0].push('PAX ' + passClass.code);
        }
        ;
        data[0].push('Total Bags', 'Actual Connection Time')
        //const connectingStations: { [iata: string]: { [seat: string]: any } } = {};
        // const parentLegIdsToSearch = [];
        // const legIdsToSearch = [];
        for (const passenger of this.connectingPassengers) {
          extractSeatingConfigurations(passenger.numberOfPassengers).forEach((item: string) => {
            const amount = item.slice(0, 1);
            const code = item.slice(1);

            for (const passClassCode of passClassCodes) {
              if (code === passClassCode) {
                if (!connectingStations[passenger.destinationAirport]) {
                  connectingStations[passenger.destinationAirport] = {}
                  paxPerCode[code] = (Number(amount) || 0);
                  connectingStations[passenger.destinationAirport][code] = paxPerCode[code]
                } else {
                  paxPerCode[code] = (paxPerCode[code] || 0) + (Number(amount) || 0);
                  connectingStations[passenger.destinationAirport][code] = paxPerCode[code]
                }
              }
            }
          });
          parentLegIdsToSearch.push(passenger.parentLegId);
          legIdsToSearch.push(passenger.legId);
        }

        const parentLegs = await firstValueFrom(this.legsService.getLegs({ id: parentLegIdsToSearch }));
        const normalLegs = await firstValueFrom(this.legsService.getLegs({ id: legIdsToSearch }));


        for (const passenger of this.connectingPassengers) {
          const legTod = normalLegs.find((nLeg) => nLeg.id === passenger.legId).tod
          const calculatedGroundTime = dayjs(legTod).diff(dayjs(parentLegs.find((pLeg) => pLeg.id === passenger.parentLegId).toa));
          const headers = data[0];
          const destinationAirportIndex = headers.indexOf('Arrival Airport');
          const index2 = data.length;
          let rowIndex = -1;
          console.log('Calculated Ground Time', calculatedGroundTime, 'Minimum', this.minimumPassengerConnectionTime* 60000, 'isSmaller :',calculatedGroundTime < this.minimumPassengerConnectionTime * 60000)

          if (calculatedGroundTime < this.minimumPassengerConnectionTime * 60000) {

            // Find if the name exists in the dataArray
            for (let i = 1; i < data.length; i++) {
              if (data[i][destinationAirportIndex] === passenger.destinationAirport) {
                rowIndex = i;
                break;
              }
            }

            if (rowIndex !== -1) {
              // Update existing row
              console.log('Update the row')
              for (const [key, value] of Object.entries(connectingStations[passenger.destinationAirport])) {
                const passClassIndex = headers.indexOf(`PAX ${key}`);
                data[rowIndex][passClassIndex] = value;
              }
            } else {
              // Add new row

              data.push([
                dayjs(parentLegs.find((pLeg) => pLeg.id === passenger.parentLegId).tod).format('DD/MM/YYYY'),
                parentLegs.find((pLeg) => pLeg.id === passenger.parentLegId).departureStation,
                passenger.parentLegFlightNumber,
                passenger.connectingAirport,
                passenger.dateOfOperation,
                passenger.connectingFlightNumber,
                passenger.destinationAirport,
              ])
              for (const [key, value] of Object.entries(connectingStations[passenger.destinationAirport])) {
                const passClassIndex = headers.indexOf(`PAX ${key}`);

                data[index2][passClassIndex] = value;

              }
              const legTod = normalLegs.find((nLeg) => nLeg.id === passenger.legId).tod
              const diffInMilliseconds = dayjs(legTod).diff(dayjs(parentLegs.find((pLeg) => pLeg.id === passenger.parentLegId).toa));
              const duration = dayjs.duration(diffInMilliseconds);
              const formattedDuration = `${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}`;

              data[index2].push(passenger.numberOfBags.split('B')[0])
              data[index2].push(formattedDuration)
            }
          }
        }

        this.createFileName('Passengers', 'Passenger Misconnections');
        this.sheetName = 'Passenger Misconnections'
        break;

      default:
        break;
    }
    this.createExcelFile(data, this.fileName, this.sheetName);
  }

  async createUtilizationExcelFile(category, legs) {

    let data = [];
    let periodSelectedInDays;
    switch (category) {
      case 'AC Reg Utilization':

        data.push(['Date', 'Ac Registration', 'Actual Block Time (Hours)', 'Scheduled Block Time (Hours)']);

        periodSelectedInDays = Math.round((dayjs(this.filterDayTo).diff(dayjs(this.filterDayFrom))) / 86400000);
        if (!periodSelectedInDays) {
          periodSelectedInDays = 1
        }
        ;

        for (const leg of legs) {
          if (leg.atd !== null && leg.ata !== null) {
            data.push([dayjs(leg.atd).format('DD-MM-YYYY'), leg.acRegistration, (dayjs(leg.ata).diff(dayjs(leg.atd))) / 3600000, (dayjs(leg.sta).diff(dayjs(leg.std))) / 3600000]);

          }
          ;
        }
        ;
        data.push([], [], ['Info for Calculations'], ['Actual Block Time (per tail) hours/Number of days selected in the period']);
        this.createFileName('Operations', 'Ac Reg Utilization');
        this.sheetName = 'Ac Reg Utilization'
        break;

      case 'AC Type Utilization':

        data.push(['Date', 'Ac Type', 'Actual Block Time (Hours)',]);
        const blockTimePerAcType: { [acType: string]: number } = {};

        periodSelectedInDays = Math.round((dayjs(this.filterDayTo).diff(dayjs(this.filterDayFrom))) / 86400000);
        if (!periodSelectedInDays) {
          periodSelectedInDays = 1
        }

        for (const leg of legs) {
          if (leg.atd !== null && leg.ata !== null) {
            data.push([dayjs(leg.atd).format('DD-MM-YYYY'), leg.acType, (dayjs(leg.ata).diff(dayjs(leg.atd))) / 3600000,]);
            blockTimePerAcType[leg.acType] = ((dayjs(leg.ata).diff(dayjs(leg.atd))) / 3600000) / periodSelectedInDays + (blockTimePerAcType[leg.acType] || 0);
          }
          ;
        }
        ;
        data.push([]);
        for (const [key, value] of Object.entries(blockTimePerAcType)) {
          data.push(['Total A/C Type Utilization ' + key, value])
        }
        ;
        this.createFileName('Operations', 'AC Type Utilization');
        this.sheetName = 'AC Type Utilization'
        break;
      default:
        break;
    }
    this.createExcelFile(data, this.fileName, this.sheetName)
  }

  createExcelFile(data, fileName, sheetName) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = worksheet;
    XLSX.writeFile(workbook, fileName);
  }

  createFileName(category: string, graph: string) {
    this.fileName = category + ' - ' + graph + ' for ';
    this.fileName += (this.filterStation === null ? 'All Airports ' : this.filterStation);
    this.fileName += this.formGroup.get(category.toLowerCase()).get('date').value === null ? dayjs.utc().format('DD-MM-YYYY') : ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.from).format('DD-MM-YYYY') + ' ' + (this.formGroup.get(category.toLowerCase()).get('date').value.to ? ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.to).format('DD-MM-YYYY') : ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.from).format('DD-MM-YYYY'));
    this.fileName += '.xlsx';
  }

  createPNGFileName(category: string, graph: string) {
    this.fileName = category + ' - ' + graph + ' for ';
    this.fileName += (this.filterStation === null ? 'All Airports ' : this.filterStation);
    this.fileName += this.formGroup.get(category.toLowerCase()).get('date').value === null ? dayjs.utc().format('DD-MM-YYYY') : ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.from).format('DD-MM-YYYY') + ' ' + (this.formGroup.get(category.toLowerCase()).get('date').value.to ? ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.to).format('DD-MM-YYYY') : ngbDateToDayjs(this.formGroup.get(category.toLowerCase()).get('date').value?.from).format('DD-MM-YYYY'));
    this.fileName += '.png';
  }

  convertSecondsToMinutes(secondsPassed: number) {
    const minutes = Math.floor(secondsPassed / 60);
    const seconds = secondsPassed - minutes * 60;
    if (seconds >= 10) {
      return `${minutes}:${seconds}`
    } else {
      return `${minutes}:0${seconds}`
    }

  }
}
