import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ISimpleData } from '../../../../../shared/models/simpleData.model';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../services/toast.service';

@Component({
  selector: 'app-add-edit-passenger-classes-dialog',
  templateUrl: './add-edit-passenger-classes-dialog.component.html',
  styleUrls: ['./add-edit-passenger-classes-dialog.component.scss']
})
export class AddEditPassengerClassesDialogComponent implements OnInit {

  form?: UntypedFormGroup;
  isBusy = false;
  @Input() passengerClass?: ISimpleData;
  @Input() title?: string;
  constructor(private generalSettingsService: GeneralSettingsService, private activeModal: NgbActiveModal, private toastService: ToastService) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      code: new UntypedFormControl(this.passengerClass?.code || '', Validators.required),
      description: new UntypedFormControl(this.passengerClass?.description || '', Validators.required)
    });
  }

  closeModal(reason?: any) {
    this.activeModal.dismiss(reason);
  }

  onSaveClick() {
    const data = { ...this.form.value, id: this.passengerClass?.id };
    this.isBusy = true;
    this.generalSettingsService.savePassengerClass(data).subscribe((result) => {
      if(result?.id) {
        this.toastService.showSuccess("Passenger class has been saved");
        this.closeModal(true);
      }
      this.isBusy = false;
    });
  }

}
