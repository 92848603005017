import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe, NgOptimizedImage, TitleCasePipe} from '@angular/common';
import {LegComponent} from './components/leg/leg.component';
import {IconTextItemComponent} from './components/icon-text-item/icon-text-item.component';
import {VcardComponent} from './components/vcard/vcard.component';
import {VcardListComponent} from './components/list/vcard-list/vcard-list.component';
import {LegListComponent} from './components/list/leg-list/leg-list.component';
import {ChatAreaComponent} from './components/chat-area/chat-area.component';
import {ChatHeaderComponent} from './components/chat-area/chat-header/chat-header.component';
import {ChatBodyComponent} from './components/chat-area/chat-body/chat-body.component';
import {ChatFormComponent} from './components/chat-area/chat-form/chat-form.component';
import {ImagesComponent} from './components/chat-area/chat-header/images/images.component';
import {MessageItemComponent} from './components/chat-area/chat-body/chat-session/message-item/message-item.component';
import {ChatSessionComponent} from './components/chat-area/chat-body/chat-session/chat-session.component';
import {ExecutionFilterComponent} from './components/execution-filter/execution-filter.component';
import {LegDetailComponent} from './components/leg/leg-detail/leg-detail.component';
import {IconTextItemSmallComponent} from './components/icon-text-item-small/icon-text-item-small.component';
import {IndicatorDotComponent} from './components/indicator-dot/indicator-dot.component';
import {IndicatorTextComponent} from './components/indicator-text/indicator-text.component';
import {TaProcessComponent} from './components/leg/leg-detail/ta-process/ta-process.component';
import {EquipmentIconComponent} from './components/equipment-icon/equipment-icon.component';
import {
  NgbDatepickerModule,
  NgbModule,
  NgbProgressbarModule,
  NgbTimepickerModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {EditDeleteRowComponent} from './components/interactions/edit-delete-row/edit-delete-row.component';
import {AddRowComponent} from './components/interactions/add-row/add-row.component';
import {SettingsListComponent} from './components/list/settings-list/settings-list.component';
import {TimeFromMinutesPipe} from './pipes/time-from-minutes.pipe';
import {ArrayToStringPipe} from './pipes/array-to-string.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  FlightsViewFurtherInformationComponent
} from './views/flights/flights-view-further-information/flights-view-further-information.component';
import {ViewModalComponent} from './components/view-modal/view-modal.component';
import {
  FlightsChangeFlightDetailsComponent
} from './views/flights/flights-change-flight-details/flights-change-flight-details.component';
import {RestoreCancelRowComponent} from './components/interactions/restore-cancel-row/restore-cancel-row.component';
import {
  ChangeFlightsDepartingFlightInformationComponent
} from './forms/flights/change-flights-departing-flight-information/change-flights-departing-flight-information.component';
import {
  ChangeFlightsArrivingFlightInformationComponent
} from './forms/flights/change-flights-arriving-flight-information/change-flights-arriving-flight-information.component';
import {FlightsSearchFlightsComponent} from './forms/flights/flights-search-flights/flights-search-flights.component';
import {FlightsSelectFlightsComponent} from './forms/flights/flights-select-flights/flights-select-flights.component';
import {FlightsModifyFlightsComponent} from './forms/flights/flights-modify-flights/flights-modify-flights.component';
import {
  FlightsAddFlightsViewComponent
} from './views/flights/flights-add-flights-view/flights-add-flights-view.component';
import {
  FlightsModifyFlightsViewComponent
} from './views/flights/flights-modify-flights-view/flights-modify-flights-view.component';
import {
  FlightsCancelFlightsViewComponent
} from './views/flights/flights-cancel-flights-view/flights-cancel-flights-view.component';
import {StepMenuComponent} from './components/step-menu/step-menu.component';
import {FlightListItemComponent} from './components/flight-list-item/flight-list-item.component';
import {
  SettingsViewToleranceThresholdBarComponent
} from './views/settings/view-tolerance-threshold-bar/settings-view-tolerance-threshold-bar.component';
import {DeleteModalComponent} from './components/delete-modal/delete-modal.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {DeprecatedLegComponent} from './components/deprecated-leg/deprecated-leg.component';
import {
  DeprecatedLegDetailComponent
} from './components/deprecated-leg/deprecated-leg-detail/deprecated-leg-detail.component';
import {
  DeprecatedTaProcessComponent
} from './components/deprecated-leg/deprecated-ta-process/deprecated-ta-process.component';
import {PairDetailLegComponent} from './components/leg/leg-detail/pair-detail-leg/pair-detail-leg.component';
import {
  TooltipContentDelaysComponent
} from './components/leg/leg-detail/pair-detail-leg/tooltip-content-delays/tooltip-content-delays.component';
import {
  TooltipContentGateComponent
} from './components/leg/leg-detail/pair-detail-leg/tooltip-content-gate/tooltip-content-gate.component';
import {
  TooltipContentPaxComponent
} from './components/leg/leg-detail/pair-detail-leg/tooltip-content-pax/tooltip-content-pax.component';
import {SeatObjectToStringPipe} from './pipes/seat-object-to-string.pipe';
import {SanitizeLocationPipe} from './pipes/sanitize-location.pipe';
import {ValueOrDashPipe} from './pipes/valueOrDash.pipe';
import {UtcVariationVisualizerPipe} from './pipes/utc-variation-visualizer.pipe';
import {FullWeekdayFromThreeCharsPipe} from './pipes/full-weekday-from-three-chars.pipe';
import {FullMonthFromThreeCharsPipe} from './pipes/full-month-from-three-chars.pipe';
import {
  SimpleDataAddEditFormComponent
} from './forms/generic/simple-data-add-edit-form/simple-data-add-edit-form.component';
import {MinutesFromTimePipe} from './pipes/minutes-from-time.pipe';
import {IssueEtdRequestComponent} from './components/leg/leg-detail/issue-etd-request/issue-etd-request.component';
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component';
import {
  ChatHeaderSelectableComponent
} from './components/chat-area/chat-header-selectable/chat-header-selectable.component';
import {ToastsContainerComponent} from './components/toasts-container/toasts-container.component';
import {MessageModalComponent} from './components/message-modal/message-modal.component';
import {
  ChangeFlightDetailsDialogComponent
} from './components/flight-details/change-flight-details-dialog/change-flight-details-dialog.component';
import {AddEditMemosDialogComponent} from './components/add-edit-memos-dialog/add-edit-memos-dialog.component';
import {ViewMemosDialogComponent} from './components/view-memos-dialog/view-memos-dialog.component';
import {IssueNewInfoDialogComponent} from './components/issue-new-info-dialog/issue-new-info-dialog.component';
import {IssueEtdDialogComponent} from './components/issue-etd-dialog/issue-etd-dialog.component';
import {
  ConfirmResumeTurnaroundDialogComponent
} from './components/leg/leg-detail/confirm-resume-turnaround-dialog/confirm-resume-turnaround-dialog.component';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {environment} from '../../environments/environment';
import {AngularFireModule} from '@angular/fire/compat';
import {LegListSqlComponent} from './components/list/leg-list-sql/leg-list-sql.component';
import {DatePickerRangePopupComponent} from './components/date-picker-range-popup/date-picker-range-popup.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {UserPhotoComponent} from './components/user-photo/user-photo.component';
import {
  ChangeFlightsTurnaroundProcessesComponent
} from './forms/flights/change-flights-turnaround-processes/change-flights-turnaround-processes.component';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {CustomDatePipe} from './pipes/custom-date.pipe';
import {MemoDeleteFormComponent} from './forms/flights/memo-delete-form/memo-delete-form.component';
import {
  ReturnToRampAirborneDialogComponent
} from './components/return-to-ramp-airborne-dialog/return-to-ramp-airborne-dialog.component';
import {DiversionDialogComponent} from './components/diversion-dialog/diversion-dialog.component';
import {AdvancedChatContactComponent} from '../content/chat/advanced-chat-contact/advanced-chat-contact.component';
import {TimeInputComponent} from './components/time-input/time-input.component';
import {LegInformationWindowComponent} from './components/leg-information-window/leg-information-window.component';
import {TabsNavigationComponent} from './components/tabs-navigation/tabs-navigation.component';
import {
  SmallChatContactsContainerComponent
} from './components/small-chat-contacts-container/small-chat-contacts-container.component';
import {SettingsHeaderComponent} from './components/settings-header/settings-header.component';
import {SettingsCardComponent} from './components/settings-list-card/settings-card.component';
import {SettingsDataTableComponent} from './components/settings-data-table/settings-data-table.component';
import {SearchBarComponent} from './components/search-bar/search-bar.component';
import {PropNameDirective} from './directives/prop-name.directive';
import {TestAttrDirective} from './directives/test-attr.directive';
import {RouterModule} from '@angular/router';
import {
  MessageCreationPreviewComponent
} from './components/message-creation-preview/message-creation-preview.component';
import {UpperCaseInputDirective} from './directives/to-uppercase.directive';
import {NgbdSortableHeader} from './directives/sortable-table-header.directive';
import {PaginationButtonComponent} from './components/pagination-button/pagination-button.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {LegTestAttributePipe} from './pipes/leg-test-attribute.pipe';
import {PairTestAttributePipe} from './pipes/pair-test-attribute.pipe';
import {AlertTestAttributePipe} from './pipes/alert-test-attribute.pipe';
import {MessageTestTagPipe} from './pipes/message-test-tag.pipe';
import {TestTagPipe} from './pipes/test-tag.pipe';
import {AirlineDesignatorTestTagPipe} from './pipes/airline-designator-test-tag.pipe';
import {PassengerClassesTestTagPipe} from './pipes/passenger-classes-test-tag.pipe';
import {FlightServiceTypesTestTagPipe} from './pipes/flight-service-types-test-tag.pipe';
import {DelayCodesTestTagPipe} from './pipes/delay-codes-test-tag.pipe';
import {MeasurementUnitsTestTagPipe} from './pipes/measurement-units-test-tag.pipe';
import {UsersTestTagPipe} from './pipes/users-test-tag.pipe';
import {AcTypesTestTagPipe} from './pipes/ac-types-test-tag.pipe';
import {SeatingConfigurationsTestTagPipe} from './pipes/seating-configurations-test-tag.pipe';
import {AcRegistrationsTestTagPipe} from './pipes/ac-registrations-test-tag.pipe';
import {AirportsTestTagPipe} from './pipes/airports-test-tag.pipe';
import {SlaGseTestTagPipe} from './pipes/sla-gse-test-tag.pipe';
import {SeatingConfigurationsForAcTypesTestTagPipe} from './pipes/seating-configurations-for-ac-types-test-tag.pipe';
import {AirportTerminalsTestTagPipe} from './pipes/airport-terminals-test-tag.pipe';
import {AirportContactsTestTagPipe} from './pipes/airport-contacts-test-tag.pipe';
import {InterfacesTestTagPipe} from './pipes/interfaces-test-tag.pipe';
import {FlightNumberPipe} from './pipes/flight-number.pipe';
import {CameraFeedComponent} from './components/leg/leg-detail/camera-feed/camera-feed.component';
import {CameraModalComponent} from './components/camera-modal/camera-modal.component';
import {BoardingProcessComponent} from './components/leg/leg-detail/boarding-process/boarding-process.component';
import {timeFromSecondsPipe} from './pipes/time-from-seconds.pipe';
import {TimepickerFocusDirective} from './directives/timepicker-focus.directive';
import {AlertDescriptionPipe} from './pipes/alert-description.pipe';
import {AlertTypeDescriptionPipe} from "./pipes/alert-type-description.pipe";
import {SelectRampAgentComponent} from './components/select-ramp-agent/select-ramp-agent.component';
import {CustomAccordionComponent} from './components/custom-accordion/custom-accordion.component';
import {AccordionTemplateDirective} from './directives/accordion-template.directive';
import {AccessDeniedComponent} from './components/access-denied/access-denied.component';
import {RoleManagementTestTagPipe} from "./pipes/role-management-test-tag.pipe";
import {GanttChartComponent} from './components/gantt-chart/gantt-chart.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {CdkMenuModule} from "@angular/cdk/menu";
import {AlertListComponent} from "../content/alerts/alert-list/alert-list.component";
import {
  AlertListNewItemComponent
} from "../content/alerts/alert-list/alert-list-new-item/alert-list-new-item.component";
import {AlertListItemComponent} from "../content/flights/alerts/alert-list-item/alert-list-item.component";
import {GanttSectionTabComponent} from './components/gantt-section-tab/gantt-section-tab.component';


@NgModule({
  declarations: [
    LegComponent,
    IconTextItemComponent,
    IconTextItemSmallComponent,
    VcardComponent,
    VcardListComponent,
    LegListComponent,
    ChatAreaComponent,
    ChatHeaderComponent,
    ChatBodyComponent,
    ChatFormComponent,
    ImagesComponent,
    MessageItemComponent,
    ChatSessionComponent,
    ExecutionFilterComponent,
    LegDetailComponent,
    IndicatorDotComponent,
    IndicatorTextComponent,
    TaProcessComponent,
    EquipmentIconComponent,
    EditDeleteRowComponent,
    AddRowComponent,
    SettingsListComponent,
    TimeFromMinutesPipe,
    timeFromSecondsPipe,
    ArrayToStringPipe,
    SeatObjectToStringPipe,
    SanitizeLocationPipe,
    ValueOrDashPipe,
    FlightsViewFurtherInformationComponent,
    ViewModalComponent,
    FlightsChangeFlightDetailsComponent,
    RestoreCancelRowComponent,
    ChangeFlightsDepartingFlightInformationComponent,
    ChangeFlightsArrivingFlightInformationComponent,
    FlightsSearchFlightsComponent,
    FlightsSelectFlightsComponent,
    FlightsModifyFlightsComponent,
    FlightsAddFlightsViewComponent,
    FlightsModifyFlightsViewComponent,
    FlightsCancelFlightsViewComponent,
    StepMenuComponent,
    FlightListItemComponent,
    SettingsViewToleranceThresholdBarComponent,
    DeleteModalComponent,
    DeprecatedLegComponent,
    DeprecatedLegDetailComponent,
    DeprecatedTaProcessComponent,
    ChangeFlightsTurnaroundProcessesComponent,
    PairDetailLegComponent,
    TooltipContentDelaysComponent,
    TooltipContentGateComponent,
    TooltipContentPaxComponent,
    UtcVariationVisualizerPipe,
    FullWeekdayFromThreeCharsPipe,
    FullMonthFromThreeCharsPipe,
    SimpleDataAddEditFormComponent,
    MinutesFromTimePipe,
    IssueEtdRequestComponent,
    ConfirmationModalComponent,
    ChatHeaderSelectableComponent,
    ToastsContainerComponent,
    MessageModalComponent,
    ChangeFlightDetailsDialogComponent,
    AddEditMemosDialogComponent,
    ViewMemosDialogComponent,
    IssueNewInfoDialogComponent,
    IssueEtdDialogComponent,
    ConfirmResumeTurnaroundDialogComponent,
    LegListSqlComponent,
    DatePickerRangePopupComponent,
    ChatSessionComponent,
    ChatFormComponent,
    UserPhotoComponent,
    LoadingSpinnerComponent,
    CustomDatePipe,
    MemoDeleteFormComponent,
    ReturnToRampAirborneDialogComponent,
    DiversionDialogComponent,
    AdvancedChatContactComponent,
    TimeInputComponent,
    LegInformationWindowComponent,
    TabsNavigationComponent,
    SmallChatContactsContainerComponent,
    SettingsHeaderComponent,
    SettingsCardComponent,
    SettingsDataTableComponent,
    SearchBarComponent,
    PropNameDirective,
    TestAttrDirective,
    MessageCreationPreviewComponent,
    UpperCaseInputDirective,
    NgbdSortableHeader,
    PaginationButtonComponent,
    LegTestAttributePipe,
    PairTestAttributePipe,
    AlertTestAttributePipe,
    TestTagPipe,
    MessageTestTagPipe,
    AirlineDesignatorTestTagPipe,
    PassengerClassesTestTagPipe,
    FlightServiceTypesTestTagPipe,
    DelayCodesTestTagPipe,
    MeasurementUnitsTestTagPipe,
    UsersTestTagPipe,
    AcTypesTestTagPipe,
    SeatingConfigurationsTestTagPipe,
    AcRegistrationsTestTagPipe,
    AirportsTestTagPipe,
    SlaGseTestTagPipe,
    SeatingConfigurationsForAcTypesTestTagPipe,
    AirportTerminalsTestTagPipe,
    AirportContactsTestTagPipe,
    InterfacesTestTagPipe,
    FlightNumberPipe,
    CameraFeedComponent,
    CameraModalComponent,
    BoardingProcessComponent,
    AlertDescriptionPipe,
    TimepickerFocusDirective,
    AlertTypeDescriptionPipe,
    SelectRampAgentComponent,
    CustomAccordionComponent,
    AccordionTemplateDirective,
    AccessDeniedComponent,
    RoleManagementTestTagPipe,
    GanttChartComponent,
    AlertListComponent,
    AlertListNewItemComponent,
    AlertListItemComponent,
    GanttSectionTabComponent,
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbProgressbarModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    AngularFireModule,
    FontAwesomeModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    RouterModule,
    NgSelectModule,
    DragDropModule,
    CdkMenuModule,
    NgOptimizedImage,
  ],
  exports: [
    LegComponent,
    VcardComponent,
    VcardListComponent,
    LegListComponent,
    ChatAreaComponent,
    ChatSessionComponent,
    ExecutionFilterComponent,
    IndicatorDotComponent,
    IndicatorTextComponent,
    EditDeleteRowComponent,
    AddRowComponent,
    StepMenuComponent,
    SettingsListComponent,
    TimeFromMinutesPipe,
    timeFromSecondsPipe,
    ArrayToStringPipe,
    SeatObjectToStringPipe,
    SanitizeLocationPipe,
    ValueOrDashPipe,
    ToastsContainerComponent,
    UtcVariationVisualizerPipe,
    FullMonthFromThreeCharsPipe,
    FullWeekdayFromThreeCharsPipe,
    SettingsViewToleranceThresholdBarComponent,
    LegListSqlComponent,
    DatePickerRangePopupComponent,
    ChatFormComponent,
    UserPhotoComponent,
    LoadingSpinnerComponent,
    CustomDatePipe,
    ChangeFlightsDepartingFlightInformationComponent,
    AdvancedChatContactComponent,
    SmallChatContactsContainerComponent,
    SettingsHeaderComponent,
    SettingsCardComponent,
    SettingsDataTableComponent,
    SearchBarComponent,
    PropNameDirective,
    TestAttrDirective,
    MessageCreationPreviewComponent,
    UpperCaseInputDirective,
    PaginationButtonComponent,
    LegTestAttributePipe,
    PairTestAttributePipe,
    AlertTestAttributePipe,
    SeatingConfigurationsForAcTypesTestTagPipe,
    FlightNumberPipe,
    AlertDescriptionPipe,
    TimepickerFocusDirective,
    AlertTypeDescriptionPipe,
    CustomAccordionComponent,
    AccordionTemplateDirective,
    AccessDeniedComponent,
    GanttChartComponent,
    AlertListComponent,
    AlertListNewItemComponent,
    AlertListItemComponent,
    GanttSectionTabComponent,
    TimeInputComponent,
  ],
  providers: [
    DecimalPipe,
    TitleCasePipe,
    MinutesFromTimePipe,
    MessageTestTagPipe,
    AirlineDesignatorTestTagPipe,
    PassengerClassesTestTagPipe,
    FlightServiceTypesTestTagPipe,
    DelayCodesTestTagPipe,
    MeasurementUnitsTestTagPipe,
    UsersTestTagPipe,
    AcTypesTestTagPipe,
    SeatingConfigurationsTestTagPipe,
    AcRegistrationsTestTagPipe,
    AirportsTestTagPipe,
    SlaGseTestTagPipe,
    SeatingConfigurationsForAcTypesTestTagPipe,
    AirportTerminalsTestTagPipe,
    AirportContactsTestTagPipe,
    InterfacesTestTagPipe,
    AlertTypeDescriptionPipe
  ]
})
export class SharedModule {
}
