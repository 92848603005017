import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IAirport} from '../../../models/airport.model';
export class SettingsAirportMainFormBuilder {

  static constructForm(
    fb: UntypedFormBuilder): UntypedFormGroup {

    return fb.group({
      title: ['', Validators.required],
      iata: ['', Validators.required],
      icao: ['', Validators.required],
      country: ['', Validators.required],
      icaoCountry: [''],
      latitude: [''],
      longitude: [''],
      slotCoordinationLevel: [''],
      fireCategory: [''],
    });
  }

  static patchForm(
    fb: UntypedFormBuilder,
    form: UntypedFormGroup,
    airport: IAirport): UntypedFormGroup {

    if (!fb || !form || !airport) {
      return new UntypedFormGroup({});
    }
  }
}
