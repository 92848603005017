import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {IDelayCode} from "../../../../../shared/models/delay-code.model";
import {ISimpleData} from "../../../../../shared/models/simpleData.model";
import {TimezoneService} from "../../../../../services/timezone.service";
import {takeUntil} from "rxjs/operators";
import {forkJoin, Subject} from "rxjs";
import {IGanttChartData} from "../../../../../shared/models/gantt-chart-data.model";
import {PairsService} from "../../../../../services/pairs.service";
import {IPairOverview} from "../../../../../shared/models/pair-overview.model";

@Component({
  selector: 'app-gantt-pairs-tab',
  templateUrl: './gantt-pairs-tab.component.html',
  styleUrls: ['./gantt-pairs-tab.component.scss']
})
export class GanttPairsTabComponent implements OnDestroy, OnChanges {

  @Input() data: IGanttChartData;
  @Input() delayCodes: IDelayCode[];
  @Input() passengerClasses?: ISimpleData[];
  @Input() selectedSectorIds: number[];
  @Input() selectedPairId?: number;
  @Output() refetchFlightsRequired: EventEmitter<void> = new EventEmitter();
  pairList: IPairOverview[];
  timezone: string;
  fetching = false;
  activeIndex: string;

  unsubscribe$ = new Subject<void>();


  constructor(private timezoneService: TimezoneService, private pairService: PairsService) {
    this.timezoneService.getTimezone().pipe(takeUntil(this.unsubscribe$)).subscribe(timezone => {
      this.timezone = timezone;
    });
    this.refetchFlightsRequired.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.refresh());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  refresh() {
    this.pairList = [];
    if (!this.data) {
      return;
    }
    this.fetching = true;
    if (Object.keys(this.selectedSectorIds ?? {}).length) {
      this.activeIndex = undefined;
      forkJoin([this.pairService.searchPairsOverview({
        isActive: true,
        arrivalLegId: this.selectedSectorIds
      }), this.pairService.searchPairsOverview({
        isActive: true,
        departureLegId: this.selectedSectorIds
      })]).subscribe((results) => {
        const pairsAdded: Record<number, boolean> = {};
        for (const pair of results[0]) {
          if (!pairsAdded[pair.id]) {
            pairsAdded[pair.id] = true;
            this.pairList.push(pair);
          }
        }
        for (const pair of results[1]) {
          if (!pairsAdded[pair.id]) {
            pairsAdded[pair.id] = true;
            this.pairList.push(pair);
          }
        }
        this.pairList = [...this.pairList];
        this.fetching = false;
      });
      return;
    }
    if (this.selectedPairId) {
      this.pairService.searchPairsOverview({id: this.selectedPairId}).subscribe((pair) => {
        this.pairList = pair;
        this.activeIndex = this.selectedPairId.toString();
        this.fetching = false;
      });

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.selectedSectorIds || changes.selectedPairId) {
      this.refresh();
    }
  }
}
