import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IUser} from '../../../models/user.model';
import {IFlightPair} from '../../../models/flight-pair.model';

@Component({
  selector: 'app-chat-header-selectable',
  templateUrl: './chat-header-selectable.component.html',
  styleUrls: ['./chat-header-selectable.component.scss']
})
export class ChatHeaderSelectableComponent {

  @Input() user: IUser;
  @Input() pair: IFlightPair;
  @Output() closeChatAction = new EventEmitter();

  @Input() canClose = true;

  constructor() {
  }

  close() {
    this.closeChatAction.emit();
  }
}
