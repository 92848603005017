<div class="main-container dark-blue p-3 position-relative">
  <app-access-denied *ngIf="!asmAccess"></app-access-denied>
  <div class="flex flex-column">
    <div class="flex justify-content-between mt-3 mb-3">
      <div class="flex">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="for-avitium" [(ngModel)]="forAvitium"
                 data-test="exit-screen-for-avitium-check">
          <label class="form-check-label" for="for-avitium">For Avitium</label>
        </div>
        <div class="form-check form-switch ms-3">
          <input class="form-check-input" type="checkbox" role="switch" id="for-other" [(ngModel)]="forOthers" disabled
                 data-test="exit-screen-for-others-check">
          <label class="form-check-label" for="for-other">For Other Systems</label>
        </div>
      </div>
      <span class="me-3">* All times in UTC</span>
    </div>

    <div class="flex gap-3 skip-invalid-border" [formGroup]="form" *ngIf="form">
      <app-settings-card class="flex-grow-1">
        <div class="flex dark-blue justify-content-between">
          <div class="flex-basis-30">
            <label for="message-type">Action - Message Type</label>
            <select class="form-control form-select" id="message-type" formControlName="messageType"
                    data-test="exit-screen-message-type-select">
              <option value="CNL" data-test="message-type-cnl-option">CNL - Cancel Flight</option>
              <option value="EQT" data-test="message-type-eqt-option">EQT - Equipment Change</option>
              <option value="NEW" data-test="message-type-new-option">NEW - New Flight</option>
              <option value="CON" data-test="message-type-con-option">CON - Configuration Change</option>
              <option value="RRT" data-test="message-type-rrt-option">RRT - Reroute Flight</option>
              <option value="TIM" data-test="message-type-tim-option">TIM - Reschedule Flight</option>
            </select>
          </div>

          <div class="flex-basis-30">
            <label for="dateOfDeparture">Departure Date</label>
            <div class="input-group">
              <input class="form-control" id="dateOfDeparture"
                     name="dp" formControlName="dateOfDeparture" ngbDatepicker #d="ngbDatepicker"
                     placeholder="dd/mm/yyyy" data-test="exit-screen-departure-date-input">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar h-100"
                        (click)="d.toggle()" type="button"
                        data-test="exit-screen-departure-date-calendar-button"></button>
              </div>
            </div>
          </div>

          <div class="flex-basis-30">
            <label for="airline-designator">Airline Designator</label>
            <select class="form-control form-select" id="airline-designator" formControlName="airlineDesignator"
                    data-test="exit-screen-airline-designator-select">
              <option *ngFor="let airDesignator of airlineDesignator" [value]="airDesignator.code"
                      [attr.data-test]="'exit-airline-des-' + airDesignator?.code">{{ airDesignator.code }}
              </option>

            </select>
          </div>
        </div>

        <div class="flex dark-blue justify-content-between">
          <div class="flex-basis-30">
            <label for="flight-number"
                   ngbTooltip="If you want to add more than one flight numbers, separate them with ' / '">Flight
              Number(s)
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" class="bi bi-info-circle-fill"
                   fill="#4285F6" viewBox="0 0 16 16">
                <path
                  d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
              </svg>
            </label>
            <input type="text" class="form-control" id="flight-number" placeholder="" formControlName="flightNumber"
                   data-test="exit-screen-flight-number-input">
          </div>


          <div class="flex-basis-30">
            <label for="departure-airport">Departure Airport</label>
            <ng-select id="departure-airport" formControlName="depAirport"
                       data-test="exit-screen-departure-airport-select"
                       [inputAttrs]="{ 'data-test': 'departure-airport-input' }">
              <ng-option *ngFor="let airport of airports" [value]="airport.iata">{{ airport.iata }}</ng-option>
              <ng-template ng-option-tmp let-item="item">
                <span class="ng-option-label" [attr.data-test]="'departure-airport-' + item">{{ item }}</span>
              </ng-template>
            </ng-select>
          </div>
          <div class="flex-basis-30">
            <label for="arriving-airport">Arriving Airport</label>
            <ng-select id="arriving-airport" formControlName="arrAirport" data-test="exit-screen-arrival-airport-select"
                       [inputAttrs]="{ 'data-test': 'arriving-airport-input' }">
              <ng-option *ngFor="let airport of airports" [value]="airport.iata">{{ airport.iata }}</ng-option>
              <ng-template ng-option-tmp let-item="item">
                <span class="ng-option-label" [attr.data-test]="'arrival-airport-' + item">{{ item }}</span>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="flex dark-blue justify-content-between">

          <div class="flex-basis-30">
            <label>A/C Type</label>
            <select class="form-control form-select" id="ac-type" formControlName="acType"
                    data-test="exit-screen-ac-type-select">
              <option [ngValue]="null">-</option>
              <option *ngFor="let acType of availableAcTypes" [ngValue]="acType"
                      [attr.data-test]="'exit-ac-type-' + acType.iata">{{ acType.description || acType.alias }}
              </option>
            </select>
          </div>

          <div class="flex-basis-30">
            <label for="ac-registration">A/C Registration</label>
            <ng-select id="ac-registration" formControlName="acRegistration"
                       data-test="exit-screen-ac-registration-select"
                       [inputAttrs]="{ 'data-test': 'ac-registration-input' }">
              <ng-option *ngFor="let acRegistration of availableAcRegistrations"
                         [value]="acRegistration">{{ acRegistration.registration }}
              </ng-option>
              <ng-template ng-option-tmp let-item="item">
                <span class="ng-option-label"
                      [attr.data-test]="'ac-registration-' + item.registration">{{ item.registration }}</span>
              </ng-template>
            </ng-select>
          </div>

          <div class="flex-basis-30">
            <label for="ac-config">A/C Configuration</label>
            <input toUppercase type="text" class="form-control" id="ac-config" placeholder="" formControlName="acConfig"
                   data-test="exit-screen-ac-config-input">
          </div>
        </div>

        <div class="flex dark-blue justify-content-between">
          <div class="flex-basis-30">
            <label>STD</label>
            <ngb-timepicker appTimepickerFocus formControlName="std" [spinners]="false"
                            data-test="exit-screen-std-picker"></ngb-timepicker>
          </div>
          <div class="flex-basis-30">
            <label>STA</label>
            <ngb-timepicker appTimepickerFocus formControlName="sta" [spinners]="false"
                            data-test="exit-screen-sta-picker"></ngb-timepicker>
          </div>

          <div class="flex-basis-30">
            <label for="flight-service">Flight Service Type</label>
            <select class="form-control form-select" id="flight-service" formControlName="flightServiceType"
                    data-test="exit-screen-flight-service-type-select">
              <option *ngFor="let flightSType of flightServiceTypes" [value]="flightSType.code"
                      [attr.data-test]="'flight-type-' + flightSType.code">{{ flightSType.code }}
                - {{ flightSType.description }}
              </option>
            </select>
          </div>

        </div>

        <div class="dark-blue">
          <label for="si">SI</label>
          <input toUppercase type="text" class="form-control" id="si" placeholder="" formControlName="si"
                 data-test="exit-screen-si-input">
        </div>
      </app-settings-card>
      <div class="flex flex-column right-side gap-3">
        <app-message-creation-preview class=" mt-3" [text]="this.fileContent"></app-message-creation-preview>
        <div class="flex justify-content-between">
          <button class="btn btn-outline-secondary clear-btn" (click)="clearForm()"
                  data-test="exit-screen-clear-button">Clear
          </button>
          <button class="btn button-blue" [disabled]="!forAvitium || form.invalid || isBusy" (click)="submit()"
                  data-test="exit-screen-submit-button">
            <ng-container *ngIf="!isBusy; else spinner">
              Submit
            </ng-container>
            <ng-template #spinner>
              <app-loading-spinner></app-loading-spinner>
            </ng-template>

          </button>
          <button class="btn button-blue" (click)="downloadFile()" [disabled]="form.invalid"
                  data-test="exit-screen-download-button">Download
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
