import {Component} from '@angular/core';
import {AuthService} from './services/auth.service';
import {IUser} from './shared/models/user.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as weekday from 'dayjs/plugin/weekday';
import * as arraySupport from 'dayjs/plugin/arraySupport';
import * as customFormat from 'dayjs/plugin/customParseFormat';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone'
import * as duration from 'dayjs/plugin/duration';
import * as minMax from 'dayjs/plugin/minMax';
import {ourTimezone} from './shared/utils/dayjs-timezone.plugin';
import {PermissionService} from "./services/permission.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'AvitiumTurnaround';
  user: IUser;
  unsubscribe$ = new Subject();

  constructor(private authService: AuthService, private permissionService: PermissionService) {

  }

  ngOnInit(): void {
    dayjs.extend(utc);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isBetween);
    dayjs.extend(weekday);
    dayjs.extend(arraySupport);
    dayjs.extend(customFormat);
    dayjs.extend(timezone);
    dayjs.extend(duration);
    dayjs.extend(ourTimezone);
    dayjs.extend(minMax);
    this.authService.userSubject.asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: IUser) => this.user = user);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
