import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { LegsService } from '../../../services/legs.service';
import { ToastService } from '../../../services/toast.service';
import { FlightsDiversionFormBuilder } from '../../forms/formBuilders/flights/flights-diversion-form-builder';
import { ILegsModel } from '../../models/legs.model';
import * as dayjs from 'dayjs';
import { dayjsToNgbDate, ngbDateToDayjs } from '../../utils/utils';
import { IGenericContainerObject } from '../../models/genericContainerObject.model';
import { ISimpleData } from '../../models/simpleData.model';
import { AirportsService } from '../../../services/airports.service';
import { firstValueFrom, Subject } from 'rxjs';


@Component({
  selector: 'app-diversion-dialog',
  templateUrl: './diversion-dialog.component.html',
  styleUrls: ['./diversion-dialog.component.scss']
})
export class DiversionDialogComponent implements OnInit {
  title?: string;
  leg?: ILegsModel;
  form?: UntypedFormGroup;
  isBusy = false;
  dateFormat = 'dd/mm/yyyy';
  airports?: any;
  unsubscribe = new Subject();


  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private activeModal: NgbActiveModal,
    private legService: LegsService,
    private airportsService: AirportsService,
    private generalSettingsService: GeneralSettingsService,
    ) { }

  ngOnInit(): void {
    this.dateFormat = this.generalSettingsService.generalSettings.value.dateFormat;
    this.fetchAirports();
    this.generateForm();
    this.patchForm();
    this.form?.markAsDirty();
  }

  closeModal(reason?: any) {
    this.activeModal.dismiss(reason);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  generateForm() {
    this.form = FlightsDiversionFormBuilder.constructForm(this.fb, this.dateFormat);
  }

  patchForm() {
    let predefinedEta = null;
    if (this.leg && this.leg.tod && dayjs(this.leg.tod).isValid()) {
      predefinedEta = dayjs(this.leg.tod).utc();
    }
    this.form?.patchValue({
      etaDate: predefinedEta ? dayjsToNgbDate(predefinedEta) : null,
      std: this.leg && this.leg.std ? this.leg.std : null
    });
  }

  fetchAirports () {
    this.airports = {};
    this.airportsService.fetchAirports().subscribe((result) => {
      if(result?.length) {
        for (const airport of result) {
          this.airports[airport.id] = airport;
        }
      }
    });
    console.log('airports',this.airports)
  }

  async onSaveClick() {
    //IMPORTANT : The flight MUST have atd in order to assign the new eta or ata
    if (!this.form || this.form.invalid || !this.leg) {
      return;
    }
    this.isBusy = true;
    const formValue = this.form.value;
    let eta;
    if (formValue.etaTime) {
      const etaTimeSplit = formValue.etaTime.split(':');
      eta = ngbDateToDayjs(formValue.etaDate)?.utc(true).hour(etaTimeSplit[0]).minute(etaTimeSplit[1]).toDate() ?? null;
    }

    const diversionLeg = {
      ...this.leg,
      arrivalStation: this.airports[formValue.airport].iata,
      arrivalStationId: formValue.airport,
      arrivalGate: null,
      arrivalAcStand: null,
      std: this.leg.std,
      sta: eta,
      tod: this.leg.tod,
      toa: eta,
      eta: eta,
      etd: this.leg.etd,
      ata: null,
      atd: this.leg.atd,
      lastChangedAt: new Date(),
    };
    console.log('DIversion Leg', diversionLeg);
    console.log('DIversion Airport', this.airports[formValue.airport]);
    this.leg.lastChangedAt = new Date();
    await firstValueFrom(this.legService.divertFlight({leg: diversionLeg , si: formValue.si, oldLeg: this.leg}));

    this.toastService.showSuccess('Leg has been saved');

    this.closeModal('repair');
    this.isBusy = false;
  }

}
