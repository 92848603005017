import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[accordionTemplate]'
})
export class AccordionTemplateDirective {
  @Input() accordionTemplate: string;

  constructor(public template: TemplateRef<any>) {
  }

}
