import {Pipe, PipeTransform} from '@angular/core';
import {IAlertTypesModel} from "../models/alert-types.model";

@Pipe({
  name: 'alertTypeDescription'
})
export class AlertTypeDescriptionPipe implements PipeTransform {
  constructor() {
  }

  transform(value: IAlertTypesModel, ...args: unknown[]): unknown {
    if (!value) {
      return '';
    }
    switch (value.id) {
      case 18:
      case 19:
      case 20:
      case 21:
        return 'Time Difference between MVT and ACARS of same event times';
      default:
        return value.description;
    }
  }

}
