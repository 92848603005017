<div class="modal-header">
  <span class="modal-title">{{ title ?? 'Delete ' + term }}</span>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('Cross click')">
    <!--<span aria-hidden="true" class="colorWhite">&times;</span> -->
  </button>
</div>
<div class="modal-body dark-blue">

  <ng-container [ngSwitch]="formType">
    <ng-container *ngSwitchCase="formTypes.DELETE_MEMO_FORM">
      <app-memo-delete-form [data]="data" (deleteClicked)="nonClosingDeleteAction($event)"
                            [outerDeleteClicked]="eventsSubject.asObservable()"
                            (statusChanged)="formStatusChanged($event)"></app-memo-delete-form>
    </ng-container>

    <ng-container *ngSwitchDefault>
      You are about to delete this {{ term }}. Are you sure?
    </ng-container>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" ngbAutofocus
          (click)="closeModal('Close click')">No, cancel
  </button>
  <button type="button" class="btn btn-outline-danger settings-modal-button" (click)="delete()">Yes, delete it!</button>
</div>
