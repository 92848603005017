<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" *ngIf="form">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <div class="container">
            <div class="row">
              <div class="col-12 form-field-header colorBlack mb-3">
                Departure Slot Tolerance
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header">
                Early
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <div class="mb-3">
                    <ngb-timepicker appTimepickerFocus formControlName="departureEarlyInMinutes" [spinners]="false" name="settings-departing-slot-tolerance-early"></ngb-timepicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header">
                Late
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <div class="mb-3">
                    <ngb-timepicker appTimepickerFocus formControlName="departureLateInMinutes" [spinners]="false" name="settings-departing-slot-tolerance-late"></ngb-timepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 border-start">
          <div class="container">
            <div class="row">
              <div class="col-12 form-field-header colorBlack mb-3">
                Arrival Slot Tolerance
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header">
                Early
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <div class="mb-3">
                    <ngb-timepicker appTimepickerFocus formControlName="arrivalEarlyInMinutes" [spinners]="false" name="settings-arriving-slot-tolerance-early"></ngb-timepicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row h-54">
              <div class="col-6 form-field-header">
                Late
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <div class="mb-3">
                    <ngb-timepicker appTimepickerFocus formControlName="arrivalLateInMinutes" [spinners]="false" name="settings-arriving-slot-tolerance-late"></ngb-timepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="form?.disabled || form?.invalid || isBusy">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
