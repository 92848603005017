<div class="modal-header">
    <span class="modal-title">{{title}}</span>
    <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="rtr-close-button">
    </button>
  </div>
<div class="modal-body">
<form [formGroup]="form">
    <div class="container">
      <div class="row">
        <div class="col-7">
          <div class="container">
            <div class="mb-3 flightInfo">
             <b>{{leg?.departureStation}} {{leg?.std | date:'HH:mm':'+00:00'}}z - {{leg?.arrivalStation}} {{leg?.sta | date:'HH:mm':'+00:00'}}z ({{leg?.acType}})</b>
            </div>
            <div class="row">
              <div class="col-5 form-field-header">
                ETA ({{leg?.departureStation}})
              </div>
              <div class="col-4">
                <div class="mb-3">
                  <div class="input-group">
                    <input class="form-control" [placeholder]="dateFormat"
                           name="dp" formControlName="etaDate" ngbDatepicker #d="ngbDatepicker" data-test="rtr-eta-date-input">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar" (click)="d.toggle()" type="button" data-test="rtr-eta-calendar-button"></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3" style="display:flex;" [ngClass]="">
                <ngb-timepicker appTimepickerFocus formControlName="etaTime" [spinners]="false" data-test="rtr-eta-time-picker"></ngb-timepicker>
              </div>
            </div>
            <div class="row ">
              <div class="container">
                <div class="mb-3 flightInfo">
                  <b>OR</b>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-5 form-field-header">
                  ATA ({{leg?.departureStation}})
                </div>
                <div class="col-4">
                  <div class="mb-3">
                    <div class="input-group">
                      <input class="form-control" [placeholder]="dateFormat"
                             name="dp" formControlName="ataDate" ngbDatepicker #d="ngbDatepicker" data-test="rtr-ata-date-input">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar" (click)="d.toggle()" type="button" data-test="rtr-ata-calendar-button"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3" style="display:flex;" [ngClass]="">
                  <ngb-timepicker appTimepickerFocus name="newAtaTime" formControlName="ataTime" [spinners]="false" data-test="rtr-ata-time-picker"></ngb-timepicker>
                </div>
            </div>
          </div>
        </div>
        <div class="col-5">
            <div class="container flex " style="margin-top: 43px">
                    <div class="form-field-header col-3">SI</div>
                    <div class="col-9">
                    <textarea  class="form-control" name="si" formControlName="si" data-test="rtr-si-textarea"></textarea>
                    </div>
            </div>
        </div>
      </div>
    </div>
  </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary settings-modal-button" [disabled]="form?.disabled || isBusy" (click)="closeModal()" data-test="rtr-cancel-button">Cancel
    </button>
    <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
            [disabled]="form?.disabled || form?.invalid || isBusy" data-test="rtr-save-button">
      {{ isBusy ? '' : 'Save' }}
      <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
    </button>
  </div>
