import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { ngbDateToFormat } from '../../../utils/utils';


export class FlightsReturnToRampFormBuilder {

  static constructForm(fb: UntypedFormBuilder, dateFormat: string): UntypedFormGroup {

    return fb.group({
      etaDate: [''],
      etaTime: [''],
      ataDate: [''],
      ataTime: [''],
      si: [''],
      std: ['']
    }, 
    //{validator: this.etdMustNotBeSameOrBeforeThanStdValidation(dateFormat)}
    );
  }

  static etdMustNotBeSameOrBeforeThanStdValidation(dateFormat: string): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors | null => {
      if (!group || !group.value || !group.value.etdDate || !group.value.etdTime || !group.value.std) {
        return {invalidEtd: true};
      }

      const val = group.value;

      const stdMoment = dayjs.utc(val.std);
      const etdMoment = dayjs.utc(ngbDateToFormat(val.etdDate, dateFormat) + '-' + val.etdTime, dateFormat + '-HH:mm');

      return stdMoment.isValid() && etdMoment.isValid() ?
        null : {invalidEtd: true};
    };
  }
}
