<div class="modal-header">
  <span class="modal-title">{{title}}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()" data-test="edit-airport-curfew-close-x-button">
  </button>
</div>
<div class="modal-body dark-blue">
<!--  <form [formGroup]="formGroup">-->
<!--    <div class="container">-->
<!--      <div class="row">-->
<!--        <div class="col-12">-->
<!--          <div class="container">-->
<!--            <div class="row form-field-header h-54">-->
<!--              <div class="col-12 flex">-->
<!--                <div class="mb-3 form-check me-3">-->
<!--                  <input class="form-check-input" type="radio" id="timezoneZulu" formControlName="isUtc" [value]="true" data-test="edit-airport-curfew-is-utc-true-check">-->
<!--                  <label class="form-check-label" for="timezoneZulu">UTC</label>-->
<!--                </div>-->
<!--                <div class="mb-3 form-check">-->
<!--                  <input class="form-check-input" type="radio" id="timezoneLocal" formControlName="isUtc" [value]="false" data-test="edit-airport-curfew-is-utc-false-check">-->
<!--                  <label class="form-check-label" for="timezoneLocal">Local</label>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <div class="container dateRangeContainer">-->
<!--            <div class="row">-->
<!--              <div class="col-6 form-field-header">-->
<!--                Date range-->
<!--              </div>-->
<!--              <div class="col-6 mb-2">-->
<!--                <div>-->
<!--                  <div class="flex-row-space-between-align-center">-->
<!--                    <span>{{getDateString(this.formGroup?.get('validFrom').value)}} - {{getDateString(this.formGroup?.get('validTo').value)}}</span>-->
<!--                    <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar"-->
<!--                            [ngClass]="{'active': drp}"-->
<!--                            (click)="drp = !drp" data-test="edit-airport-curfew-date-range-calendar-button"></button>-->
<!--                  </div>-->

<!--                  <ngb-datepicker *ngIf="drp" (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t"-->
<!--                                  outsideDays="hidden" data-test="edit-airport-curfew-date-range-date-picker">-->
<!--                  </ngb-datepicker>-->


<!--                  <ng-template #t let-date let-focused="focused">-->
<!--                    <span class="custom-day"-->
<!--                          [class.focused]="focused"-->
<!--                          [class.range]="isRange(date)"-->
<!--                          [class.faded]="isHovered(date) || isInside(date)"-->
<!--                          (mouseenter)="hoveredDate = date"-->
<!--                          (mouseleave)="hoveredDate = null">-->
<!--                      {{ date.day }}-->
<!--                    </span>-->
<!--                  </ng-template>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="container h-54">-->
<!--            <div class="row">-->
<!--              <div class="col-6 form-field-header">-->
<!--                Weekdays-->
<!--              </div>-->
<!--              <div class="col-6 flex weekdays-container mb-3 pb-3">-->
<!--                <button class="btn daySelector" [ngClass]="{'button-blue': mon, 'btn-outline-secondary' : !mon}"-->
<!--                        (click)="toggleWeekday('mon')" data-test="edit-airport-curfew-mon-check">Mon-->
<!--                </button>-->
<!--                <button class="btn daySelector" [ngClass]="{'button-blue': tue, 'btn-outline-secondary' : !tue}"-->
<!--                        (click)="toggleWeekday('tue')" data-test="edit-airport-curfew-tue-check">Tue-->
<!--                </button>-->
<!--                <button class="btn daySelector" [ngClass]="{'button-blue': wed, 'btn-outline-secondary' : !wed}"-->
<!--                        (click)="toggleWeekday('wed')" data-test="edit-airport-curfew-wed-check">Wed-->
<!--                </button>-->
<!--                <button class="btn daySelector" [ngClass]="{'button-blue': thu, 'btn-outline-secondary' : !thu}"-->
<!--                        (click)="toggleWeekday('thu')" data-test="edit-airport-curfew-thu-check">Thu-->
<!--                </button>-->
<!--                <button class="btn daySelector" [ngClass]="{'button-blue': fri, 'btn-outline-secondary' : !fri}"-->
<!--                        (click)="toggleWeekday('fri')" data-test="edit-airport-curfew-fri-check">Fri-->
<!--                </button>-->
<!--                <button class="btn daySelector" [ngClass]="{'button-blue': sat, 'btn-outline-secondary' : !sat}"-->
<!--                        (click)="toggleWeekday('sat')" data-test="edit-airport-curfew-sat-check">Sat-->
<!--                </button>-->
<!--                <button class="btn daySelector" [ngClass]="{'button-blue': sun, 'btn-outline-secondary' : !sun}"-->
<!--                        (click)="toggleWeekday('sun')" data-test="edit-airport-curfew-sun-check">Sun-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="col-6 border-start">-->
<!--          <div class="container h-54">-->
<!--            <div class="row">-->
<!--              <div class="col-6 form-field-header">-->
<!--                Closing time-->
<!--              </div>-->
<!--              <div class="col-6 flex">-->
<!--                <ngb-timepicker [spinners]="false" formControlName="closingTime" data-test="edit-airport-curfew-closing-time-picker"></ngb-timepicker>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="container h-54">-->
<!--              <div class="row">-->
<!--                <div class="col-6 form-field-header">-->
<!--                  Opening time-->
<!--                </div>-->
<!--                <div class="col-6 flex">-->
<!--                  <ngb-timepicker [spinners]="false" formControlName="openingTime" data-test="edit-airport-curfew-opening-time-picker"></ngb-timepicker>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--        </div>-->
<!--      &lt;!&ndash;  <div class="col-12 mt-3">-->
<!--          <div class="container h-54">-->
<!--            <div class="row">-->
<!--              <div class="col-3 form-field-header">-->
<!--                Add Curfew Time-->
<!--              </div>-->
<!--              <div class="col-2 form-field-subheader">-->
<!--                Closing time-->
<!--              </div>-->
<!--              <div class="col-2">-->
<!--                <ngb-timepicker [(ngModel)]="closingTime" [spinners]="false" name="closingTime"></ngb-timepicker>-->
<!--              </div>-->
<!--              <div class="col-2 form-field-subheader">-->
<!--                Opening time-->
<!--              </div>-->
<!--              <div class="col-2">-->
<!--                <ngb-timepicker [(ngModel)]="openingTime" [spinners]="false" name="openingTime"></ngb-timepicker>-->
<!--              </div>-->
<!--              <div class="col-1">-->
<!--                <div class="icon-button-container icon-plus-container-bg-color"-->
<!--                     (click)="addTime(openingTime, closingTime)">-->
<!--                  <span class="iconized">+</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div> &ndash;&gt;-->
<!--      &lt;!&ndash;  <div class="col-12 form-field-header ms-3 mt-3">-->
<!--          Curfew Times-->
<!--        </div>-->
<!--        <ng-container *ngIf="times && times.length > 0">-->
<!--          <div class="col-12 form-field-content" *ngFor="let time of times; let i = index">-->
<!--            <div class="timeContainer" *ngIf="times.length > 0">-->
<!--              closed from {{time?.closedFromTime}} until {{time?.closedUntilTime}}-->
<!--              <div class="icon-button-container icon-minus-container-bg-color ms-3" (click)="removeTime(i)">-->
<!--                <span class="iconized">-</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="!times || times.length === 0">-->
<!--          <div class="col-12 form-field-content">-->
<!--            <div class="timeContainer" *ngIf="times.length === 0">-->
<!--              none-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-container> &ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--  </form>-->

  <form [formGroup]="formGroup" *ngIf="formGroup">
    <div class="flex flex-column gap-4">
      <div class="flex gap-3">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="local-check" formControlName="isLocal" data-test="curfew-local-check">
          <label class="form-check-label dark-blue user-select-none" for="local-check">Local {{ airport.country | titlecase }}</label>
        </div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="utc-check" formControlName="isUtc" data-test="curfew-utc-check">
          <label class="form-check-label dark-blue user-select-none" for="utc-check">UTC</label>
        </div>
      </div>

      <div class="flex justify-content-between">
        <div class="form-group">
          <label for="from">Validity From</label>
          <div class="input-group">
            <input class="form-control light-blue-border" [placeholder]="dateFormat" id="from"
                   name="dp" formControlName="validFrom" ngbDatepicker #d="ngbDatepicker" data-test="curfew-validity-from-date-input">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar light-blue-border"
                      (click)="d.toggle()" type="button" data-test="curfew-validity-from-calendar-button"></button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="to">Validity To</label>
          <div class="input-group">
            <input class="form-control light-blue-border" [placeholder]="dateFormat" id="to"
                   name="dp" formControlName="validTo" ngbDatepicker #d2="ngbDatepicker" data-test="curfew-validity-to-date-input">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar light-blue-border"
                      (click)="d2.toggle()" type="button" data-test="curfew-validity-to-calendar-button"></button>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="start">Start Time</label>
          <ngb-timepicker appTimepickerFocus id="start" formControlName="openingTime" [spinners]="false" data-test="curfew-start-time-picker"></ngb-timepicker>
        </div>
        <div class="form-group">
          <label for="end">End Time</label>
          <ngb-timepicker appTimepickerFocus id="end" formControlName="closingTime" [spinners]="false" data-test="curfew-end-time-picker"></ngb-timepicker>
        </div>
      </div>

      <div class="flex justify-content-between">
        <div class="flex flex-column">
          <label class="form-check-label dark-blue user-select-none" for="mon">Mon</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="mon" formControlName="mon" data-test="mon-check">
          </div>
        </div>

        <div class="flex flex-column">
          <label class="form-check-label dark-blue user-select-none" for="tue">Tue</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="tue" formControlName="tue" data-test="tue-check">
          </div>
        </div>

        <div class="flex flex-column">
          <label class="form-check-label dark-blue user-select-none" for="wed">Wed</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="wed" formControlName="wed" data-test="wed-check">
          </div>
        </div>

        <div class="flex flex-column">
          <label class="form-check-label dark-blue user-select-none" for="thu">Thu</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="thu" formControlName="thu" data-test="thu-check">
          </div>
        </div>

        <div class="flex flex-column">
          <label class="form-check-label dark-blue user-select-none" for="fri">Fri</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="fri" formControlName="fri" data-test="fri-check">
          </div>
        </div>

        <div class="flex flex-column">
          <label class="form-check-label dark-blue user-select-none" for="sat">Sat</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="sat" formControlName="sat" data-test="sat-check">
          </div>
        </div>

        <div class="flex flex-column">
          <label class="form-check-label dark-blue user-select-none" for="sun">Sun</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="sun" formControlName="sun" data-test="sun-check">
          </div>
        </div>

      </div>

      <div *ngIf="formGroup.errors?.overlap" class="flex flex-column overlap-message">
        <div class="text-danger">An overlap has been detected:</div>
        <div class="text-end">Existing data: {{ overlapData?.[0] }}</div>
        <div class="text-end">New data: <span class="text-danger">{{ overlapData?.[1] }}</span></div>
      </div>
    </div>
  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()" data-test="edit-airport-curfew-close-button">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="formGroup?.disabled || formGroup?.invalid || isBusy" data-test="edit-airport-curfew-save-button">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
