<form [formGroup]="filterForm">
  <div class="filter-container mx-1 flex flex-row">
    <div class="filterItem" [ngClass]="{ 'date-picker': dateFilter }">
      <select class="form-control form-select selectItem light-blue-border" id="filterTimeRange" formControlName="timespan" *ngIf="!dateFilter" [ngClass]="{ 'selected-color': filterForm.value.timespan }">
        <option [ngValue]="null">Time Span</option>
        <option *ngFor="let val of TimespanFilterValuesConstant | keyvalue" [value]="val.key">-{{val?.value[0]?.toString().padStart(2, '0')}}h | +{{val?.value[1]?.toString().padStart(2, '0')}}h</option>
      </select>
      <div class="input-group" *ngIf="dateFilter">
        <input class="form-control light-blue-border filter-background" [placeholder]="datePlaceholder"
               name="dp" formControlName="date" ngbDatepicker #dp="ngbDatepicker" data-test="date-input">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar date-selector-btn"
                  (click)="dp.toggle()" type="button" data-test="date-calendar-button"></button>
        </div>
      </div>
    </div>
    <div class="filterItem">
      <input class="form-control custom-input light-blue-border" placeholder="AC Registration" id="acRegistration" formControlName="acRegistration" data-test="flight-filter-ac-reg-input"/>
    </div>
    <div class="filterItem">
      <input class="form-control custom-input light-blue-border" placeholder="Flight Number" id="flightNumber" formControlName="flightNumber" data-test="flight-filter-flight-number-input"/>
    </div>
    <div class="filterItem">
      <ng-select class="new-select" placeholder="Airport" id="filterAirport" formControlName="station" data-test="flight-filter-station-select" [inputAttrs]="{ 'data-test': 'station-input'}">
        <ng-container *ngIf="user?.role !== 'DUTY_MANAGER'; else dutyManagerAirportFilter">
          <ng-option *ngFor="let station of stations" [value]="station">{{station}}</ng-option>
          <ng-template ng-option-tmp let-item="item">
            <span class="ng-option-label" [attr.data-test]="'station-' + item">{{ item }}</span>
          </ng-template>
        </ng-container>
        <ng-template #dutyManagerAirportFilter>
          <ng-option [value]="user?.location">{{user?.location}}</ng-option>
          <ng-template ng-option-tmp>
            <span class="ng-option-label" [attr.data-test]="'station-' + user?.location">{{ user?.location }}</span>
          </ng-template>
        </ng-template>
      </ng-select>
    </div>
    <div class="filterItem">
      <ng-select class="new-select" placeholder="Status" id="filterStatus" formControlName="status" data-test="flight-filter-status-select" [inputAttrs]="{ 'data-test': 'status-input'}">
        <ng-option value="OK">Green</ng-option>
        <ng-option value="WARNING">Amber</ng-option>
        <ng-option value="ERROR">Red</ng-option>
      </ng-select>
    </div>
    <div class="filterItem">
      <ng-select class="new-select" placeholder="Alerts" id="filterAlerts" formControlName="alerts" data-test="flight-filter-alert-select" [inputAttrs]="{ 'data-test': 'alert-input'}">
        <ng-option value="true">with Alerts</ng-option>
        <ng-option value="false">without Alerts</ng-option>
      </ng-select>
    </div>
    <!-- <div class="filterItem">
      <select class="form-control form-select selectItem" id="filterSortBy" formControlName="sortBy">
        <option [value]="null">Default</option>
        <option value="ETA">Sort by ETA</option>
        <option value="STD">Sort by STD</option>
        <option value="ETD">Sort by ETD</option>
        <option value="STA">Sort by STA</option>
        <option value="REG">Sort by REG</option>
      </select>
    </div> -->
    <div class="filterItem hide-unpaired">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="show-unpaired" formControlName="showUnpaired" data-test="flight-filter-show-unpaired-check">
        <label class="form-check-label dark-blue" for="show-unpaired">Show unpaired sectors</label>
      </div>
    </div>
  </div>
</form>
