<!-- Version 1 - removed xx.07.19
<div class="process-container">
  <div class="checkmark-icon-container rounded-circle" [ngClass]="{'hidden' : !done}">
    <i class="demo-icon icon-icons8-checkmark"></i>
  </div>
  <span class="name-span">{{name}}</span>
  <span class="time-span">{{starttime}}</span>
  <div class="forward-icon-container rounded-circle">
    <i class="demo-icon icon-icons8-forward"></i>
  </div>
  <span class="time-span">{{endtime}}</span>
</div> -->

<!-- Version 2 - removed 21.08.19
<div class="process-container2 me-3">
  <div class="a0 rounded-circle" [ngClass]="{'hidden' : !done}">
    <i class="demo-icon icon-icons8-checkmark"></i>
  </div>
  <span class="a1">{{name}}</span>
  <span class="a2">{{starttime}}</span>
  <div class="a3 rounded-circle">
    <i class="demo-icon icon-icons8-forward"></i>
  </div>
  <span class="a4">{{endtime}}</span>
</div> -->

<!-- Version 3 - added 21.08.19 -->
<!-- <div class="process-container2 me-3">
</div> -->
<div class="a-new-container">
  <div class="a-new">
    <span *ngIf="isSla" class="sla-indicator sla">SLA</span>
    <span *ngIf="!isSla" class="sla-indicator non-sla">Non-SLA</span>
    <span *ngIf="processTimePercentage !== 0" class="name d-flex">&nbsp; &nbsp;{{name}}&nbsp; &nbsp;
      <span *ngIf="endTime !== null" [ngClass]="{'red-text': processTimePercentage> 100}">({{completedProcessTime | timeFromSeconds}} | {{processTimePercentage}} %)</span>
      <span *ngIf="endTime === null" [ngClass]="{'red-text': processTimePercentage> 100}">({{processTimePercentage}} %)</span>
      <label class="ms-1" *ngIf="endTime !== null">              
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" class="bi bi-check-circle-fill" fill="#00ff00" viewBox="0 0 16 16">
          <path d="M 16 8 A 8 8 0 1 1 0 8 a 8 8 0 0 1 16 0 Z m -3.97 -3.03 a 0.75 0.75 0 0 0 -1.08 0.022 L 7.477 9.417 L 5.384 7.323 a 0.75 0.75 0 0 0 -1.06 1.06 L 6.97 11.03 a 0.75 0.75 0 0 0 1.079 -0.02 l 3.992 -4.99 a 0.75 0.75 0 0 0 -0.01 -1.05 Z"/>
        </svg>
      </label>
    </span>
    <span *ngIf="processTimePercentage === 0" class="name">&nbsp; &nbsp;{{name}}</span>
  </div>
</div>
