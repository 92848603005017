<div class="main-gantt-container">
  <div class="left-toolbar-container">
    <div class="toolbox-container">
      <div class="select-button" (click)="changeTool(GanttTool.SELECT)">
        <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" id="Icons_Cursor" overflow="hidden" width="30"
             height="30" [ngClass]="{ 'active-tool': activeTool === GanttTool.SELECT}">
          <path
            d="M76 67.1 55.8 46.91 73.6 40.5C74.514 40.1859 75.0004 39.1903 74.6862 38.2763 74.5109 37.7661 74.1101 37.3653 73.6 37.19L22.31 20.09C22.1261 20.0294 21.9336 19.999 21.74 20L21.74 20C20.7792 20.0206 20.0171 20.8161 20.0377 21.7769 20.0415 21.955 20.0726 22.1314 20.13 22.3L37.19 73.64C37.512 74.5513 38.5118 75.0289 39.4231 74.7069 39.9217 74.5307 40.3138 74.1386 40.49 73.64L46.91 55.82 67.09 76Z"
            stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="4"
            fill="#002060"/>
        </svg>
      </div>
      <div class="change-registration" (click)="changeTool(GanttTool.CHANGE_REGISTRATION)">
        <svg width="16px" height="10.32px" viewBox="0 0 16 10.32"
             xmlns="http://www.w3.org/2000/svg"
             [ngClass]="{ 'active-tool': activeTool === GanttTool.CHANGE_REGISTRATION}">
          <g id="arrow">
            <path
              d="M6.96196 0.470397L0.344073 8.03141C-0.436936 8.92395 0.197806 10.32 1.38312 10.32L14.6189 10.32C15.381 10.3207 15.9993 9.7035 16 8.94175C16.0003 8.6068 15.8787 8.28331 15.6579 8.03141L9.04005 0.471777C8.53859 -0.101915 7.66686 -0.160571 7.09302 0.340752C7.04644 0.381447 7.00266 0.425218 6.96196 0.471777L6.96196 0.470397Z"
              id="Shape" fill="#002060" stroke="none" clip-path="url(#clip_1)"/>
          </g>
        </svg>
        <svg width="16px" height="10.32px" viewBox="0 0 16 10.32"
             xmlns="http://www.w3.org/2000/svg"
             [ngClass]="{ 'active-tool': activeTool === GanttTool.CHANGE_REGISTRATION}">
          <g id="arrow">
            <path
              d="M6.96196 0.470397L0.344073 8.03141C-0.436936 8.92395 0.197806 10.32 1.38312 10.32L14.6189 10.32C15.381 10.3207 15.9993 9.7035 16 8.94175C16.0003 8.6068 15.8787 8.28331 15.6579 8.03141L9.04005 0.471777C8.53859 -0.101915 7.66686 -0.160571 7.09302 0.340752C7.04644 0.381447 7.00266 0.425218 6.96196 0.471777L6.96196 0.470397Z"
              id="Shape" fill="#002060" stroke="none" clip-path="url(#clip_1)"/>
          </g>
        </svg>
      </div>

      <div class="reschedule" (click)="changeTool(GanttTool.RESCHEDULE)">
        <svg width="16px" height="10.32px" viewBox="0 0 16 10.32"
             xmlns="http://www.w3.org/2000/svg" [ngClass]="{ 'active-tool': activeTool === GanttTool.RESCHEDULE}">
          <g id="arrow">
            <path
              d="M6.96196 0.470397L0.344073 8.03141C-0.436936 8.92395 0.197806 10.32 1.38312 10.32L14.6189 10.32C15.381 10.3207 15.9993 9.7035 16 8.94175C16.0003 8.6068 15.8787 8.28331 15.6579 8.03141L9.04005 0.471777C8.53859 -0.101915 7.66686 -0.160571 7.09302 0.340752C7.04644 0.381447 7.00266 0.425218 6.96196 0.471777L6.96196 0.470397Z"
              id="Shape" fill="#002060" stroke="none" clip-path="url(#clip_1)"/>
          </g>
        </svg>
        <svg width="16px" height="10.32px" viewBox="0 0 16 10.32"
             xmlns="http://www.w3.org/2000/svg" [ngClass]="{ 'active-tool': activeTool === GanttTool.RESCHEDULE}">
          <g id="arrow">
            <path
              d="M6.96196 0.470397L0.344073 8.03141C-0.436936 8.92395 0.197806 10.32 1.38312 10.32L14.6189 10.32C15.381 10.3207 15.9993 9.7035 16 8.94175C16.0003 8.6068 15.8787 8.28331 15.6579 8.03141L9.04005 0.471777C8.53859 -0.101915 7.66686 -0.160571 7.09302 0.340752C7.04644 0.381447 7.00266 0.425218 6.96196 0.471777L6.96196 0.470397Z"
              id="Shape" fill="#002060" stroke="none" clip-path="url(#clip_1)"/>
          </g>
        </svg>
      </div>

      <div class="group-sectors" (click)="groupSectors()">
        <img src="content/images/icons/gantt/panels.svg" width="24" height="24" alt="Group Sectors">
      </div>

      <div class="ungroup-sectors" (click)="ungroupSectors()">
        <img src="content/images/icons/gantt/two%20panels.png" width="24" height="24" alt="Ungroup Sectors">
      </div>

      <div class="highlight-sectors" (click)="highlightSectors()">
        <img src="content/images/icons/gantt/left-right-arrows.svg" width="24" height="24" alt="Highlight Sectors">
      </div>
    </div>

    <div class="toolbox-container second-section">
      <div class="move-button">
        <img src="content/images/icons/gantt/four-arrows.svg" width="24" height="24" alt="Move">
      </div>

      <div class="zoom-in-button" (click)="zoomIn()">
        <img src="content/images/icons/gantt/magnifier-plus.svg" width="24" height="24" alt="Zoom In">
      </div>

      <div class="zoom-out-button" (click)="zoomOut()">
        <img src="content/images/icons/gantt/magnifier-minus.svg" width="24" height="24" alt="Zoom Out">
      </div>
    </div>

    <div class="toolbox-container last-section">
      <div class="settings-button">
        <img src="content/images/icons/gantt/gear.svg" width="24" height="24" alt="Settings">
      </div>
    </div>
  </div>
  <div class="flex gantt-border gantt-zoomable-container avitium-scrollbar" #rightContainer
       (scroll)="syncVerticalScroll(rightContainer, div1); this.debouncedRedrawOnScroll()"
  >
    <div class="loading-screen" *ngIf="showLoadingSpinner">
      <div class="flex gap-3">
        <div class="loading-text dark-blue ">Loading data...</div>
        <app-loading-spinner *ngIf="showLoadingSpinner" color="#4285F6" class="align-self-center"></app-loading-spinner>
      </div>
    </div>
    <div class="flex flex-column left-side avitium-scrollbar">
      <div class="lane-name registrations-text flex flex-column gap-1 align-items-center">
        <div>Registrations</div>
        <img
          [src]="hasHiddenRegistrations ? 'content/images/icons/gantt/filter-blue.svg' : 'content/images/icons/gantt/filter.svg'"
          width="20" height="20" (click)="showAllRegistrations()" class="cursor-pointer">
      </div>
      <div class="registration-list avitium-scrollbar" cdkDropList #div1
           (scroll)="syncVerticalScroll(div1, rightContainer)" (cdkDropListDropped)="onRegistrationDrop($event)">
        <ng-container *ngFor="let reg of registrations; trackBy: trackByObjectId; let i = index">
          <div class="lane-name registration-box" cdkDrag cdkDragLockAxis="y"
               [ngStyle]="{ minHeight: (laneHeightOverride[i] || 95) + 'px' }"
               [ngClass]="{ 'd-none': hiddenRegistrations[registrations[i]?.registration] }"
               [cdkContextMenuTriggerFor]="registrationMenu"
               [cdkContextMenuTriggerData]="{ $implicit: registrations[i]?.registration }">
            <div>{{ reg.registration }}</div>
            <div>{{ acTypes[reg?.acTypeId] }}</div>
            <div>(
              <ng-container
                *ngFor="let seat of reg.seatingConfigurations; let i = index">{{ seat.code }}{{ seat.description }}
                <ng-container *ngIf="reg.seatingConfigurations.length-1 > i">/&nbsp;</ng-container>
              </ng-container>
              )
            </div>
          </div>
        </ng-container>
        <div class="lane-name bg-white unassigned-row"
             [ngStyle]="{ minHeight: (laneHeightOverride?.[registrations?.length] || 85) + 'px' }">
        </div>
      </div>

    </div>
    <div class="flex flex-column right-container">
      <div class="current-time" [style.left.px]="currentTimeLineOffset" *ngIf="currentTimeLineOffset"></div>
      <div class="flex top-header">
        <div class="lane-header">
          <div class="flex flex-column blue-bg justify-content-between date-container"
               *ngFor="let segment of dateSegments; let k = index"
               [ngStyle]="{ width: (dateWidthContainer[k] || 0) + '%' }">
            <div class="gantt-date"> {{ segment.date | date: 'dd/MM/yyyy':'UTC' }}</div>
            <div class="gantt-hours flex">
              <div class="gantt-hour" *ngFor="let hour of segment.hours">{{ hour }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="lane-list avitium-scrollbar" #div2
           cdkDropListGroup>
        <div class="lane" [ngStyle]="{  minHeight: (laneHeightOverride[i] || 85) + 'px' }"
             *ngFor="let flightData of ganttData2; let i = index; trackBy: trackByObjectId" #lane="cdkDropList"
             [ngClass]="{ 'unassigned-row': i === registrations?.length, 'd-none': hiddenRegistrations[registrations[i]?.registration] }"
             cdkDropList cdkDropListSortingDisabled
             [cdkDropListConnectedTo]="lane" (cdkDropListDropped)="drop($event)" [cdkDropListData]="i"
             (click)="onLaneClick()"
        >
          <ng-container *ngFor="let flight of flightData; trackBy: trackByObjectId">
            <ng-container *ngIf="!flight.isVirtual; else virtualLeg">
              <div class="gantt-item"
                   [ngStyle]="{ width: getTaskWidth(flight), left: getTaskPosition(flight), top: ((legTopOffset[flight.id] || 0) + staticTopOffset) + 'px' }"
                   [ngClass]="{ selected: selectedSectors[flight.id], cancelled: flight.operationStatus !== 'operating', highlighted: flight.highlighted }"
                   cdkDrag
                   [cdkDragDisabled]="activeTool !== GanttTool.RESCHEDULE && activeTool !== GanttTool.CHANGE_REGISTRATION || !canDrag(flight)"
                   (cdkDragEnded)="onDragEnded()"
                   (cdkDragStarted)="onDragStarted($event, flight)"
                   (cdkDragMoved)="onDragMove($event)"
                   [cdkDragBoundary]="div2"
                   [cdkDragLockAxis]="lockedAxis"
                   [cdkDragData]="flight"
                   (mousedown)="onMouseDown($event)"
                   (click)="sectorClicked(flight, $event)"
                   [attr.data-flight]="flight.id"
                   (mouseenter)="onTaskHover(flight.id)"
                   (mouseleave)="onTaskLeave(flight.id)"
              >
                <div class="top-flight-info dark-blue">{{ flight.departureStation }}
                  - {{ flight.arrivalStation }}
                </div>
                <div class="std-time dark-blue">{{ flight.std | date:'HH:mm':'UTC' }}</div>
                <div class="sta-time dark-blue">{{ flight.sta | date:'HH:mm':'UTC' }}</div>
                <div *ngIf="flight.etd" class="gantt-etd" [cdkContextMenuTriggerFor]="menu"
                     [cdkContextMenuTriggerData]="{ $implicit: flight }"
                     (cdkContextMenuClosed)="contextMenuClosed()"
                     [ngStyle]="{ minWidth: getTaskETDWidth(flight) }"></div>
                <div class="gantt-std" [cdkContextMenuTriggerFor]="menu"
                     (cdkContextMenuClosed)="contextMenuClosed()"
                     [cdkContextMenuTriggerData]="{ $implicit: flight }"
                     [ngClass]="{ 'insufficient-ground-time': this.data[flight.id]?.departure?.pairStatus === 'ERROR'}">
                  {{ flight.flightNumber }}
                  <ng-container *ngIf="flight.totalPassengers">({{ flight.totalPassengers }})</ng-container>
                </div>
                <svg *ngIf="this.data?.[flight.id]?.hasAlerts" class="alert-icon" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 50 50" width="12px" height="12px">
                  <g id="surface1">
                    <path style="fill: #ff0000" stroke="red" stroke-width="2"
                          d="M 24.84375 3.4375 C 24.539063 3.484375 24.273438 3.667969 24.125 3.9375 L 0.125 45.5 C -0.0546875 45.8125 -0.0546875 46.195313 0.128906 46.503906 C 0.308594 46.8125 0.640625 47.003906 1 47 L 49 47 C 49.359375 47.003906 49.691406 46.8125 49.871094 46.503906 C 50.054688 46.195313 50.054688 45.8125 49.875 45.5 L 25.875 3.9375 C 25.671875 3.570313 25.257813 3.371094 24.84375 3.4375 Z M 25 6.4375 L 47.25 45 L 2.75 45 Z M 23.84375 18.90625 C 23.628906 18.90625 23.5 19.035156 23.5 19.25 L 23.5 33.71875 C 23.5 33.933594 23.628906 34.09375 23.84375 34.09375 L 26.15625 34.09375 C 26.371094 34.09375 26.5 33.933594 26.5 33.71875 L 26.5 19.25 C 26.5 19.035156 26.371094 18.90625 26.15625 18.90625 Z M 23.625 36.96875 C 23.492188 37.019531 23.4375 37.152344 23.4375 37.3125 L 23.4375 39.9375 C 23.4375 40.152344 23.566406 40.28125 23.78125 40.28125 L 26.21875 40.28125 C 26.433594 40.28125 26.5625 40.152344 26.5625 39.9375 L 26.5625 37.3125 C 26.5625 37.097656 26.433594 36.96875 26.21875 36.96875 L 23.78125 36.96875 C 23.726563 36.96875 23.667969 36.953125 23.625 36.96875 Z "/>
                  </g>
                </svg>

                <div class="left-anchor"></div>
                <div class="right-anchor"></div>
              </div>
            </ng-container>
            <ng-template #virtualLeg>
              <div class="group-overlay"
                   [style.left]="'calc(' + getTaskPosition(flight) + ' - 18px)'"
                   [style.width]="'calc(' + getTaskWidth(flight) + ' + 36px)'"
                   [style.top.px]="(legTopOffset[flight.id] || 0) + staticTopOffset - 20">

              </div>
            </ng-template>

          </ng-container>

          <ng-container *ngFor="let groundTime of groundTimes[i]">
            <div class="ground-time-overlay"
                 [ngClass]="{ 'shown': hoveredLeg && (groundTime.arrivalLegId === hoveredLeg || groundTime.departureLegId === hoveredLeg), active: selectedPairId === groundTime.pairId}"
                 [style.left]="getGroundTimePosition(groundTime)"
                 [style.width]="getGroundTimeWidth(groundTime)"
                 [style.top.px]="(legTopOffset[groundTime.arrivalLegId] || 0) + staticTopOffset"
                 (click)="groundTimeClicked(groundTime, $event)"></div>
          </ng-container>

          <ng-container *ngIf="totalTimeSegments">
            <div class="segment-container" [style.height.px]="laneHeightOverride[i] || 85">
              <div class="segment-box" *ngFor="let i of totalTimeSegments">
              </div>
            </div>

          </ng-container>

        </div>
        <canvas id="canvas" #canvas></canvas>
      </div>

    </div>

  </div>


</div>

<!--<div class="group-overlay" *ngFor="let rect of groupOverlayData" [style.left.px]="rect.x" [style.width.px]="rect.width">-->

<!--</div>-->

<ng-template #registrationMenu let-reg>
  <div class="flight-menu light-blue-border" cdkMenu>
    <button class="flight-menu-item"
            cdkMenuItem
            (click)="hideRegistration(reg)">Hide
    </button>
  </div>
</ng-template>


<ng-template #menu let-flight>
  <div class="flight-menu light-blue-border" cdkMenu>
    <button class="flight-menu-item"
            [disabled]="!canGroupSectors()" cdkMenuItem
            (click)="groupSectors()">Group
      Sectors
    </button>
    <button class="flight-menu-item disabled-menu-item" cdkMenuItem (click)="ungroupSectors(flight?.id)"
            [disabled]="!canUngroupSectors(flight?.id)">
      Ungroup Sectors
    </button>
    <button class="flight-menu-item disabled-menu-item" cdkMenuItem
            (click)="menuItemClicked(MenuItemType.HIGHTLIGHT_MATCHES, flight)">Highlight
      Matches
    </button>
    <div class="context-separator"></div>
    <button class="flight-menu-item" cdkMenuItem [cdkMenuTriggerFor]="registration_list"
            [cdkMenuTriggerData]="{ $implicit: flight }">
      <div class="flex justify-content-between w-100">
        <div>Assign Tail</div>
        <span>&#10148;</span>
      </div>
    </button>
    <button class="flight-menu-item" [disabled]="!flight.acRegistrationId" cdkMenuItem
            (click)="menuItemClicked(MenuItemType.UNASSIGN_TAIL, flight)">Unassign
      Tail(s)
    </button>
    <button class="flight-menu-item" [disabled]="flight.operationStatus !== 'operating'" cdkMenuItem
            (click)="menuItemClicked(MenuItemType.CANCEL_FLIGHT, flight)">Cancel
      Flight(s)
    </button>
    <div class="context-separator"></div>
    <button class="flight-menu-item" cdkMenuItem
            (click)="menuItemClicked(MenuItemType.CONNECTED_PASSENGERS, flight)">
      Connected Passengers
    </button>
    <button class="flight-menu-item" cdkMenuItem
            (click)="menuItemClicked(MenuItemType.MISCONNECTED_PASSENGERS, flight)">
      Misconnected Passengers
    </button>
    <button class="flight-menu-item" disabled cdkMenuItem
            (click)="menuItemClicked(MenuItemType.MISCONNECTED_CREW)">Misconnected
      Crew
    </button>
  </div>
</ng-template>

<ng-template #registration_list let-flight>
  <div class="registration-menu light-blue-border">
    <div class="input-group has-search search-box">
      <img src="content/images/icons/search.svg" width="26" height="26" class="form-control-feedback"
           alt="search">
      <input type="text" class="form-control search-bar input-bg-color" placeholder="Search"
             data-test="gantt-registration-search-input" [(ngModel)]="registrationSearch"
             (ngModelChange)="onRegistrationSearch()">
    </div>
    <div class="registration-sub-menu avitium-scrollbar" cdkMenuGroup>
      <button *ngFor="let registration of filteredRegistrationsForContextMenu" class="flight-menu-item" cdkMenuItemRadio
              (click)="setRegistration(flight, registration)">{{ registration.registration }}
      </button>
    </div>

  </div>
</ng-template>

