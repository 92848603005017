<div class="position-relative h-100" *ngIf="pageAccess === null">
  <app-access-denied></app-access-denied>
</div>
<ng-container *ngIf="form">
  <app-settings-header [backButton]="true" backButtonUrl=".."
                       [text]="acType ? 'Edit Aircraft Type ' + acType?.iata : 'Add Aircraft Type'"
                       [showSaveButton]="true" [textSize]="20" (onSaveClicked)="save()"
                       [saveButtonDisabled]="pageAccess !== Access.RW || form.invalid || forms[FlightType.DOMDOM]?.invalid || forms[FlightType.DOMINT]?.invalid || forms[FlightType.INTINT]?.invalid || forms[FlightType.INTDOM]?.invalid"
                       [spinner]="isBusy"></app-settings-header>
  <form class="settings-container mt-3 flex flex-column avitium-scrollbar">
    <app-settings-card sectionTitle="General" [formGroup]="form" data-test="general-card">
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="alias" class="form-label">A/C Type</label>
          <input type="text" class="form-control" id="alias" formControlName="alias"
                 data-test="edit-ac-type-alias-input">
        </div>
        <div class="flex-basis-30">
          <label for="iata" class="form-label">IATA Code</label>
          <input type="text" class="form-control" id="iata" formControlName="iata" data-test="edit-ac-type-iata-input">
        </div>
        <div class="flex-basis-30">
          <label for="icao" class="form-label">ICAO Code</label>
          <input type="text" class="form-control" id="icao" formControlName="icao" data-test="edit-ac-type-icao-input">
        </div>
      </div>
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="description" class="form-label">Description</label>
          <input type="text" class="form-control" id="description" formControlName="description"
                 data-test="edit-ac-type-description-input">
        </div>
        <div class="flex-basis-30">
          <label for="mtow" class="form-label">MTOW</label>
          <input type="number" class="form-control" id="mtow" formControlName="mtow"
                 data-test="edit-ac-type-mtow-input">
        </div>
        <div class="flex-basis-30">
          <label for="calibration" class="form-label">Calibration</label>
          <select class="form-select light-blue-border dark-blue" id="calibration" formControlName="calibrationId"
                  data-test="edit-ac-type-calibration-select">
            <option *ngFor="let unit of measurementUnits | keyvalue" [ngValue]="unit.value.id"
                    [attr.data-test]="'ac-type-dialog-measurement-' + unit.value.code">
              {{ unit.value.code }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex dark-blue justify-content-between">
        <div class="flex-basis-30">
          <label for="wake" class="form-label">Wake Turbulence</label>
          <input type="text" class="form-control" id="wake" formControlName="wakeTurbulence"
                 data-test="edit-ac-type-wake-turbulence-input">
        </div>
        <div class="flex-basis-30">
          <label for="fireCategory" class="form-label">Fire Category</label>
          <input type="number" class="form-control" id="fireCategory" formControlName="fireCategory"
                 data-test="edit-ac-type-fire-category-input">
        </div>
        <div class="flex-basis-30">
          <label for="defaultMinTurnaroundTimeInMinutes" class="form-label">Default Turnaround Time</label>
          <ngb-timepicker appTimepickerFocus formControlName="defaultMinTurnaroundTimeInMinutes"
                          id="defaultMinTurnaroundTimeInMinutes" [spinners]="false"
                          data-test="edit-ac-type-default-turnaround-minutes-picker"></ngb-timepicker>
        </div>
      </div>
    </app-settings-card>

    <app-settings-card data-test="seating-configurations-card" sectionTitle="Seating Configuration" [formGroup]="form"
                       addText="Add Seating Configuration"
                       informationTooltip="At least one seating configuration has to be entered for each A/C Type"
                       (onAddClicked)="addNewSeat()" [canAdd]="pageAccess === Access.RW">
      <ng-container formGroupName="seatingConfig">
        <div class="flex flex-column skip-invalid-border gap-3" formArrayName="seats" cdkDropList
             (cdkDropListDropped)="drop($event)" data-test="seat-configs-container">
          <ng-container *ngFor="let group of seats.controls | keyvalue; let i = index">
            <app-seat-configuration cdkDrag [form]="group.value" [passengerClasses]="passengerClasses"
                                    [airlineDesignators]="this.airlineDesignators" [attr.data-test]="'seat-' + i">
              <div class="align-self-center cursor-pointer handle mt-4" cdkDragHandle *ngIf="pageAccess === Access.RW">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#002060"
                     class="bi bi-grip-horizontal" viewBox="0 0 16 16">
                  <path
                    d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
              </div>
            </app-seat-configuration>
          </ng-container>
        </div>
      </ng-container>

      <!--    <div class="seating-grid dark-blue" formGroupName="seatingConfig">-->
      <!--      <div *ngFor="let passClass of this.passengerClasses | keyvalue" class="flex flex-column">-->
      <!--        <div>-->
      <!--          {{passClass?.value?.code}}-->
      <!--        </div>-->
      <!--        <ng-container [formGroupName]="passClass?.value?.code">-->
      <!--          <input type="number" min="0" class="form-control" id="settings-ac-type-seating-config-{{passClass?.key}}" formControlName="description">-->
      <!--        </ng-container>-->
      <!--      </div>-->
      <!--    </div>-->
    </app-settings-card>

    <app-settings-card sectionTitle="Turnaround Times: Domestic to Domestic" data-test="tt-domdom-card">
      <div class="flex flex-column dark-blue gap-3">
        <div>Default Turnaround Time</div>
        <div class="flex gap-3">
          <input type="text" [(ngModel)]="turnaroundTime" name="domdom"
                 class="form-control light-blue-border default-turnaround-time"
                 data-test="edit-ac-type-turnaround-time-apply-to-all-domdom-input"
                 [disabled]="pageAccess !== Access.RW">
          <button type="button" class="btn button-blue apply-button" (click)="applyTimeToAll(flightTypes.DOMDOM)"
                  data-test="edit-ac-type-turnaround-time-apply-to-all-domdom-button"
                  [disabled]="pageAccess !== Access.RW">Apply to all Service types
          </button>
        </div>
        <ng-container *ngIf="forms[flightTypes.DOMDOM]">
          <div class="overflow-auto avitium-scrollbar skip-invalid-border" [formGroup]="forms[flightTypes.DOMDOM]">
            <table class="table table-striped turnaround-table dark-blue">
              <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" *ngFor="let flightServiceType of forms[flightTypes.DOMDOM]?.controls | keyvalue">
                  Outbound {{ flightServiceType?.key }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let flightServiceType of forms[flightTypes.DOMDOM]?.controls | keyvalue"
                  [formGroupName]="flightServiceType.key" [attr.data-test]="'inbound-' + flightServiceType?.key">
                <td>Inbound {{ flightServiceType?.key }}</td>
                <td *ngFor="let serviceType of flightServiceType.value?.controls | keyvalue"
                    [formGroupName]="serviceType?.key" [attr.data-test]="'outbound-' + serviceType?.key">
                  <input type="text" class="form-control time-input"
                         id="arrival-flight-passenger-gate-{{flightServiceType?.key}}-{{serviceType?.key}}"
                         placeholder="" formControlName="time" pattern="[0-9][0-9]\:[0-9][0-9]">
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Turnaround Times: Domestic to International" data-test="tt-domint-card">
      <div class="flex flex-column dark-blue gap-3">
        <div>Default Turnaround Time</div>
        <div class="flex gap-3">
          <input type="text" [(ngModel)]="turnaroundTime" name="domint"
                 class="form-control light-blue-border default-turnaround-time"
                 data-test="edit-ac-type-turnaround-time-apply-to-all-domint-input"
                 [disabled]="pageAccess !== Access.RW">
          <button type="button" class="btn button-blue apply-button" (click)="applyTimeToAll(flightTypes.DOMINT)"
                  data-test="edit-ac-type-turnaround-time-apply-to-all-domint-button"
                  [disabled]="pageAccess !== Access.RW">Apply to all Service types
          </button>
        </div>
        <ng-container *ngIf="forms[flightTypes.DOMINT]">
          <div class="overflow-auto avitium-scrollbar skip-invalid-border" [formGroup]="forms[flightTypes.DOMINT]">
            <table class="table table-striped turnaround-table dark-blue">
              <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" *ngFor="let flightServiceType of forms[flightTypes.DOMINT]?.controls | keyvalue">
                  Outbound {{ flightServiceType?.key }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let flightServiceType of forms[flightTypes.DOMINT]?.controls | keyvalue"
                  [formGroupName]="flightServiceType.key" [attr.data-test]="'inbound-' + flightServiceType?.key">
                <td>Inbound {{ flightServiceType?.key }}</td>
                <td *ngFor="let serviceType of flightServiceType.value?.controls | keyvalue"
                    [formGroupName]="serviceType?.key" [attr.data-test]="'outbound-' + serviceType?.key">
                  <!--                {{ forms[flightTypes.DOMDOM]?.controls[flightServiceType?.value?.id][serviceType?.value?.id] | timeFromMinutes }}-->
                  <input type="text" class="form-control time-input"
                         id="arrival-flight-passenger-gate-{{flightServiceType?.key}}-{{serviceType?.key}}"
                         placeholder="" formControlName="time" pattern="[0-9][0-9]\:[0-9][0-9]">
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Turnaround Times: International to International" data-test="tt-intint-card">
      <div class="flex flex-column dark-blue gap-3">
        <div>Default Turnaround Time</div>
        <div class="flex gap-3">
          <input type="text" [(ngModel)]="turnaroundTime" name="intint"
                 class="form-control light-blue-border default-turnaround-time"
                 data-test="edit-ac-type-turnaround-time-apply-to-all-intint-input"
                 [disabled]="pageAccess !== Access.RW">
          <button type="button" class="btn button-blue apply-button" (click)="applyTimeToAll(flightTypes.INTINT)"
                  data-test="edit-ac-type-turnaround-time-apply-to-all-intint-button"
                  [disabled]="pageAccess !== Access.RW">Apply to all Service types
          </button>
        </div>
        <ng-container *ngIf="forms[flightTypes.INTINT]">
          <div class="overflow-auto avitium-scrollbar skip-invalid-border" [formGroup]="forms[flightTypes.INTINT]">
            <table class="table table-striped turnaround-table dark-blue">
              <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" *ngFor="let flightServiceType of forms[flightTypes.INTINT]?.controls | keyvalue">
                  Outbound {{ flightServiceType?.key }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let flightServiceType of forms[flightTypes.INTINT]?.controls | keyvalue"
                  [formGroupName]="flightServiceType.key" [attr.data-test]="'inbound-' + flightServiceType?.key">
                <td>Inbound {{ flightServiceType?.key }}</td>
                <td *ngFor="let serviceType of flightServiceType.value?.controls | keyvalue"
                    [formGroupName]="serviceType?.key" [attr.data-test]="'outbound-' + serviceType?.key">
                  <!--                {{ forms[flightTypes.DOMDOM]?.controls[flightServiceType?.value?.id][serviceType?.value?.id] | timeFromMinutes }}-->
                  <input type="text" class="form-control time-input"
                         id="arrival-flight-passenger-gate-{{flightServiceType?.key}}-{{serviceType?.key}}"
                         placeholder="" formControlName="time" pattern="[0-9][0-9]\:[0-9][0-9]">
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
    </app-settings-card>

    <app-settings-card sectionTitle="Turnaround Times: International to Domestic" data-test="tt-intdom-card">
      <div class="flex flex-column dark-blue gap-3">
        <div>Default Turnaround Time</div>
        <div class="flex gap-3">
          <input type="text" [(ngModel)]="turnaroundTime" name="intdom"
                 class="form-control light-blue-border default-turnaround-time"
                 data-test="edit-ac-type-turnaround-time-apply-to-all-intdom-input"
                 [disabled]="pageAccess !== Access.RW">
          <button type="button" class="btn button-blue apply-button" (click)="applyTimeToAll(flightTypes.INTDOM)"
                  data-test="edit-ac-type-turnaround-time-apply-to-all-intdom-button"
                  [disabled]="pageAccess !== Access.RW">Apply to all Service types
          </button>
        </div>
        <ng-container *ngIf="forms[flightTypes.INTDOM]">
          <div class="overflow-auto avitium-scrollbar skip-invalid-border" [formGroup]="forms[flightTypes.INTDOM]">
            <table class="table table-striped turnaround-table dark-blue">
              <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" *ngFor="let flightServiceType of forms[flightTypes.INTDOM]?.controls | keyvalue">
                  Outbound {{ flightServiceType?.key }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let flightServiceType of forms[flightTypes.INTDOM]?.controls | keyvalue"
                  [formGroupName]="flightServiceType.key" [attr.data-test]="'inbound-' + flightServiceType?.key">
                <td>Inbound {{ flightServiceType?.key }}</td>
                <td *ngFor="let serviceType of flightServiceType.value?.controls | keyvalue"
                    [formGroupName]="serviceType?.key" [attr.data-test]="'outbound-' + serviceType?.key">
                  <!--                {{ forms[flightTypes.DOMDOM]?.controls[flightServiceType?.value?.id][serviceType?.value?.id] | timeFromMinutes }}-->
                  <input type="text" class="form-control time-input"
                         id="arrival-flight-passenger-gate-{{flightServiceType?.key}}-{{serviceType?.key}}"
                         placeholder="" formControlName="time" pattern="[0-9][0-9]\:[0-9][0-9]">
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
    </app-settings-card>
  </form>

</ng-container>
