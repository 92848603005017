import { Pipe, PipeTransform } from '@angular/core';
import { ILegsModel } from '../models/legs.model';
import { TestTagsUtils } from '../utils/test-tags.utils';

@Pipe({
  name: 'legTestAttribute'
})
export class LegTestAttributePipe implements PipeTransform {

  transform(value: ILegsModel, ...args: unknown[]): string {
    return TestTagsUtils.getLegTestTag(value);
  }
}
