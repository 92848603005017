import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IGeneralSettingsModel } from '../../../../../shared/models/general-settings.model';
import { GeneralSettingsService } from '../../../../../services/general-settings.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../services/toast.service';
import { TimeFromMinutesPipe } from '../../../../../shared/pipes/time-from-minutes.pipe';
import { MinutesFromTimePipe } from '../../../../../shared/pipes/minutes-from-time.pipe';

@Component({
  selector: 'app-add-edit-turnaround-threshold-dialog',
  templateUrl: './add-edit-turnaround-threshold-dialog.component.html',
  styleUrls: ['./add-edit-turnaround-threshold-dialog.component.scss']
})
export class AddEditTurnaroundThresholdDialogComponent implements OnInit {

  timePicker?: UntypedFormControl;
  isBusy = false;
  @Input() title = "Manage Turnaround Threshold";
  @Input() settings?: IGeneralSettingsModel;
  constructor(private generalSettingsService: GeneralSettingsService, private activeModal: NgbActiveModal, private toastService: ToastService, private timeFromMinutesPipe: TimeFromMinutesPipe, private minutesFromTimePipe: MinutesFromTimePipe) { }

  ngOnInit(): void {
    this.timePicker = new UntypedFormControl(this.timeFromMinutesPipe.transform(this.settings?.turnaroundThresholdInMinutes));
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onSaveClick() {
    const data: IGeneralSettingsModel = { turnaroundThresholdInMinutes: this.minutesFromTimePipe.transform(this.timePicker.value), id: this.settings?.id };
    this.isBusy = true;
    this.generalSettingsService.saveTurnaroundThreshold(data).subscribe((result) => {
      if(result?.id) {
        this.toastService.showSuccess("Turnaround threshold has been saved");
        this.closeModal();
      }
      this.isBusy = false;
    });
  }

}
