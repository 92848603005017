import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, firstValueFrom, forkJoin, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {IAcRegistration} from '../../../../../shared/models/ac-registration.model';
import {IAcType} from '../../../../../shared/models/ac-type.model';
import {IGenericContainerObject} from '../../../../../shared/models/genericContainerObject.model';
import {ISimpleData} from '../../../../../shared/models/simpleData.model';
import {
  SettingsAcRegistrationMainFormBuilder
} from '../../../../../shared/forms/formBuilders/settings/settings-ac-registration-main-form-builder';
import {SeatingConfigurationService} from '../../../../../services/seating-configuration.service';
import {AcRegistrationsService} from '../../../../../services/ac-registrations.service';
import {ToastService} from '../../../../../services/toast.service';
import * as dayjs from 'dayjs';
import {GeneralSettingsService} from '../../../../../services/general-settings.service';
import {dayjsToNgbDate, ngbDateToDayjs} from '../../../../../shared/utils/utils';
import {ActivatedRoute, Router} from '@angular/router';
import {AcTypesService} from '../../../../../services/ac-types.service';
import {IAirlineDesignator} from '../../../../../shared/models/airline-designator.model';
import {ISeatingConfigurationModel} from '../../../../../shared/models/seating-configuration.model';
import {IGeneralSettingsModel} from '../../../../../shared/models/general-settings.model';
import {PermissionService} from "../../../../../services/permission.service";
import {Access, PermissionUIMasks} from "../../../../../shared/constants/enums";

@Component({
  selector: 'app-add-edit-ac-registration-dialog',
  templateUrl: './add-edit-ac-registration-dialog.component.html',
  styleUrls: ['./add-edit-ac-registration-dialog.component.scss'],
})
export class AddEditAcRegistrationDialogComponent implements OnInit, OnDestroy {

  @Input() acRegistration: IAcRegistration;
  @Input() acTypes: IAcType[];
  @Input() passengerClasses: ISimpleData[];
  @Input() title: string;
  @Input() outerSaveClicked?: Observable<any>;
  @Input() measurementUnits?: ISimpleData[];
  acRegistrationId: number;
  generalSettings: IGeneralSettingsModel;
  destroySubject = new Subject();
  form?: UntypedFormGroup;
  fieldAcTypeSubscription: any;
  seatingConfigurations: IGenericContainerObject<ISimpleData>;
  passengerLetters: { [p: string]: number };
  dateFormat: string;
  isBusy = false;
  loaded = false;
  unsubscribe$ = new Subject();
  airlineDesignators: IAirlineDesignator[];
  acAvailableSeats: { [order: number]: ISeatingConfigurationModel[] } = {};
  acSeat: ISeatingConfigurationModel;
  operatedBy: number;
  pageAccess: Access;

  constructor(
    private acRegistrationsService: AcRegistrationsService,
    private acTypesService: AcTypesService,
    private toastService: ToastService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private seatingConfigurationService: SeatingConfigurationService,
    private generalSettingsService: GeneralSettingsService,
    private router: Router,
    private permissionService: PermissionService) {
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_AC_REGISTRATION_MANAGEMENT).then((pageAccess) => {
      this.pageAccess = pageAccess;
      if (!this.pageAccess) {
        return;
      }
      this.acRegistrationId = Number((this.route.params as BehaviorSubject<any>).value.id) || null;
      const obs = [this.acTypesService.fetchAcTypes(), this.generalSettingsService.getAirlineDesignators(), this.generalSettingsService.getPassengerClasses(), this.generalSettingsService.getMeasurementUnits(), this.generalSettingsService.getGeneralSettings()];
      if (this.acRegistrationId) {
        obs.push(this.acRegistrationsService.fetchAcRegistrations({
          id: this.acRegistrationId,
          isActive: true,
        }));
      }
      forkJoin(obs).subscribe((results) => {
        this.acTypes = results[0];
        this.airlineDesignators = results[1];
        this.passengerClasses = results[2];
        this.measurementUnits = results[3];
        this.generalSettings = results[4]?.[0];
        this.acRegistration = results[5]?.[0];
        if (this.acRegistrationId && !this.acRegistration) {
          this.router.navigate(['..'], {relativeTo: this.route});
          return;
        }

        this.dateFormat = this.generalSettingsService.generalSettings.value.dateFormat;
        this.form = SettingsAcRegistrationMainFormBuilder.constructForm(this.fb, this.passengerClasses);

        this.passengerLetters = {};
        this.seatingConfigurations = {};
        for (const pClass of this.passengerClasses) {
          this.passengerLetters[pClass.code] = pClass.id;
        }
        if (this.acRegistration) {
          if (this.acRegistration.seatingConfigurations) {

            for (const seatConfig of this.acRegistration.seatingConfigurations) {
              this.seatingConfigurations[seatConfig.code] = seatConfig;
              this.operatedBy = seatConfig.operatedBy;
            }
          }
          const dayjsObj = dayjs(this.acRegistration?.deliveryDate);
          this.form.patchValue({
            ...this.acRegistration,
            deliveryDate: dayjsObj.isValid() ? dayjsToNgbDate(dayjsObj) : null,
            seatingConfig: this.seatingConfigurations,
          });
          this.operatedByChanged(this.operatedBy);
          this.acAvailableSeats = {};
          const selectedAcType = this.acTypes.find((ac) => ac.id === this.acRegistration.acTypeId);
          for (const seat of selectedAcType?.seatingConfigurations.filter((item) => item.acRegistrationId === null && (item.validFrom ? dayjs.utc().isAfter(dayjs.utc(item.validFrom)) : true) && (item.validTo ? dayjs.utc().isBefore(dayjs.utc(item.validTo)) : true))) {
            //seatingConfigurations[seat.code] = seat;
            if (!this.acAvailableSeats[seat.order]) {
              this.acAvailableSeats[seat.order] = [];
            }
            this.acAvailableSeats[seat.order].push(seat);
          }
        }

        this.fieldAcTypeSubscription = this.form.get('acTypeId')?.valueChanges.subscribe((acTypeId: string) => {
          this.patchFormWithAcTypeData(+acTypeId);
        });

        if (this.pageAccess === Access.R) {
          this.form.disable();
        }
      });
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  ngOnInit() {

  }

  async onSaveClick() {
    if (!this.form.valid) {
      return;
    }
    this.isBusy = true;
    const data = {
      ...this.form.value,
      id: this.acRegistration?.id,
      seatingConfig: undefined,
      operatedBy: this.operatedBy
    };
    const saveResult = await this.save(data);
    if (!saveResult?.id) {
      this.isBusy = false;
      return;
    } else {
      this.acRegistration = saveResult;
    }
    const changedSeatConfigs: ISeatingConfigurationModel[] = [];
    const seatingConfigs = this.form.get('seatingConfig') as UntypedFormGroup;
    for (const seatingConfig in seatingConfigs.controls) {
      const value = Number(seatingConfigs.controls[seatingConfig].get('description').value) || 0;
      const exitingValue = Number(this.seatingConfigurations[seatingConfig]?.description) || 0;
      if (exitingValue !== value) {
        changedSeatConfigs.push({
          ...seatingConfigs.controls[seatingConfig].value,
          id: this.seatingConfigurations[seatingConfig]?.id,
          operatedBy: this.operatedBy
        })
      }
    }
    if (changedSeatConfigs.length > 0) {
      const observables: Observable<any>[] = [];
      for (const seat of changedSeatConfigs) {
        if (seat.id) {
          observables.push(this.seatingConfigurationService.deleteSeatingConfiguration(seat.id).pipe(map((item) => !!item)));
        }

        if (String(seat?.description || '').trim().length) {
          observables.push(this.seatingConfigurationService.saveSeatingConfiguration({
            ...seat,
            id: undefined,
            acRegistrationId: this.acRegistration.id,
            acTypeId: this.acRegistration.acTypeId
          }).pipe(map((item) => !!item)));
        }
      }
      forkJoin(observables).subscribe((results) => {
        this.acRegistrationsService.fetchAcRegistrations({
          id: this.acRegistration?.id,
          isActive: true,
        }).subscribe((results) => {
          this.acRegistration = results[0];
          this.seatingConfigurations = {};
          if (this.acRegistration?.seatingConfigurations) {
            for (const seatConfig of this.acRegistration.seatingConfigurations) {
              this.seatingConfigurations[seatConfig.code] = seatConfig;
            }
          }
        });
        const errors = results.filter((item) => !item);
        if (errors.length) {
          this.toastService.showError("Saving AC Registration Seating Configurations failed");
        } else {
          this.isBusy = false;
        }
      });
    } else {
      this.isBusy = false;
      this.acRegistrationsService.fetchAcRegistrations({
        id: this.acRegistrationId,
        isActive: true,
      }).subscribe((results) => {
        this.acRegistration = results[0];
        this.seatingConfigurations = {};
        if (this.acRegistration.seatingConfigurations) {
          for (const seatConfig of this.acRegistration.seatingConfigurations) {
            this.seatingConfigurations[seatConfig.code] = seatConfig;
          }
        }
      });
    }

  }

  async save(data: IAcRegistration): Promise<IAcRegistration> {
    if (data.deliveryDate) {
      data.deliveryDate = ngbDateToDayjs(data.deliveryDate as any).toDate().toDateString();
    }
    const result = await firstValueFrom(this.acRegistrationsService.saveAcRegistration(data));
    if (result?.id) {
      this.toastService.showSuccess("AC Registration has been saved");
    } else {
      this.toastService.showError("AC Registration saving has failed");
    }
    return result;
  }

  patchFormWithAcTypeData(acTypeId: number) {
    if (!acTypeId || !this.acTypes || this.acTypes.findIndex((acType) => acType.id === acTypeId) === -1) {
      this.form?.patchValue({
        description: '',
        calibrationId: '',
        mtow: '',
      });
    } else {
      const selectedAcType = this.acTypes.find((acType) => acType.id === acTypeId);
      const seatingConfigurations = {};
      for (const key in this.passengerClasses) {
        seatingConfigurations[key] = {
          code: key,
          description: null,
        };
      }
      this.operatedBy = null;
      this.acAvailableSeats = {};
      for (const seat of selectedAcType?.seatingConfigurations.filter((item) => item.acRegistrationId === null && (item.validFrom ? dayjs.utc().isAfter(dayjs.utc(item.validFrom)) : true) && (item.validTo ? dayjs.utc().isBefore(dayjs.utc(item.validTo)) : true))) {
        //seatingConfigurations[seat.code] = seat;
        if (!this.acAvailableSeats[seat.order]) {
          this.acAvailableSeats[seat.order] = [];
        }
        this.acAvailableSeats[seat.order].push(seat);
      }
      this.form?.patchValue({
        description: selectedAcType?.description,
        calibrationId: selectedAcType?.calibrationId,
        mtow: selectedAcType?.mtow,
        //seatingConfig: seatingConfigurations,
      });
    }
  }

  acSeatSelected(order: string) {
    const seats: ISeatingConfigurationModel[] = this.acAvailableSeats[order];
    const seatObj = {};
    for (const seat of seats) {
      seatObj[seat.code] = {code: seat.code, description: seat.description};
    }
    const fg = this.form.get('seatingConfig') as UntypedFormGroup;
    for (const seat in fg.controls) {
      fg.controls[seat].patchValue({
        description: null
      });
    }
    this.form?.patchValue({
      seatingConfig: seatObj,
    });
    this.operatedBy = seats[0].operatedBy;
    this.operatedByChanged(this.operatedBy);
  }

  getAcSeatList(order: string): string {
    const seatModels: ISeatingConfigurationModel[] = this.acAvailableSeats[+order];
    const seatText = seatModels.map((seat) => seat.code + seat.description).join('/');
    if (seatModels?.length) {
      const designator = this.airlineDesignators.find((ad) => ad.id === seatModels[0].operatedBy) || undefined;
      return seatText + ' (' + (designator?.description || '-') + ')';
    }
    return seatText;
  }

  operatedByChanged(value: any) {
    const seatControls = this.form?.controls['seatingConfig'] as UntypedFormGroup;
    if (!value) {
      for (const seat in seatControls.controls) {
        seatControls.disable();
      }
      return;
    }
    for (const seat in seatControls.controls) {
      seatControls.enable();
    }
  }

  public readonly Access = Access;
}
