<div class="modal-body p-4 w-100 dark-blue">
  <div class="flex">
    <div class="flex flex-column gap-2 w-60" *ngIf="showContent">
      <strong class="fs-5">Involved previous pair(s):</strong>
      <div class="fs-5">{{ pairDetails[0]?.arrivalLeg?.departureStation }} - {{ pairDetails[0]?.arrivalLeg?.arrivalStation }} / {{ pairDetails[0]?.departureLeg?.departureStation }} - {{ pairDetails[0]?.departureLeg?.arrivalStation }} </div>
      <app-leg
        [flightPairKey]="pairDetails[0]?.id?.toString()"
        [pairOverview]="firstPreviousPair"
        [timezone]="timezone"
        [inEditPairMode]="true"
        [attr.data-test]="firstPreviousPair | pairTestAttribute"
      ></app-leg>
      <ng-container *ngIf="this.pairDetails[1]">
        <div class="mt-2 fs-5">{{ pairDetails[1]?.arrivalLeg?.departureStation }} - {{ pairDetails[1]?.arrivalLeg?.arrivalStation }} / {{ pairDetails[1]?.departureLeg?.departureStation }} - {{ pairDetails[1]?.departureLeg?.arrivalStation }} </div>
        <app-leg
          [flightPairKey]="pairDetails[1]?.id?.toString()"
          [pairOverview]="secondPreviousPair"
          [timezone]="timezone"
          [inEditPairMode]="true"
          [attr.data-test]="secondPreviousPair | pairTestAttribute"
        ></app-leg>
      </ng-container>
    </div>
    <div class="flex flex-column gap-2 w-40" *ngIf="showContent">
      <strong class="fs-5">Issue ETD for Flight {{ pairDetails[0].departureLeg?.airlineDesignator + pairDetails[0].departureLeg?.flightNumber }}</strong>
      <form [formGroup]="form" class="mt-4">
        <div class="container mt-4">
          <div class="row">
            <div class="col-12">
              <div class="container">
                <div class="row">
                  <div class="col-5 form-field-header">
                    New ETD*
                  </div>
                  <div class="col-4">
                    <div class="mb-3">
                      <div class="input-group">
                        <input class="form-control" [placeholder]="dateFormat"
                               name="dp" formControlName="etdDate" ngbDatepicker #d="ngbDatepicker" data-test="overlap-alert-calendar-input">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar h-100" (click)="d.toggle()" type="button" data-test="overlap-alert-calendar-button"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 pl-fix">
                    <ngb-timepicker appTimepickerFocus name="newEtdTime" formControlName="newEtdTime" [spinners]="false" data-test="overlap-alert-new-etd-picker"></ngb-timepicker>
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="row mt-6">
                  <div class="col-5 form-field-header">
                    Delay Code*
                  </div>
                  <div class="col-4">
                    <div class="mb-3">
                      <div class="mb-3">
                        <ng-select  id="etd-delay-code" formControlName="delayCode" data-test="overlap-alert-delay-code-select">
                          <ng-option *ngFor="let delayCode of delayCodes" [value]="delayCode.id">{{delayCode.code}} {{ delayCode.description}}</ng-option>
                          <ng-template ng-option-tmp let-item="item">
                            <span class="ng-option-label" [attr.data-test]="'delay-code-' + item">{{ delayCodesKV?.[item]?.code }} {{ delayCodesKV?.[item]?.description }}</span>
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 pl-fix">
                    <div class="mb-3">
                      <input type="text" class="form-control" id="arrival-flight-passenger-gate" placeholder="" [value]="delayInMinutes | timeFromMinutes" disabled data-test="overlap-alert-delay-minutes-input">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()" data-test="overlap-alert-close-button">Close</button>
  <button type="button" class="btn button-blue settings-modal-button" (click)="save()" [disabled]="!form || form?.invalid || isBusy" data-test="overlap-alert-save-button">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
