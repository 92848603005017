import { Pipe, PipeTransform } from '@angular/core';
import { ISeatingConfigurationModel } from '../models/seating-configuration.model';
import { IGenericContainerObject } from '../models/genericContainerObject.model';

@Pipe({
  name: 'seatingConfigurationsForAcTypesTestTag'
})
export class SeatingConfigurationsForAcTypesTestTagPipe implements PipeTransform {

  transform(seats: IGenericContainerObject<ISeatingConfigurationModel[]>, ...args: number[]): string {
    if (!seats || !Object.keys(seats)?.length || !args?.length || !seats[args?.[0]]) {
      return 'invalid-value';
    }
    const order: number = args[0] as number;
    const seatArr: string[] = [];
    for(let seat of seats?.[order]) {
      seatArr.push(seat.code + seat.description);
    }
    return `seating-configuration-${order}-${seatArr.join('_')}`;
  }


}
