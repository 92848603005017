<div *ngIf="isActive" class="bg-white rounded-lg legDetailOuter light-blue-border">
  <div class="flight-info-container border-bottom-gray padding-bottom-15">
    <div class="from-flight-container border-right-gray">
      <app-pair-detail-leg [isArrivalLeg]="true"
                           [leg]="pairDetail?.arrivalLeg"
                           [passengerClasses]="passengerClasses"
                           [timezone]="timezone"
                           [pairDetail]="pairDetail"
      ></app-pair-detail-leg>
    </div>
    <div class="to-flight-container">
      <!-- TODO add pairlegtimes log data-->
      <app-pair-detail-leg [isArrivalLeg]="false"
                           [leg]="pairDetail?.departureLeg"
                           [passengerClasses]="passengerClasses"
                           [timezone]="timezone"
                           [pairDetail]="pairDetail"
      ></app-pair-detail-leg>
    </div>
  </div>
  <div class="processes-container border-bottom-gray margin-top-15 padding-bottom-15"
       (click)="$event.stopPropagation()">
    <div class="container-fluid ps-0">
      <div class="row mb-2">
        <div class="col-12 font-20">
          <span>Turnaround Processes</span>
          <button *ngIf="pairDetail?.turnaround?.currentState === turnaroundStates.FINISHED"
                  class="btn button-blue action-button text-white float-end"
                  (click)="openResumeTurnaroundConfirmationModal()" data-test="leg-resume-turnaround-button">Resume
            Turnaround
          </button>
        </div>
        <div
          *ngIf="pairDetail?.turnaround && pairDetail?.turnaround?.lastExtendEtdRequest && (!pairDetail.turnaround?.lastExtendEtdRequest?.answerUser)"
          class="col-12">
          <app-issue-etd-request [timezone]="timezone" [extendEtdRequest]="pairDetail?.turnaround?.lastExtendEtdRequest"
                                 [pair]="this.pairDetail" [refreshSql]="refreshSql" [pairOverview]="this.pairOverview"
                                 data-test="etd-request"></app-issue-etd-request>
        </div>
      </div>
      <div class="row ps-3 mb-3">
        <div class="flex gap-5">
          <div class="flex-grow-1 flex flex-column justify-content-evenly ta-boarding-container">
            <div class="flex flex-column">
              <div class="flex justify-content-between">
                <div class="ps-3 font-14" data-test="turnaround-status-text">
                  <span
                    *ngIf="!pairDetail?.turnaround?.currentState || pairDetail?.turnaround?.currentState === turnaroundStates.UPCOMING"
                    class="ps-3">Turnaround has not started yet</span>
                  <span
                    *ngIf="(pairDetail?.turnaround?.currentState  === turnaroundStates.PAUSED) && remainingTimeInMinutes >= 0"
                    class="ps-3">Turnaround is paused currently: {{ remainingTimeInMinutes | timeFromMinutes }}
                    remaining to TOBT/ETD/STD</span>
                  <span
                    *ngIf="(pairDetail?.turnaround?.currentState  === turnaroundStates.PAUSED) && remainingTimeInMinutes < 0 "
                    class="ps-3">Turnaround is paused currently: {{ remainingTimeInMinutes | timeFromMinutes }} and is overdue by {{ (remainingTimeInMinutes | timeFromMinutes).replace('-', '') }}
                    minutes</span>
                  <span
                    *ngIf="(pairDetail?.turnaround?.currentState  === turnaroundStates.RESUMED || pairDetail?.turnaround?.currentState  === turnaroundStates.IN_PROGRESS) && remainingTimeInMinutes >= 0"
                    class="ps-3">Turnaround started at {{ pairDetail.turnaround.lastStartedAt | customDate:'HH:mm':timezone }}{{ (timezone === 'zulu' || timezone === '+0000') ? 'z' : 'L' }}
                    and currently {{ remainingTimeInMinutes | timeFromMinutes }}
                    remaining to TOBT/ETD/STD</span>
                  <span
                    *ngIf="(pairDetail?.turnaround?.currentState  === turnaroundStates.RESUMED || pairDetail?.turnaround?.currentState  === turnaroundStates.IN_PROGRESS) && remainingTimeInMinutes < 0"
                    class="ps-3">Turnaround started at {{ pairDetail.turnaround.lastStartedAt | customDate:'HH:mm':timezone }}{{ (timezone === 'zulu' || timezone === '+0000') ? 'z' : 'L' }}
                    and is overdue by {{ (remainingTimeInMinutes | timeFromMinutes).replace('-', '') }} minutes</span>
                  <span *ngIf="pairDetail?.turnaround?.currentState  === turnaroundStates.FINISHED" class="ps-3">Turnaround has finished</span>
                  <!-- <span *ngIf="!turnaroundProgress || '-' === turnaroundProgress.text" class="ps-3">Tunaround has not started yet 17:59 remaining to TOBT/ETD/STD</span>
                  <span *ngIf="'-' !== turnaroundProgress.text" class="ps-3">{{turnaroundProgress?.text}}</span> -->
                </div>
                <div class="pe-3 text-end font-14" data-test="turnaround-total-time">
                  <span class="pe-3" *ngIf="groundTime">{{ groundTime | timeFromMinutes }} in total</span>
                  <span class="pe-3" *ngIf="!groundTime">--:-- in total</span>
                  <!-- <span class="pe-3" *ngIf="!turnaroundProgress || '-' === turnaroundProgress.total">--:-- in total</span> -->
                </div>
              </div>

              <div class="col-12 mt-1">
                <ngb-progressbar
                  [value]="turnaroundProgressPercentage"
                  [striped]="true"
                  [type]="turnaroundProgressBarColor"
                ></ngb-progressbar>
              </div>
            </div>

            <app-boarding-process></app-boarding-process>

          </div>
          <div class="camera-feed" *ngIf="shouldShowCamera">
            <app-camera-feed></app-camera-feed>
          </div>
        </div>
      </div>

      <!--
      <div *ngIf="flightpairDetail?.vie?.processes" class="row ps-3">
        <div class="col-4" *ngFor="let process of flightpairDetail?.vie?.processes | keyvalue">
          <app-ta-process [done]="process.value?.finished" [name]="process.value?.name | titlecase" [isSla]="process.value?.isSla" [starttime]="process.value?.starttime | date:'HH:mm'" [endtime]="process?.endtime | date:'HH:mm'"></app-ta-process>
        </div>
      </div>
      <div *ngIf="!flightPair || flightpairDetail?.vie" class="row ps-3">
        <div class="col-4">
          <app-ta-process [done]="true" name="De-boarding" [isSla]="true" starttime="12:07" endtime="12:17"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="true" name="Refueling" [isSla]="true" starttime="12:10" endtime="12:25"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="true" name="De-icing" starttime="12:10" endtime="12:30"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="true" name="Catering" [isSla]="true" starttime="12:10" endtime="12:40"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="true" name="Airconditioning" starttime="12:10" endtime="12:40"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="true" name="Towing" [isSla]="true" starttime="12:10" endtime="12:40"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Cleaning" [isSla]="true" starttime="12:17" endtime="12:45"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Air Starting" starttime="12:30" endtime="13:00"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Lavatory Service" [isSla]="true" starttime="12:30" endtime="13:10"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Powering" [isSla]="true" starttime="12:30" endtime="13:10"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Engine Checking" [isSla]="true" starttime="12:45" endtime="12:40"></app-ta-process>
        </div>
        <div class="col-4">
          <app-ta-process [done]="false" name="Guiding" [isSla]="true" starttime="12:45" endtime="12:40"></app-ta-process>
        </div>
      </div> -->
      <div *ngIf="pairDetail" class="row ps-3" data-test="processes">
        <div *ngIf="!pairDetail?.processes || (pairDetail?.processes)?.length === 0" class="col-12 text-center">No
          processes are
          added yet!
        </div>
        <ng-container *ngIf="pairDetail?.processes?.length > 0">
          <div *ngFor="let process of pairDetail?.processes" class="col-4"
               [attr.data-test]="'process-' + process.title">
            <app-ta-process [done]="true" [name]="process.title" [startTime]="process.startTime"
                            [endTime]="process.endTime" [maxProcessMinutes]="process.maxProcessTimeInMinutes"
                            [isSla]="process.isSla"></app-ta-process>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="equipments-container border-bottom-gray margin-top-15 padding-bottom-15">
    <div class="equipments-headline">Turnaround Equipment</div>
    <!-- <div *ngIf="flightpairDetail?.vie?.processes" class="equipments-list-container px-3">
      <ng-container *ngFor="let process of flightpairDetail?.vie?.processes | keyvalue">
        <app-equipment-icon [equipment]="process?.value?.iconId | uppercase"></app-equipment-icon>
      </ng-container>
    </div>
    <div *ngIf="!flightPair || !flightpairDetail?.vie?.processes" class="equipments-list-container px-3">
      <ng-container *ngFor="let equipment of equipmentList">
        <app-equipment-icon [equipment]="equipment"></app-equipment-icon>
      </ng-container>
    </div> -->
    <!-- ORIGINAL -->
    <!-- <div *ngIf="cleanedGses && cleanedGses.length > 0" class="equipments-list-container px3">
      <ng-container *ngFor="let equipment of cleanedGses">
        <app-equipment-icon [equipment]="definedGses[equipment?.gseType]"
                            [amount]="equipment?.amount"></app-equipment-icon>
      </ng-container>
    </div> -->
    <div *ngIf="pairDetail?.processes" class="equipments-list-container px3" data-test="processes-equipment-list">
      <ng-container *ngFor="let process of pairDetail?.processes">
        <app-equipment-icon *ngIf="process.gseType?.length && process.gseType !== 'None'" [equipment]="process.gseType"
                            [amount]="process.amount"></app-equipment-icon>
      </ng-container>
    </div>
    <div *ngIf="!pairDetail?.processes || pairDetail?.processes?.length === 0"
         class="equipments-list-container px3 justify-content-center">
      No GSEs are used yet!
    </div>
  </div>
  <div class="equipments-container margin-top-15 padding-bottom-15">
    <div class="action-button-container">
      <button class="btn button-blue mx-1 action-button text-white" (click)="openMemo()" data-test="leg-memo-button"
              [disabled]="!canEnterMemos">
        Memo
      </button>
      <button class="btn button-blue mx-1 action-button text-white"
              [disabled]="!pairDetail?.departureLeg?.id && !pairDetail?.arrivalLeg?.id || !canEnterChangeFlightDetails"
              (click)="openChangeFlightDetails()" data-test="leg-change-flight-details-button">Change Flight
        Details
      </button>
      <button class="btn button-blue mx-1 action-button text-white" (click)="openIssueETD()"
              [disabled]="!pairDetail?.departureLeg?.id || pairDetail?.departureLeg?.atd || !issueEtdPermission"
              data-test="leg-open-issue-etd-button">Issue ETD
      </button>
      <button class="btn button-blue mx-1 action-button text-white" (click)="openIssueNI()"
              [disabled]="!pairDetail?.departureLeg?.id || pairDetail?.departureLeg?.atd || !issueNiPermission"
              data-test="leg-open-ni-button">Issue New Information
      </button>
      <button class="btn mx-1 action-button text-white"
              [ngClass]="{ 'btn-success': !!userAssigned, 'button-blue' : !userAssigned}"
              (click)="openAssignRampAgent()"
              [disabled]="!pairDetail?.arrivalLeg?.id || !assignRampAgentPermission || assignRampAgentPermission !== 'RW'"
              data-test="leg-assign-ramp-agent-button">{{ assignRampAgentPermission ? userAssigned ? userAssigned.firstname + ' ' + userAssigned.lastname : 'Assign Ramp Agent' : '-' }}
      </button>
    </div>
  </div>
</div>
