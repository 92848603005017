import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AirportsService} from '../../../../../services/airports.service';
import {ToastService} from '../../../../../services/toast.service';
import {mergeMap, take, takeUntil} from 'rxjs/operators';
import {NgbDate, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IAirport} from '../../../../../shared/models/airport.model';
import {IGenericContainerObject} from '../../../../../shared/models/genericContainerObject.model';
import {TurnaroundType} from '../../../../../shared/constants/turnaround-type.constants';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  map,
  Observable,
  of,
  OperatorFunction,
  Subject
} from 'rxjs';
import {TitleCasePipe} from '@angular/common';
import {
  SettingsAirportMainFormBuilder
} from '../../../../../shared/forms/formBuilders/settings/settings-airport-main-form-builder';
import {
  AddEditAirportTimezoneDialogComponent
} from '../add-edit-airport-timezone-dialog/add-edit-airport-timezone-dialog.component';
import {
  AddEditAirportSlotTimeToleranceDialogComponent
} from '../add-edit-airport-slot-time-tolerance-dialog/add-edit-airport-slot-time-tolerance-dialog.component';
import {
  AddEditAirportTurnaroundTimesDialogComponent
} from '../add-edit-airport-turnaround-times-dialog/add-edit-airport-turnaround-times-dialog.component';
import {IGeneralSettingsModel} from '../../../../../shared/models/general-settings.model';
import {IMasterAirportsModel} from '../../../../../shared/models/global-airport.model';
import {IMasterCountriesModel} from '../../../../../shared/models/master-countries.model';
import {CountriesService} from '../../../../../services/countries.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IAirportTerminals} from '../../../../../shared/models/airport-terminals.model';
import {IAirportContact} from '../../../../../shared/models/airport-contact.model';
import {ISlotTimeTolerance} from '../../../../../shared/models/slotTimeTolerance.model';
import {
  AddEditAirportTerminalDialogComponent
} from '../add-edit-airport-terminal-dialog/add-edit-airport-terminal-dialog.component';
import {
  AddEditAirportContactComponent
} from '../add-edit-airport-contact-dialog/add-edit-airport-contact-dialog.component';
import {ITimezone} from '../../../../../shared/models/timezone.model';
import {MinutesFromTimePipe} from '../../../../../shared/pipes/minutes-from-time.pipe';
import {TimeFromMinutesPipe} from '../../../../../shared/pipes/time-from-minutes.pipe';
import {ISimpleData} from '../../../../../shared/models/simpleData.model';
import {GeneralSettingsService} from '../../../../../services/general-settings.service';
import {
  CurfewWeekdayIdToName,
  IAirportCurfewTableData,
  IAirportCurfewTime
} from '../../../../../shared/models/airport-curfew-time.model';
import {WeekdaysService} from '../../../../../services/weekdays-service';
import {IAcType} from '../../../../../shared/models/ac-type.model';
import {FlightType} from '../../../../../shared/constants/flight-types.constants';
import {AcTypesService} from '../../../../../services/ac-types.service';
import {ITurnaroundTimes} from '../../../../../shared/models/turnaround-times.model';
import {DeleteModalComponent} from '../../../../../shared/components/delete-modal/delete-modal.component';
import {IGlobalCountry} from '../../../../../shared/models/global-country.model';
import {AirportTerminalsTestTagPipe} from '../../../../../shared/pipes/airport-terminals-test-tag.pipe';
import {AirportContactsTestTagPipe} from '../../../../../shared/pipes/airport-contacts-test-tag.pipe';
import dayjs from "dayjs";
import {
  AddEditAirportCurfewTimeDialogComponent
} from "../add-edit-airport-curfew-time-dialog/add-edit-airport-curfew-time-dialog.component";
import {getCurrentTimezoneOffset} from "../../../../../shared/utils/timezone-functions";
import {PermissionService} from "../../../../../services/permission.service";
import {Access, PermissionUIMasks} from "../../../../../shared/constants/enums";

@Component({
  selector: 'app-add-edit-airport-dialog',
  templateUrl: './add-edit-airport-dialog.component.html',
  styleUrls: ['./add-edit-airport-dialog.component.scss']
})
export class AddEditAirportDialogComponent implements OnInit {

  title = "Edit Airport";
  @Input() generalSettings?: IGeneralSettingsModel;
  @Input() outerSaveClicked?: Observable<any>;
  @Output() statusChanged = new EventEmitter();
  @Output() saveClicked = new EventEmitter();
  airport: IAirport;
  isBusy = false;
  loaded = true;

  masterAirportsKV?: IGenericContainerObject<IMasterAirportsModel>;
  masterCountriesKV?: IGenericContainerObject<IMasterCountriesModel>;
  flightServiceTypes: ISimpleData[];
  destroySubject = new Subject();
  turnaroundType?: TurnaroundType;
  formGroup?: UntypedFormGroup;
  timezoneFormGroup?: UntypedFormGroup;
  slotTimeToleranceFormGroup?: UntypedFormGroup;
  curfewTimesFormGroup?: UntypedFormGroup;
  airportId: number;
  airportTerminals: IAirportTerminals[];
  airportContacts: IAirportContact[];
  airportSlotTolerance: ISlotTimeTolerance;
  airportCurfewTimes: IAirportCurfewTime[];
  curfewTimeData: IAirportCurfewTime;
  timezoneData?: ITimezone;
  mon = false;
  tue = false;
  wed = false;
  thu = false;
  fri = false;
  sat = false;
  sun = false;
  from: any;
  to: any;
  hoveredDate: NgbDate;
  drp = false;

  fromDate: NgbDate;
  toDate: NgbDate;
  cameraFeed: boolean;

  acTypes: IAcType[];
  activeAcTypeId: number = 0;
  // AC Type ID -> Flight Service Type From -> Flight Service Type To -> Time in minutes
  DOMDOM: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};
  DOMINT: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};
  INTINT: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};
  INTDOM: IGenericContainerObject<IGenericContainerObject<IGenericContainerObject<ITurnaroundTimes>>> = {};

  selectedAcType: IAcType;
  turnaroundTypes = TurnaroundType;
  turnaroundTimes: ITurnaroundTimes[];
  countryList: IGlobalCountry[] = [];
  airportsWithCamera: string[];
  pageAccess: Access;

  constructor(private acTypeService: AcTypesService, private generalSettingsService: GeneralSettingsService, private weekdayService: WeekdaysService, private countriesService: CountriesService, private modalService: NgbModal, private titleCasePipe: TitleCasePipe, private fb: UntypedFormBuilder, private airportService: AirportsService, private toastService: ToastService, private route: ActivatedRoute, private router: Router, private cd: ChangeDetectorRef, private minsFromTimePipe: MinutesFromTimePipe, private timeFromMinutesPipe: TimeFromMinutesPipe, private permissionService: PermissionService) {
    this.airportId = Number((this.route.params as BehaviorSubject<any>).value.id) || null;
    this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_SETTINGS_AIRPORT_MANAGEMENT, this.airportId).then((pageAccess) => {
      this.pageAccess = pageAccess;
      if (!this.pageAccess) {
        return;
      }
      const airportsWithCam = localStorage.getItem('cameras');
      const observables: Observable<any>[] = [
        this.generalSettingsService.getFlightServiceTypes(),
        this.acTypeService.fetchAcTypes(),
        this.countriesService.fetchCountries(),
      ];
      if (this.airportId) {
        observables.push(this.airportService.fetchAirports({
          id: this.airportId
        }));
        observables.push(this.airportService.fetchAirportTerminals(this.airportId));
        observables.push(this.airportService.fetchAirportContactCompanies(this.airportId));
        observables.push(this.airportService.fetchAirportSlotTimeTolerance(this.airportId));
        observables.push(this.airportService.fetchAirportCurfewTimes(this.airportId));
        observables.push(this.airportService.fetchAirportTimezones(this.airportId));
      }
      forkJoin(observables).subscribe((results) => {
        this.flightServiceTypes = results[0];
        this.acTypes = results[1];
        this.countryList = results[2];
        this.createTimezoneForm();
        if (this.airportId) {
          this.airport = results[3]?.[0];
          if (!this.airport) {
            this.router.navigate(['..'], {relativeTo: this.route});
            return;
          }
          this.airportTerminals = results[4];
          this.airportContacts = results[5];
          this.airportSlotTolerance = results[6]?.[0];
          this.timezoneData = results[8]?.[0];
          this.patchTimezoneForm(this.timezoneData);
          this.onCurfewTimesFetched(results[7]);
        }
        if (this.acTypes.length) {
          this.selectedAcType = this.acTypes[0];
          this.selectedAcTypeChanged();
        }

        const depEarlyMinutes = this.timeFromMinutesPipe.transform(this.airportSlotTolerance?.departureEarlyInMinutes || 0);
        const depLateMinutes = this.timeFromMinutesPipe.transform(this.airportSlotTolerance?.departureLateInMinutes || 0);
        const arrEarlyMinutes = this.timeFromMinutesPipe.transform(this.airportSlotTolerance?.arrivalEarlyInMinutes || 0);
        const arrLateMinutes = this.timeFromMinutesPipe.transform(this.airportSlotTolerance?.arrivalLateInMinutes || 0);
        this.slotTimeToleranceFormGroup = new UntypedFormGroup({
          departureEarlyInMinutes: new UntypedFormControl(depEarlyMinutes, Validators.required),
          departureLateInMinutes: new UntypedFormControl(depLateMinutes, Validators.required),
          arrivalEarlyInMinutes: new UntypedFormControl(arrEarlyMinutes, Validators.required),
          arrivalLateInMinutes: new UntypedFormControl(arrLateMinutes, Validators.required),
        });
        if (this.pageAccess !== Access.RW) {
          this.slotTimeToleranceFormGroup.disable();
        }

        this.buildTable();

        if (this.airport) {
          this.patchForm(this.fb, this.airport);
          this.formGroup?.patchValue(this.airport);
        }

        this.handleStatusChanged();
        if (airportsWithCam?.length) {
          this.airportsWithCamera = JSON.parse(airportsWithCam);
          if (this.airportsWithCamera?.includes(this.airport?.iata)) {
            this.cameraFeed = true;
          }
        } else {
          this.airportsWithCamera = [];
        }

        if (this.pageAccess !== Access.RW) {
          this.formGroup.disable();
        }
      });
    });
  }

  get curfewFormArray(): UntypedFormArray {
    return this.curfewTimesFormGroup?.get('data') as UntypedFormArray;
  }

  buildTable() {
    for (const acType of this.acTypes) {
      this.createTurnaroundTimesMapForAcType(acType.id);
    }
    this.getMinimumTurnaroundTimes();
  }

  selectedAcTypeChanged() {
    this.activeAcTypeId = this.selectedAcType?.id;
  }

  ngOnInit() {
    this.fetchMasterAirports();
    this.fetchMasterCountries();
    this.createForm(this.fb);

  }

  fetchMasterAirports() {
    this.airportService.fetchMasterAirports().subscribe((result) => {
      this.masterAirportsKV = {};
      for (const airport of result) {
        this.masterAirportsKV[airport.iata] = airport;
      }
    });
  }

  fetchMasterCountries() {
    this.countriesService.fetchCountries().subscribe((result) => {
      this.masterCountriesKV = {};
      for (const country of result) {
        this.masterCountriesKV[country.icao] = country;
      }
    });
  }

  createForm(fb: UntypedFormBuilder) {
    this.formGroup = SettingsAirportMainFormBuilder.constructForm(fb);
  }

  patchForm(fb: UntypedFormBuilder, airport: IAirport) {
    console.log('patch form');
    if (!airport) {
      console.log('patch form value is empty');

      return;
    }

    if (airport.terminals && airport.terminals.length > 0) {
      console.log('terminals found');
      const terminalsFormGroup = new UntypedFormGroup({});

      // Object.keys(airport.terminals).forEach((item: string) => {
      //   terminalsFormGroup.addControl(item, fb.group({
      //     code: [''],
      //     description: ['']
      //   }));
      // });
      for (const terminal of airport.terminals) {
        terminalsFormGroup.addControl(String(terminal.id), fb.group({
          name: terminal.name,
          alias: terminal.alias
        }));
      }

      this.formGroup?.setControl('terminals', terminalsFormGroup);
    }

    if (airport.curfewTimes && Object.keys(airport.curfewTimes).length > 0) {
      const curfewTimesFormGroup = new UntypedFormGroup({});

      Object.keys(airport.curfewTimes).forEach((item: string) => {
        curfewTimesFormGroup.addControl(item, fb.group({
          id: [''],
          dateFrom: [''],
          dateTo: [''],
          timeFrom: [''],
          timeTo: [''],
          isUtc: [false],
          mon: [false],
          tue: [false],
          wed: [false],
          thu: [false],
          fri: [false],
          sat: [false],
          sun: [false]
        }));
      });

      this.formGroup?.setControl('curfewTimes', curfewTimesFormGroup);
    }

    if (airport.contacts && Object.keys(airport.contacts).length > 0) {
      const contactsFormGroup = new UntypedFormGroup({});

      Object.keys(airport.contacts).forEach((item: string) => {
        contactsFormGroup.addControl(item, fb.group({
          id: [''],
          firstname: ['', Validators.required],
          lastname: ['', Validators.required],
          email: ['', Validators.required],
          phone: [''],
          mobile: [''],
          vhf: [''],
          company: ['', Validators.required],
          department: ['', Validators.required],
          remarks: [''],
          sita1: ['', Validators.pattern('[A-Za-z0-9]{7}')],
          sita2: ['', Validators.pattern('[A-Za-z0-9]{7}')]
        }));
      });

      this.formGroup?.setControl('contacts', contactsFormGroup);
    }
  }

  handleStatusChanged() {
    this.statusChanged.emit(this.formGroup?.status);
    this.formGroup?.statusChanges.pipe(takeUntil(this.destroySubject)).subscribe(item => this.statusChanged.emit(item));
  }

  ngOnDestroy(): void {
    this.destroySubject.next(null);
    this.destroySubject.complete();
  }

  openManageAirportContacts(id?: number) {
    const modalRef = this.modalService.open(AddEditAirportContactComponent, {size: 'xl' as 'lg'});
    const modal = modalRef.componentInstance as AddEditAirportContactComponent;
    modal.title = id ? 'Edit Airport Contact' : 'Add Airport Contact';
    modal.contactData = this.airportContacts.find((item) => item.id === id);
    modal.airportId = this.airportId;
    if (this.pageAccess !== Access.RW) {
      modal.readonly = true;
    }
    modalRef.dismissed.pipe(take(1)).subscribe((reason) => {
      if (reason) {
        this.airportService.fetchAirportContactCompanies(this.airportId).subscribe((result) => {
          this.airportContacts = result;
        });
      }
    });
  }

  openManageAirportTerminals(id?: number) {
    const modalRef = this.modalService.open(AddEditAirportTerminalDialogComponent, {size: 'xl' as 'lg'});
    const modal = modalRef.componentInstance as AddEditAirportTerminalDialogComponent;
    modal.title = id ? 'Edit Airport Terminal' : 'Add Airport Terminal';
    modal.terminalData = this.airportTerminals.find((item) => item.id === id) || {
      airportId: this.airportId,
      validFrom: null,
      validTo: null
    };
    modalRef.dismissed.pipe(take(1)).subscribe((reason) => {
      if (reason) {
        this.airportService.fetchAirportTerminals(this.airportId).subscribe((result) => {
          this.airportTerminals = result;
        });
      }
    });
    //this.router.navigate(['terminal', id ? String(id) : 'add'], {relativeTo: this.route });
  }

  openManageAirportTimezone() {
    // TODO: Check if there is a country or airport id, otherwise should not open
    const modalRef = this.modalService.open(AddEditAirportTimezoneDialogComponent, {size: 'xl' as 'lg'});
    const modal: AddEditAirportTimezoneDialogComponent = modalRef.componentInstance;
    modalRef.componentInstance.title = 'Edit Airport Timezone';
    modalRef.componentInstance.readonly = false;
    if (this.airport?.id) {
      modal.airportId = this.airport.id;
    } else if (this.masterCountriesKV[this.masterAirportsKV[this.formGroup.value.iata]?.countryIcao]) {
      modal.masterCountryId = this.masterCountriesKV[this.masterAirportsKV[this.formGroup.value.iata].countryIcao].id;
    }
  }

  openEditSlotTimeTolerance() {
    const modalRef = this.modalService.open(AddEditAirportSlotTimeToleranceDialogComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.title = 'Edit Slot Time Tolerance';
    modalRef.componentInstance.airportId = this.airport?.id;
  }

  openEditMinimumTurnaroundTimes() {
    if (!this.airport) {
      return;
    }
    const modalRef = this.modalService.open(AddEditAirportTurnaroundTimesDialogComponent, {size: 'xxl' as 'lg'});
    modalRef.componentInstance.title = 'Edit ' + this.titleCasePipe.transform('' + this.generalSettings.turnaroundTimeType) + ' Turnaround Times';
    modalRef.componentInstance.airportId = this.airport?.id;
  }

  onSaveClick() {
    const obs: Observable<any>[] = [];
    this.airportService.saveAirport({
      ...this.formGroup.value,
      minTurnaroundTimeInMinutes: 0,
      id: this.airport?.id
    }).pipe((mergeMap(result => {
      if (!result.id) {
        return of(null);
      }
      if (this.cameraFeed) {
        if (!this.airportsWithCamera?.includes(this.airport?.iata)) {
          this.airportsWithCamera.push(this.airport?.iata);
          localStorage.setItem('cameras', JSON.stringify(this.airportsWithCamera));
        }
      } else {
        if (this.airportsWithCamera?.includes(this.airport?.iata)) {
          const index = this.airportsWithCamera.indexOf(this.airport?.iata);
          this.airportsWithCamera.splice(index, 1);
          localStorage.setItem('cameras', JSON.stringify(this.airportsWithCamera));
        }
      }
      this.airport = result;
      if (this.timezoneFormGroup.valid) {
        obs.push(this.saveTimezone());
      }
      if (this.slotTimeToleranceFormGroup.valid) {
        obs.push(this.saveSlotTimeTolerance());
      }
      if (obs.length) {
        return forkJoin(obs);
      }
    }))).subscribe(() => {
      this.isBusy = false;
      this.toastService.showSuccess('Data have been saved!');
      this.airportService.fetchAirports().subscribe(() => {
      });
    });
  }

  searchMasterAirport: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : Object.keys(this.masterAirportsKV).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  autoFill(iata: string) {
    if (!iata || typeof iata !== 'string') {
      return;
    }
    iata = iata.toUpperCase();
    if (this.masterAirportsKV[iata]) {
      this.formGroup.patchValue({
        country: this.masterAirportsKV[iata].country,
        latitude: this.masterAirportsKV[iata].latitude,
        longitude: this.masterAirportsKV[iata].longitude,
        icao: this.masterAirportsKV[iata].icao,
        title: this.masterAirportsKV[iata].title,
      })
    }
  }

  deleteContact(contactId: number) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    const contact = this.airportContacts.find((terminal) => terminal.id === contactId);
    if (!contact) {
      return;
    }
    modalRef.componentInstance.term = 'contact ' + contact.firstname + ' ' + contact.lastname;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.airportService.deleteAirportContactCompany(contactId).subscribe(() => {
        this.toastService.showSuccess(`Contact ${contact.firstname + ' ' + contact.lastname} has been deleted`);
        this.airportService.fetchAirportContactCompanies(this.airportId).subscribe((result) => {
          this.airportContacts = result;
        });
      });
    });
  }

  deleteTerminal(terminalId: number) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    const terminal = this.airportTerminals.find((terminal) => terminal.id === terminalId);
    if (!terminal) {
      return;
    }
    modalRef.componentInstance.term = 'terminal ' + terminal.name;
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      this.airportService.deleteAirportTerminal(terminalId).subscribe(() => {
        this.toastService.showSuccess(`Terminal ${terminal.name} has been deleted`);
        this.airportService.fetchAirportTerminals(this.airportId).subscribe((result) => {
          this.airportTerminals = result;
        });
      });
    });
  }

  appliesDstChanged(isApplying: boolean) {
    if (isApplying) {
      this.timezoneFormGroup?.get('utcWithDstTime')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstStartMonth')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstStartTime')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstStartWeekday')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstStartWeekOfMonth')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstEndMonth')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstEndTime')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstEndWeekday')?.setValidators(Validators.required);
      this.timezoneFormGroup?.get('dstEndWeekOfMonth')?.setValidators(Validators.required);
      this.cd.detectChanges();
    } else {
      this.timezoneFormGroup?.get('utcWithDstTime')?.clearValidators();
      this.timezoneFormGroup?.get('dstStartMonth')?.clearValidators();
      this.timezoneFormGroup?.get('dstStartTime')?.clearValidators();
      this.timezoneFormGroup?.get('dstStartWeekday')?.clearValidators();
      this.timezoneFormGroup?.get('dstStartWeekOfMonth')?.clearValidators();
      this.timezoneFormGroup?.get('dstEndMonth')?.clearValidators();
      this.timezoneFormGroup?.get('dstEndMonth')?.updateValueAndValidity();
      this.timezoneFormGroup?.get('dstEndTime')?.clearValidators();
      this.timezoneFormGroup?.get('dstEndWeekday')?.clearValidators();
      this.timezoneFormGroup?.get('dstEndWeekOfMonth')?.clearValidators();
      this.cd.detectChanges();
    }
    for (const ctrlName in this.timezoneFormGroup.controls) {
      this.timezoneFormGroup.get(ctrlName).updateValueAndValidity({emitEvent: false, onlySelf: true});
    }
  }

  createTimezoneForm() {
    this.timezoneFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(undefined),
      countryTimezone: new UntypedFormControl(this.airport?.country || '', Validators.required),
      dstApplies: new UntypedFormControl(false, Validators.required),
      timezoneCode: new UntypedFormControl(''),
      utcWithoutDst: new UntypedFormControl('', Validators.required),
      utcWithDst: new UntypedFormControl('', Validators.required),

      dstStartMonth: new UntypedFormControl(''),
      dstStartTime: new UntypedFormControl(''),
      dstStartWeekday: new UntypedFormControl(''),
      dstStartWeekOfMonth: new UntypedFormControl(''),

      dstEndMonth: new UntypedFormControl(''),
      dstEndTime: new UntypedFormControl(''),
      dstEndWeekday: new UntypedFormControl(''),
      dstEndWeekOfMonth: new UntypedFormControl(''),
      utcWithDstOperator: new UntypedFormControl(''),
      utcWithoutDstOperator: new UntypedFormControl(''),
    });
    this.timezoneFormGroup.get('dstApplies').valueChanges.pipe(takeUntil(this.destroySubject)).subscribe((applying) => {
      this.appliesDstChanged(applying);
    });
  }

  patchTimezoneForm(result: ITimezone) {
    let utcWithoutDst = result?.utcWithoutDst?.replace('-', '').padStart(4, '0');
    let utcWithDst = result?.utcWithDst?.replace('-', '').padStart(4, '0');
    let dstStartTime = result?.dstStartTime?.replace('-', '').padStart(4, '0');
    let dstEndTime = result?.dstEndTime?.replace('-', '').padStart(4, '0');
    this.timezoneFormGroup.patchValue({
      id: result?.id,
      countryTimezone: result?.countryTimezone?.length ? result.countryTimezone : '',
      dstApplies: result?.dstApplies === 'YES',
      timezoneCode: result?.timezoneCode,
      utcWithoutDst: utcWithoutDst.substring(0, 2) + ':' + utcWithoutDst.substring(2, 3),
      utcWithDst: utcWithDst.substring(0, 2) + ':' + utcWithDst.substring(2, 3),

      dstStartMonth: result?.dstStartMonth,
      dstStartTime: dstStartTime.substring(0, 2) + ':' + dstStartTime.substring(2, 3),
      dstStartWeekday: result?.dstStartWeekday,
      dstStartWeekOfMonth: result?.dstEndWeekOfMonth,

      dstEndMonth: result?.dstEndMonth,
      dstEndTime: dstEndTime.substring(0, 2) + ':' + dstEndTime.substring(2, 3),
      dstEndWeekday: result?.dstEndWeekday,
      dstEndWeekOfMonth: result?.dstEndWeekOfMonth,
      utcWithDstOperator: result?.utcWithDst.startsWith('-') ? '-' : '',
      utcWithoutDstOperator: result?.utcWithoutDst.startsWith('-') ? '-' : '',
    });
    console.log('TImezone Form: ', this.timezoneFormGroup.value)
    this.timezoneData = result || {};
    if (this.pageAccess !== Access.RW) {
      this.timezoneFormGroup.disable();
    }
  }

  saveTimezone(): Observable<ITimezone> {
    const data = {...this.timezoneFormGroup.value};
    data.utcWithDst = data.utcWithDst.startsWith('0') ? data.utcWithDst.replace('0', '').replace(':', '') : data.utcWithDst.replace(':', '');
    data.utcWithoutDst = data.utcWithoutDst.startsWith('0') ? data.utcWithoutDst.replace('0', '').replace(':', '') : data.utcWithoutDst.replace(':', '');
    data.dstStartTime = data.dstStartTime.startsWith('0') ? data.dstStartTime.replace('0', '').replace(':', '') : data.dstStartTime.replace(':', '');
    data.dstEndTime = data.dstEndTime.startsWith('0') ? data.dstEndTime.replace('0', '').replace(':', '') : data.dstEndTime.replace(':', '');
    if (data.utcWithDstOperator === '-') {
      data.utcWithDst = '-'.concat(data.utcWithDst);
    }
    if (data.utcWithoutDstOperator === '-') {
      //data.utcWithoutDst *= -1;
      data.utcWithoutDst = '-'.concat(data.utcWithoutDst);
    }
    delete data.utcWithDstOperator;
    delete data.utcWithoutDstOperator;
    data.airportId = this.airportId;
    data.dstApplies = data.dstApplies ? 'YES' : 'NO';
    if (!data.id) {
      delete data.id;
    }
    if (!data.timezoneCode) {
      data.timezoneCode = 'UTC';
    }
    this.isBusy = true;
    console.log('Data to be saved', data);
    return this.airportService.saveAirportTimezone(data);
  }

  saveSlotTimeTolerance(): Observable<ISlotTimeTolerance> {
    const data = this.slotTimeToleranceFormGroup.value;
    data.departureLateInMinutes = this.minsFromTimePipe.transform(data?.departureLateInMinutes);
    data.departureEarlyInMinutes = this.minsFromTimePipe.transform(data?.departureEarlyInMinutes);
    data.arrivalLateInMinutes = this.minsFromTimePipe.transform(data?.arrivalLateInMinutes);
    data.arrivalEarlyInMinutes = this.minsFromTimePipe.transform(data?.arrivalEarlyInMinutes);
    data.airportId = this.airport.id;
    data.id = this.airportSlotTolerance?.id;
    if (!data.id) {
      data.id = undefined;
    }
    return this.airportService.saveAirportSlotTimeTolerance(data);
  }

  getDateString(date: NgbDate) {
    if (!date) {
      return 'none';
    }
    return date.day + '.' + date.month + '.' + date.year;
  }

  createTurnaroundTimesMapForAcType(acTypeId: number) {
    for (const fType in FlightType) {
      this[FlightType[fType]][acTypeId] = {};
      for (const flightServiceTypeHeader of this.flightServiceTypes) {
        for (const flightServiceType of this.flightServiceTypes) {
          if (!this[FlightType[fType]][acTypeId][flightServiceTypeHeader.id]) {
            this[FlightType[fType]][acTypeId][flightServiceTypeHeader.id] = {};
          }
          this[FlightType[fType]][acTypeId][flightServiceTypeHeader.id][flightServiceType.id] = 0;
        }
      }
    }
  }

  getMinimumTurnaroundTimes(flightType?: FlightType) {
    this.airportService.fetchTurnaroundTimesByAirport(this.airportId, flightType).subscribe((result) => {
      this.turnaroundTimes = result;
      this.insertTurnaroundDataToObjects();
    });
  }

  insertTurnaroundDataToObjects() {
    for (const turnaroundTime of this.turnaroundTimes) {
      if (this[turnaroundTime.flightType]?.[turnaroundTime.acTypeId]?.[turnaroundTime.flightServiceTypeFrom]?.[turnaroundTime.flightServiceTypeTo] !== undefined) {
        this[turnaroundTime.flightType][turnaroundTime.acTypeId][turnaroundTime.flightServiceTypeFrom][turnaroundTime.flightServiceTypeTo] = turnaroundTime;
      }
    }
  }

  protected readonly FlightType = FlightType;

  editTurnaroundTimes() {
    this.router.navigate(['turnaround', this.activeAcTypeId], {
      relativeTo: this.route,
      state: [this.selectedAcType, this.airport, this.turnaroundTimes]
    })
  }

  searchCountry: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.countryList.map((country) => country.title).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  onCountryBlur() {
    this.formGroup.get('country').setValue(this.formGroup.value.country.toUpperCase());
    const country: IGlobalCountry = this.countryList.find((country) => country.title === this.formGroup.value.country);
    if (!country) {
      this.formGroup.get('country').setErrors({
        error: true,
      });
    } else {
      this.formGroup.patchValue({
        icaoCountry: country.icao,
      });
    }
  }

  public readonly AirportTerminalsTestTagPipe = AirportTerminalsTestTagPipe;
  public readonly AirportContactsTestTagPipe = AirportContactsTestTagPipe;

  manageCurfewTimes(value?: IAirportCurfewTableData) {
    if (this.pageAccess !== Access.RW) {
      return;
    }
    const modalRef = this.modalService.open(AddEditAirportCurfewTimeDialogComponent, {size: 'xl' as 'lg'});
    const modal = modalRef.componentInstance as AddEditAirportCurfewTimeDialogComponent;
    modal.title = `${value ? 'Edit' : 'Add'} Curfew time`;
    modal.airport = this.airport;
    modal.data = value;
    modal.timezone = this.timezoneData;
    const entryIds: number[] = [];
    for (const day of ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']) {
      if (value?.[day]?.id) {
        entryIds.push(value[day].id);
      }
    }
    modal.allCurfewTimes = this.airportCurfewTimes.filter((entry) => !entryIds.includes(entry.id));
    modalRef.dismissed.pipe(take(1)).subscribe((res) => {
      if (res) {
        this.airportService.fetchAirportCurfewTimes(this.airportId).subscribe((res) => {
          this.onCurfewTimesFetched(res);
        });
      }
    });
  }

  deleteCurfewTimes(value: IAirportCurfewTableData) {
    if (this.pageAccess !== Access.RW) {
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent);
    console.log('From', value.from);
    console.log('To', value.to)
    modalRef.componentInstance.term = 'Curfew time period ' + dayjs.utc(value.from, this.generalSettingsService.generalSettings.value.dateFormat).format(this.generalSettingsService.generalSettings.value.dateFormat) + ' - ' + dayjs.utc(value.to, this.generalSettingsService.generalSettings.value.dateFormat).format(this.generalSettingsService.generalSettings.value.dateFormat);
    modalRef.componentInstance.startDeletion.pipe(take(1)).subscribe(() => {
      const observables: Observable<boolean>[] = [];
      for (const day of ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']) {
        if (value[day]?.id) {
          observables.push(this.airportService.deleteAirportCurfewTime(value[day].id));
        }
      }
      forkJoin(observables).subscribe((results) => {
        const fail = results.find((result) => !result);
        if (!fail) {
          this.toastService.showSuccess('The selected curfew times have been deleted');
        }
        this.airportService.fetchAirportCurfewTimes(this.airport?.id).subscribe((results) => {
          this.onCurfewTimesFetched(results);
        })
      });
    });
  }

  private onCurfewTimesFetched(res: IAirportCurfewTime[]) {
    this.airportCurfewTimes = res;
    this.curfewTimesFormGroup = new UntypedFormGroup({});
    if (this.airportCurfewTimes?.length) {
      const offset = getCurrentTimezoneOffset(this.timezoneData);
      for (const airportCurfewTime of this.airportCurfewTimes) {
        airportCurfewTime.curfewStartTime = airportCurfewTime.curfewStartTime.substring(0, 5);
        airportCurfewTime.curfewEndTime = airportCurfewTime.curfewEndTime.substring(0, 5);
        if (!airportCurfewTime.isUtc) {
          airportCurfewTime.curfewStartTimeLocal = dayjs.utc(airportCurfewTime.curfewStartTime, 'HH:mm').utcOffset(airportCurfewTime.isUtc ? 0 : offset).local().format('HH:mm');
          airportCurfewTime.curfewEndTimeLocal = dayjs.utc(airportCurfewTime.curfewEndTime, 'HH:mm').utcOffset(airportCurfewTime.isUtc ? 0 : offset).local().format('HH:mm');
        } else {
          airportCurfewTime.curfewStartTimeLocal = airportCurfewTime.curfewStartTime;
          airportCurfewTime.curfewEndTimeLocal = airportCurfewTime.curfewEndTime
        }
        const dateRange = `${dayjs.utc(airportCurfewTime.validFrom).format('DD/MM/YYYY')}-${airportCurfewTime.validTo ? dayjs.utc(airportCurfewTime.validTo).format('DD/MM/YYYY') : 'null'}`;
        const dateEntryExists = !!this.curfewTimesFormGroup.get(dateRange);
        if (!dateEntryExists) {
          this.curfewTimesFormGroup.addControl(dateRange, new UntypedFormGroup({
            from: new UntypedFormControl(dayjs(airportCurfewTime.validFrom).format('DD/MM/YYYY')),
            to: new UntypedFormControl(airportCurfewTime.validTo ? dayjs.utc(airportCurfewTime.validTo).format('DD/MM/YYYY') : null),
            opening: new UntypedFormControl(),
            closing: new UntypedFormControl(),
            isUtc: new UntypedFormControl(airportCurfewTime.isUtc),
            mon: new UntypedFormControl(),
            tue: new UntypedFormControl(),
            wed: new UntypedFormControl(),
            thu: new UntypedFormControl(),
            fri: new UntypedFormControl(),
            sat: new UntypedFormControl(),
            sun: new UntypedFormControl(),
          }));
        }
        this.curfewTimesFormGroup.get(dateRange)?.get(CurfewWeekdayIdToName[airportCurfewTime.weekday])?.setValue(airportCurfewTime);
        this.curfewTimesFormGroup.get(dateRange)?.patchValue({
          opening: airportCurfewTime.curfewStartTimeLocal,
          closing: airportCurfewTime.curfewEndTimeLocal,
        });
        if (this.pageAccess !== Access.RW) {
          this.curfewTimesFormGroup.disable();
        }
      }
    }
  }

  public readonly Access = Access;
}
