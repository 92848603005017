<div class="modal-header">
  <span class="modal-title">{{ title }}</span>
  <button type="button" class="btn-close " aria-label="Close" (click)="closeModal()"
          data-test="gse-edit-airport-close-button">
  </button>
</div>
<div class="modal-body">
  <div class="flex">
    <div class="flex-basis-30">
      <div class="form-check form-switch">
        <label class="form-check-label dark-blue" for="toggleApplicable">Change all fields "Is Applicable" to No</label>
        <input class="form-check-input" type="checkbox" role="switch" id="toggleApplicable"
               [(ngModel)]="toggleApplicable" (ngModelChange)="toggleApplicableChanged()"
               data-test="gse-edit-airport-toggle-applicable-switch" [disabled]="readonly">
      </div>
    </div>
    <div class="flex-basis-30">
      <div class="form-check form-switch">
        <label class="form-check-label dark-blue" for="toggleHasGse">Change all fields "Has GSE" to No</label>
        <input class="form-check-input" type="checkbox" role="switch" id="toggleHasGse" [(ngModel)]="toggleHasGse"
               (ngModelChange)="toggleHasGseChanged()" data-test="gse-edit-airport-toggle-has-gse-switch"
               [disabled]="readonly">
      </div>
    </div>
    <div class="flex-basis-30">
      <div class="form-check form-switch">
        <label class="form-check-label dark-blue" for="toggleSla">Change all fields "SLA / Non-SLA" to Non-SLA</label>
        <input class="form-check-input" type="checkbox" role="switch" id="toggleSla" [(ngModel)]="toggleSla"
               (ngModelChange)="toggleSlaChanged()" data-test="gse-edit-airport-toggle-sla-switch"
               [disabled]="readonly">
      </div>
    </div>
  </div>
  <ng-container *ngIf="formGroup">
    <table class="table table-striped turnaround-table dark-blue skip-invalid-border mt-4" [formGroup]="formGroup">
      <thead>
      <tr>
        <th scope="col">A/C Type</th>
        <th scope="col">Is Applicable</th>
        <th scope="col">SLA / Non-SLA</th>
        <th scope="col">Time (min)</th>
        <th scope="col">GSE Required</th>
        <th scope="col">GSE Type</th>
        <th scope="col">Icon</th>
      </tr>
      </thead>
      <tbody class="skip-invalid-border" formArrayName="data">
      <tr *ngFor="let row of tableDataGroups; let i = index" [formGroupName]="i">
        <td>{{ row.getRawValue().acType.iata }}</td>
        <td>
          <select class="form-control form-select light-blue-border" formControlName="isApplicable"
                  data-test="gse-edit-airport-is-applicable-select">
            <option [ngValue]="true">Yes</option>
            <option [ngValue]="false">No</option>
          </select>
        </td>
        <td>
          <select class="form-control form-select light-blue-border" formControlName="isSla"
                  data-test="gse-edit-airport-is-sla-select">
            <option [ngValue]="true">SLA</option>
            <option [ngValue]="false">Non-SLA</option>
          </select>
        </td>
        <td>
          <input class="form-control light-blue-border" type="number" formControlName="time"
                 data-test="gse-edit-airport-time-input">
        </td>
        <td>
          <select class="form-control form-select light-blue-border" formControlName="gseRequired"
                  data-test="gse-edit-airport-gse-required-select">
            <option [ngValue]="true">Yes</option>
            <option [ngValue]="false">No</option>
          </select>
        </td>
        <td>
          <select class="form-control form-select light-blue-border" formControlName="gseType"
                  data-test="gse-edit-airport-gse-type-select">
            <option value="">-</option>
            <option *ngFor="let gse of definedGses | keyvalue" [value]="gse?.value.equipmentIdentifier"
                    [attr.data-test]="'gse-' + gse.value?.title">{{ gse?.value?.title }}
            </option>
          </select>
        </td>
        <td>
          <ng-container *ngIf="row.getRawValue().gseRequired && row.getRawValue().gseType">
            <i
              [class]="'demo-icon vertical-middle align-self-center ' + getIconClass(row.value.gseType) + ' fs-30'"></i>
          </ng-container>
        </td>
      </tr>
      </tbody>
    </table>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary settings-modal-button" (click)="closeModal()"
          data-test="gse-edit-airport-cancel-button">Cancel
  </button>
  <button type="button" class="btn button-blue settings-modal-button" ngbAutofocus (click)="onSaveClick()"
          [disabled]="formGroup?.disabled || formGroup?.invalid || isBusy" data-test="gse-edit-airport-save-button">
    {{ isBusy ? '' : 'Save' }}
    <app-loading-spinner *ngIf="isBusy"></app-loading-spinner>
  </button>
</div>
